import {v4} from 'uuid'
import {action, makeObservable, observable, runInAction } from 'mobx'
import { getColor } from '../../../colors'
import { TimeSeries } from './TimeSeries'
import { DimensionState, OstDimension, OstResult } from '../results'
import { RateSchedule } from './Schedule'
import { MultiYearVar } from './MultiYearVar'
import { SystemSizerLimits } from '../../../services/inputs'
import { EnergyMarket } from './EnergyMarket'
import { Project } from '../model'
import api, { StatusResponse, SystemSizerTableResponse } from '../../../services/api'


export type ContractEnergyPriceKind = 'schedule' | 'import'
export type ContractObligationKind = 'daily' | 'import' | 'none'
export type ContractResultState = 'loading' | 'calculating' | 'error' | 'ready' | 'cancelled' | 'calculated' | 'blank'
export type ShortagePenaltyKind = 'flat' | 'market' | 'delta' | 'none'

// rate color selection from palette
let nextRateColorIndex = 0
let useSolar = false
let useWind = false
let useStorage = false

export function getNextRateColor() {
    const rv = getColor(nextRateColorIndex)
    nextRateColorIndex += 2
    return rv
}

export type DayKind = 'all' | 'weekday' | 'weekend'

const defaultTable: SystemSizerResultTable = {
    header: {
        optimization: [],
    },
    items: [],
}
export class ContractObligationRequirement {
    id: string = v4()
    constructor(init: Partial<ContractObligationRequirement> = {}) {
        Object.assign(this, init)
        makeObservable(this)
    }

    @observable dayKind: DayKind = 'all'
    @observable startDate: Date = new Date()
    @observable endDate: Date = new Date()
    @observable startHour: number = 0 // 0..23
    @observable endHour: number = 1 // 1..24
    @observable value: number = 0 // (MWh)
    @observable obligationTimeSeries: TimeSeries = new TimeSeries()
   
    @action setDayKind(x: DayKind) { this.dayKind = x }
    @action setStartDate(x: Date) { this.startDate = x}
    @action setStartHour(x: number) { this.startHour = x }
    @action setEndDate(x: Date) { this.endDate = x }
    @action setEndHour(x: number) { this.endHour = x }
    @action setValue(x: number) { this.value = x }
}

export const defaultOstDimension: OstDimension = {
    ids: [],
    displayNames: [],
    components: [],
    units: [],
    points: [],
    steps: [],
    isXAxis: false,
    isYAxis: false,
    selectedValue: 0,
}

export const defaultOstResult: OstResult = {
    planes: [],
    blendedPlane: [],
    ostLegends: [],
    superimposedValues: [],
    simulationPoints: []
}

export class Contract {
    id = v4()
    constructor(lifetime: number, public projId: string) {
        makeObservable(this)
        this.energyPriceEscalator.updateLifetime(lifetime)
        this.shortagePenaltyPriceEscalator.updateLifetime(lifetime)
    }

    // energy price
    @observable energyPriceKind: ContractEnergyPriceKind = 'schedule'

    // for kind = 'schedule'
    //@observable energyPriceRates: ScheduleRate[] = observable([])
    //@observable energyPriceSchedule: Schedule = defaultSchedule()
    @observable energyPriceSchedule: RateSchedule = new RateSchedule

    // for kind = 'import'
    @observable energyPriceTimeSeries: TimeSeries = new TimeSeries()

    @observable energyPriceEscalator: MultiYearVar = new MultiYearVar()

    // obligations
    @observable obligationKind: ContractObligationKind = 'daily'

    // for kind = 'daily'
    @observable obligations: ContractObligationRequirement[] = observable([])
    @observable dailyObligationsTimeSeries: TimeSeries = new TimeSeries()

    // for kind = 'import'
    @observable obligationTimeSeries: TimeSeries = new TimeSeries()
    @observable obligationTimeSeriesName: string = 'Delivery Profile'
    // for kind = 'none'
    @observable contractedSolarCapacity: number = 100
    @observable contractedWindCapacity: number = 100
    @observable contractedStorageCapacity: number = 100
    @observable flatShortagePenalty: number = 0
    @observable competeWithWholesaleMarket: boolean = false
    @observable progress: number = 0
    @observable state: ContractResultState = 'blank'
    @observable estimatedRemainingTime: string = "00:00:00"

    @observable systemSizerLimits: SystemSizerLimits = new SystemSizerLimitsClass()
    @observable useSystemSizer: boolean = true
    @observable calculated: boolean = false

    @observable shortagePenaltyKind: ShortagePenaltyKind = 'flat'
    @observable useShortagePenalty: boolean = false
    @observable energyMarket: EnergyMarket | null = null
    @observable shortagePenaltyPriceEscalator: MultiYearVar = new MultiYearVar()

    @action setScheduleData(data: number[], year: number) {
        this.energyPriceTimeSeries.setData(data, year)
    }

    @action setObligationData(data: number[], year: number, fileName: string) {
        this.obligationTimeSeries.setData(data, year)
        this.obligationTimeSeriesName = fileName
    }

    @action setEnergyPriceKind(x: ContractEnergyPriceKind) { this.energyPriceKind = x }
    @action setObligationKind(x: ContractObligationKind) { this.obligationKind = x }
    @action setUseShortagePenalty(value: boolean) { this.useShortagePenalty = value }
    @action setShortagePenaltyKind(value: ShortagePenaltyKind) { this.shortagePenaltyKind = value }

    @action addNewObligation(init: Partial<ContractObligationRequirement>, isEdit?: boolean) {
        const x = new ContractObligationRequirement(init)
        if (!isEdit)
            this.obligations.push(x)
        else {
            const index = this.obligations.findIndex(i => i.id === x.id)
            this.obligations[index] = x;
        }
    }

    @action removeObligation(id: string) {
        const index = this.obligations.findIndex(x => x.id === id)
        this.obligations.splice(index, 1)
    }

    @action setContractedSolarCapacity(x: number) { this.contractedSolarCapacity = x }
    @action setContractedWindCapacity(x: number) { this.contractedWindCapacity = x }
    @action setContractedStorageCapacity(x: number) { this.contractedStorageCapacity = x }
    @action setCompeteWithWholesaleMarket(x: boolean) { this.competeWithWholesaleMarket = x }
    @action setFlatShortagePenalty(x: number) { this.flatShortagePenalty = x }
    @action setEnergyMarket(x: EnergyMarket | null) { this.energyMarket = x }

    @action setUseSolar(x: boolean) { useSolar = x }
    @action setUseWind(x: boolean) { useWind = x }
    @action setUseStorage(x: boolean) { useStorage = x }

    @action setSolarMaxSize(x: number) { this.systemSizerLimits.solarMaxSize = x; this.clearResults() }
    @action setSolarMinSize(x: number) { this.systemSizerLimits.solarMinSize = x; this.clearResults() }
    @action setRatingSize(x: number) { this.systemSizerLimits.windRatingSize = x; this.clearResults() }
    @action setWindMaxSize(x: number) { this.systemSizerLimits.windMaxSize = x; this.clearResults() }
    @action setWindMinSize(x: number) { this.systemSizerLimits.windMinSize = x; this.clearResults() }
    @action setStorageMaxSize(x: number) { this.systemSizerLimits.storageMaxSize = x; this.clearResults() }
    @action setStorageMinSize(x: number) { this.systemSizerLimits.storageMinSize = x; this.clearResults() }
    @action setSystemSizer(model: Project, value: boolean) { model.setUseSystemSizer(value) }
    @action setUseSystemSizer(value: boolean) { this.useSystemSizer = value }
    @action setCalculated(value: boolean) { this.calculated = value }

    clearResults() {
        this.calculated = false
        this.state = 'blank'
        this.resultTableState = 'ready'
        this.resultTable = defaultTable
        this.progress = 0
    }

    checkInputsChange() {
        if (this.useSystemSizer && this.resultTable.items.length > 0) {
            if (this.systemSizerLimits.useSolar != useSolar || this.systemSizerLimits.useWind != useWind || this.systemSizerLimits.useStorage != useStorage) {
                this.clearResults()
                this.systemSizerLimits.useSolar = useSolar
                this.systemSizerLimits.useWind = useWind
                this.systemSizerLimits.useStorage = useStorage
            }
        }
        else {
            this.systemSizerLimits.useSolar = useSolar
            this.systemSizerLimits.useWind = useWind
            this.systemSizerLimits.useStorage = useStorage
        }
    }

    @action
    async calculatesystemsize() {
        this.state = 'calculating'
        this.resultTableState = 'calculating'
        this.resultTable = defaultTable
        this.progress = 0
        let data = SerializeSystemSizerLimits(this.systemSizerLimits)
        let rv : StatusResponse
        try {
            rv = await api.calculatePro(this.projId, data)
        }
        catch(error) {
            this.calculated = false
            this.resultTableState = 'ready'
            this.state = 'error'
            return
        }

        runInAction(() => {
            this.progress = rv.progress ?? 0
            if (rv.status !== 'ready') return
            this.state = 'calculated'
        })
    }

    @action
    async checkStatus() {
        const rv = await api.getProStatus(this.projId)
        runInAction(() => {
            this.progress = Math.max(this.progress, rv.progress ?? 0)
            this.estimatedRemainingTime = rv.estimatedRemainingTime
            if (rv.status === 'cancelled') {
                this.state = 'cancelled'
            }
            if (rv.status !== 'ready') return
            this.state = 'calculated'
        })
    }

    // result tables
    @observable resultTable: SystemSizerResultTable = defaultTable
    @observable resultTableState: ContractResultState = 'ready'
    @observable resultGraphState: DimensionState = 'loading'
    @observable ostDimension: OstDimension[] | null = [defaultOstDimension]
    @observable ostResult: OstResult | null = defaultOstResult

    @action
    async fetchSensitivityTable() {
        this.resultTableState = 'loading'
        let t: SystemSizerTableResponse
        try {
            t = await api.getProResultTable(this.projId)
        }
        catch (error) {
            this.calculated = true
            this.resultTableState = 'ready'
            this.state = 'error'
            return
        }

        runInAction(() => {
            this.resultTable = t.data            
            this.calculated = true
            if (this.resultTable.items.length > 0) {
                this.state = 'ready'
                this.resultTableState = 'ready'
            }
            else {
                this.state = 'cancelled'
                this.resultTableState = 'ready'
                this.state = 'error'
            }
        })

        //Commented code below is used for testing: downloading the .homer and opening in Pro
        //let data = SerializeSystemSizerLimits(this.systemSizerLimits)
        //await api.downloadHomerProFile(this.projId, data);
    }
}

export type SystemSizerResultsColKind = 'solar' | 'storage' | 'wind'

export class SystemSizerResultTable {
    constructor(init: Partial<SystemSizerResultTable> = {}) {
        makeObservable(this)
        Object.assign(this, init)
    }
    @observable items: SystemSizerResultItem[] = []
    @observable header!: SystemSizerResultHeader
}

export class SystemSizerResultHeader {
    constructor() { makeObservable(this) }
    @observable optimization: SystemSizerResultsCol[] = []
}
export class SystemSizerResultsCol {
    constructor() { makeObservable(this) }
    @observable name: string = ''
    @observable units: string[] = []
    @observable kind!: SystemSizerResultsColKind
}

export class SystemSizerResultItem {
    constructor() { makeObservable(this) }
    @observable id: string = ''
    @observable results: ((number | null)[])[] = []
}
export class SystemSizerLimitsClass{
    constructor() { makeObservable(this) }
    @observable useSolar: boolean = true
    @observable useWind: boolean = true
    @observable useStorage: boolean = true
    @observable solarMaxSize: number = 0
    @observable solarMinSize: number = 0
    @observable windRatingSize: number = 0
    @observable windMaxSize: number = 0
    @observable windMinSize: number = 0
    @observable storageMaxSize: number = 0
    @observable storageMinSize: number = 0
}

export function SerializeSystemSizerLimits(limitsObject: SystemSizerLimitsClass): SystemSizerLimits {
    var limits = {} as SystemSizerLimits

    limits.useSolar = limitsObject.useSolar
    limits.useWind = limitsObject.useWind
    limits.useStorage = limitsObject.useStorage

    limits.solarMaxSize = limitsObject.solarMaxSize
    /*if (limitsObject.solarMaxSize == 0) {
        limits.useSolar  = false
    }*/
    limits.solarMinSize = limitsObject.solarMinSize
    limits.windRatingSize = limitsObject.windRatingSize
    limits.windMaxSize = limitsObject.windMaxSize
    /*if (limitsObject.windMaxSize == 0) {
        limits.useWind = false
    }*/
    limits.windMinSize = limitsObject.windMinSize
    limits.storageMaxSize = limitsObject.storageMaxSize
    /*if (limitsObject.storageMaxSize == 0) {
        limits.useStorage = false
    }*/
    limits.storageMinSize = limitsObject.storageMinSize
    return limits
}
