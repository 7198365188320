import { RouteComponentProps } from '@reach/router'
import { Button, Form, Modal, Row, Spinner } from 'react-bootstrap'
import {  useEffect, useState } from 'react'
import {  ResultTable } from '../../results'
import ContextHelp from '../../../helper/ContextHelp'
import ResultTableView from './ResultTableView'
import { observer } from 'mobx-react-lite'
import CompareEconomicsAnnualCashFlowChart from './CompareEconomicsAnnualCashFlowChart'
import CompareEconomicsCumulativeCashFlowChart from './CompareEconomicsCumulativeCashFlowChart'
import { IntlService } from 'Kendo-Intl-5'
import './CompareEconomics.scss'
import ToolTipInfo from '../../../helper/ToolTipInfo'
import * as React from 'react'
import { useProjectStore } from '../../ProjectProvider'

interface ResultTimeSeriesSectionProps extends RouteComponentProps {
    showBaseYearAsYearZero: boolean
    baseYear: number
    intlCulture: string
}


const ResultCompareEconomics = observer(({ showBaseYearAsYearZero, baseYear, intlCulture }: ResultTimeSeriesSectionProps) => {
    const intl = new IntlService(intlCulture)

    const project = useProjectStore().project!
    const results = project.results
    const details = results.details
    const baseCaseDetails = results.baseCaseDetails.details
    const currency = project.currencySymbol
    const [show, setShow] = useState(false)
    const [showError, setShowError] = useState(false)
    const [viewType, setViewType] = useState<CompareEconomicsType>('graph')
    const [annualDifference, setAnnualDifference] = useState<number[]>([])
    const [cumulativeDifference, setCumulativeDifference] = useState<number[]>([])
    const [isCumulativeGraph, setIsCumulativeGraph] = useState(false)
    const [isDifferenceGraph, setIsDifferenceGraph] = useState(false)

    const handleSetShowModal = async (x: boolean) => {
        results.baseCaseOptimizationState = 'blank'
        results.baseCaseDetailsState = 'blank'
        setShowError(false)
        if (results.selection.kind !== 'none') {
            await results.fetchSelectBaseCaseOptimizationTable(results.selection.sensitivity, 'all')
        }
        setShow(x)
    }
    const handleConfirmBaseCaseSelection = async () => {
        setShowError(false)
        if (!results.useZeroCashFlow) {
            results.baseCaseState = 'loading'
            await getBaseCaseOptimizationTable()
            await results.fetchBaseCaseDetails()
        }
        else {
            setShow(false)
        }
    }
    const getBaseCaseOptimizationTable = async () => {
        if (results.selection.kind === 'optimization') {
            await results.fetchBaseCaseOptimizationTable(results.selection.sensitivity)
        }
        let baseCaseOptimizationTable: ResultTable = results.compareEconomicsBaseCaseOptimizationTable
        results.compareEconomicsOptimizationTableState = 'loading'
        if (!results.useZeroCashFlow) {
            let baseCase = results.compareEconomicsBaseCaseOptimizationTable.items.filter(x => x.id == results.baseCaseId)
            let selectedCase = results.compareEconomicsBaseCaseOptimizationTable.items.filter(x => x.id == results.resultId)
            baseCaseOptimizationTable.items = []
            if (selectedCase.length > 0 && baseCase.length > 0) {
                baseCaseOptimizationTable.items.push({ ...baseCase[0] })
                baseCaseOptimizationTable.items.push({ ...selectedCase[0] })
            }
        }
        results.compareEconomicsOptimizationTable = { ...baseCaseOptimizationTable, items: { ...baseCaseOptimizationTable.items } }
        results.compareEconomicsOptimizationTableState = 'ready'
        results.proposedCaseId = results.resultId
    }

    useEffect(() => {
        if (results.selection.kind != 'none' && results.proposedCaseId != '' && results.proposedCaseId != results.resultId) {
            results.compareEconomicsOptimizationTableState = 'blank'
        }
    }, [])
    useEffect(() => {
        if (results.baseCaseDetailsState == 'blank')
            return
        else if (results.baseCaseDetailsState == 'error')
            setShowError(true)
        else {
            let annualDifferenceValues: number[] = []
            let cumulativeDifferenceValues: number[] = []
            for (let i = 0; i < results.details.details.cashflow.length; i++) {
                annualDifferenceValues.push(details.details.cashflow[i] - baseCaseDetails.cashflow[i])
                cumulativeDifferenceValues.push(details.details.cashflowCumulative[i] - baseCaseDetails.cashflowCumulative[i])
            }
            setAnnualDifference(annualDifferenceValues)
            setCumulativeDifference(cumulativeDifferenceValues)
            setShow(false)
            setShowError(false)
            results.baseCaseState = 'ready'
        }
    }, [results.baseCaseDetails])
    const handleSetUseZeroCashFlow = (x: boolean) => {
        results.setUseZeroCashFlow(x)
        setShowError(false)
    }
    const handleSelectBaseCase = (index: number) => {
        setShowError(false)
        let baseCaseId = results.baseCaseOptimizationTable.items[index].id
        results.setBaseCaseId(baseCaseId)
        results.setBaseCaseIndex(index)
        results.baseCaseOptimizationState = 'ready'
    }

    return (<>
        <Row className='ml-2'>
            <div className='col-5 mt-3 ml-1'>
                <div className='row'>
                    <div className='col-6'>
                        <Form.Check name='use_zero_cash_flow_as_base_case' custom label='Use Zero Cash Flow as Base Case'
                            id='use_zero_cash_flow'
                            checked={results.useZeroCashFlow}
                            onChange={ev => handleSetUseZeroCashFlow(ev.target.checked)} />
                    </div>
                    <div className='col-1 mt-1'>
                        <ContextHelp helpId='COMPAREECONOMICS' />
                    </div>
                </div>

                <div className='col-11 mt-2'>
                    <Button id='setBaseCase' disabled={results.useZeroCashFlow} size='sm' className='btn btn-primary d-flex align-items-center mt-3 pillButton' onClick={() => handleSetShowModal(true)}>
                        Select Base Case
                    </Button>
                </div>
                <div className='form-group d-flex mb-2 mt-2 col-11'>
                    <div className='mr-3'>
                        <Form.Check name='compare_economics_view_type' custom label='Table'
                            id='view_type_table' type='radio'
                            checked={viewType === 'table'}
                            onChange={ev => ev.target.checked && setViewType('table')} />
                    </div>
                </div>
                <div className='form-group d-flex mb-2 col-11'>
                    <div className='mr-3'>
                        <Form.Check name='compare_economics_view_type' custom label='Graph'
                            id='view_ype_graph' type='radio'
                            checked={viewType === 'graph'}
                            onChange={ev => ev.target.checked && setViewType('graph')} />
                    </div>
                </div>
                <div className='form-group d-flex ml-2 mb-2 col-11'>
                    <div className='ml-1 col-2'>
                        <Form.Check name='compare_economics_graph_scope' custom label='Both'
                            id='show_both_graphs' type='radio' disabled={viewType === 'table'}
                            checked={!isDifferenceGraph}
                            onChange={ev => setIsDifferenceGraph(!ev.target.checked)} />
                    </div>
                    <div className='ml-2 col-2'>
                        <Form.Check name='optimization_result_graph_scope' custom label='Difference'
                            id='show_difference_graph' type='radio' disabled={viewType === 'table'}
                            checked={isDifferenceGraph}
                            onChange={ev => setIsDifferenceGraph(ev.target.checked)} />
                    </div>
                </div>
                <div className='form-group d-flex mb-2 ml-2 col-11'>
                    <div className='ml-1 col-2'>
                        <Form.Check name='compare_economics_graph_type' custom label='Annual'
                            id='show_annual_graph' type='radio' disabled={viewType === 'table'}
                            checked={!isCumulativeGraph}
                            onChange={ev => setIsCumulativeGraph(!ev.target.checked)} />
                    </div>
                    <div className='ml-2 col-2'>
                        <Form.Check name='optimization_result_graph_option' custom label='Cumulative'
                            id='show_cummulative_graph' type='radio' disabled={viewType === 'table'}
                            checked={isCumulativeGraph}
                            onChange={ev => setIsCumulativeGraph(ev.target.checked)} />
                    </div>
                    <div className='ml-4 col-2'>
                        {results.useZeroCashFlow && <ToolTipInfo label={'Compared to zero cash flow'} />}
                        {!results.useZeroCashFlow && <ToolTipInfo label={'Proposed case compared to base case'} />}
                    </div>
                </div>
            </div>
            <div className='col-7'>
                {results.useZeroCashFlow && <div className='ml-2 mt-3'><h4>Base Case: Zero Cash Flow</h4></div>}
                {((!results.useZeroCashFlow && results.baseCaseState !== 'ready') || (results.proposedCaseId !== '' && results.proposedCaseId !== results.resultId)) && <div className='ml-2 mt-3'><h4>Select Base Case</h4></div>}
                {!results.useZeroCashFlow && results.baseCaseState === 'ready' && results.compareEconomicsOptimizationTableState === 'ready' && <>
                    <div className='y'>
                        <table className='styled-table'>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th colSpan={results.compareEconomicsOptimizationTable.header.optimization?.length}>Optimization</th>
                                    <th colSpan={2}>Cost</th>
                                    <th colSpan={3}>Project Economics</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    {results.compareEconomicsOptimizationTable.header.optimization.map(x =>
                                        <th>{`${x.name} (${x.units.join('/')})`}</th>
                                    )}
                                    <th>Capital Expenses</th>
                                    <th style={{ width: '150px' }}>Operating Expenses Excl. Augmentation</th>
                                    <th>NPV</th>
                                    <th>IRR</th>
                                    <th>Payback</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Base System</td>
                                    {results.compareEconomicsOptimizationTable.items[0].optimization.map(x => <th>{(x.length === 1 && x[0] != null ? intl.formatNumber(x[0], 'n3') : x.length === 2 && x[0] != null && x[1] != null ? `${intl.formatNumber(x[0], 'n3')}/${intl.formatNumber(x[1], 'n3')}` : '')}</th>)}
                                    <td>{intl.formatNumber(results.compareEconomicsOptimizationTable.items[0].capex, 'n0')}</td>
                                    <td>{intl.formatNumber(results.compareEconomicsOptimizationTable.items[0].opex, 'n0')}</td>
                                    <td>{intl.formatNumber(results.compareEconomicsOptimizationTable.items[0].npv, 'n0')}</td>
                                    <td>{(results.compareEconomicsOptimizationTable.items[0].irr != null ? intl.formatNumber(results.compareEconomicsOptimizationTable.items[0].irr, 'n0') : '-')}</td>
                                    <td>{(results.compareEconomicsOptimizationTable.items[0].payback != null ? intl.formatNumber(results.compareEconomicsOptimizationTable.items[0].payback, 'n1') : '-')}</td>
                                </tr>
                                <tr>
                                    <td>Proposed System</td>
                                    {results.compareEconomicsOptimizationTable.items[1].optimization.map(x => <th>{(x.length == 1 && x[0] != null ? intl.formatNumber(x[0], 'n3') : x.length == 2 && x[0] != null && x[1] != null ? `${intl.formatNumber(x[0], 'n3')}/${intl.formatNumber(x[1], 'n3')}` : '')}</th>)}
                                    <td>{intl.formatNumber(results.compareEconomicsOptimizationTable.items[1].capex, 'n0')}</td>
                                    <td>{intl.formatNumber(results.compareEconomicsOptimizationTable.items[1].opex, 'n0')}</td>
                                    <td>{intl.formatNumber(results.compareEconomicsOptimizationTable.items[1].npv, 'n0')}</td>
                                    <td>{(results.compareEconomicsOptimizationTable.items[1].irr != null ? intl.formatNumber(results.compareEconomicsOptimizationTable.items[1].irr, 'n0') : '-')}</td>
                                    <td>{(results.compareEconomicsOptimizationTable.items[1].payback != null ? intl.formatNumber(results.compareEconomicsOptimizationTable.items[1].payback, 'n1') : '-')}</td>
                                </tr>
                                <tr>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>}
            </div>
        </Row>

        <Row className='ml-4'>

        </Row>
        <Row className='ml-1'>

        </Row>
        {viewType === 'graph' && <div className='ml-2 col-11'>
            {!isCumulativeGraph && <CompareEconomicsAnnualCashFlowChart
                currency={currency}
                chartData1={(isDifferenceGraph && results.baseCaseState === 'ready' ? annualDifference : details.details.cashflow)}
                chartData2={!results.useZeroCashFlow && results.baseCaseState === 'ready' && !isDifferenceGraph ? baseCaseDetails.cashflow : null}
                noOfYears={project.lifetime}
                showBaseYearAsYearZero={showBaseYearAsYearZero}
                baseYear={project.baseYear}
                chartTitle={`Annual Cash Flow Chart ${currency}`} />}
            {isCumulativeGraph && <CompareEconomicsCumulativeCashFlowChart
                currency={currency}
                chartData1={(isDifferenceGraph && results.baseCaseState === 'ready' ? cumulativeDifference : details.details.cashflowCumulative)}
                chartData2={!results.useZeroCashFlow && results.baseCaseState === 'ready' && !isDifferenceGraph ? baseCaseDetails.cashflowCumulative : null}
                noOfYears={project.lifetime}
                showBaseYearAsYearZero={showBaseYearAsYearZero}
                baseYear={project.baseYear}
                chartTitle={`Cumulative Cash Flow Chart (${currency})`} />}
        </div>}
        {viewType === 'table' && <>
            <div className='ml-2'>
                <div className='mt-3'>
                    <h5>{`Cash Flow Table (${currency})`}</h5>
                    <table className='styled-table'>
                        <thead>
                            <tr>
                                <th></th>
                                <th colSpan={2}>Proposed System</th>
                                <th colSpan={2}>Base System</th>
                                <th colSpan={2}>Difference</th>
                            </tr>
                            <tr>
                                <th>Year</th>
                                <th>Annual</th>
                                <th>Cumulative</th>
                                <th>Annual</th>
                                <th>Cumulative</th>
                                <th>Annual</th>
                                <th>Cumulative</th>
                            </tr>
                        </thead>
                        <tbody>
                            {details.details.cashflowTable.map((x, i) =>
                                <tr>
                                    <td>{(showBaseYearAsYearZero ? baseYear - 1 + i : i)}</td>
                                    <td>{intl.formatNumber(x.income, 'n2')}</td>
                                    <td>{intl.formatNumber(x.cumulative, 'n2')}</td>
                                    <td>{(results.useZeroCashFlow || results.baseCaseState !== 'ready' ? 0 : intl.formatNumber(baseCaseDetails.cashflowTable[i].income, 'n2'))}</td>
                                    <td>{(results.useZeroCashFlow || results.baseCaseState !== 'ready' ? 0 : intl.formatNumber(baseCaseDetails.cashflowTable[i].cumulative, 'n2'))}</td>
                                    <td>{(results.useZeroCashFlow || results.baseCaseState !== 'ready' ? intl.formatNumber(x.income, 'n2') : intl.formatNumber(annualDifference[i], 'n2'))}</td>
                                    <td>{(results.useZeroCashFlow || results.baseCaseState !== 'ready' ? intl.formatNumber(x.cumulative, 'n2') : intl.formatNumber(cumulativeDifference[i], 'n2'))}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>}
        <Modal show={show} scrollable={true} onHide={() => handleSetShowModal(false)} size='lg'>
            <Modal.Header closeButton onHide={() => handleSetShowModal(false)}>
                <Modal.Title>Set Base Case</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='ml-2'>
                        <Row>
                            <div className='mt-2 ml-2' style={{ fontSize: '12px' }}>Click on a row to set as Base Case</div>
                            <div className='m-auto' />
                            <ContextHelp helpId='SENSITIVITY' />
                            <div className='mr-3' />
                        </Row>
                    {results.baseCaseOptimizationTableState === 'ready' &&
                        <Row>
                            <ResultTableView onSelect={handleSelectBaseCase} calculate={() => {return} }
                            intlCulture='en-US' resultType='compareEconomics' />
                        </Row>}
                    {results.baseCaseOptimizationTableState !== 'ready' &&
                        <div className='d-flex flex-column h-100 align-items-center justify-content-center'>
                            <Spinner animation='border' className='text-primary' />
                            <p>Loading Base Case Optimization Table, Please Wait</p>
                        </div>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                {results.baseCaseOptimizationState !== 'ready' && <p className='mr-2'>Select Base Case</p>}
                {results.baseCaseState === 'loading' && results.baseCaseDetailsState !== 'error' && <p className='mr-2'>Loading base case results, {(results.estimatedBaseCaseRemainingTime !== "00:00:00" ? 'estimated remaning time: ' + results.estimatedBaseCaseRemainingTime : 'please wait')}</p>}
                {showError && <div className='mt-2 text-danger'>Error loading base case, please check that it is not infeasible </div>}
                <Spinner hidden={results.baseCaseState !== 'loading' || results.baseCaseDetailsState === 'error'} animation='border' size='sm' className='text-primary' />
                <Button className='pillButton' onClick={() => handleSetShowModal(false)} variant='outline-primary'>Cancel</Button>
                <Button className='pillButton' onClick={() => handleConfirmBaseCaseSelection()} disabled={results.baseCaseState === 'loading' || results.baseCaseOptimizationState !== 'ready'}>Get Results to Compare</Button>
            </Modal.Footer>
        </Modal>
    </>
    )
})

export default ResultCompareEconomics
type CompareEconomicsType = 'graph' | 'table'
