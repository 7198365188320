import {observer} from 'mobx-react-lite'
import CostTableView from './shared/CostTableView'
import * as React from 'react'
import { Inverter } from '../../model/Inverter'
import { Validation, ValidationFunc } from '../../../helper/validation'
import ContextHelp from '../../../helper/ContextHelp'
import SizingView from './shared/SizingView'
import MultiYearView from '../SharedComponents/MultiYearView'
import SensitivityVarView from '../EnergyMarket/SensitivityVarView'


interface InverterViewProps {
    model: Inverter
    label: string
    absoluteUnits?: string
    relativeUnits?: string
    absoluteLabel?: string
    relativeLabel?: string
    type?: string
    sizeValidator?: ValidationFunc
    useCommaAsDecimalPoint: boolean
    hideSensitivity?: boolean
    hideHelp?: boolean
    isSystemConverter?: boolean
    defaultCurrency?: string | null
    firstYear?: number
}


const InverterView = observer(({ model, label, absoluteUnits, relativeUnits, absoluteLabel, relativeLabel, type, sizeValidator, useCommaAsDecimalPoint, hideSensitivity = false, hideHelp = false, isSystemConverter = false, defaultCurrency = null, firstYear = 0 }: InverterViewProps) => {
    return (
        <>
            {type && <div className='col-12'>
                <div className='float-right'>{isSystemConverter && < ContextHelp helpId='SYSTEM' />}</div>
            </div>}
            <div className='form-group d-flex mb-2 mt-4'>

                <div className='border rounded w-50 mr-1 p-2'>
                    <SizingView id={model.id} model={model.sizing} validator={sizeValidator} showLabel={false} 
                        absoluteUnits={absoluteUnits} relativeUnits={relativeUnits}
                        absoluteLabel={absoluteLabel} relativeLabel={relativeLabel} useCommaAsDecimalPoint={useCommaAsDecimalPoint} hideHelp={hideHelp} />
                </div>
                <div className='border rounded w-50 ml-1 p-2'>
                    <CostTableView cost={model.cost} id={model.id}
                        showDetailedCostTable={false}
                        showReplacement={true} hideSensitivity={hideSensitivity}
                        simpleCostLabel='Economies of Scale' defaultCurrency={defaultCurrency}
                        complexCostLabel='Cost Breakdown' type={type} useCommaAsDecimalPoint={useCommaAsDecimalPoint} hideHelp={hideHelp} />
                    {!hideSensitivity && <>
                        <MultiYearView model={model.replacementEscalator} firstYear={firstYear}
                        label='Replacement cost escalator (%/yr)' id={'converter-replacement-' + model.id} />
                        <MultiYearView model={model.omEscalator} firstYear={firstYear}
                            label='O&M cost escalator (%/yr)' id={'converter-om-' + model.id} />
                    </>}
                        <SensitivityVarView model={model.lifetime} label='Lifetime (yrs)' units='years' useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                </div>
            </div>
            <div className='w-50'>
                <SensitivityVarView model={model.efficiency}
                    label={`${label} Efficiency (%)`} units='%'
                    validator={Validation.range(0, 100, true, false)}
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
            </div>
        </>
    )
})


export default InverterView
