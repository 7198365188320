import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface SuccessModalProps {
    show: boolean
    onCancel?: () => void
    onConfirm?: () => void
    label?: string
    multipleFiles?: boolean
}

const SuccessImportModal = observer(({ show, onCancel, onConfirm, label, multipleFiles = false }: SuccessModalProps) => {

    const handleCancel = () => {
        onCancel?.()
    }

    const handleConfirm = () => {
        onConfirm?.()
    }
    
    return (
        <Modal show={show} onHide={handleCancel} backdrop='static'>
            <Modal.Header>
                <Modal.Title>{`${(label !== undefined && label !== '' ? label : 'File Import')}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='alert alert-success'>{`Time series file${(multipleFiles ? '(s)' : '')} imported successfully`}</Modal.Body>
            <Modal.Footer>
                {/*<Button className='pillButton' variant='secondary' onClick={handleCancel}>Cancel</Button>*/}
                <Button className='pillButton' variant='primary' onClick={handleConfirm}>Ok</Button>
            </Modal.Footer>
        </Modal>
    )
})

export default SuccessImportModal
