import { observer } from 'mobx-react-lite'
import SideNavigationView from './Results/SideNavigationView'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import * as React from 'react'
import { ChangeEvent, useEffect, useState } from 'react'
import OptimizationResults from './Results/OptimizationResults'
import NoMatch from '../../helper/NoMatch'
import { Redirect, RouteComponentProps, Router, useLocation, useMatch } from '@reach/router'
import '../pages/Results/results.scss'
import SelectedSystem from './Results/SelectedSystem'
import { Button, Form, Modal, ProgressBar, Spinner } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import InfeasibleResult from './Results/InfeasibleResult'
import { UserEmailNotification } from '../../../services/api'
import { ResultModel, ResultState } from '../results'
import GraphResults from './Results/GraphResults'
import { IntlProvider, LocalizationProvider } from 'Kendo-Intl-5'
import { UnlockProjectModal } from '../ProjectView'
import { useProjectStore } from '../ProjectProvider'
import { delay } from '../../../utils'
import SensitivityResults from './Results/SensitivityResults'
import { AnalyzerItem } from '../analyze'


interface ResultsPageProps extends RouteComponentProps { }

const ResultsPage = observer((_: ResultsPageProps) => {
    const store = useProjectStore()
    const project = store.project!
    const info = store.info
    const status = project.status
    const results = project.results
    const estimatedCalculationTime = project.estimatedCalculationTime()
    const estimatedCalculationTimeString = estimatedCalculationTime !== undefined && estimatedCalculationTime.length > 0 && estimatedCalculationTime[0] !== undefined && estimatedCalculationTime[0].label !== undefined ? estimatedCalculationTime[0].label : ''

    const intlCulture = project.useCommaAsDecimalPoint ? 'es' : 'en'
    // selection state
    const isSensitivity = useMatch('sensitivity/:selection')
    const isOptimization = useMatch('optimization/:selection')
    const isGraph = useMatch('graph/-')
    const isDetails = useMatch('details/:selection')
    const match = isSensitivity ?? isOptimization ?? isDetails
    const loc = useLocation()

    const [cancelError, setCancelError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [cancelling, setCancelling] = useState(false)
    const blank = project.results.state === 'blank'
    const requestError = project.results.state === 'error'
    const initialLoad = project.results.state === 'initialLoad'
    const loading = project.results.state === 'loading' /*|| project.results.state === 'ready'*/
    const isDetailsLoading = project.results.detailState === 'loading'
    const cancelled = project.results.state === 'cancelled'
    const detailsCancelled = project.results.detailState === 'cancelled'

    let events: EventItem[] = [{ index: 0, year: 1 }]
    const [eventList, setEventList] = useState(events)
    const [selectedEvent, setSelectedEvent] = useState(events[events.length - 1])

    const clearErrors = () => {
        if (cancelError)
            setCancelError(false)
        if (emailError)
            setEmailError(false)
    }

    useEffect(() => {
        status.setCurrentStep(4)
        if (project.canNotCalculate) return
        if (project.results.state !== 'ready' /*&& project.results.state !== 'loaded'*/) return
        var s = ''
        if (isGraph) {
            project.results.fetchOstResult()
        }
        else {
            s = match?.['selection'] ?? ''
            project.results.ostResult = null
        }
        clearErrors()
        project.results.select(s).then(x => x)
    }, [project.results.state, loc.pathname])

    const calculate = (recalculate: boolean = false) => {
        try {
            console.log("resultsPage recalculate")
            if (project.canNotCalculate) return
            let cancel = false
            const run = async () => {
                await store.checkLock(true)
                if (store.readOnly !== true)
                    await store.saveProject()

                const calculateStatus = await project.results.calculate(recalculate)
                if (calculateStatus === 'error') {
                    project.results.state = 'error'
                }
                console.log("resultsPage recalculate continue", calculateStatus)

                let calculationStarted = calculateStatus === 'calculating' || calculateStatus === 'ready'
                if (calculateStatus !== undefined && calculationStarted) {
                    if (calculateStatus === 'calculating')
                        store.setDbProjectStatus(2)
                    else
                        store.setDbProjectStatus(3)
                }
                if (cancel) return
                let it = 0
                const MAX = 1000
                while (it < MAX) {
                    if (cancel) return
                    await project.results.checkStatus()
                    await delay(5 * 1000)
                    if (project.results.state === 'ready' || project.results.state === 'cancelled') {
                        break
                    }
                }
                clearErrors()
            }
            run().then()
            return () => { cancel = true }
        }
        catch {
            project.results.state = 'error'
        }

    }
    
    useEffect(() => {
        project.results.checkInitialStatus()
    }, [])

    useEffect(() => {
        if (project.results.detailState === 'cancelled')
            setCancelling(false)
        if (project.results.detailState === 'ready') {
            clearErrors()
        }
    }, [project.results.detailState])

    //project.analyze()

    const cancelCalculation = async () => {
        clearErrors()
        let rv = false
        if (isDetails == null)
            rv = await project.results.cancelCalculation()
        else
            rv = await project.results.cancelDetailedCalculation()
        if (!rv)
            setCancelError(true)
        else {
            setCancelling(true)
        }
    }

    const gotoSetup = async () => {
        status.goTo(0);
    }

    const restartCalculation = async () => {
        clearErrors()
        setCancelling(false)
        if (isDetails == null)
            project.setRestartCalculation(!project.restartCalculation)
        else
            results.fetchDetails()
    }

    const handleNavigateToError = async (link: string) => {
        await project.status.navigateUrl(link)
    }

    const handleCloseReadOnlyPopup = async () => {
        await store.updateShowReadOnlyPopup(false)
    }

    if (project.canNotCalculate) {
        return (<ModelErrorsView issues={project.issues.filter(x => x.estimation !== true)} onClick={handleNavigateToError} />)
    }

    if (initialLoad) {
        return (
            <div className='d-flex flex-column h-100 align-items-center justify-content-center'>
                    <Spinner animation='border' className='text-primary' />
            </div>)
    }

    if (requestError) {
        return (<>
                <div className='d-flex flex-column h-100 align-items-center justify-content-center'>
                    <h5 className='mb-3'>An error ocurred, please try again.</h5>
                    <button id='btn_trigger_calculation' className='btn btn-primary pillButton' onClick={() => calculate()}>Calculate</button>
                </div>
                <UnlockProjectModal show={store.showReadOnlyPopup} onClose={handleCloseReadOnlyPopup} />
        </>)
    }

    if (blank) {
        return (<>
                        <div className='d-flex flex-column h-100 align-items-center justify-content-center'>
                            <h5 className='mb-3'>This is a new project, please trigger calculation to get results.</h5>
                <button id='btn_trigger_calculation' className='btn btn-primary pillButton' onClick={() => calculate()}>Calculate</button>
                        </div>
            <UnlockProjectModal show={store.showReadOnlyPopup} onClose={handleCloseReadOnlyPopup} />
        </>)
    }

    if (loading || cancelled) {
        return (<LoadingResultsView results={project.results} progress={project.results.progress} state={project.results.state} isDetails={isDetails != null} emailError={emailError} setEmailError={setEmailError} estimatedRemainingTime={estimatedCalculationTimeString} hasSensitivities={project.results.sensitivityTable.header.sensitivity.length > 0} isEarlyAnalysis={info?.earlyAnalysis} cancelCalculation={cancelCalculation} gotoSetup={gotoSetup} restartCalculation={restartCalculation} cancelError={cancelError} cancelling={cancelling} />)
    }

    if ((isDetailsLoading || detailsCancelled) && isDetails) {
        return (<LoadingResultsView results={project.results} progress={project.results.detailProgress} emailError={emailError} setEmailError={setEmailError} state={project.results.detailState} isDetails={isDetails != null} estimatedRemainingTime={''} hasSensitivities={project.results.sensitivityTable.header.sensitivity.length > 0} isEarlyAnalysis={info?.earlyAnalysis} cancelCalculation={cancelCalculation} gotoSetup={gotoSetup} restartCalculation={restartCalculation} cancelError={cancelError} cancelling={cancelling} />)
    }

    if (project.results.infeasible) {
        return (<InfeasibleResult calculate={calculate} />)
    }

    return (<>
        <LocalizationProvider language={intlCulture}>
            <IntlProvider locale={intlCulture}>
                <div className='result-side-nav-main-container d-flex flex-column h-100'>
                    <Row className='result-side-nav-main-container-row'>
                        <Col sm={3} md={2} lg={2} className='navigation-menu-col k-overflow-x-hidden overflow-auto'
                            style={{ height: 'calc(100vh - 173px)' }}>
                            <SideNavigationView />
                        </Col>
                        <Col sm={9} md={10} lg={10} className='navigation-content-col'>
                            <Router className='overflow-auto k-overflow-x-hidden'
                                style={{ height: 'calc(100vh - 173px)' }}>
                                <Redirect from='/' to='sensitivity/-' noThrow />
                                <SensitivityResults calculate={calculate} path='sensitivity/:selection' />
                                <GraphResults path='graph/-' />
                                <OptimizationResults calculate={calculate} path='optimization/:selection' />
                                <SelectedSystem calculate={calculate} path='details/:selection' />
                                <NoMatch default />
                            </Router>
                        </Col>
                    </Row>
                </div>
            </IntlProvider >
        </LocalizationProvider>
        <UnlockProjectModal show={store.showReadOnlyPopup} onClose={handleCloseReadOnlyPopup} />
    </>
    )
})

export default ResultsPage


interface LoadingViewProps {
    results: ResultModel
    progress: number
    state: ResultState
    isDetails: boolean
    estimatedRemainingTime: string
    hasSensitivities?: boolean
    isEarlyAnalysis?: boolean
    cancelCalculation: () => {}
    gotoSetup: () => {}
    restartCalculation: () => {}
    cancelError: boolean
    cancelling: boolean
    emailError: boolean
    setEmailError: (error: boolean) => void
}

const LoadingResultsView = ({ results, progress, state, estimatedRemainingTime, cancelCalculation, cancelError, cancelling, gotoSetup, restartCalculation, emailError, setEmailError }: LoadingViewProps) => {
    const [show, setShow] = useState(false)
    const [checked, setChecked] = useState(false)

    const onCheck = async (checked: boolean) => {
        if (checked)
            setShow(true)
        else {
            setChecked(false)
            if (emailError)
                setEmailError(false)
            await results.deleteEmailNotification()
        }
    }
    const onConfirm = async (userEmailNotification: UserEmailNotification) => {
        const r = await results.setEmailNotification(userEmailNotification)
        if (r) {
            setChecked(true)
            if (emailError)
                setEmailError(false)
        }
        else
            setEmailError(true)
        setShow(false)
    }
    const onCancel = () => {
        setShow(false)
        if (emailError)
            setEmailError(false)
    }

    return (
        <div className='d-flex flex-column h-100 align-items-center justify-content-center'>
            {state === 'loading' && <React.Fragment>
                <p className={(progress === 0 ? 'mt-3' :'mt-3 mb-3')}>Calculating results, please wait</p>
                {!cancelling && progress > 0 &&
                    <ProgressBar min={0} max={100} now={progress}
                        animated={true} style={{ width: '210px' }} />}
                {estimatedRemainingTime !== '' && <p className='mt-3'>{estimatedRemainingTime}</p>}

                <Form.Check id="sendEmail" disabled={progress === 0 || progress === 100}
                    type='checkbox' label={"Email me when calculation is complete"} custom
                    className='mt-2'
                    checked={checked}
                    onChange={ev => onCheck(ev.target.checked)} />
                <SendResultsEmail show={show && progress > 0 && progress < 100} onConfirm={onConfirm} onCancel={onCancel} />
                <p className="mt-1" hidden={!checked || progress === 0 || progress === 100}>You can close this tab, the calculation will continue</p>
                <p className="mt-1" hidden={!emailError}>Unable to set email, please try again or wait for the calculation</p>
                            </React.Fragment>
            }
        </div>
    )
}

interface SendResultsEmailModalProps {
    show: boolean
    onConfirm: (userEmailNotification: UserEmailNotification) => void
    onCancel: () => void
}

const SendResultsEmail = ({ show, onConfirm, onCancel }: SendResultsEmailModalProps) => {
    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState<string>('')

    const commitEmail = () => {
        var emailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!email.match(emailformat) || email == '')
            setErrors('Please enter a valid email')
        else if (email.length > 250) {
            setErrors('Email cannot have more than 250 characters')
        }
        else {
            const emailNotification: UserEmailNotification = { userEmail: email }
            onConfirm(emailNotification)
        }
    }

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setEmail(ev.target.value)
        setErrors('')
    }

    const c = 'form-control ' +
        (errors.length > 0 ? 'is-invalid ' : '')

    return (
        <Modal show={show} onHide={onCancel}>
            <Modal.Body>
                <p style={{ textAlign: "center", fontWeight: "bold" }}>Enter email address</p>
                <div className='row mt-2 ml-3 mr-3'>
                    <input type='text' id={'emailNotification'} className={c}
                        value={email}
                        onChange={handleChange}
                        autoFocus={true}
                    />
                    {errors.length > 0 &&
                        <div className='invalid-tooltip text-keep-lines'>
                            {errors}
                        </div>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='pillButton' onClick={onCancel} variant='outline-primary'>Cancel</Button>
                <Button className='pillButton' onClick={() => commitEmail()}>OK</Button>
            </Modal.Footer>
        </Modal>
    )
}

interface SendResultsEmailModalProps {
    show: boolean
    onConfirm: (userEmailNotification: UserEmailNotification) => void
    onCancel: () => void
}
interface ModelErrorsViewProps {
    issues: AnalyzerItem[]
    onClick?: (link: string) => void
}

const ModelErrorsView = observer(({ issues, onClick }: ModelErrorsViewProps) => {
    const project = useProjectStore().project!
    let uniqueIssues = issues.filter((obj, index, self) => {
        return index === self.findIndex((o) => o.label === obj.label);
    });
    return (
        <div className='d-flex flex-column align-items-center justify-content-center h-100'>
            <div>
                {uniqueIssues.map(item =>
                    <Alert key={item.label} variant={item.warning ? 'warning' : 'danger'} style={{ cursor: 'pointer' }}
                        onClick={() => item.link && onClick?.(item.link)}>
                        <span className='text-keep-lines'>{item.label}</span>
                    </Alert>)}
            </div>
            {!project.hasErrors &&
                <button className='btn btn-primary pillButton' onClick={() => project.setIgnoreWarnings()}>Next</button>}
        </div>
    )
})

export interface EventItem {
    index: number
    year: number
}
