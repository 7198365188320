import {v4} from 'uuid'
import {action, computed, makeObservable, observable} from 'mobx'
import { SensitivityVar } from './SensitivityVar'
import { ComponentKind } from '../../../services/inputs'
import { Sizing } from './Sizing'
import { TimeSeries } from './TimeSeries'
import { ComplexCostItem, ComplexCostTable, CostTable } from './CostTable'
import { MultiYearVar } from './MultiYearVar'
import { Inverter } from './Inverter'


export class Solar {
    id: string = v4()
    constructor(/*lifetime: number*/) {
        this.cost.complex = defaultSolarComplexCostTable()
        makeObservable(this)
    //    this.endYear = lifetime
    }

    @observable kind: ComponentKind = ComponentKind.homer

    // homer
    @observable sizing: Sizing = new Sizing()

    // custom
    @observable customSize: number = 1 // MW
    @observable customSizeArray: Sizing = new Sizing()
    @observable customData: TimeSeries = new TimeSeries()
    @observable customName: string = ''
    @observable customAbbreviation: string = ''

    @observable cost: CostTable = new CostTable()
    @observable omEscalator: MultiYearVar = new MultiYearVar()
    @observable degradation: MultiYearVar = new MultiYearVar()
    @observable deratingFactor: SensitivityVar = new SensitivityVar()
    @observable lifetime: SensitivityVar = new SensitivityVar(20)
    @observable allowReplacement: boolean = false

    @observable hasConverter: boolean = false
    @observable converter: Inverter = new Inverter()

    //@observable startYear: number = 1 // MW
    //@observable endYear: number = 2 // MW

    @action setKind(x: ComponentKind) { this.kind = x }
    @action setCustomSize(x: number) { this.customSize = x }
    @action setCustomName(x: string) { this.customName = x }
    @action setCustomAbbreviation(x: string) { this.customAbbreviation = x }
    @action setHasConverter(x: boolean) { this.hasConverter = x }
    @action setAllowReplacement(x: boolean) { this.allowReplacement = x }

    //@action setStartYear(x: number) { this.startYear = x }
    //@action setEndYear(x: number) { this.endYear = x }

    @computed get isZero(): boolean {
        switch (this.kind) {
            case ComponentKind.homer: { return this.sizing.onlyZero }
            case ComponentKind.custom: { return this.customSize === 0 && this.customSizeArray.onlyZero }
            default: { return true }
        }
    }

    estimateOptimizationSimulations(): number {
        const converter = this.hasConverter ? this.converter.estimateOptimizationSimulations() : 1
        const size = this.kind === ComponentKind.homer ? this.sizing.estimateSimulations() : this.customSizeArray.estimateSimulations()
        return converter * size
    }

    estimateSensitivitySimulations(): number {
        const converterSens = this.hasConverter ? this.converter.estimateSensitiviySimulations() : 1
        const cost = this.cost.estimateSimulations()
        const sens = this.kind === ComponentKind.homer ? this.deratingFactor.estimateSimulations() : 1
        return converterSens * cost * sens
    }
}


function defaultSolarComplexCostTable(): ComplexCostTable {
    const rv = new ComplexCostTable()
    rv.directCapital.items.splice(0, 0, ...[
        new ComplexCostItem({name: 'Module', unit: 'CostPerWattDc'}),
        new ComplexCostItem({name: 'Power Conversion System', unit: 'CostPerWattDc'}),
        new ComplexCostItem({name: 'Balance of System', unit: 'CostPerWattDc'}),
        new ComplexCostItem({name: 'Installation Labor', unit: 'CostPerWattDc'}),
        new ComplexCostItem({name: 'Installer Overhead and Margin', unit: 'CostPerWattDc'}),
        new ComplexCostItem({name: 'Contingency', unit: 'PercentOfDirectCapital'}),
    ])
    rv.indirectCapital.items.splice(0, 0, ...[
        new ComplexCostItem({name: 'Permitting and Environmental', unit: 'CostPerWattDc'}),
        new ComplexCostItem({name: 'Engineering and Developer Overhead', unit: 'CostPerWattDc'}),
        new ComplexCostItem({name: 'Land Purchase, Preparation and Transmissions', unit: 'CostPerWattDc'}),
        new ComplexCostItem({name: 'Working Capital Reserve Account', unit: 'Cost'}),
        new ComplexCostItem({name: 'Sales Tax Rate', unit: 'PercentOfDirectCapital'}),
    ])
    rv.operating.items.splice(0, 0, ...[
        new ComplexCostItem({name: 'Fixed Annual Cost', unit: 'CostPerYear'}),
        new ComplexCostItem({name: 'Fixed Cost by Capacity', unit: 'CostPerMwDcYear'}),
        new ComplexCostItem({name: 'Variable Cost by PV Generation', unit: 'CostPerMwhAc'}),
        new ComplexCostItem({name: 'Insurance', unit: 'PercentOfDirectCapitalPerYear'}),
        new ComplexCostItem({name: 'Audit & Program Management Cost', unit: 'CostPerYear'}),
        new ComplexCostItem({name: 'Site Lease Cost', unit: 'CostPerYear'}),
        new ComplexCostItem({name: 'Property Tax Rate', unit: 'PercentOfDirectCapitalPerYear'}),
        new ComplexCostItem({name: 'Station Power Cost', unit: 'CostPerYear'}),
        new ComplexCostItem({name: 'Interest on All Reserves', unit: 'CostPerYear'}),
        new ComplexCostItem({name: 'Solar Decommissioning Reserve', unit: 'CostPerMwDcYear'}),
    ])
    rv.replacement.items.splice(0, 0, ...[
        new ComplexCostItem({name: 'Replacement Cost', unit: 'CostPerWattDc'}),
    ])
    return rv
}
