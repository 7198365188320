import { useProjectStore } from '../../ProjectProvider'
import WindLossesView from './WindLossesView'
import * as React from 'react'

const ResultWindCalculations = () => {
    const project = useProjectStore().project!
    const intlCulture = project.useCommaAsDecimalPoint ? 'es' : 'en'
    return (<>
        <div className='form-group d-flex flex-column mb-2'>
            <h5 className='mt-2 font-size-16 font-weight-bold'>Parameters for Selected Wind Turbines</h5>
            <div className='w-100  mr-1 p-3'>
                <WindLossesView model={project.wind[0]} intlCulture={intlCulture}></WindLossesView>
            </div>
        </div>

    </>)
}

export default ResultWindCalculations
