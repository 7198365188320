import { RouteComponentProps } from '@reach/router'
import { Project } from '../../model'
import { WindResult } from '../../results-details'
import { observer } from 'mobx-react-lite'
import ResultWindCalculations from '../../pages/Results/ResultWindCalculations'
import PowerCurveChart from '../../pages/equipment/PowerCurveChart'
import ResultWindMetricTable from '../../pages/Results/ResultWindMetricTable'
import ResultWindChartByYear from '../../pages/Results/ResultWindChartByYear'
import ResultWindChartByMonth from '../../pages/Results/ResultWindChartByMonth'
import api from '../../../../services/api'
import * as React from 'react'

interface ResultWindProps extends RouteComponentProps {
    result: WindResult
    model: Project
}

const ResultWind = observer(({ result, model }: ResultWindProps) => {
    const intlCulture = model.useCommaAsDecimalPoint ? 'es' : 'en'
    const dateFormat = model.dateFormat
    const showBaseYearAsYearZero = api.showBaseYearAsYearZero

    return (
        <>
        <div className='row m-2'>
            <div className='col-6'>
                <div className='row'>
                    <div className='col-12'>
                        <ResultWindChartByYear items={result.windTurbines[0].items} showBaseYearAsYearZero={showBaseYearAsYearZero} baseYear={model.baseYear} />
                    </div>
                    <div className='col-12'>
                        <ResultWindChartByMonth values={result.windTurbines[0].totalProductionByMonth} />
                    </div>
                </div>
            </div>
            <div className='col-6'>
                <div className='row'>
                    <div className='col-12'>
                        <ResultWindMetricTable result={result.windTurbines[0]!} model={model} intlCulture={intlCulture} dateFormat={dateFormat} />
                    </div>
                    <div className='col-12' hidden={model.wind[0].kind === 'production'}>
                        <h5 className='font-size-16 font-weight-bold'>Wind Turbine Power Curve</h5>
                        <PowerCurveChart model={model.wind[0].specs?.powerCurve} id={model.wind[0].id} yAxisTitle='Power Output(MW)' />
                    </div>
                    <div className='col-12' hidden={model.wind[0].kind === 'production'}>
                        <ResultWindCalculations />
                    </div>
                </div>
            </div>
        </div>
    </>)
})

export default ResultWind
