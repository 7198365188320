import {useState, useEffect, ChangeEvent, FocusEvent, KeyboardEvent,ClipboardEvent} from 'react'
import { useInternationalization } from 'Kendo-Intl-5'
import { ValidationFunc } from './validation'
import * as React from 'react'

interface NumberInputProps {
    value: number
    onChange?: (x: number) => void
    onEnter?: () => void

    id?: string
    className?: string
    disabled?: boolean,
    autoFocus?: boolean

    validator?: ValidationFunc
    onPaste?: (x: ClipboardEvent<HTMLInputElement>) => void
    useCommaAsDecimalPoint: boolean
}

function formatDecimal(originalNumber: number): string {
    if (originalNumber === 0) return '0'

    let stringNumber = originalNumber.toString()
    // If in scientific notation, convert to decimal
    if (stringNumber.includes('e') || stringNumber.includes('E')) {
        stringNumber = originalNumber.toFixed(20)
    }

    // Remove trailing zeroes after decimal (and possible trailing '.')
    stringNumber = stringNumber.replace(/(\.\d*?[1-9])0+$/g, '$1').replace(/\.0+$/, '')

    return stringNumber
}

const NumberInput = ({value, onChange, onEnter, id, className, disabled, autoFocus, validator, onPaste, useCommaAsDecimalPoint}: NumberInputProps) => {
    let formatedText = formatDecimal(value)
    const intl = useInternationalization()
    if (useCommaAsDecimalPoint)
        formatedText = formatedText.replace(/\,/g, "").replace(/\./g, ",")

    const [text, setText] = useState(formatedText)
    const [errors, setErrors] = useState<string[]>([])

    useEffect(() => {
        let formatedText = formatDecimal(value);
        if (useCommaAsDecimalPoint) {
          formatedText = formatedText.replace(/,/g, '').replace(/\./g, ',');
        }

        // If it matches our current text's parse, skip re-committing
        // to avoid calling onChange repeatedly.
        const parsedCurrent = parseNumberFromText(text, useCommaAsDecimalPoint);
        if (parsedCurrent !== value) {
          setText(formatedText);
          commit(formatedText);
        } else {
          setText(formatedText);
        }
    }, [value, useCommaAsDecimalPoint]);

    function parseNumberFromText(s: string, commaAsDecimal: boolean): number {
        let replaced = s;
        if (commaAsDecimal) {
          replaced = replaced.replace(/\./g, "").replace(/,/g, ".");
        }
        return Number(replaced);
    }

    const commit = (inputText: string) => {
        let formatedInputText = inputText
        if (useCommaAsDecimalPoint)
            formatedInputText = formatedInputText.replace(/\./g, "").replace(/\,/g, ".")
        const value = Number(formatedInputText)
        const good = !Number.isNaN(value)
        if (!good) {
            const errors = ['Enter a number']
            setErrors(errors)
            if (useCommaAsDecimalPoint)
                formatedInputText = formatedInputText.replace(/\./g, "").replace(/\,/g, ".")
        } else {
            const errors = validator?.(value) ?? []
            setErrors(errors)
            onChange?.(value)
        }
    }

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setText(ev.target.value)
        setErrors([])
    }

    const handleOnPaste = (ev: ClipboardEvent<HTMLInputElement>) => {
        onPaste?.(ev)
    }
    const handleBlur = (ev: FocusEvent<HTMLInputElement>) => {
        commit(text)
    }

    const handleKey = (ev: KeyboardEvent<HTMLInputElement>) => {
        if (ev.key !== 'Enter') return
        commit(text)
        onEnter?.()
    }

    const c = 'form-control ' +
        (errors.length > 0 ? 'is-invalid ' : '') +
        (className ?? '')

    return (
        <>
            <input type='text' id={id} className={c} disabled={disabled}
                   value={text}
                   onChange={handleChange}
                   onBlur={handleBlur}
                   onKeyPress={handleKey}
                autoFocus={autoFocus}
                onPaste={handleOnPaste}/>
            {errors.length > 0 &&
            <div className='invalid-tooltip text-keep-lines'>
                {errors.join('\n')}
            </div>}
        </>
    )
}


export default NumberInput
