import {v4} from 'uuid'
import {makeObservable, observable} from 'mobx'
import { Sizing } from './Sizing'
import { CostTable } from './CostTable'
import { MultiYearVar } from './MultiYearVar'
import { SensitivityVar } from './SensitivityVar'


export class Inverter {
    id: string = v4()
    constructor() { makeObservable(this) }

    @observable sizing: Sizing = new Sizing()
    @observable cost: CostTable = new CostTable()
    @observable omEscalator: MultiYearVar = new MultiYearVar()
    @observable replacementEscalator: MultiYearVar = new MultiYearVar()
    @observable efficiency: SensitivityVar = new SensitivityVar(98) // (%)
    @observable lifetime: SensitivityVar = new SensitivityVar(15) // (years)

    estimateOptimizationSimulations(): number {
        const size = this.sizing.estimateSimulations()
        return size
    }

    estimateSensitiviySimulations(): number {
        const sens =
            this.efficiency.estimateSimulations() *
            this.lifetime.estimateSimulations()
        const cost = this.cost.estimateSimulations()
        return sens * cost
    }
}
