import { observer } from 'mobx-react-lite'
import { Dropdown, Form, Row } from 'react-bootstrap'
import * as React from 'react'
import { useState } from 'react'
import { GoX } from 'react-icons/all'
import { RegulationMarket } from '../../model/RegulationMarket'
import StringField from '../../../helper/StringField'
import { StrValidation } from '../../../helper/StringValidation'
import TimeSeriesImport from '../../../helper/TimeSeriesImport'
import TimeSeriesView from '../../../helper/TimeSeriesView'
import { TimeSeries } from '../../model/TimeSeries'
import { getEnergyMarketKindLabel } from '../../model/EnergyMarket'
import { DeleteComponentState } from '../equipment/shared/DeleteConfirmationModal'
import SuccessImportModal from '../equipment/shared/ImportSuccessModal'
import ContextHelp from '../../../helper/ContextHelp'
import { useProjectStore } from '../../ProjectProvider'
import SensitivityVarView from '../EnergyMarket/SensitivityVarView'

interface RegulationMarketCollectionProps {
    onDelete: (componentToDelete: DeleteComponentState) => void
}

const RegulationMarketCollection = ({ onDelete }: RegulationMarketCollectionProps) => {
    const project = useProjectStore().project!
    const useCommaAsDecimalPoint = project.useCommaAsDecimalPoint
    const onDeleteMarket = (id: string) => {
        onDelete({ kind: 'Regulation Market', id: id })
    }
    return (<>
        {project.regulationMarkets.map((x, index) =>
            <React.Fragment key={x.id}>
                {index > 0 && <hr className='bg-dark' style={{width:'90%'}} />}
                <RegulationMarketView model={x} index={index} useCommaAsDecimalPoint={useCommaAsDecimalPoint} onDelete={onDeleteMarket} />
            </React.Fragment>)}
        {/*{project.canAddRegulationMarket &&*/}
        <div className='d-flex flex-column align-items-center my-4'>
            <button className='btn btn-outline-primary pillButton' onClick={() => project.addNewRegulationMarket()}>Add another regulation market</button>
        </div >
        {/*}*/}
    </>)
}

export default observer(RegulationMarketCollection)


interface RegulationMarketProps {
    model: RegulationMarket
    index: number
    useCommaAsDecimalPoint: boolean
    onDelete?: (id: string) => void
}


const RegulationMarketView = observer(({ model, index, useCommaAsDecimalPoint, onDelete }: RegulationMarketProps) => {
    const store = useProjectStore()
    const project = store.project!
    const currency = project.currencySymbol ?? "$"
    const useCommaAsDecimalSeparator = project.useCommaAsDecimalPoint
    const intlCulture = project.useCommaAsDecimalPoint ? 'es' : 'en'

    //const handleSelectEnergyPrice = async (data: number[], year: number) => {
    //    model.setEnergyData(data, year)
    //    await store.saveProject()
    //}
    const handleSelectRegUpPrice = async (data: number[], year: number) => {
        model.setRegUpData(data, year)
        await store.saveProject()
    }
    const handleSelectRegDownPrice = async (data: number[], year: number) => {
        model.setRegDownData(data, year)
        await store.saveProject()
    }
    model.name = model.name === '' ? `Regulation Market ${index + 1}` : model.name
    model.abbreviation = model.abbreviation === '' ? `Reg Mkt ${index + 1}` : model.abbreviation

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
    const closeSuccessModal = () => {
        setShowSuccessModal(false)
    }
    const openSuccessModal = () => {
        setShowSuccessModal(true)
    }

    return (
        <>
            <div className='form-group d-flex mb-2' id={model.id}>
                <div className='border rounded w-50 mr-1 p-2'>
                    <Row>
                        <StringField label="Market Name" value={model.name} onChange={x => model.setName(x)} validator={StrValidation.maxLen(40)} />
                        <div className='ml-2'></div>
                        <ContextHelp helpId='REGMARKET' />
                    </Row>
                    <Row>
                        <StringField label="Abbreviation" className='ml-2' value={model.abbreviation} onChange={x => model.setAbbreviation(x)} validator={StrValidation.maxLen(20)} />
                    </Row>
                </div>
                <div className={'border rounded p-2 w-50 ml-1'}>
                    <div className='d-flex align-items-center col-12 pb-2 mt-2'>
                        {/*<div className='mt-2 ml-3' >*/}
                        <SensitivityVarView model={model.throughputPercentage} label='Storage Throughput (%)' labelWidth='col-6' units='%' useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                        <ContextHelp helpId='REGMARKETTHROUGHPUT' />
                        {/*</div>*/}
                    <div className='m-auto' />
                        <button className='btn btn-outline-danger pillButton' onClick={() => onDelete?.(model.id)}><GoX /></button>
                    </div>
                    <div className='d-flex align-items-center col-12 pb-2 mt-4'>
                        <Form.Check custom label='Include Energy Settlement Revenue' id={`useRegulationMarketEnergyPrice-${model.id}`}
                            checked={model.useEnergyPrice}
                            onChange={ev => model.setUseEnergyPrice(ev.target.checked)} />
                        <div className='ml-5' />
                        <ContextHelp helpId='SETTLEMENT' />
                    </div>
                    <div className='col-12 mt-3'>
                        <Row>
                            <div className={`ml-3 mt-1 ${(!project.useEnergyMarket || !model.useEnergyPrice ? "aria-disabled" : "")}`}>Energy Price</div>
                            <div className='ml-3'> <Dropdown>
                                <Dropdown.Toggle className='pillButton' variant='outline-primary' id={`dropdown-basic-${model.id}`} disabled={!project.useEnergyMarket || !model.useEnergyPrice}>
                                    {model.energyMarket?.name ?? 'Select Energy Market'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {project.energyMarkets.map(x =>
                                        <Dropdown.Item key={x.id} onClick={() => { model.setEnergyMarket(x) }}>
                                            {getEnergyMarketKindLabel(x.kind)}
                                        </Dropdown.Item>)}
                                </Dropdown.Menu>
                            </Dropdown>
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
            <div className='form-group d-flex mb-2'>
                <div className='border rounded w-50 mr-1 p-2'>
                    <div className='d-flex align-items-center col-12 pb-2 mt-2'>
                        <Form.Check custom label='Reg-Up' id={`useRegulationMarketRegUpPrice-${model.id}`}
                            checked={model.useRegUp}
                            onChange={ev => model.setUseRegUp(ev.target.checked)} />
                        <div className='ml-5' />
                        <ContextHelp helpId='REGUP' />
                    </div>
                    <div className='col-12'>Reg-Up Price ({currency}/MWh)</div>
                    <div className='col-12 labelHelpText mt-1'>
                        <p>Negative values will be replaced by zeros</p>
                    </div>
                    <div className='d-flex align-items-center col-12 pb-2 mt-2 mb-1'>
                        <TimeSeriesImport onSelect={handleSelectRegUpPrice} disabled={!model.useRegUp} showSuccessModal={openSuccessModal} removeZeros={true} />
                    </div>
                    <div className='ml-3 mr-4' >
                        <TimeSeriesView intlCulture={intlCulture} model={model.useRegUp && model.regUpPrice !== undefined && model.regUpPrice.data.length > 0 ? model.regUpPrice : new TimeSeries()} chartTitle={model.regUpPriceName} yAxisTitle={`${currency}/MWh`} />
                    </div>
                    <div className='mt-3 ml-3' >
                        <SensitivityVarView model={model.maxCommitmentRegUp} label='Max Reg-Up Storage Commitment (%)' units='%' useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                        <SensitivityVarView model={model.minRegUpPrice} label={`Min Reg-Up Capacity Price (${currency}/MWh)`} units={`${currency}/MWh`} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    </div>
                </div>
                <div className='border rounded w-50 ml-1 p-2'>
                    <div className='d-flex align-items-center col-12 pb-2 mt-2'>
                        <Form.Check custom label='Reg-Down' id={`useRegulationMarketRegDownPrice-${model.id}`}
                            checked={model.useRegDown}
                            onChange={ev => model.setUseRegDown(ev.target.checked)} />
                        <div className='ml-5' />
                        <ContextHelp helpId='REGDOWN' />
                        <div className='m-auto' />
                        { }
                    </div>
                    <div className='col-12'>Reg-Down Price ({currency}/MWh)</div>
                    <div className='col-12 labelHelpText mt-1'>
                        <p>Negative values will be replaced by zeros</p>
                    </div>
                    <div className='d-flex align-items-center col-12 pb-2 mt-2 mb-1'>
                        <TimeSeriesImport onSelect={handleSelectRegDownPrice} disabled={!model.useRegDown} showSuccessModal={openSuccessModal} removeZeros={true} />
                    </div>
                    <div className='ml-3 mr-4' >
                        <TimeSeriesView intlCulture={intlCulture} model={model.useRegDown && model.regDownPrice !== undefined && model.regDownPrice.data.length > 0 ? model.regDownPrice : new TimeSeries()} chartTitle={model.regDownPriceName} yAxisTitle={`${currency}/MWh`} />
                    </div>
                    <div className='mt-3 ml-3' >
                        <SensitivityVarView model={model.maxCommitmentRegDown} label='Max Reg-Down Storage Commitment (%)' units='%' useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                        <SensitivityVarView model={model.minRegDownPrice} label={`Min Reg-Down Capacity Price (${currency}/MWh)`} units={`${currency}/MWh`} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    </div>
                </div>
            </div>
            <SuccessImportModal show={showSuccessModal} onConfirm={closeSuccessModal} />
        </>
    )
})
