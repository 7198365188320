import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartLegend,
    ChartValueAxis,
    ChartValueAxisItem,
} from '@progress/kendo-react-charts'
import { RouteComponentProps } from '@reach/router'
import * as React from 'react'
import { range } from '../../../../utils'

interface chartsSeriesComponentProps extends RouteComponentProps {
    noOfYears: number
    chartData1: number[]
    chartData2: number[] | null
    showBaseYearAsYearZero: boolean
    baseYear: number
    chartTitle: string
    currency: string
}

const CompareEconomicsCumulativeCashFlowChart = ({ noOfYears, currency, showBaseYearAsYearZero, baseYear, chartTitle, chartData1, chartData2 }: chartsSeriesComponentProps) => {
    const years = range(0, noOfYears).map(i => showBaseYearAsYearZero ? baseYear - 1 + i : i)

    return (
        <Chart>
            <ChartTitle text={chartTitle} />
            <ChartLegend position='bottom' orientation='horizontal' />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem baseUnitStep={2} axisCrossingValue={[0, years.length]} categories={years} />
            </ChartCategoryAxis>
            <ChartValueAxis>
                <ChartValueAxisItem key='cumulative' labels={{ format: '{0:n}', rotation: 'auto' }} name='cumulative' />
            </ChartValueAxis>
            <ChartSeries>
                {chartData2 && < ChartSeriesItem key='annualBaseCase' type='line' data={chartData2} name='Cumulative Base Case' color='#67B1E2' axis='cumulative' />}
                <ChartSeriesItem key='annualProposedSystem' type='line' data={chartData1} name='Cumulative Difference' color='#ffa500' axis='cumulative' />
            </ChartSeries>
        </Chart>
    )
}
export default CompareEconomicsCumulativeCashFlowChart
