import { observer } from "mobx-react-lite"
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import ContextHelp from "../helper/ContextHelp";
import NumberInput from "../helper/NumberInput";
import { Validation } from "../helper/validation";
import { UserStorageClass } from "./UserStorage"
import { TemperatureLifetimeChart } from "./UserStorageChart";
import StorageCurveTable from "./UserStorageCurveTable";
import api from "services/api";
import { RecalculateLifetimeTemperatureRequestDto } from "services/Service";

interface StorageFuncModelTabProps {
    model: UserStorageClass
    useCommaAsDecimalPoint: boolean
}

const StorageLibraryTempLifetimeTab = observer(({ model, useCommaAsDecimalPoint }: StorageFuncModelTabProps) => {
    const [ignoreTempEffectOnLifetime, setIgnoreTempEffectOnLifetime] = useState(model.ignoreTemperatureEffectOnLifetime)
    const [isLoading, setIsLoading] = useState(false)
    const [showError, setShowError] = useState(false);

    const handleRecalculateLifetimeTemperature = async () => {
        setIsLoading(true);

        const requestData: RecalculateLifetimeTemperatureRequestDto = {
            IgnoreTemperatureEffectOnLifetime: ignoreTempEffectOnLifetime,
            SimpleLifetimeInYears: model.simpleLifetimeInYears,
            DegradationLimitForFit: model.capacityDegradationLimitForFitLifetimeTemperatureModel,
            TableData: model.lifetimeTemperatureCurve.map(item => ({
                Lifetime: item.x,
                Temperature: item.y
            }))
        }

        try {
            const response = await api.recalculateLifetimeTemperature(requestData);
            model.setUserStorageArrheniusDegredationB(response.arrheniusDegredationB ?? 0);
            model.setUserStorageArrheniusDegredationD(response.arrheniusDegredationD ?? 0);
            setShowError(false);
        } catch {
            setShowError(true);
        } finally {
            setIsLoading(false)
        }
    }


    return (
        <>
            <Row>
                <Form.Check className='col-4 ml-3 mt-2' custom label='Use Constant Lifetime Regardless of Temperature (years):' id='useConstantShelfLifeRegardlessOfTemp'
                    checked={model.ignoreTemperatureEffectOnLifetime}
                    onChange={ev => { model.setUserStorageIgnoreTemperatureEffectOnLifetime(ev.target.checked); setIgnoreTempEffectOnLifetime(ev.target.checked) }} />
                <NumberInput  value={model.simpleLifetimeInYears}
                    onChange={model.setUserStorageSimpleLifetimeInYears}
                    disabled={!ignoreTempEffectOnLifetime}
                    className="mt-1 col-1"
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0)}/>
            </Row>
            <Row className="mt-2 d-flex flex-column align-items-center justify-content-center">

                <h4>Lifetime Curve</h4>
            </Row>
            <Row>
                <Col xs={3}>
                    <StorageCurveTable disabled={ignoreTempEffectOnLifetime} model={model.lifetimeTemperatureCurve} useCommaAsDecimalPoint={useCommaAsDecimalPoint} setItem={model.setUserStorageLifetimeTemperatureCurveItem} deleteItem={model.userStorageLifetimeTemperatureCurveDeleteItem} addItem={model.userStorageLifetimeTemperatureCurveAddRow} columnHead1="Lifetime (years)" columnHead2="Temperature (C)" />
                    <div hidden={!showError} className='text-danger'>Could not recalculate, input is invalid </div>
                </Col>
                <Col xs={5}>
                    <TemperatureLifetimeChart model={model.lifetimeTemperatureCurve} />
                </Col>
                <Col xs={4}>
                    <Row>
                        <label className="col-7 mt-2">Degradation Limit for Model Fitting (%):</label>
                        <NumberInput disabled={ignoreTempEffectOnLifetime} value={model.capacityDegradationLimitForFitLifetimeTemperatureModel}
                            onChange={model.setUserStorageCapacityDegradationLimitForFitLifetimeTemperatureModel}
                            className="mt-1 col-2 mb-4 mr-4"
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.range(0, 100)} />
                        <ContextHelp helpId='USERLIBRARYTEMPLIFETIME' />
                    </Row>
                    <p><b>Model Parameters Fitted from Data:</b></p>
                    <p>kt = B*e^(-d/T)</p>
                    <Row>
                        <label className="col-7 mt-2">Fitted B:</label>
                        <NumberInput
                            disabled={ignoreTempEffectOnLifetime}
                            value={model.arrheniusDegredationB}
                            onChange={model.setUserStorageArrheniusDegredationB}
                            className="mt-1 col-2"
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint}
                            validator={Validation.min(0, false)}
                        />
                    </Row>
                    <Row>
                        <label className="col-7 mt-2">Fitted D:</label>
                        <NumberInput disabled={ignoreTempEffectOnLifetime} value={model.arrheniusDegredationD}
                            onChange={model.setUserStorageArrheniusDegredationD}
                            className="mt-1 col-2"
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0, false)}/>
                    </Row>
                    <Button variant="primary" onClick={handleRecalculateLifetimeTemperature} disabled={isLoading}>
                        {isLoading ? 'Recalculating...' : 'Recalculate Lifetime Temperature'}
                    </Button>
                </Col>
            </Row>
            <Row className="mt-1 d-flex">

            </Row>
        </>
    )
})

export default StorageLibraryTempLifetimeTab
