import { observer } from 'mobx-react-lite'
import { RouteComponentProps } from '@reach/router'
import { Form } from 'react-bootstrap'
import orderofCommit from 'assets/images/order-of-commitment.svg'
import { IntlProvider, LocalizationProvider } from 'Kendo-Intl-5'
import { UnlockProjectModal } from '../ProjectView'
import DeleteConfirmationModal, { DeleteComponentState } from './equipment/shared/DeleteConfirmationModal'
import { useState } from 'react'
import * as React from 'react'
import { useProjectStore } from '../ProjectProvider'
import ContextHelp from '../../helper/ContextHelp'
import OverflowComponent from './SharedComponents/OverflowComponent'
import CollapsibleGroup from '../../helper/CollapsibleGroup'
import EnergyMarketCollection from './revenue/EnergyMarket'
import RegulationMarketCollection from './revenue/RegulationMarket'
import CapacityMarketCollection from './revenue/CapacityMarket'
import ContractCollection from './revenue/Contract'

interface RevenuePageProps extends RouteComponentProps { }

const RevenuePage = (_: RevenuePageProps) => {
    const store = useProjectStore()
    const project = store.project!
    const intlCulture = project.useCommaAsDecimalPoint ? 'es' : 'en'
   
    const onSelectEnergyMarket = (value: boolean) => { project.setUseEnergyMarket(value) }
    const onSelectRegulationMarket = (value: boolean) => { project.setUseRegulationMarket(value) }
    const onSelectCapacityMarket = (value: boolean) => { project.setUseCapacityMarket(value) }
    const onSelectContract = (value: boolean) => { project.setUseContract(value) }
    //const getPercentage = (model: Commitments) => {
    //    model.percentage = (model.name == 'Capacity market' ? project.capacityMarkets.map(x => x.allocatedStorageCapacity)!.reduce((a, b) => a + b, 0) : (model.name == 'Time of Delivery Contract' ? project.contracts.map(x => x.contractedStorageCapacity)!.reduce((a, b) => a + b, 0) : 0));
    //    return model.percentage ? <span className='font-weight-bold'>{(` (${model.percentage}% storage capacity)`)}</span> : ''
    //}
    const handleCloseReadOnlyPopup = async () => {
        await store.updateShowReadOnlyPopup(false)
    }
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [componentToDelete, setComponentToDelete] = useState<DeleteComponentState>({ kind: 'Component' })
    const onDelete = (componentToDelete: DeleteComponentState) => {
        setComponentToDelete(componentToDelete)
        setShowDeleteModal(true)
    }
    const onConfirmDelete = () => {
        switch (componentToDelete.kind) {
            case 'Energy Market':
                project.removeEnergyMarket(componentToDelete.id!)
                break;
            case 'Regulation Market':
                project.removeRegulationMarket(componentToDelete.id!)
                break;
            case 'Capacity Market':
                project.removeCapacityMarket()
                break;
            case 'TOD Contract':
                project.removeContract()
                break;
            default:
                break;
        }
        setShowDeleteModal(false)
    }
    const html = <div>
        <div className='d-flex align-items-center justify-content-between mb-3'>
            <h6 className='mr-2'>Order of Commitment</h6>
        </div>
        <table className='table table-borderless'>
            <tbody>
                {project.orderOfCommitments.slice().sort((a, b) => {

                    return a.reCorder - b.reCorder;
                }).map((model, i) =>
                    <tr key={model.id}>
                        <td className='py-1 align-middle'>{i + 1}.</td>
                        <td className='py-1 align-middle'>{model.name}</td>
                    </tr>)}
            </tbody>
        </table>
        <div>Capacity market events take priority over time-of-delivery contracts, which take priority over participation in energy and regulation markets.</div>
        <br/>
        <a href="https://support.ul-renewables.com/homer-manual-front/order_of_commitment.html">See more about Order of Commitment</a>
        <br />
        <a href="https://support.ul-renewables.com/homer-manual-front/front_dispatch_strategy.html">See more about Multiple Energy Markets</a>
    </div>
    return (<>
        <LocalizationProvider language={intlCulture} >
            <IntlProvider locale={intlCulture}>
                <div className='d-flex flex-column p-4' style={{ height: 'calc(100vh - 173px)' }}>
                    <div className='d-flex'>
                        <div className='mr-3'>
                            <Form.Check custom label='Energy Market' id='useEnergyMarket'
                                checked={project.useEnergyMarket}
                                onChange={ev => onSelectEnergyMarket(ev.target.checked)} />
                        </div>
                        <div className='mr-3'>
                            <Form.Check custom label='Regulation Market' id='useRegulationMarket'
                                checked={project.useRegulationMarket}
                                onChange={ev => onSelectRegulationMarket(ev.target.checked)} />
                        </div>
                        <div className='mr-3'>
                            <Form.Check custom label='Capacity Market' id='useCapacityMarket'
                                checked={project.useCapacityMarket}
                                onChange={ev => onSelectCapacityMarket(ev.target.checked)} />
                        </div>
                        <div className='mr-3'>
                            <Form.Check custom label='Time of Delivery Contract' id='useContract'
                                checked={project.useContract}
                                onChange={ev => onSelectContract(ev.target.checked)} />
                        </div>
                        <div className='m-auto' />
                        <ContextHelp helpId='APPLICATION' />
                        {project.commitments.length > 0 &&
                            <div className='m-1'>
                                <OverflowComponent title=" " toolTipTitle="Order of Commitments" children={html} icon={orderofCommit} />
                            </div>
                        }
                    </div>

                    <div className='py-2' />

                    <div className='flex-fill overflow-scroll k-overflow-x-hidden'>
                        {project.useEnergyMarket &&
                            <CollapsibleGroup key='energyMarket' title='Energy Market' className='mb-4'>
                                <EnergyMarketCollection onDelete={onDelete} />
                            </CollapsibleGroup>}

                        {project.useRegulationMarket &&
                            <CollapsibleGroup key='regulationMarket' title='Regulation Market' className='mb-4'>
                                <RegulationMarketCollection onDelete={onDelete} />
                            </CollapsibleGroup>}

                        {project.useCapacityMarket &&
                            <CollapsibleGroup key='capacityMarket' title='Capacity Market' className='mb-4'>
                                <CapacityMarketCollection onDelete={onDelete} />
                            </CollapsibleGroup>}

                        {project.useContract &&
                            <CollapsibleGroup key='contract' title='Time of Delivery Contract' className='mb-4'>
                                <ContractCollection onDelete={onDelete} />
                            </CollapsibleGroup>}
                    </div>
                </div>
            </IntlProvider >
        </LocalizationProvider>
        <UnlockProjectModal show={store.showReadOnlyPopup} onClose={handleCloseReadOnlyPopup} />
        <DeleteConfirmationModal show={showDeleteModal} componentState={componentToDelete} onCancel={() => setShowDeleteModal(false)} onConfirm={onConfirmDelete} />
        </>
        
    )
}

export default observer(RevenuePage)
