import NoMatch from './components/helper/NoMatch'
import ProjectView, { ProjectHeaderView } from './components/project/ProjectView'
import LibraryView from './components/library/LibraryView'
import logo from 'assets/images/logo-horizontal.svg'
import { Link, Redirect, Router } from '@reach/router'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import UserStorageLibraryView from './components/userLibrary/UserStorageLibraryView'
import UserStorageProvider from './components/userLibrary/UserStorageProvider'
import { UserStorage } from './components/userLibrary/UserStorage'
import UserStorageLibraryComponentView from './components/userLibrary/UserStorageLibraryEditView'
import { Row } from 'react-bootstrap'
import api from './services/api'
import { ProjectStore } from './components/project/ProjectStore'
import ProjectProvider from './components/project/ProjectProvider'
import LoginView from './components/auth/LoginView'
import version from './version.json'
import envVariables from './env-variables.json'


const App = () => {
    const [online, setOnline] = useState(false)

    useEffect(() => {
        api.authenticationHandler = () => {
            console.info('401:Unauthorized')
            setOnline(false)
        }
    }, [])

    const handleLogin = async (name: string, password: string) => {
        const result = await api.login(name, password)
        if (result.success) {
            setOnline(true)
        }
        return result
    }

    const [projectStore] = useState(new ProjectStore())
    const [userStorage] = useState(new UserStorage())

    const appInsights = new ApplicationInsights({
        config: {
            connectionString: envVariables[process.env.NODE_ENV].appInsightsConnString
        }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();

    return online ?
        <>
            <ProjectProvider value={projectStore}>
                <UserStorageProvider value={userStorage}>
                    <Header />
                    <Main />
                    <Footer />
                </UserStorageProvider>
            </ProjectProvider>
        </> :
        <>
            <LoginView onLogin={handleLogin} />
        </>
}

export default App


const Header = () => {
    return (
        <header className='d-flex align-items-center justify-content-between p-2 bg-white shadow-page'>
            <Link to='/'>
                <Row className='ml-4 align-items-center'>
                    <img className='logo' src={logo} alt='logo' title={version[process.env.NODE_ENV].interface} />
                    <div style={{ borderRight: 'thin solid #d3d3d3', height: '24px', width: '10px' }}></div>
                    <span className='ml-2' style={{ fontSize: '20px', fontWeight: 'bold', color: 'black' }}>HOMER Front</span>
                </Row>
            </Link>
            <ProjectHeaderView path='project/:projectId/*' />
        </header>
    )
}


const Footer = () => <></>


const Main = () => {
    const [isProjectSaving, setIsProjectSaving] = useState<projectListStatus>('ready')
    return (
        <main className='flex-fill p-3 overflow-auto'>
            <Router className='h-100'>
                <Redirect from='/' to='library' noThrow />
                <LibraryView setIsProjectSaving={setIsProjectSaving} isProjectSaving={isProjectSaving} path='library' />
                <UserStorageLibraryView path='storageLibrary' />
                <UserStorageLibraryComponentView path='storageLibrary/edit/:id/*' />
                <ProjectView setIsProjectSaving={setIsProjectSaving} path='project/:projectId/*' />
                <NoMatch default />
            </Router>
        </main>
    )
}

export type projectListStatus = 'ready' | 'saving' | 'loading'
