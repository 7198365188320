import { getTrackBackground, Range, } from 'react-range'
import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import { observer } from 'mobx-react-lite'
import { useProjectStore } from '../../../ProjectProvider'
import { useEffect, useState } from 'react'
import ContextHelp from '../../../../helper/ContextHelp'

interface ComponentLifetimeProps {
    isMiniature?: boolean
}

const ComponentLifetime = observer(({ isMiniature = false }: ComponentLifetimeProps) => {
    const project = useProjectStore().project!
    const projectLifetime = project.lifetime
    return (<>
        <div className={(isMiniature ? 'm-3' : 'm-2 mt-2')}>
                    <Row className="mb-1" style={{ fontSize: isMiniature ? 14 : 16, fontWeight: 500 }}>
                <p className='mr-2 ml-3'>Lifetime per Technology (years)</p>{/*{!isMiniature && <ContextHelp helpId='LIFETIMEBYTECHNOLOGY' />}*/}
                    </Row>
                    {project.useSolar && project.solar.length > 0 &&
                        <Row className="mb-1">
                            <Col xs={1} style={{ fontSize: isMiniature ? 12 : 13 }}>Solar</Col>
                            <Col xs={12}><LifetimeSlider technology='Solar' isMiniature={isMiniature} /></Col>
                        </Row>}
                    {project.useWind && project.wind.length > 0 &&
                        <Row className="mb-1">
                            <Col xs={1} style={{ fontSize: isMiniature ? 12 : 13 }}>Wind</Col>
                            <Col xs={12}><LifetimeSlider technology='Wind' isMiniature={isMiniature} /></Col>
                        </Row>}
                    {project.useStorage && project.storage.length > 0 &&
                        <Row className="mb-2">
                            <Col xs={1} style={{ fontSize: isMiniature ? 12 : 13 }}>Storage</Col>
                            <Col xs={12}><LifetimeSlider technology='Storage' isMiniature={isMiniature} /></Col>
                        </Row>}
                    {!isMiniature && <Row className="mb-2">
                        <Col xs={12}><Timeline lifetime={projectLifetime} /></Col>
                    </Row>}
        </div>
    </>)
})

export default ComponentLifetime

interface LifetimeSliderProps {
    technology: ComponentTechnology
    isMiniature: boolean
}
type ComponentTechnology = 'Solar' | 'Wind' | 'Storage'

const LifetimeSlider = observer(({ technology, isMiniature }: LifetimeSliderProps) => {
    const project = useProjectStore().project!
    const isEarlyAnalysis = project.earlyAnalysis
    const projectLifetime = project.lifetime
    const initialStartYear = isEarlyAnalysis ? 1 : technology === 'Solar' ? project.solarStartYear : technology === 'Wind' ? project.windStartYear : project.storageStartYear
    const initialEndYear = isEarlyAnalysis ? projectLifetime : technology === 'Solar' ? project.solarEndYear : technology === 'Wind' ? project.windEndYear : project.storageEndYear
    const color = technology === 'Solar' ? isEarlyAnalysis ? '#C9B685' : '#F1CA4A' : technology === 'Wind' ? isEarlyAnalysis ? '#7F7FBF' : 'blue' : isEarlyAnalysis ? '#8CB49F' : '#6ED096'
    //const augmentationColor = technology === 'Solar' ? '#F3E9C5' : technology === 'Wind' ? '#9CCEFF' : '#CCEADA'
    const useAugmentation = technology === 'Storage' ? project.storage.some(x => x.allowAugmentation) : false
    const setStartYear = (x: number) => technology === 'Solar' ? project.setSolarStartYear(x) : technology === 'Wind' ? project.setWindStartYear(x) : project.setStorageStartYear(x)
    const setEndYear = (x: number) => technology === 'Solar' ? project.setSolarEndYear(x) : technology === 'Wind' ? project.setWindEndYear(x) : project.setStorageEndYear(x)
    const [isDragging, setIsDragging] = useState(false);
    const [values, setValues] = useState([initialStartYear - 1, (isEarlyAnalysis ? initialEndYear : initialEndYear > projectLifetime ? projectLifetime : initialEndYear)])

    const BACK_COLOR = 'transparent'
    const COLORS = [BACK_COLOR, color, BACK_COLOR]

    const handleChange = (x: number[]) => {
        let startYear = x[0]
        let endYear = x[1]
        if (startYear === endYear)
            return
        if (startYear >= projectLifetime - 1)
            startYear = projectLifetime - 1
        if (endYear === 0)
            endYear = 1
        //if (useAugmentation)
        //    setValues([startYear, ((projectLifetime - startYear) / 2) + startYear])
        //else
        setValues([startYear, endYear])
    }

    const handleFinalChange = (x: number[]) => {
        let startYear = x[0]
        let endYear = x[1]
        if (startYear >= projectLifetime - 1)
            startYear = projectLifetime - 1
        if (endYear === 0)
            endYear = 1
        //if (useAugmentation)
        //    setValues([startYear, ((projectLifetime - startYear) / 2) + startYear])
        //else
        setValues([startYear, endYear])

        setStartYear(startYear + 1)
        //if (!useAugmentation)
        setEndYear(endYear)
    }

    //useEffect(() => {
        //if (useAugmentation)
            //handleChange(values)
        //else
        //    handleChange([initialStartYear - 1, initialEndYear])
    //}, [useAugmentation])

    return (<>
        <Range disabled={isEarlyAnalysis || isMiniature}
            values={values} min={0} max={projectLifetime} step={1}
            onChange={handleChange}
            onFinalChange={handleFinalChange}
            draggableTrack
            allowOverlap={false}
            renderTrack={({ props, children }) =>
                <div
                    title={`${technology}\nStart Year: ${values[0] + 1}\n${(useAugmentation && !isEarlyAnalysis ? '' : 'End Year: ' + values[1])}${(technology === 'Storage' ? '\nAllow Augmentation: ' : '')}${(technology !== 'Storage' ? '' : useAugmentation ? 'Yes' : 'No')}`}
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={{
                        ...props.style,
                        height: '25px',
                        display: 'flex',
                        width: '100%',
                        borderRadius: '30px',
                    }}
                >
                    <div
                        ref={props.ref}
                        onMouseDown={() => setIsDragging(true)}
                        onMouseUp={() => setIsDragging(false)}
                        style={{
                            height: '25px',
                            width: '100%',
                            borderRadius: '3px',
                            cursor: isMiniature ? 'context-menu' : isEarlyAnalysis ? 'not-allowed' : isDragging ? 'grabbing' : 'grab',
                            background: getTrackBackground({
                                values,
                                colors: COLORS,
                                min: 0,
                                max: projectLifetime,
                            }),
                            alignSelf: 'center',
                        }}
                    >
                        {children}
                    </div>
                </div>}
            renderThumb={({ props, index, isDragged }) =>
                <div {...props}
                    style={{
                        ...props.style,
                        height: '25px',
                        width: '8px',
                        borderRadius: '30px',
                        backgroundColor: color,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        //transition: 'box-shadow 0.3s ease',
                        cursor: isMiniature ? 'context-menu' : isEarlyAnalysis ? 'not-allowed' : 'col-resize'
                    }}
                >
                </div>
            }
        />
    </>)
})

interface TimelineProps {
    lifetime: number
}

const Timeline: React.FC<TimelineProps> = ({ lifetime }) => {
    const totalYears = lifetime;
    return (
        <div style={styles.timeline}>
            {Array.from({ length: totalYears + 1 }, (_, i) => (
                <div
                    key={i}
                    style={{
                        ...styles.tick,
                        left: `${(i / totalYears) * 100}%`
                    }}
                />
            ))}
            {Array.from({ length: totalYears }, (_, i) => (
                <div
                    key={i}
                    style={{
                        ...styles.yearLabel,
                        left: `${((i + 0.5) / totalYears) * 100}%`
                    }}
                >
                    {1 + i}
                </div>
            ))}
        </div>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    timeline: {
        position: 'relative',
        width: '100%',
        height: '35px',
        borderTop: '2px solid black',
        marginTop: '20px',
    },
    tick: {
        position: 'absolute',
        top: '-5px',
        borderLeft: '1px solid black',
        height: '10px',
        transform: 'translateX(-50%)',
    },
    yearLabel: {
        position: 'absolute',
        top: '10px',
        textAlign: 'center',
        fontSize: '12px',
        transform: 'translateX(-50%)',
    },
};
