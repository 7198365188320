import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {GoInfo} from 'react-icons/all'
import * as React from 'react'

interface ToolTipInfoProps {
    label: string
    centerAlign?: boolean
}

const ToolTipInfo = ({ label, centerAlign }: ToolTipInfoProps) => {
    var align = centerAlign ? 'd-flex align-items-center ' : ''
    return <OverlayTrigger trigger='hover' placement='auto'
                           overlay={<Tooltip id='info'>{label}</Tooltip>}>
        {({ref, ...triggerHandler}) =>
            <span ref={ref} className={`${align}text-primary ml-1`}>
                <GoInfo color='black' {...triggerHandler} />
            </span>
        }
    </OverlayTrigger>
}

export default ToolTipInfo
