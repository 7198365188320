import SchematicIcon from './SchematicIcon'
import Converter from './Converter'
import solarPanel from 'assets/images/solar-panel.svg'
import * as React from 'react'

interface SolarTechnologyProps {
    hasConverter: boolean;
    bus: string;
}

const SolarTechnology = ({hasConverter, bus}: SolarTechnologyProps) => {
    return (
        <div className='d-flex flex-row align-items-center justify-content-center'>
            <SchematicIcon src={solarPanel}/>
            {hasConverter && <Converter bus={bus}/>}
        </div>
    )
}

export default SolarTechnology
