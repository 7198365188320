import { RouteComponentProps } from '@reach/router'
import * as React from 'react'
import { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { observer } from 'mobx-react-lite'
import { useProjectStore } from '../../ProjectProvider'
import SummaryChartByHour, { DaySelection, DaySelector } from '../../pages/Results/SummaryChartByHour'
import { EnergyPriceSummary, FinancialSummary, PerformanceSummary, ResultValueItem, RevenueSummary } from '../../results-details'
import { IntlService } from 'Kendo-Intl-5'
import { EventItem } from '../ResultsPage'
import ContextHelp from '../../../helper/ContextHelp'
import ToolTipInfo from '../../../helper/ToolTipInfo'
import DropDownSelector from '../SharedComponents/DropDownSelector'


interface SummaryProps extends RouteComponentProps {
    showBaseYearAsYearZero: boolean
}


type ChartKind = 'year' | 'month' | 'hour'
const allChartKinds: ChartKind[] = ['hour']

const getChartKindLabel = (x: ChartKind) => {
    switch (x) {
        case 'year':
            return 'By Year'
        case 'month':
            return 'By Month'
        case 'hour':
            return 'By Hour'
    }
}

const ResultSummary = observer(({ showBaseYearAsYearZero }: SummaryProps) => {
    const project = useProjectStore().project!
    const details = project.results.details
    const intlCulture = project.useCommaAsDecimalPoint ? 'es' : 'en'

    const [chart, setChart] = useState<ChartKind>('hour')
    const [daySelection, setDaySelection] = useState<DaySelection>({ kind: 'highest' })
    const [yearSelection, setYearSelection] = useState(0)


    return (
        <div className='m-2'>
            <div className='d-flex flex-row mb-2 align-items-center'>
                <Dropdown>
                    <Dropdown.Toggle className='pillButton' variant='outline-primary' id='dropdown-basic'>
                        {getChartKindLabel(chart)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {allChartKinds.map(x =>
                            <Dropdown.Item key={x} onClick={() => setChart(x)}>
                                {getChartKindLabel(x)}
                            </Dropdown.Item>)}
                    </Dropdown.Menu>
                </Dropdown>
                <div className='mx-2'/>
                {chart === 'month' && <span/>}
                {chart === 'hour' && <DaySelector
                    highest={details.details.summaryChartList !== undefined && details.details.summaryChartList.length > 0 ? details.details.summaryChartList[yearSelection].highestRevenueDayIndex : details.details.summaryChart.highestRevenueDayIndex}
                    lowest={details.details.summaryChartList !== undefined && details.details.summaryChartList.length > 0 ? details.details.summaryChartList[yearSelection].lowestRevenueDayIndex : details.details.summaryChart.lowestRevenueDayIndex}
                    value={daySelection}
                    onValueChange={x => setDaySelection(x)} />}
                <span className='mr-3 ml-3'>Year</span>
                <DropDownSelector max={project.lifetime} value={yearSelection} onValueChange={setYearSelection} showBaseYearAsYearZero={showBaseYearAsYearZero} baseYear={project.baseYear} />
            </div>

            {chart === 'hour' && <SummaryChartByHour selection={daySelection} intlCulture={intlCulture} year={yearSelection} />}
            <hr/>
            <div className='row'>
                <div className='col-6'>
                    <SectionHeader label='Financial Summary' helpId='FINSUM' />
                    <FinancialSummaryTable model={details.details.financial} intlCulture={intlCulture} />
                </div>
                <div className='col-6'>
                    <SectionHeader label='Revenue Summary' helpId='REVSUM' />
                    <RevenueSummaryTable intlCulture={intlCulture} model={details.details.revenue} />
                </div>
                <div className='col-6'>
                    <SectionHeader label='Energy Price Summary' helpId='EPSUM' />
                    <EnergyPriceSummaryTable model={details.details.price} intlCulture={intlCulture} />
                </div>
                <div className='col-6'>
                    <SectionHeader label='System Performance' helpId='SYSPER' />
                    <PerformanceSummaryTable intlCulture={intlCulture} model={details.details.performance} />
                </div>
            </div>
        </div>
    )
})


interface SectionHeaderProps {
    label: string
    helpId: string
}

const SectionHeader = ({label, helpId}: SectionHeaderProps) =>
    <div className='d-flex flex-row align-content-center my-2'>
        <div className='mr-2 font-weight-bold'>{label}</div>
        <ContextHelp helpId={helpId}/>
    </div>


export default ResultSummary


interface TableEntryProps {
    label: string
    value: string
    average?: string
    units?: string
    indent?: boolean
    description?: string,
    isHelp?:boolean
}

const TableEntry = ({label, value, average, units, indent, description,isHelp}: TableEntryProps) => {
    return (
        <tr>
            <td className={(indent ? 'pl-4' : '') + ` ${isHelp?' d-flex align-items-center':''} text-wrap`}>
                {label}
                {description !== undefined &&
                    <ToolTipInfo label={description}/>}
                {isHelp && <span className='ml-1'><ContextHelp helpId='CBID' /></span>}
            </td>
            <td>{value}</td>
            {average !== undefined && <td>{average}</td>}
            {units !== undefined && <td>{units}</td>}
        </tr>
    )
}


interface MetricRowProps {
    label: string
    metric: ResultValueItem | null
    format?: string
    units?: string
    indent?: boolean
    description?: string
    isHelp?: boolean
    intlCulture: string
}

export const MetricRow = ({ label, metric, format, units, indent, description, isHelp, intlCulture }: MetricRowProps) => {
    const intl = new IntlService(intlCulture)

    return (metric !== null
        ? <TableEntry label={label}
            value={intl.formatNumber(metric.first, format ?? 'n')}
            average={intl.formatNumber(metric.average, format ?? 'n')}
            units={units} indent={indent} description={description} isHelp={isHelp}/>
        : null)
}


interface LabelRowProps {
    label: string
}

const LabelRow = ({label}: LabelRowProps) =>
    <tr>
        <td colSpan={4}>{label}</td>
    </tr>


interface FinancialSummaryTableProps {
    model: FinancialSummary
    intlCulture: string
}

const FinancialSummaryTable = ({model, intlCulture}: FinancialSummaryTableProps) => {
    const intl = new IntlService(intlCulture)


    const project = useProjectStore().project!
    const currency = project.currencySymbol ?? "$"
    const currencyByPowerUnit = `${currency}/MWh`

    return (
        <table id='financialSummaryTable' className='table table-bordered table-responsive-sm small'>
            <tbody>
                <TableEntry label='Real discount rate' value={intl.formatNumber(model.realDiscountRate, 'n2')} units='%'/>
                <TableEntry label='Project life' value={intl.formatNumber(model.projectLifeTime, 'n0')} units='yrs'/>
                <TableEntry label='Capital expenses' value={intl.formatNumber(model.capitalCost, 'n0')} units={currency}/>
                <TableEntry label='Operating expenses, excl. augmentation' value={intl.formatNumber(model.operatingCost, 'n0')} units={`${currency}/yr`}/>
                <TableEntry label='Augmentation expenses' value={intl.formatNumber(model.augmentationCost, 'n0')} units={`${currency}/yr`}/>
                {(model.contractParticipation && model.contractParticipation !== 'none') && <TableEntry label='Contract participation' value={model.contractParticipation} units='' />}
                {(model.marketParticipation && model.marketParticipation !== 'none') && <TableEntry label='Market participation' value={model.marketParticipation} units='' />}
                {project.useCapacityMarket && <TableEntry label='Capacity Market participation' value={model.capacityMarketParticipation} units='' />}
                <TableEntry label='Revenue' value={intl.formatNumber(model.revenue, 'n0')} units={`${currency}/yr`}/>
                <TableEntry label='IRR' value={model.irr === null ? '—' : intl.formatNumber(model.irr, 'n1')} units='%'/>
                <TableEntry label='NPV' value={intl.formatNumber(model.npv, 'n0')} units={currency}/>
                <TableEntry label='LCOE' value={intl.formatNumber(model.lcoe, 'n3')} units={`${currencyByPowerUnit}`}/>
            </tbody>
        </table>
    )
}


interface EnergyPriceSummaryTableProps {
    model: EnergyPriceSummary
    intlCulture: string
}

const EnergyPriceSummaryTable = ({ model, intlCulture }: EnergyPriceSummaryTableProps) => {
    const currency = useProjectStore().project!.currencySymbol ?? "$"
    const intl = new IntlService(intlCulture)
    const currencyByPowerUnit = `${currency}/MW-mo`

    return (
        <table className='table table-bordered table-responsive-sm small'>
            <thead>
                <tr>
                    <th/>
                    <th>Year 1</th>
                    <th>Annual Average</th>
                    <th/>
                </tr>
            </thead>
            <tbody>
                {model.markets.map(market =>
                    <React.Fragment key={market.name}>
                        <LabelRow label={market.name} />
                        <MetricRow label={`${market.name} purchase price`} metric={market.purchasePrice} format='n2' units={`${currency}/MWh`} indent={true} intlCulture={intlCulture} />
                        <MetricRow label={`${market.name} sale price`} metric={market.salePrice} format='n2' units={`${currency}/MWh`} indent={true}
                            description={`Annualized revenue (${currency}) divided by the total exports (MWh)`}
                            intlCulture={intlCulture} />
                    </React.Fragment>)}
                {model.capacityPrice !== null &&
                    <TableEntry label='Capacity' value={intl.formatNumber(model.capacityPrice, 'n2')} average='' units={`${currencyByPowerUnit}`}/>}
            </tbody>
        </table>
    )
}


interface RevenueSummaryTableProps {
    model: RevenueSummary
    intlCulture: string
}

const RevenueSummaryTable = ({ model, intlCulture }: RevenueSummaryTableProps) => {
    const project = useProjectStore().project!
    const currency = project.currencySymbol ?? "$"

    return (
        <table className='table table-bordered table-responsive-sm small'>
            <thead>
                <tr>
                    <th/>
                    <th>Year 1</th>
                    <th>Annual Average</th>
                    <th/>
                </tr>
            </thead>
            <tbody>
                {model.energyMarkets.map(market =>
                    <React.Fragment key={market.name}>
                        <LabelRow label={market.name}/>
                        <MetricRow intlCulture={intlCulture}  label={`Solar energy to ${market.name}`} metric={market.solarRevenue} format='n0' units={currency} indent={true}/>
                        <MetricRow intlCulture={intlCulture}  label={`Storage energy to ${market.name}`} metric={market.storageRevenue} format='n0' units={currency} indent={true}/>
                        <MetricRow intlCulture={intlCulture}  label={`Wind energy to ${market.name}`} metric={market.windRevenue} format='n0' units={currency} indent={true}/>
                    </React.Fragment>)}
                {model.capacityMarkets.map(market =>
                    <React.Fragment key={market.name}>
                        <LabelRow label={market.name}/>
                        <MetricRow intlCulture={intlCulture}  label='Energy' metric={market.energyRevenue} format='n0' units={currency} indent={true}/>
                        <MetricRow intlCulture={intlCulture}  label='Capacity' metric={market.capacityRevenue} format='n0' units={currency} indent={true}/>
                        <MetricRow intlCulture={intlCulture}  label='Capacity bid' metric={market.capacityBid} format='n1' units='MW' indent={true} isHelp={true}/>
                    </React.Fragment>)}
                {model.contracts.map(market =>
                    <MetricRow intlCulture={intlCulture} key={market.name} label={market.name} metric={market.revenue} format='n0' units={currency} />)}
                {model.regulationMarkets?.map((market, index) =>
                    <React.Fragment key={market.name}>
                        <LabelRow label={market.name} />
                        {project.regulationMarkets[index].useRegUp && <>
                            <MetricRow intlCulture={intlCulture} label='Reg-Up Capacity Revenue' metric={market.regUpCapacityRevenue} format='n0' units={currency} indent={true} />
                            {project.regulationMarkets[index].useEnergyPrice && <MetricRow intlCulture={intlCulture} label='Reg-Up Energy Revenue' metric={market.regUpEnergyRevenue} format='n0' units={currency} indent={true} />}
                        </>}
                        {project.regulationMarkets[index].useRegDown && <>
                            <MetricRow intlCulture={intlCulture} label='Reg-Down Capacity Revenue' metric={market.regDownCapacityRevenue} format='n0' units={currency} indent={true} />
                            {project.regulationMarkets[index].useEnergyPrice && <MetricRow intlCulture={intlCulture} label='Reg-Down Energy Expense' metric={market.regDownEnergyExpense} format='n0' units={currency} indent={true} />}
                        </>}
                    </React.Fragment>)}
            </tbody>
        </table>
    )
}


interface PerformanceSummaryTableProps {
    model: PerformanceSummary
    intlCulture: string
}

const PerformanceSummaryTable = ({ model, intlCulture }: PerformanceSummaryTableProps) => {
    const project = useProjectStore().project!
    const currency = project.currencySymbol ?? "$"

    if (model.solarToStorage !== undefined) {
        model.solarToStorage.first += 0
        model.solarToStorage.average += 0
    }

    //const useRegUp = project.regulationMarkets.some(x => x.useRegUp === true)
    //const useRegDown = project.regulationMarkets.some(x => x.useRegDown === true)
    return (
        <table className='table table-bordered table-responsive-sm small'>
            <thead>
                <tr>
                    <th/>
                    <th>Year 1</th>
                    <th>Annual Average</th>
                    <th/>
                </tr>
            </thead>
            <tbody>
                <MetricRow intlCulture={intlCulture} label='Solar energy production' metric={model.solarProduction} format='n0' units='MWh'/>
                <MetricRow intlCulture={intlCulture} label='Wind energy production' metric={model.windProduction} format='n0' units='MWh'/>
                <MetricRow intlCulture={intlCulture} label='Storage throughput' metric={model.storageThroughput} format='n0' units='MWh'/>
                <MetricRow intlCulture={intlCulture} label='Renewables to Storage' metric={model.solarToStorage} format='n0' units='MWh'/>
                <MetricRow intlCulture={intlCulture} label='Grid energy to charge storage' metric={model.gridToStorage} format='n0' units='MWh' />
                <MetricRow intlCulture={intlCulture} label='Economic Curtailed Energy' metric={model.curtailedEnergy} format='n0' units='MWh' />
                <MetricRow intlCulture={intlCulture} label='Clipped solar energy' metric={model.solarClippedEnergy} format='n0' units='MWh'/>
                <MetricRow intlCulture={intlCulture} label='Clipped energy from system converter' metric={model.otherClippedEnergy} format='n0' units='MWh' />
                {model.excessElectricity !== undefined && < MetricRow intlCulture={intlCulture} label='Curtailed energy at interconnection' metric={model.excessElectricity} format='n0' units='MWh' />}
                {model.acLineLosses !== undefined && <MetricRow intlCulture={intlCulture} label='AC Line Losses' metric={model.acLineLosses} format='n0' units='MWh' />}
                {model.transformerLosses !== undefined && <MetricRow intlCulture={intlCulture} label='Project Transformer Losses' metric={model.transformerLosses} format='n0' units='MWh' />}
                {model.energyMarkets.map(market =>
                    <React.Fragment key={market.name}>
                        <MetricRow intlCulture={intlCulture}  label={`Solar energy export to ${market.name}`} metric={market.solarEnergy} format='n0' units='MWh'/>
                        <MetricRow intlCulture={intlCulture}  label={`Storage energy export to ${market.name}`} metric={market.storageEnergy} format='n0' units='MWh'/>
                        <MetricRow intlCulture={intlCulture}  label={`Wind energy export to ${market.name}`} metric={market.windEnergy} format='n0' units='MWh'/>
                    </React.Fragment>)}
                {model.capacityMarkets.map(market =>
                    <React.Fragment key={market.name}>
                        <MetricRow intlCulture={intlCulture}  label={`Storage energy export to ${market.name}`} metric={market.storageEnergy} format='n0' units='MWh'/>
                    </React.Fragment>)}
                <MetricRow intlCulture={intlCulture}  label='Total energy export' metric={model.totalEnergy} format='n0' units='MWh'/>

                <MetricRow intlCulture={intlCulture}  label='Energy storage mean state of charge' metric={model.meanStateOfCharge} format='n0' units='%'/>
                <MetricRow intlCulture={intlCulture}  label='Energy storage cycle cost' metric={model.cycleCost} format='n5' units={`${currency}/MWh`}/>
                <MetricRow intlCulture={intlCulture}  label='Energy storage grid import time' metric={model.hoursFromGrid} format='n0' units='hrs'/>
                <MetricRow intlCulture={intlCulture}  label='Energy storage grid export time' metric={model.hoursToGrid} format='n0' units='hrs'/>
                <MetricRow intlCulture={intlCulture}  label='Energy storage idle time' metric={model.hoursIdle} format='n0' units='hrs'/>
                <MetricRow intlCulture={intlCulture} label='Energy storage utilization rate' metric={model.utilizationRate} format='n0' units='%/day' />

                {model.regulationMarkets?.map((market, index) =>
                    <React.Fragment key={market.name}>
                        <LabelRow label={market.name} />
                        {project.regulationMarkets[index].useRegUp && <>
                            <MetricRow intlCulture={intlCulture} label='Reg-Up Capacity Committed' metric={market.regUpCapacityCommitted} format='n0' units='MWh' indent={true} />
                            {project.regulationMarkets[index].useEnergyPrice && <MetricRow intlCulture={intlCulture} label='Reg-Up Energy Exported' metric={market.regUpEnergyExports} format='n0' units='MWh' indent={true} />}
                        </>}
                        {project.regulationMarkets[index].useRegDown && <>
                            <MetricRow intlCulture={intlCulture} label='Reg-Down Capacity Committed' metric={market.regDownCapacityCommitted} format='n0' units='MWh' indent={true} />
                            {project.regulationMarkets[index].useEnergyPrice && <MetricRow intlCulture={intlCulture} label='Reg-Down Energy Imported' metric={market.regDownEnergyImports} format='n0' units='MWh' indent={true} />}
                        </>}
                    </React.Fragment>)}
            </tbody>
        </table>
    )
}
