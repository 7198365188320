import {SolarResultItem} from '../../results-details'
import {IntlService} from 'Kendo-Intl-5'
import {useProjectStore} from '../../ProjectProvider'
import { Project } from '../../model'
import * as React from 'react'

interface ResultSolarEnergyTableProps {
    items: SolarResultItem[]
    intlCulture: string
    showBaseYearAsYearZero: boolean
    project: Project
}

const ResultSolarEnergyTable = ({ items, intlCulture, showBaseYearAsYearZero, project}: ResultSolarEnergyTableProps) => {
    const intl = new IntlService(intlCulture)

    const currency = useProjectStore().project!.currencySymbol ?? "$"

    const columnLabel = project.bus === 'ac'
        ? 'DC/AC Converter (MWh-ac)'
        : 'DC/DC Converter (MWh-dc)'

    return (
        <table className='table table-bordered table-responsive-sm small'>
            <thead>
                <tr>
                    <th>Year</th>
                    <th>Solar Energy Production (MWh-dc)</th>
                    <th>Clipped Solar Energy Production (MWh-dc)</th>
                    <th>Solar Energy Production after {columnLabel}</th>
                    <th>Energy Yield (MWh/MW)</th>
                    <th>Cumulative Degradation (%)</th>
                    <th>Capacity Factor (%)</th>
                    <th>Solar Operating Expenses ({currency})</th>
                </tr>
            </thead>
            <tbody>
                {items.map(x =>
                    <tr key={x.year}>
                        <td>{showBaseYearAsYearZero ? project.baseYear + x.year : x.year + 1}</td>
                        <td>{intl.formatNumber(x.production + x.clippedProduction, 'n0')}</td>
                        <td>{intl.formatNumber(x.clippedProduction, 'n0')}</td>
                        <td>{intl.formatNumber(x.production, 'n0')}</td>
                        <td>{intl.formatNumber(x.energyYield, 'n0')}</td>
                        <td>{`${intl.formatNumber(x.degradation, 'n2')}%`}</td>
                        <td>{`${intl.formatNumber(x.capacityFactor, 'n2')}%`}</td>
                        <td>{`${intl.formatNumber(x.operatingTotalCost, 'n0')} ${currency}`}</td>
                    </tr>)}
            </tbody>
        </table>
    )
}

export default ResultSolarEnergyTable
