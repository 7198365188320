import {v4} from 'uuid'
import {action, computed, makeObservable, observable} from 'mobx'
import { EnergyMarket } from './EnergyMarket'
import { SizingKind } from '../../../services/inputs'
import { Deferment } from './Deferment'
import { StorageSpec } from '../../../services/api'
import { Sizing } from './Sizing'
import { ComplexCostItem, ComplexCostTable, CostTable } from './CostTable'
import { MultiYearVar } from './MultiYearVar'
import { SensitivityVar } from './SensitivityVar'
import { Inverter } from './Inverter'


const DEFAULT_STORAGE: string = 'Energy Storage (1 MW / 1 MWh)'

export type StorageKind = 'library' | 'custom'


export class Storage {
    id: string = v4()
    constructor(/*lifetime: number*/) {
        makeObservable(this)
        this.cost.complex = defaultStorageComplexCostTable()
        this.converter.sizing.kind = SizingKind.relative
    //    this.endYear =lifetime
    }

    @observable kind: StorageKind = 'library'
    @observable specs?: StorageSpec

    // library
    @observable model: string = DEFAULT_STORAGE

    // custom
    @observable customName: string = ''
    @observable customData: string = ''
    @observable customAbbreviation: string= ''

    @observable sizing: Sizing = new Sizing()
    @observable hasDeferment: boolean = false
    @observable deferment: Deferment = new Deferment()
    @observable cost: CostTable = new CostTable()
    @observable omEscalator: MultiYearVar = new MultiYearVar()
    @observable lifetime: SensitivityVar = new SensitivityVar(20)

    @observable allowAugmentation: boolean = true
    @observable allowReplacement: boolean = false
    @observable augmentationCost: number = 0 // $/kWh
    @observable augmentationDegradationLimit: SensitivityVar = new SensitivityVar(65)
    @observable augmentationPriceDecline: MultiYearVar = new MultiYearVar()

    @observable hasConverter: boolean = false
    @observable converter: Inverter = new Inverter()

    @observable useCycleLimitPerDay: boolean = false
    @observable cycleLimitPerDay: SensitivityVar = new SensitivityVar(1)
    @observable optimizeCycleLimitPerDay: boolean = false

    @observable useAuxiliaryLoad: boolean = false
    @observable auxiliaryLoadSensitivity: SensitivityVar = new SensitivityVar(1)
    @observable storageServesAuxiliaryLoad: boolean = false
    @observable useAuxiliaryLoadFlatPrice:boolean = true
    @observable auxiliaryLoadFlatPrice: number = 10
    @observable energyMarket: EnergyMarket | null = null
    @observable auxiliaryLoadPriceMultiYear: MultiYearVar = new MultiYearVar()
    @observable rteDegradationOverTime: MultiYearVar = new MultiYearVar()

    //@observable startYear: number = 1
    //@observable endYear: number = 2 
    //@observable auxiliaryLoadPrice: TimeSeries = new TimeSeries()

    @action setHasDeferment(x: boolean) { this.hasDeferment = x }
    @action setKind(x: StorageKind) { this.kind = x }
    @action setSpecs(x: StorageSpec) { this.specs = x }
    @action setUserStorageSpecs(x: StorageSpec) { this.specs = x }
    @action setSpecRoundTripEfficiency(x: number) { this.specs!.roundtripEfficiency = x }

    @action setModel(x: string) { this.model = x }
    @action setCustomName(x: string) { this.customName = x }
    @action setCustomAbbreviation(x: string) { this.customAbbreviation = x }
    @action setCustomData(x: string) { this.customData = x }
    @action setAllowAugmentation(x: boolean) { this.allowAugmentation = x }
    @action setAllowReplacement(x: boolean) { this.allowReplacement = x }
    @action setAugmentationCost(x: number) { this.augmentationCost = x }
    @action setHasConverter(x: boolean) { this.hasConverter = x }

    @action setOptimizeCycleLimitPerDay(x: boolean) { this.optimizeCycleLimitPerDay = x }
    @action setUseCycleLimitPerDay(x: boolean) { this.useCycleLimitPerDay = x }

    //@action setStartYear(x: number) { this.startYear = x }
    //@action setEndYear(x: number) { this.endYear = x }

    @computed get isZero(): boolean { return this.sizing.onlyZero }

    estimateOptimizationSimulations(): number {
        const converter = this.hasConverter ? this.converter.estimateOptimizationSimulations() : 1
        const size = this.sizing.estimateSimulations()
        return converter * size
    }

    estimateSensitivitySimulations(): number {
        const converterSens = this.hasConverter ? this.converter.estimateSensitiviySimulations() : 1
        const cost = this.cost.estimateSimulations()
        const sens = this.augmentationDegradationLimit.estimateSimulations() * this.cycleLimitPerDay.estimateSimulations() * (this.useAuxiliaryLoad ? this.auxiliaryLoadSensitivity.estimateSimulations() : 1)
        return cost * sens * converterSens
    }

    @action setEnergyMarket(x: EnergyMarket | null) { this.energyMarket = x }
    @action setUseAuxiliaryLoad(x: boolean) { this.useAuxiliaryLoad = x }
    @action setAuxiliaryLoadFlatPrice(x: number) { this.auxiliaryLoadFlatPrice = x }
    @action setUseAuxiliaryLoadFlatPrice(x: boolean) { this.useAuxiliaryLoadFlatPrice = x }
    @action setStorageServesAuxiliaryLoad(x: boolean) { this.storageServesAuxiliaryLoad = x }

}


function defaultStorageComplexCostTable(): ComplexCostTable {
    const rv = new ComplexCostTable()
    rv.directCapital.items.splice(0, 0, ...[
        new ComplexCostItem({name: 'Module', unit: 'CostPerMwh'}),
        new ComplexCostItem({name: 'Power Conversion System', unit: 'CostPerMw'}),
        new ComplexCostItem({name: 'Balance of System', unit: 'CostPerMw'}),
        new ComplexCostItem({name: 'Installation Labor', unit: 'CostPerMw'}),
        new ComplexCostItem({name: 'Installer Overhead and Margin', unit: 'CostPerMw'}),
        new ComplexCostItem({name: 'Contingency', unit: 'PercentOfDirectCapital'}),
    ])
    rv.indirectCapital.items.splice(0, 0, ...[
        new ComplexCostItem({name: 'Permitting and Environmental', unit: 'CostPerMw'}),
        new ComplexCostItem({name: 'Engineering and Developer Overhead', unit: 'CostPerMw'}),
        new ComplexCostItem({name: 'Land Purchase, Preparation and Transmissions', unit: 'CostPerMw'}),
        new ComplexCostItem({name: 'Working Capital Reserve Account', unit: 'Cost'}),
        new ComplexCostItem({name: 'Sales Tax Rate', unit: 'PercentOfDirectCapital'}),
    ])
    rv.operating.items.splice(0, 0, ...[
        new ComplexCostItem({name: 'Fixed Annual Cost', unit: 'CostPerYear'}),
        new ComplexCostItem({name: 'Fixed Cost by Capacity', unit: 'CostPerMwhYear'}),
        new ComplexCostItem({name: 'Variable Cost', unit: 'CostPerMwhThroughput'}),
        new ComplexCostItem({name: 'Insurance', unit: 'PercentOfDirectCapitalPerYear'}),
        new ComplexCostItem({name: 'Audit & Program Management Cost', unit: 'CostPerYear'}),
        new ComplexCostItem({name: 'Site Lease Cost', unit: 'CostPerYear'}),
        new ComplexCostItem({name: 'Property Tax Rate', unit: 'PercentOfDirectCapitalPerYear'}),
        new ComplexCostItem({name: 'Station Power Cost', unit: 'CostPerYear'}),
        new ComplexCostItem({name: 'Interest on All Reserves', unit: 'CostPerYear'}),
        new ComplexCostItem({name: 'Storage Decommissioning Reserve', unit: 'CostPerMwhYear'}),
    ])
    rv.replacement.items.splice(0, 0, ...[
        new ComplexCostItem({name: 'Replacement Cost', unit: 'CostPerMwh'}),
    ])
    return rv
}
