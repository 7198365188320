import {observer} from 'mobx-react-lite'
import {Form} from 'react-bootstrap'
import * as React from 'react'
import {GoX} from 'react-icons/all'
import { BonusDepreciation, Incentive, InvestmentTaxCredit, Macrs, ProductionBased } from '../model/Incentive'
import NumberField from '../../helper/NumberField'
import ContextHelp from '../../helper/ContextHelp'
import { Validation } from '../../helper/validation'
import NumberInput from '../../helper/NumberInput'
import { useProjectStore } from '../ProjectProvider'

interface IncentiveViewProps {
    model: Incentive
    onDelete?: () => void
    useCommaAsDecimalPoint: boolean
}

const IncentiveView = observer(({ model, onDelete, useCommaAsDecimalPoint }: IncentiveViewProps) => {
    switch (model.kind) {
        case 'itc': {
            return <InvestmentTaxCreditView model={model as InvestmentTaxCredit} onDelete={onDelete} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
        }
        case 'macrs': {
            return <MacrsView model={model as Macrs} onDelete={onDelete} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
        }
        case 'bonus': {
            return <BonusDepreciationView model={model as BonusDepreciation} onDelete={onDelete} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
        }
        case 'prod': {
            return <ProductionBasedView model={model as ProductionBased} onDelete={onDelete} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
        }
    }
})

export default IncentiveView


interface InvestmentTaxCreditViewProps {
    model: InvestmentTaxCredit
    onDelete?: () => void
    useCommaAsDecimalPoint: boolean
}

const InvestmentTaxCreditView = observer(({ model, onDelete, useCommaAsDecimalPoint }: InvestmentTaxCreditViewProps) => {
    return (<>
        <IncentiveHeader header='Investment Tax Credit' onDelete={onDelete} helpId='INVESTMENTTAXCREDIT'/>
        <IncentiveApplication model={model} isWindEnabled={false}/>
        <NumberField label='Investment tax credit percent of capital cost (%)'
            value={model.itcPercent}
            onChange={x => model.setItcPercent(x)}
            validator={Validation.percent}
            useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
        <NumberField label='Portion of capital cost eligible for incentive (%)'
            value={model.eligiblePercent}
            onChange={x => model.setEligiblePercent(x)}
            validator={Validation.percent}
            useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
    </>)
})


interface MacrsViewProps {
    model: Macrs
    onDelete?: () => void
    useCommaAsDecimalPoint: boolean
}

const MacrsView = observer(({ model, onDelete, useCommaAsDecimalPoint }: MacrsViewProps) => {
    return (<>
        <IncentiveHeader header='MACRS' onDelete={onDelete} helpId='MACRS'/>
        <IncentiveApplication model={model}/>
        <NumberField label='Marginal tax rate (%)'
                     value={model.marginalTaxPercent}
                     onChange={x => model.setMarginalTaxPercent(x)}
                     useCommaAsDecimalPoint={useCommaAsDecimalPoint}

                     validator={Validation.percent}/>
        <NumberField label='Portion of capital cost eligible for incentive (%)'
            value={model.eligiblePercent}
            onChange={x => model.setEligiblePercent(x)}
            validator={Validation.percent}
            useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
    </>)
})


interface BonusDepreciationViewProps {
    model: BonusDepreciation
    onDelete?: () => void
    useCommaAsDecimalPoint: boolean
}

const BonusDepreciationView = observer(({ model, onDelete, useCommaAsDecimalPoint }: BonusDepreciationViewProps) => {
    return (<>
        <IncentiveHeader header='Bonus Depreciation' onDelete={onDelete} helpId='BONUS'/>
        <IncentiveApplication model={model}/>
        <NumberField label='Bonus depreciation in first year (%)'
                     value={model.bonusPercent}
                     onChange={x => model.setBonusPercent(x)}
                     validator={Validation.percent}
                     useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
        <NumberField label='Marginal tax rate (%)'
                     value={model.marginalTaxPercent}
                     onChange={x => model.setMarginalTaxPercent(x)}
                     validator={Validation.percent}
                     useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
        <NumberField label='Portion of capital cost eligible for incentive (%)'
                     value={model.eligiblePercent}
                     onChange={x => model.setEligiblePercent(x)}
                     validator={Validation.percent}
                     useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
    </>)
})


interface ProductionBasedViewProps {
    model: ProductionBased
    onDelete?: () => void
    useCommaAsDecimalPoint: boolean
}

const ProductionBasedView = observer(({ model, onDelete, useCommaAsDecimalPoint }: ProductionBasedViewProps) => {
    const currency = useProjectStore().project!.currencySymbol ?? "$"
    const powerUnit = `MWh`

    return (<>
        <IncentiveHeader header='Production Tax Credit/REC' onDelete={onDelete} helpId='PRODUCTION'/>
        <IncentiveApplication model={model} isStorageEnabled={false} />
        <NumberField label={`Credit value per ${powerUnit} produced (${currency}/${powerUnit})`}
            value={model.credit}
            onChange={x => model.setCredit(x)}
            validator={Validation.min(0)}
            useCommaAsDecimalPoint={useCommaAsDecimalPoint}/>
        <NumberField label={`Max annual credit (${currency}/yr)`}
            value={model.maxCredit}
            onChange={x => model.setMaxCredit(x)}
            validator={Validation.min(0)}
            useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
        <div className='row  pl-3'>
            <Form.Check label='Limit incentive duration(years)' id={`lid-${model.id}`} custom className='mr-2 col-5'
                checked={model.useDuration}
                onChange={ev => model.setUseDuration(ev.target.checked)} />
            {model.useDuration && <NumberInput
                className='col-3'
                value={model.duration}
                onChange={x => model.setDuration(x)}
                validator={Validation.min(0)}
                useCommaAsDecimalPoint={useCommaAsDecimalPoint} />}
        </div>
        <div className='row  pl-3'>
            <Form.Check label='Credit is taxable (%)' id={`ct-${model.id}`} custom className='mr-2 col-5'
                checked={model.useCreditTaxPercent}
                onChange={ev => model.setUseCreditTaxPercent(ev.target.checked)} />
            {model.useCreditTaxPercent && <NumberInput className='col-3'
                value={model.creditTaxPercent}
                onChange={x => model.setCreditTaxPercent(x)}
                validator={Validation.percent}
                useCommaAsDecimalPoint={useCommaAsDecimalPoint} />}
        </div>
    </>)
})


interface IncentiveApplicationProps {
    model: Incentive
    isSolarEnabled?: boolean
    isStorageEnabled?: boolean
    isWindEnabled?: boolean
}

const IncentiveApplication = observer(({model, isSolarEnabled, isStorageEnabled, isWindEnabled}: IncentiveApplicationProps) => {
    return (
        <div className='d-flex mb-2'>
            {(isSolarEnabled ?? true) &&
                <Form.Check label='Solar' id={`solar-${model.id}`} custom className='mr-3'
                            checked={model.solar}
                            onChange={ev => model.setSolar(ev.target.checked)}/>
            }
            {(isStorageEnabled ?? true) &&
                <Form.Check label='Storage' id={`storage-${model.id}`} custom className='mr-3'
                            checked={model.storage}
                            onChange={ev => model.setStorage(ev.target.checked)}/>}
            {(isWindEnabled ?? true) &&
                <Form.Check label='Wind' id={`wind-${model.id}`} custom className='mr-3'
                            checked={model.wind}
                            onChange={ev => model.setWind(ev.target.checked)}/>}
        </div>
    )
})


interface IncentiveHeaderProps {
    header: string
    onDelete?: () => void
    helpId?: string
}

const IncentiveHeader = ({header, onDelete, helpId}: IncentiveHeaderProps) =>
    <div className='d-flex align-items-center mb-2'>
        <div className='font-weight-bold'>{header}</div>
        {helpId && <div className='mx-2'><ContextHelp helpId={helpId}/></div>}
        <div className='m-auto'/>
        <button className='btn btn-outline-danger pillButton' onClick={onDelete}><GoX/></button>
    </div>
