import { getTrackBackground, Range } from 'react-range'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Range as R } from './Range'


interface RangeSelectorProps {
    range: R
    bounds: R
    onRangeChange?: (x: R) => void
}

const SIZE_LIMIT = 12

const FRONT_COLOR = '#548BF4'
const BACK_COLOR = '#CCC'
const COLORS = [BACK_COLOR, FRONT_COLOR, BACK_COLOR]

function normalize(prev: number[], next: number[]): number[] {
    if (next[1] - next[0] >= SIZE_LIMIT) { return next }
    // check size
    let x = Math.abs(next[0] - prev[0]) < 1e-6 ?
        [next[0], next[0] + SIZE_LIMIT] :
        [next[1] - SIZE_LIMIT, next[1]]
    // check left bound
    //x = x[0] < MIN ? [MIN, MIN + SIZE_LIMIT] : x
    // check right bound
    //x = x[1] > MAX ? [MAX - SIZE_LIMIT, MAX] : x
    return x
}

const RangeSelector = ({ range, bounds, onRangeChange }: RangeSelectorProps) => {
    const { min, max } = range
    const [values, setValues] = useState([min, max])
    useEffect(() => { setValues([min, max]) }, [min, max])

    useEffect(() => {
        setValues([range.min, range.max])
    }, [range])

    const handleChange = useCallback((x) => {
        const rv = normalize(values, x)
        setValues(rv)
    }, [values])

    const handleFinalChange = useCallback((x) => {
        const rv = normalize(values, x)
        setValues(rv)
        onRangeChange?.({ min: rv[0], max: rv[1] })
    }, [values, onRangeChange])

    return (
        <Range
            values={values} min={bounds.min} max={bounds.max} step={1}
            onChange={handleChange}
            onFinalChange={handleFinalChange}
            draggableTrack
            allowOverlap={false}
            renderTrack={({ props, children }) =>
                <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={{
                        ...props.style,
                        height: '26px',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <div
                        ref={props.ref}
                        style={{
                            height: '5px',
                            width: '100%',
                            borderRadius: '4px',
                            background: getTrackBackground({
                                values,
                                colors: COLORS,
                                min: bounds.min,
                                max: bounds.max,
                            }),
                            alignSelf: 'center',
                        }}
                    >
                        {children}
                    </div>
                </div>}
            renderThumb={({ props, isDragged }) =>
                <div {...props}
                    style={{
                        ...props.style,
                        height: '15px',
                        width: '3px',
                        borderRadius: '0px',
                        backgroundColor: '#000',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0px 2px 6px #AAA',
                    }}
                >
                    <div
                        style={{
                            height: '15px',
                            width: '3px',
                            backgroundColor: '#444',
                        }}
                    />
                </div>
            }
        />
    )
}

export default RangeSelector
