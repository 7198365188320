import { observer } from 'mobx-react-lite'
import CostTableView from './shared/CostTableView'
import { Button, Dropdown, Form, Modal, Spinner } from 'react-bootstrap'
import { GoX } from 'react-icons/all'
import { useEffect, useState } from 'react'
import { IntlService } from 'Kendo-Intl-5'
import { Chart, ChartSeries, ChartSeriesItem, ChartXAxis, ChartXAxisItem, ChartYAxis, ChartYAxisItem } from '@progress/kendo-react-charts'
import StringField from '../../../helper/StringField'
import { StrValidation } from '../../../helper/StringValidation'
import { deserializeComplexCostTable, deserializeSensitivityVar, deserializeSizingVar, MultiYearKind } from '../../../../services/inputs'
import { CostTableType } from '../../model/CostTable'
import { EnergyMarket, getEnergyMarketKindLabel } from '../../model/EnergyMarket'
import * as React from 'react'
import { useProjectStore } from '../../ProjectProvider'
import { ChangeUnitOptionSetCurrency, storageUnits } from './shared/DetailCostBreakup'
import api, { StorageCurveItem, StorageSpec } from '../../../../services/api'
import SizingView from './shared/SizingView'
import { Validation } from '../../../helper/validation'
import MultiYearView from '../SharedComponents/MultiYearView'
import SensitivityVarView from '../EnergyMarket/SensitivityVarView'
import ContextHelp from '../../../helper/ContextHelp'
import NumberField from '../../../helper/NumberField'
import InverterView from './InverterView'
import NumberInput from '../../../helper/NumberInput'
import FilePicker from '../../../helper/FilePicker'
import { Storage } from '../../model/Storage'
import ToolTipInfo from 'components/helper/ToolTipInfo'

interface StorageViewProps {
    model: Storage,
    index: number
    onDelete?: () => void,
    useCommaAsDecimalPoint: boolean
}

const StorageView = observer(({ model, index, onDelete, useCommaAsDecimalPoint }: StorageViewProps) => {
    const project = useProjectStore().project!
    const currency = useProjectStore().project!.currencySymbol ?? "$"
    const storageUnitsAux = ChangeUnitOptionSetCurrency(storageUnits, currency)
    const intlCulture = project.useCommaAsDecimalPoint ? 'es' : 'en'
    const status = project.status
    const projectStartDate = new Date(project.expectedDate)
    var projectEndDate = new Date(projectStartDate);
    var projectLifetime = project.lifetime

    projectEndDate.setFullYear(projectEndDate.getFullYear() + projectLifetime)

    const gotToRevenuPage = async () => {
        status.goTo(1)
    }

    const handleSelectLibrary = (x: StorageSpec, isUserDefault: boolean) => {
        model.setKind('library')
        model.setSpecs(x)
        model.setModel(x.name)
        model.setCustomAbbreviation(x.abbreviation)
        model.setAugmentationCost(x.augmentationCost)
        model.augmentationDegradationLimit = deserializeSensitivityVar(x.augmentationDegradationLimitSensitivity)
        model.cost.costTableType = x.useComplexCost ? CostTableType.complex : CostTableType.simple
        model.cost.simple.base.capital = model.cost.simple.base.capital = x.simpleCostTable.capital
        model.cost.simple.base.operating = model.cost.simple.base.operating = x.simpleCostTable.operating
        model.cost.simple.base.replacement = model.cost.simple.base.replacement = x.simpleCostTable.replacement
        model.cost.complex = deserializeComplexCostTable(x.complexCostTable)

        model.hasConverter = x.useDedicatedConverter
        model.converter.cost.costTableType = x.dedicatedConverter.cost.costTableKind as CostTableType
        model.converter.cost.simple.base.capital = model.converter.cost.simple.base.capital = Number(x.dedicatedConverter.cost.simpleCostTable?.capital)
        model.converter.cost.simple.base.operating = model.converter.cost.simple.base.operating = Number(x.dedicatedConverter.cost.simpleCostTable?.operating)
        model.converter.cost.simple.base.replacement = model.converter.cost.simple.base.replacement = Number(x.dedicatedConverter.cost.simpleCostTable?.replacement)
        model.converter.cost.complex = deserializeComplexCostTable(x.dedicatedConverter.cost.complexCostTable)
        model.converter.efficiency = deserializeSensitivityVar(x.dedicatedConverter.efficiency)
        model.converter.lifetime = deserializeSensitivityVar(x.dedicatedConverter.lifetime)
        model.converter.sizing = deserializeSizingVar(x.dedicatedConverter.size)
    }

    const [showImport, setShowImport] = useState(false)
    const handleShowImport = () => setShowImport(true)
    const handleHideImport = () => setShowImport(false)
    const handleSelectCustom = (content: string, specs: StorageSpec) => {
        setShowImport(false)
        model.setKind('custom')
        model.setSpecs(specs)
        model.setCustomData(content)
        if (model.customName === `Storage ${index + 1}`)
            model.setCustomName(specs.name)
        if (model.customAbbreviation === `Storage ${index + 1}`)
            model.setCustomAbbreviation(specs.abbreviation)
    }

    const converterLabel = project.bus === 'ac' ? 'Storage DC/AC Converter' : 'Storage DC/DC Converter'

    // fetch available storage options
    const [modelOptions, setModelOptions] = useState<StorageSpec[]>([])
    const [userModelOptions, setUserModelOptions] = useState<StorageSpec[]>([])

    useEffect(() => {
        let cancel = false
        const libStorage = async () => {
            let rv = await api.listStorageSpecs()
            if (!cancel) {
                setModelOptions(rv)
            }
        }
        libStorage().then()
        const userStorage = async () => {
            let rv = await api.listUserStorageSpecs()
            if (!cancel && rv.length > 0) {
                setUserModelOptions(rv)
            }
        }
        userStorage().then()
        return () => { cancel = true }
    }, [])

    model.customName = model.customName === '' ? `Energy Storage ${index + 1}` : model.customName
    model.customAbbreviation = model.customAbbreviation === '' ? `Storage ${index + 1}` : model.customAbbreviation

    const handleSetUseCycleLimitPerDay = async (value: boolean) => await model.setUseCycleLimitPerDay(value)
    const handleSetOptimizeCycleLimitPerDay = async (value: boolean) => await model.setOptimizeCycleLimitPerDay(value)

    const onSelectMarket = () => {
        model.setUseAuxiliaryLoadFlatPrice(false)
        if (model.energyMarket === null || !project.energyMarkets.includes(model.energyMarket)) {
            model.setEnergyMarket(project.energyMarkets[0])
        }
    }
    const onSelectFlat = () => {
        model.setUseAuxiliaryLoadFlatPrice(true)
        model.setEnergyMarket(null)
    }

    const getEscalationKindLabel = (energyMarket: EnergyMarket): string => {
        const v = energyMarket.priceEscalator
        if (!v.enabled) return 'No escalation defined in'
        switch (v.multiYearKind) {
            case MultiYearKind.linear:
                return `${energyMarket.priceEscalator.linearValue}% escalation, as defined in`
            case MultiYearKind.custom:
                return 'Custom escalation, as defined in'
        }
    }

    const powerUnitPerHour = "MWh"
    const powerUnit = "MW"

    return (
        <>
            <div className='d-flex align-items-center mb-2'>
                <label className='mr-3 mb-0'>Storage Type: </label>
                <Dropdown>
                    <Dropdown.Toggle variant='outline-primary' className='pillButton' id={`dropdown-basic-${index}`}>
                        {model.kind === 'library' ? model.model : `Imported: ${model.customName}`}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {userModelOptions.length > 0 && userModelOptions.map(x =>
                            <Dropdown.Item key={x.name} onClick={() => handleSelectLibrary(x, true)}><b>{x.name}</b></Dropdown.Item>)}
                        <Dropdown.Divider />
                        {modelOptions.map(x =>
                            <Dropdown.Item key={x.name} onClick={() => handleSelectLibrary(x, false)}>{x.name}</Dropdown.Item>)}
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={handleShowImport}>Import...</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <div className='ml-5'>
                    <StringField label="Storage Name" value={model.customName} onChange={x => model.setCustomName(x)} validator={StrValidation.maxLen(40)} />
                </div>
                <div>
                    <StringField label="Abbreviation" value={model.customAbbreviation} onChange={x => model.setCustomAbbreviation(x)} validator={StrValidation.maxLen(20)} />
                </div>
                <div className='m-auto' />
                <button className='btn btn-outline-danger pillButton' onClick={() => onDelete?.()}><GoX /></button>
            </div>

            <StorageImportModal show={showImport} onCancel={handleHideImport} onSelect={handleSelectCustom} />
            <div className='form-group d-flex mb-2'>
                <div className='border rounded w-50 mr-1 p-2'>
                    <StorageSpecsView intlCulture={intlCulture} specs={model.specs} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    <hr />
                    <SizingView id={model.id} model={model.sizing} absoluteUnits='Units' hideHelp={true} showLabel={false} validator={Validation.integer} componentId={`storageSizeUnits-${index}`} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />

                </div>
                <div className='border rounded w-50 ml-1 p-2'>
                    <CostTableView cost={model.cost} id={model.id}
                        showDetailedCostTable={true} showDetailedReplacement={model.allowReplacement} showReplacement={model.allowReplacement} unitsSet={storageUnitsAux}
                        capitalUnits={`${currency}/${powerUnit}`} operatingUnits={`${currency}/${powerUnit}/yr`}
                        simpleCostLabel='Cost Sensitivity Analysis'
                        complexCostLabel='Cost Breakdown' isStorage={true} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    <MultiYearView model={model.omEscalator} label='O&M cost escalator (%/yr)' firstYear={(model.hasDeferment ? model.deferment.periodInYears : 0)}
                        id={`storage-om-escalator-${model.id}`} />
                    <div className='form-group row align-items-center'>
                        <div className='col-5'>
                            <Form.Check custom label='Limit Cycles per Day' id={`useCycleLimitPerDay-${index}`}
                                checked={model.useCycleLimitPerDay}
                                onChange={ev => handleSetUseCycleLimitPerDay(ev.target.checked)}
                            />
                        </div>
                        <div className='col-4 mt-3'>
                            <SensitivityVarView model={model.cycleLimitPerDay}
                                label='Limit Cycles per Day' units='' hideLabel={true} isDisabled={!model.useCycleLimitPerDay}
                                useCommaAsDecimalPoint={useCommaAsDecimalPoint}
                            />
                        </div>
                        <div className='col-1 ml-0'>
                            <ContextHelp helpId={'STORAGECYCLE'} />
                        </div>
                        <div className='ml-3'>
                            <Form.Check custom label='Make Cycle Limit Behave More Optimally (Slower)' id={`optimizeCycleLimitPerDay-${index}`}
                                checked={model.optimizeCycleLimitPerDay && model.useCycleLimitPerDay}
                                onChange={ev => handleSetOptimizeCycleLimitPerDay(ev.target.checked)}
                                disabled={!model.useCycleLimitPerDay}
                            />
                        </div>
                    </div>
                    {model.specs !== undefined &&
                        <NumberField value={model.specs.roundtripEfficiency}
                            onChange={x => model.setSpecRoundTripEfficiency(x)}
                            label={'Roundtrip Efficiency (%)'}
                            id={model.id + '-rte'}
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} />}
                    {/*<MultiYearView model={model.rteDegradationOverTime} label='RTE Degradation Over Time (%/yr)' firstYear={(model.hasDeferment ? model.deferment.periodInYears : 0)}*/}
                    {/*    id={`storage-rte-degradation-over-time-${model.id}`} />*/}
                    {!project.earlyAnalysis && <>
                    <Form.Check className='mr-2 mb-3 mt-2' custom label="Allow Augmentation" id={`storage-allowAugmentation-${model.id}`} type='switch'
                        checked={model.allowAugmentation}
                        onChange={ev => { model.setAllowAugmentation(ev.target.checked); }} />
                    </>}
                    <SensitivityVarView model={model.augmentationDegradationLimit}
                        label={(model.allowAugmentation ? 'Augmentation SoH limit (%)' : 'End-of-life SoH limit (%)')} units='%' useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    {model.allowAugmentation && <>
                        <NumberField value={model.augmentationCost}
                            onChange={x => model.setAugmentationCost(x)}
                            label={`Augmentation (${currency}/${powerUnit})`}
                            disabled={model.augmentationPriceDecline.enabled && model.augmentationPriceDecline.multiYearKind === MultiYearKind.custom}
                            validator={Validation.min(0)}
                            id={model.id + '-augmentation'}
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint}
                            tooltip={
                                <ToolTipInfo
                                    label={
                                        'Cycle cost is calculated based on the cost of augmentation'
                                    }
                                />
                            }
                        />
                        <MultiYearView model={model.augmentationPriceDecline}
                            label={model.augmentationPriceDecline.multiYearKind === MultiYearKind.linear ? 'Augmentation price decline (%/yr)' : `Augmentation price decline ($/${powerUnitPerHour})`}
                            id={`storage-augmentation-price-decline-${model.id}`} customLabel='Price decline by year' firstYear={(model.hasDeferment ? model.deferment.periodInYears : 0)} />

                    </>}
                </div>
            </div>
            <div className='border rounded p-2 mb-2'>
                <div className='d-flex mb-2 justify-content-start'>
                    <Form.Check className='mr-2' custom label={converterLabel} id={`storage-hasConverter-${model.id}`} type='switch'
                        checked={model.hasConverter}
                        onChange={ev => { model.setHasConverter(ev.target.checked); }} />
                    <ContextHelp helpId={(project.bus === 'ac' ? 'STORAGEDCACCONV' : 'STORAGEDCDCCONV')} />
                </div>
                {model.hasConverter &&
                    <div className='mt-2'>
                        <InverterView model={model.converter} label={converterLabel} firstYear={(model.hasDeferment ? model.deferment.periodInYears : 0)}
                            relativeUnits='Ratio' hideHelp={true}
                            relativeLabel='Size Relative to Storage Capacity (C-rate or 1/hr rate)'
                            sizeValidator={Validation.range(0, 1, true, false)}
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    </div>}
            </div>
            {<div className='border rounded p-2 mb-2'>
                <div className='d-flex mb-2 justify-content-start'>
                    <Form.Check className='mr-2' custom label='Model an Auxiliary Load' id={`use_auxiliary_load_${model.id}`} type='switch'
                        checked={model.useAuxiliaryLoad}
                        onChange={ev => { model.setUseAuxiliaryLoad(ev.target.checked); }} />
                    <ContextHelp helpId={'AUXLOAD'} />
                </div>
                {model.useAuxiliaryLoad && <>
                    <div className='d-flex mb-2 justify-content-between'>
                        <SensitivityVarView model={model.auxiliaryLoadSensitivity} label='Auxiliary Load (%)' units='%' useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    </div>
                    <div className='d-flex mb-2 justify-content-between'>
                        <Form.Check custom label='Storage Self-Serves Auxiliary Load' id={`storage_serves__auxiliary_load_${model.id}`} type='switch'
                            checked={model.storageServesAuxiliaryLoad}
                            onChange={ev => { model.setStorageServesAuxiliaryLoad(ev.target.checked); }} />
                        {/*    <ContextHelp helpId={'STORAGECONV'} />*/}
                    </div>
                    <div className='form-group row align-items-center'>
                        <div className='col-1'>
                            <div className='d-flex align-items-center'>
                                <Form.Check id={`aux-load-price-market-${index}`}
                                    custom label='' type='radio'
                                    disabled={project.energyMarkets.length === 0 || model.storageServesAuxiliaryLoad}
                                    checked={!model.useAuxiliaryLoadFlatPrice}
                                    onChange={ev => ev.target.checked && onSelectMarket()} />
                                <Dropdown>
                                    <Dropdown.Toggle className='pillButton' variant='outline-primary' id={`dropdown-basic-${index}`} disabled={model.storageServesAuxiliaryLoad || model.useAuxiliaryLoadFlatPrice}>
                                        {model.energyMarket?.name ?? 'Select Energy Market'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {project.energyMarkets.map(x =>
                                            <Dropdown.Item key={x.id} onClick={() => { model.setEnergyMarket(x) }}>
                                                {getEnergyMarketKindLabel(x.kind)}
                                            </Dropdown.Item>)}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        {model.energyMarket &&
                            <div className='col-7 two'>
                                <p>{getEscalationKindLabel(model.energyMarket)} <b className='text-primary' style={{ cursor: 'pointer' }} onClick={() => gotToRevenuPage()}>Energy Market Section</b></p>
                            </div>}
                    </div>
                    <div className='form-group row align-items-center'>
                        <div className='col-2'>
                            <Form.Check id={`aux-load-price-flat-${index}`}
                                type='radio' label={`Flat rate (${currency}/${powerUnitPerHour})`} custom
                                checked={model.useAuxiliaryLoadFlatPrice}
                                disabled={model.storageServesAuxiliaryLoad}
                                onChange={ev => ev.target.checked && onSelectFlat()} />
                        </div>
                        <div className='col-1'>
                            <NumberInput disabled={model.storageServesAuxiliaryLoad || !model.useAuxiliaryLoadFlatPrice}
                                value={model.auxiliaryLoadFlatPrice}
                                onChange={x => model.setAuxiliaryLoadFlatPrice(x)} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                        </div>
                    </div>
                    <div className='form-group row align-items-center'>
                        <div className='col-5'>
                            <MultiYearView model={model.auxiliaryLoadPriceMultiYear} label='Flat Price Escalator (%/yr)' disabled={model.storageServesAuxiliaryLoad || !model.useAuxiliaryLoadFlatPrice}
                                id={`auxLoadFlatPriceEscalator-${model.id}`} firstYear={(model.hasDeferment ? model.deferment.periodInYears : 0)} />
                        </div>
                    </div>
                </>}
            </div>}
        </>
    )
})

export default StorageView

interface StorageImportModalProps {
    show: boolean
    onSelect: (content: string, specs: StorageSpec) => void
    onCancel: () => void
}

type State
    = { kind: 'blank' }
    | { kind: 'importing', source: string, content: string }
    | { kind: 'ready', source: string, content: string, specs: StorageSpec }
    | { kind: 'error', source: string, message: string }

const StorageImportModal = ({ show, onSelect, onCancel }: StorageImportModalProps) => {
    const [state, setState] = useState<State>({ kind: 'blank' })
    useEffect(() => setState({ kind: 'blank' }), [show])

    const handleSelect = async (content: string, file: File) => {
        const source = file.name
        try {
            setState({ kind: 'importing', source, content })
            const r = await api.importStorage(content)
            if (!r.success) {
                console.error(r.message)
                setState({ kind: 'error', source, message: r.message })
                return
            }
            setState({ kind: 'ready', source, content, specs: r.spec! })
        } catch (error) {
            console.error(error)
            setState({ kind: 'error', source, message: `Service unavailable` })
            return
        }
    }

    const handleImport = () => {
        if (state.kind !== 'ready') return
        const { content, specs } = state
        onSelect(content, specs)
    }

    return (
        <Modal show={show} onHide={onCancel} size='lg'>
            <Modal.Header closeButton onHide={onCancel}>
                Import Battery Specifications
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex align-items-center flex-row'>
                    <FilePicker onSelect={handleSelect} label='Import *.xml' types='.xml' />
                    <div className='mx-2' />
                    {state.kind === 'ready' && <div>
                        <div>Imported: <strong>{state.source}</strong></div>
                        <div>Battery: <strong>{state.specs.name}</strong></div>
                    </div>}
                    {state.kind === 'error' && <div className='text-danger'>
                        <div>Import error:</div>
                        <div>File format unsupported.</div>
                        <div><strong>{state.source}</strong> must be an Advanced Storage Model</div>
                    </div>}
                </div>
                <div className='my-2' />
                <div>Contact <a href='mailto:support@homerenergy.com'>support@homerenergy.com</a> for assistance in creating a customized .xml file for your battery specifications.
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Spinner hidden={state.kind !== 'importing'} animation='border' size='sm' className='text-primary' />
                <Button className='pillButton' onClick={onCancel} variant='outline-primary'>Cancel</Button>
                <Button className='pillButton' onClick={handleImport} disabled={state.kind !== 'ready'}>Add Storage</Button>
            </Modal.Footer>
        </Modal>
    )
}


interface StorageSpecsViewProps {
    specs?: StorageSpec
    useCommaAsDecimalPoint: boolean
    intlCulture: string
}

const StorageSpecsView = observer(({ specs, useCommaAsDecimalPoint, intlCulture }: StorageSpecsViewProps) => {
    const intl = new IntlService(intlCulture)

    const [more, setMore] = useState(false)
    const less = !more

    const getCharge = () => {
        return (specs!.minimumStateOfCharge !== undefined && specs!.minimumStateOfCharge !== null ? 100 - specs!.minimumStateOfCharge : 0)
    }
    const powerUnit = "MW"
    const powerUnitPerHour = "MWh"

    return specs ? (
        <div>
            <div className='font-weight-bold'>Parameters for {specs.name}</div>

            {less && <>
                <div>Nominal Capacity per unit: {intl.formatNumber(specs.nominalCapacity / 1000, 'n0')} {powerUnitPerHour}</div>
                <div>Nominal Power per unit: {intl.formatNumber(specs.nominalPower / 1000, 'n0')} {powerUnit}</div>
                <div>Allowable Range of Charge: {intl.formatNumber(getCharge(), 'n0')}%</div>
            </>}
            {more && <>
                <div>Nominal Capacity per unit: {intl.formatNumber(specs.nominalCapacity / 1000, 'n0')} {powerUnitPerHour}</div>
                <div>Nominal Power per unit: {intl.formatNumber(specs.nominalPower / 1000, 'n0')} {powerUnit}</div>
                <div>Maximum Capacity per unit: {intl.formatNumber(specs.maximumCapacity, 'n0')} Ah</div>
                <div>Nominal Voltage: {intl.formatNumber(specs.nominalVoltage, 'n0')} V</div>
                <div>Charge Current: {intl.formatNumber(specs.chargeCurrent, 'n0')} A</div>
                <div>Discharge Current: {intl.formatNumber(specs.dischargeCurrent, 'n0')} A</div>
                <div>Allowable Range of Charge: {intl.formatNumber(getCharge(), 'n0')}%</div>
                <div className='d-flex align-items-center'>Minimum State of Charge (%): <NumberInput value={specs.minimumStateOfCharge} onChange={x => { specs.minimumStateOfCharge = x }} className="col-md-4 ml-2" useCommaAsDecimalPoint={useCommaAsDecimalPoint}></NumberInput></div>
                <div>Estimated lifetime throughput: {intl.formatNumber(specs.estimatedLifetimeThroughput / 1000, 'n0')} {powerUnitPerHour}</div>
                <div>Lifetime curve:</div>
                <div>
                    <LifetimeCurveView points={specs.lifetimeCurve} />
                </div>
            </>}
            <button className='btn btn-sm btn-link btn-no-focus align-self-start pillButton'
                onClick={() => setMore(!more)}>
                {more ? 'Show Less' : 'Show More'}
            </button>
        </div>
    ) : null
})


interface LifetimeCurveViewProps {
    points: StorageCurveItem[]
}

export const LifetimeCurveView = ({ points }: LifetimeCurveViewProps) => {
    return (
        <Chart>
            <ChartXAxis>
                <ChartXAxisItem title={{ text: 'Depth of Discharge (%)' }} />
            </ChartXAxis>
            <ChartYAxis>
                <ChartYAxisItem title={{ text: 'Cycles to Failure' }} />
            </ChartYAxis>
            <ChartSeries>
                <ChartSeriesItem key='annual' type='scatterLine' data={points} xField='x' yField='y' />
            </ChartSeries>
        </Chart>
    )
}
