import {observer} from 'mobx-react-lite'
import transmissionTower from 'assets/images/transmission-tower.svg'
import poi from 'assets/images/poi.svg'
import {TSvg, iconHeight} from './shared/Shapes'
import SolarTechnology from './shared/SolarTechnology'
import StorageTechnology from './shared/StorageTechnology'
import WindTechnology from './shared/WindTechnology'
import SchematicIcon from './shared/SchematicIcon'
import Converter from './shared/Converter'
import './equipment.scss'
import { v4 } from 'uuid'
import * as React from 'react'
import { Schematics } from '../../SchematicModel'
import { Project } from '../../model'

interface SchematicViewProps {
    model: Schematics,
    project:Project
}


const SchematicView = observer(({model,project}: SchematicViewProps) => {
    if (model.components.length === 0) return null
    return (
        <>
            <h6 className='mb-2 schematic-heading'>Schematic</h6>
            <div className='d-flex flex-row align-items-center justify-content-center pt-4 pb-2 schematic-container'>
                <div className='d-flex flex-column align-items-end' style={{marginBottom:(project.useWind && model.bus === 'dc')?(project.useSolar&&!project.useStorage?60:(65)):''}}>
                    {model.components.map(component => component.kind === 'solar'
                        ? <SolarTechnology key={component.id} hasConverter={component.hasConverter} bus={model.bus}/>
                        : (component.kind === 'storage'?<StorageTechnology key={component.id} hasConverter={component.hasConverter} bus={model.bus}/>:(project.useWind  && (model.bus === 'ac') &&<WindTechnology key={component.id}/>)),
                    )}
                </div>
                {model.components.length>1&& <TSvg height={iconHeight * (((project.useWind && model.bus === 'dc') ?model.components.length - 2:model.components.length - 1))} isWindDcBus={project.useWind && model.bus === 'dc'}/>}
                {model.bus === 'dc' && !project.useWind && <Converter bus={model.bus} systemConverter/>}
                
                {project.useWind && model.bus === 'dc' &&<div className='d-flex flex-column align-items-end'>
               
                {model.bus === 'dc' && project.useWind && <Converter bus={model.bus} systemConverter/>}
                    {project.useWind && model.bus === 'dc' && <WindTechnology key={v4()} />}
                    
                </div>}
                {project.useWind && model.bus === 'dc' && <TSvg height={iconHeight} />}
                <SchematicIcon src={poi} label='Point of Interconnection'/>
                <img className='schematic-icon vertical-offset' src={transmissionTower} alt='transmission-tower'/>
            </div>
        </>
    )
})


export default SchematicView
