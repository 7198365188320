import download from "downloadjs";
import * as React from 'react'
import { useReducer } from "react";
import { useState } from "react"
import { Button, Form, Modal, Row, Spinner } from "react-bootstrap"
import api from "../../../../services/api";
import { Project } from "../../model";
import { ResultModel } from "../../results";

interface ResultExportModalProps {
    show: boolean
    project: Project
    result: ResultModel
    onCancel: () => void
}

const ResultExportModal = ({ show, project, result, onCancel }: ResultExportModalProps) => {
    const initialState: ExportStateProps = {
        SummaryResults: true,
        RegulationMarket: project.useRegulationMarket,
        WindResults: project.useWind,
        SolarResults: project.useSolar,
        StorageResults: project.useStorage,
        Augmentation: project.useStorage,
        Revenue: true,
        Timeseries: true,
        Cashflow: true,
        ProForma: true,
    }
    const [state, dispatch] = useReducer(fieldsExportReducer, initialState);

    const { SummaryResults,
        RegulationMarket,
        WindResults,
        SolarResults,
        StorageResults,
        Augmentation,
        Revenue,
        Timeseries,
        Cashflow,
        ProForma,
    } = state;

    const allFields = ['SummaryResults', 'RegulationMarket', 'RevenueSummary', 'EnergyPriceSummary', 'SystemPerformanceSummary', 'WindResults', 'SolarResults', 'StorageResults', 'Augmentation', 'Revenue', 'Timeseries', 'Cashflow', 'ProForma',]
    const [showExportError, setShowExportError] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)

    const handleConfirm = async () => {
        if (showExportError)
            setShowExportError(false)
        setShowSpinner(true)
        let id = project.id
        var exportResponse = await api.exportMultiYearResultsExcel(id, result.resultId, state)
        if (exportResponse.succeded === false)
            setShowExportError(true)
        else {
            onCancelExport()
            download(exportResponse.data!, exportResponse.fileName, 'application/octet-stream')
        }
        setShowSpinner(false)
    }

    const onCancelExport = () => {
        if (showExportError)
            setShowExportError(false)
        if (showSpinner)
            setShowSpinner(false)
        onCancel()
    }
    const selectAll = () => {
        allFields.forEach(x => dispatch({ fieldName: x, state: true }))
    }
    const deselectAll = () => {
        allFields.forEach(x => dispatch({ fieldName: x, state: false }))
    }
    return (
        <Modal show={show} onHide={() => onCancelExport()} >
            <Modal.Header closeButton onHide={() => onCancelExport()}>
                <Modal.Title>Select What to Export</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-column container-fluid bg-white rounded-lg mt-0 justify-content-center'>
                    <Row className='mb-2'>
                        <Button id='selectAll' size='sm' variant ='primary'className='align-self-center pillButton' onClick={selectAll}>Check All</Button>
                        <Button id='deselectAll' size='sm' variant='outline-primary' className='align-self-center pillButton ml-1' onClick={deselectAll}>Uncheck All</Button>
                    </Row>
                    {/*<hr className='bg-dark' />*/}
                    <Row>
                        <Form.Check custom label='Summary Results' id='SummaryResults' type='checkbox'
                        checked={SummaryResults}
                            onChange={ev => { dispatch({ fieldName: 'SummaryResults', state: ev.target.checked }) }} />
                    </Row>
                    {project.useRegulationMarket && <Row>
                        <Form.Check custom label='Regulation Market' id='RegulationMarket' type='checkbox'
                            checked={RegulationMarket}
                            onChange={ev => { dispatch({ fieldName: 'RegulationMarket', state: ev.target.checked }) }} />
                    </Row>}
                    {project.useWind && <Row>
                        <Form.Check custom label='Wind Results' id='WindResults' type='checkbox'
                            checked={WindResults}
                            onChange={ev => { dispatch({ fieldName: 'WindResults', state: ev.target.checked }) }} />
                    </Row>}
                    {project.useSolar && <Row>
                        <Form.Check custom label='Solar Results' id='SolarResults' type='checkbox'
                            checked={SolarResults}
                            onChange={ev => { dispatch({ fieldName: 'SolarResults', state: ev.target.checked }) }} />
                    </Row>}
                    {project.useStorage && <Row>
                        <Form.Check custom label='Storage Results' id='StorageResults' type='checkbox'
                            checked={StorageResults}
                            onChange={ev => { dispatch({ fieldName: 'StorageResults', state: ev.target.checked }) }} />
                    </Row>}
                    {project.useStorage && <Row>
                        <Form.Check custom label='Augmentation' id='Augmentation' type='checkbox'
                            checked={Augmentation}
                            onChange={ev => { dispatch({ fieldName: 'Augmentation', state: ev.target.checked }) }} />
                    </Row>}
                    <Row>
                        <Form.Check custom label='Revenue' id='Revenue' type='checkbox'
                            checked={Revenue}
                            onChange={ev => { dispatch({ fieldName: 'Revenue', state: ev.target.checked }) }} />
                    </Row>
                    <Row>
                        <Form.Check custom label='Time Series' id='Timeseries' type='checkbox'
                            checked={Timeseries}
                            onChange={ev => { dispatch({ fieldName: 'Timeseries', state: ev.target.checked }) }} />
                    </Row>
                    <Row>
                        <Form.Check custom label='Cash Flow' id='Cashflow' type='checkbox'
                            checked={Cashflow}
                            onChange={ev => { dispatch({ fieldName: 'Cashflow', state: ev.target.checked }) }} />
                    </Row>
                    <Row>
                        <Form.Check custom label='Pro Forma' id='ProForma' type='checkbox'
                            checked={ProForma}
                            onChange={ev => { dispatch({ fieldName: 'ProForma', state: ev.target.checked }) }} />
                    </Row>
                </div>
                {showExportError && <div className='ml-4' style={{ color: 'red' }}><p>Error exporting results. Please try again later or contact support!</p></div>}
            </Modal.Body>
            <Modal.Footer>
                {showSpinner && <p>This might take a few minutes</p>}
                <Spinner hidden={!showSpinner} animation='border' size='sm' className='text-primary' />
                <Button className='pillButton' onClick={() => onCancelExport()} variant='outline-primary'>Cancel</Button>
                <Button className='pillButton' disabled={!SummaryResults && !RegulationMarket && !WindResults && !SolarResults && !StorageResults && !Augmentation && !Revenue && !Timeseries && !Cashflow && !ProForma } onClick={() => handleConfirm()}>Export</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ResultExportModal

export interface ExportStateProps {
    SummaryResults: boolean
    RegulationMarket: boolean
    WindResults: boolean
    SolarResults: boolean
    StorageResults: boolean
    Augmentation: boolean
    Revenue: boolean
    Timeseries: boolean
    Cashflow: boolean
    ProForma: boolean
};

interface FieldSetProps {
    fieldName: string
    state: boolean
}

function fieldsExportReducer(state: ExportStateProps, set: FieldSetProps) {
    return {
        ...state,
        [set.fieldName]: set.state,
    };
}
