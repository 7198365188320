import * as React from 'react'
import { useEffect, useState } from 'react'
import {Button, Modal, Spinner} from 'react-bootstrap'
import TimeSeriesView from './TimeSeriesView'
import { TimeSeries } from '../project/model/TimeSeries'
import { useProjectStore } from '../project/ProjectProvider'
import { MultiYearImportStatus } from '../project/model/MultiYearTimeSeries'
import api from '../../services/api'
import FilePicker from './FilePicker'
import YearSelector from './YearSelector'


interface TimeSeriesImportProps {
    onSelect?: (data: any, year: number, fileName: string) => void
    onSelectMultiYear?: (data: any, year: number, fileName: string) => Promise<MultiYearImportStatus> 
    label?: string
    disabled?: boolean
    visible?: boolean
    intlCulture?: string
    model?: TimeSeries
    showTimseriesViewer?: boolean
    timeSeriesViewerTitle?: string
    isSingleFileMultiYear?: boolean
    lifetime?: number
    showSuccessModal?: () => void
    removeZeros?: boolean
    hideYearSelector?: boolean
}

const TimeSeriesImport = ({ onSelect, onSelectMultiYear, label, model, showSuccessModal, removeZeros = false, intlCulture = "en-US", showTimseriesViewer = false, disabled = false, visible = true, timeSeriesViewerTitle = '', isSingleFileMultiYear = false, lifetime = 2, hideYearSelector = false }: TimeSeriesImportProps) => {
    const [show, setShow] = useState(false)
    const open = () => setShow(true)
    const close = () => setShow(false)

    const handleSelect = (data: any, year: number, fileName: string)  => {
        if (!showTimseriesViewer)
            close()
        onSelect?.(data, year, fileName)
    }

    const handleSelectMultiYear = async (data: any, year: number, fileName: string): Promise<MultiYearImportStatus> => {
        const status = await onSelectMultiYear?.(data, year, fileName)
        if (!showTimseriesViewer)
            close()
        return status ?? { success: false, message: 'Error saving price file' }
    }

    return (<>
        {visible && <button className={`btn btn-file btn-primary pillButton`} disabled={disabled}
            onClick={open}>
            {label ?? 'Import...'}
        </button>}
        <TimeSeriesImportModal show={show} removeZeros={removeZeros} showSuccessModal={showSuccessModal} onSelect={handleSelect} onSelectMultiYear={handleSelectMultiYear} onCancel={close} model={model} showTimseriesViewer={showTimseriesViewer} timeSeriesViewerTitle={timeSeriesViewerTitle} isSingleFileMultiYear={isSingleFileMultiYear} lifetime={lifetime} hideYearSelector={hideYearSelector} />
    </>)
}

export default TimeSeriesImport


interface TimeSeriesImportModalProps {
    show: boolean
    onSelect: (data: any, year: number, fileName: string) => void
    onSelectMultiYear: (data: any, year: number, fileName: string) => Promise<MultiYearImportStatus>
    onCancel: () => void
    intlCulture?: string
    model?: TimeSeries
    showTimseriesViewer?: boolean
    timeSeriesViewerTitle?: string
    isSingleFileMultiYear: boolean
    lifetime: number
    showSuccessModal?: () => void
    removeZeros: boolean
    hideYearSelector: boolean
}

type State
    = { kind: 'blank' }
    | { kind: 'selected', source: string, content: string, year?: number }
    | { kind: 'importing', source: string, content: string, year?: number }
    | { kind: 'imported', source: string, content: string, year?: number }
    | { kind: 'error', source: string, message: string }

const TimeSeriesImportModal = ({ show, onSelect, onSelectMultiYear, onCancel, showSuccessModal, model, removeZeros, intlCulture = "en-US", showTimseriesViewer = false, timeSeriesViewerTitle = '', isSingleFileMultiYear = false, lifetime, hideYearSelector }: TimeSeriesImportModalProps) => {
    const project = useProjectStore().project!
    const useCommaAsDecimalPoint = project.useCommaAsDecimalPoint

    const [state, setState] = useState<State>({ kind: 'blank' })
    useEffect(() => setState({kind: 'blank'}), [show])

    const handleSelect = (content: string, file: File) => {
        const source = file.name
        let fileName = source.split('.')
        if (fileName.length > 1 && fileName.pop()!.toString().startsWith('xl')) {
            setState({ kind: 'error', source, message: 'Import error: Excel files are not supported' })
            return
        }
        setState({kind: 'selected', source, content})
    }

    const [importedNewFile, setHasImportedNewFile] = useState(false)
    const close = () => {
        onCancel()
        if (showSuccessModal && importedNewFile) {
            setHasImportedNewFile(false)
            showSuccessModal()
        }
    }

    const handleImport = async () => {
        if (state.kind !== 'selected') return
        const {source, content, year} = state

        try {
            setState({ kind: 'importing', source, content, year })
            const r = await api.import(useCommaAsDecimalPoint, content, year, removeZeros)
            if (!r.success) {
                console.error(r.message)
                let message = r.message !== undefined && r.message !== '' ? r.message : 'Import error: incorrect file format'
                setState({kind: 'error', source, message: message})
                return
            }
            if (isSingleFileMultiYear) {
                const status = await onSelectMultiYear(r.data, r.year, state.source)
                if (status.success) {
                    setState({ kind: 'imported', source, content, year })
                    if (showSuccessModal) {
                        if (showTimseriesViewer)
                            setHasImportedNewFile(true)
                        else
                            showSuccessModal()
                    }
                    else {
                        console.error(status.message)
                        setState({ kind: 'error', source, message: `Import error: service unavailable` })
                        return
                    }
                }
            }
            else {
                onSelect(r.data, r.year, state.source)
                setState({ kind: 'imported', source, content, year })
                if (showSuccessModal) {
                    if (showTimseriesViewer)
                        setHasImportedNewFile(true)
                    else
                        showSuccessModal()
                }
            }
            
        } catch (error) {
            console.error(error)
            setState({ kind: 'error', source, message: `Import error: service unavailable`})
            return
        }
    }

    const handleImportSingleFileMultiYear = async () => {
        if (state.kind !== 'selected') return
        const { source, content, year } = state

        try {
            setState({ kind: 'importing', source, content, year })
            const r = await api.singleFileMultiYearImport(lifetime, useCommaAsDecimalPoint, content, year)
            if (!r.success) {
                console.error(r.message)
                let message = r.message !== undefined && r.message !== '' ? r.message : 'Import error: incorrect file format'
                setState({ kind: 'error', source, message: message })
                return
            }
            await onSelectMultiYear(r.data, r.year, state.source)//todo await on select?
            setState({ kind: 'imported', source, content, year })
            if (showSuccessModal)
                showSuccessModal()
        } catch (error) {
            console.error(error)
            setState({ kind: 'error', source, message: `Import error: service unavailable` })
            return
        }
    }

    const handleSetYear = (x?: number) => {
        if (state.kind !== 'selected') return
        setState({...state, year: x})
    }

    return (
        <Modal show={show} onHide={close}>
            <Modal.Header closeButton onHide={close}>
                <Modal.Title>Import File</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-row align-items-center'>
                    <FilePicker onSelect={handleSelect} label='Open...'/>
                    <div className='mx-2'/>
                    {(state.kind === 'selected' || state.kind === 'importing' || state.kind === 'error') &&
                        <div>File: <strong>{state.source}</strong></div>}
                </div>
                {((state.kind === 'selected' || state.kind === 'importing') && !hideYearSelector) &&
                    <div className='mt-2'>
                        <YearSelector label='Year (optional)' value={state.year}
                                      onValueChanged={handleSetYear} min={2000} max={2200}/>
                    </div>}
                {(state.kind !== "selected" && state.kind !== "importing" && model?.data !== undefined && model.state === 'ready' && showTimseriesViewer) &&
                    <div className='mt-2'> {/*            <div className='col-6 pb-2 d-flex flex-column align-items-center'>*/}
                        <TimeSeriesView intlCulture={intlCulture} model={model?.data !== undefined && model.data.length > 0 ? model : new TimeSeries()} chartTitle={timeSeriesViewerTitle} yAxisTitle={`kw`} />
                    </div>}
                {state.kind === 'error' &&
                    <div className='mt-2 text-danger'>{state.message}</div>}
            </Modal.Body>
            <Modal.Footer>
                <Spinner hidden={state.kind !== 'importing'} animation='border' size='sm' className='text-primary pillButton'/>
                <Button className='pillButton' onClick={onCancel} hidden={state.kind === 'imported'} variant='outline-primary'>Cancel</Button>
                <Button className='pillButton' onClick={(isSingleFileMultiYear ? handleImportSingleFileMultiYear : handleImport)} hidden={state.kind !== 'selected'} disabled={state.kind !== 'selected'}>{(showTimseriesViewer ? "Import" : "Ok")}</Button>
                <Button className='pillButton' onClick={close} hidden={state.kind === 'selected'} disabled={state.kind !== 'imported'}>{(showTimseriesViewer ? "Close" : "Ok")}</Button>
            </Modal.Footer>
        </Modal>
    )
}
