
import { ComponentKind, SizingKind } from '../../services/inputs'
import { eq } from '../../utils'
import { ValidationFunc } from '../helper/validation'
import { MAX_SIMULATION_ERROR_THRESHOLD, MAX_SIMULATION_WARNING_THRESHOLD, Project } from './model'
import { CapacityMarket } from './model/CapacityMarket'
import { Contract } from './model/Contract'
import { ComplexCostItems, ComplexCostTable, CostTableType } from './model/CostTable'
import { EnergyMarketKind } from './model/EnergyMarket'
import { BonusDepreciation, InvestmentTaxCredit, Macrs } from './model/Incentive'
import { MultiYearTimeSeries } from './model/MultiYearTimeSeries'
import { solarUnits, storageUnits, UnitOption, UnitOptionSet } from './pages/equipment/shared/DetailCostBreakup'
import { URL_APPLICATION, URL_EQUIPMENT, URL_OTHER, URL_SETUP } from './ProjectStatus'


export interface AnalyzerItem {
    label: string
    link?: string
    warning?: boolean
    estimation?: boolean
}

export function* analyze(model: Project) {
    //estimate calculation time
    let tempEstimation = model.getEstimatedCalculationTime()
    //minimum increments of 1 minute, if over 10 minutes, show increments of 5 minutes, and if over 2 hours, show increments of 1 hour
    let estimation = tempEstimation < 60 ? 60 : (tempEstimation <= 600 && tempEstimation % 60 === 0) || (tempEstimation > 600 && tempEstimation <= 7200 && tempEstimation % 300 === 0) || (tempEstimation > 7200 && tempEstimation % 3600 === 0) ? tempEstimation : tempEstimation <= 600 ? (Math.floor(tempEstimation / 60) * 60) + 60 : tempEstimation > 600 && tempEstimation <= 7200 ? (Math.floor(tempEstimation / 300) * 300) + 300 : (Math.floor(tempEstimation / 3600) * 3600) + 3600
    yield { label: `Estimated calculation time: ${(estimation <= 7200 ? Math.ceil(estimation / 60) : Math.ceil(estimation / 60 / 60))} ${(estimation <= 7200 ? 'minute(s)' : 'hours')}`, estimation: true }

    // has revenue stream?
    if (!model.useEnergyMarket && !model.useCapacityMarket && !model.useContract && !model.useRegulationMarket)
        yield { label: 'Add a revenue stream', link: URL_APPLICATION }

    // has components?
    if (model.useCapacityMarket) {
        if (!model.useStorage || model.storage.every(x => x.isZero))
            yield { label: 'Capacity markets require storage (with size > 0)', link: URL_EQUIPMENT }
        if (!model.useEnergyMarket &&
            (!model.useSolar || model.solar.every(x => x.isZero)) && (!model.useWind || model.wind.every(x => x.isZero)))
            yield { label: 'Capacity markets require a source of power to charge the battery (with size > 0)', link: URL_EQUIPMENT }
        if (model.hasExportLimitSchedule || model.hasExportLimitTimeSeries)
            yield { label: "Export limits might impact Capacity Market results", link: URL_EQUIPMENT, warning: true }
    } else {
        if ((!model.useSolar || model.solar.every(x => x.isZero)) && (!model.useWind || model.wind.every(x => x.isZero)) &&
            (!model.useStorage || model.storage.every(x => x.isZero)))
            yield { label: 'Add storage or a non-market source of power (with size > 0)', link: URL_EQUIPMENT }
    }

    // EM
    if (model.useEnergyMarket) {
        const hasImport = model.energyMarkets.every(item => (item.useMultiYearPrice !== true && item.price.state === 'ready') || (item.useMultiYearPrice && !item.useSingleFileMultiYearPrice && item.multiYearPrice.length > 0) || (item.useMultiYearPrice && item.useSingleFileMultiYearPrice && item.singleFileMultiYearPrice.length > 0))
        if (!hasImport)
            yield { label: 'No imported energy market price', link: URL_APPLICATION }

        const hasInvalidYears = model.energyMarkets.every(item => !item.useMultiYearPrice || (!item.useSingleFileMultiYearPrice && (item.multiYearPrice.length === 0 || checkMultiYearEnergyPrice(item.multiYearPrice))) || (item.useSingleFileMultiYearPrice && (item.singleFileMultiYearPrice.length === 0 || checkMultiYearEnergyPrice(item.singleFileMultiYearPrice))))
        if (!hasInvalidYears)
            yield { label: 'One or more years have not being assigned a price strip file', link: URL_APPLICATION }

        if (model.energyMarkets.length > 0) {
            for (let y = 0; y < model.energyMarkets[0].pvAllocationSensitivity.values.length; y++) {
                let totalPvAllocation: number = 0
                model.energyMarkets.map(x => totalPvAllocation += x.pvAllocationSensitivity.values[y].value)
                if (totalPvAllocation !== 100)
                    yield { label: 'The sum of PV allocation sensitivity across Energy Markets should be 100%', link: URL_APPLICATION }
            }

            for (let y = 0; y < model.energyMarkets[0].windAllocationSensitivity.values.length; y++) {
                let totalWindAllocation: number = 0
                model.energyMarkets.map(x => totalWindAllocation += x.windAllocationSensitivity.values[y].value)
                if (totalWindAllocation !== 100)
                    yield { label: 'The sum of Wind allocation sensitivity across Energy Markets should be 100%', link: URL_APPLICATION }
            }
        }
    }

    //Reg Market
    if (model.useRegulationMarket) {
        if (!model.useEnergyMarket)
            yield { label: 'Add an Energy Market', link: URL_APPLICATION }

        //let hasEnergyMktStream = model.regulationMarkets.every(x => x.useEnergyPrice || x.useRegUp || x.useRegDown) //TODO can settlement work without reg up or down?
        //if (!hasEnergyMktStream)
        //    yield { label: 'Add a Regulation Market revenue stream', link: URL_APPLICATION }

        let hasRegUpImport = model.regulationMarkets.every(x => !x.useRegUp || (x.regUpPrice.data.length > 0 && x.regUpPrice.state === 'ready'))
        if (!hasRegUpImport)
            yield { label: 'No imported Reg-Up market price', link: URL_APPLICATION }

        let hasRegUpMaxCommitment = model.regulationMarkets.every(x => !x.useRegUp || (x.maxCommitmentRegUp.singleValue !== 0 || x.maxCommitmentRegUp.values.some(item => item.value !== 0)))
        if (!hasRegUpMaxCommitment)
            yield { label: 'At least one optimization value for Max Reg-Up Commitment must be more than 0', link: URL_APPLICATION }

        let hasRegDownImport = model.regulationMarkets.every(x => !x.useRegDown || (x.regDownPrice.data.length > 0 && x.regDownPrice.state === 'ready'))
        if (!hasRegDownImport)
            yield { label: 'No imported Reg-Down market price', link: URL_APPLICATION }

        let hasRegDownMaxCommitment = model.regulationMarkets.every(x => !x.useRegDown || (x.maxCommitmentRegDown.singleValue !== 0 || x.maxCommitmentRegDown.values.some(item => item.value !== 0)))
        if (!hasRegDownMaxCommitment)
            yield { label: 'At least one optimization value for Max Reg-Down Commitment must be more than 0', link: URL_APPLICATION }

        let hasEnergyPrice = model.regulationMarkets.every(x => !x.useEnergyPrice || (x.energyMarket != null || x.energyMarket !== undefined))
        if (!hasEnergyPrice)
            yield { label: 'Select an Energy Market as Regulation Market energy price', link: URL_APPLICATION }

        let usesRegUpOrRegDown = model.regulationMarkets.every(x => /*!x.useEnergyPrice ||*/(x.useRegUp || x.useRegDown)) //TODO can settlement be used without reg up or down?
        if (!usesRegUpOrRegDown)
            yield { label: 'Select Reg-Up or Reg-Down', link: URL_APPLICATION }

        let hasSettlementAllocation = model.regulationMarkets.every(x => !x.useEnergyPrice || (x.throughputPercentage.singleValue !== 0 || x.throughputPercentage.values.some(item => item.value !== 0)))
        if (!hasSettlementAllocation)
            yield { label: 'At least one optimization value for Settlement Allocation must be more than 0', link: URL_APPLICATION }

        if (model.regulationMarkets.length > 0) {
            let regMktNames: string[] = []
            model.regulationMarkets.map(x => regMktNames.push(x.name))
            let hasDuplicatedRegMktNames = Analyzer.checkIfDuplicateExists(regMktNames)
            if (hasDuplicatedRegMktNames)
                yield { label: 'One or more Regulation Markets have the same name', link: URL_APPLICATION }
        }
    }

    // CM events
    if (model.useCapacityMarket) {
        const energyMarketKind = model.energyMarkets.map(i => i.kind);
        for (const capacityMarket of model.capacityMarkets) {
            for (const x of analyzeCapacityMarket(capacityMarket, energyMarketKind, model.useEnergyMarket))
                yield { ...x, link: URL_APPLICATION + '#' + capacityMarket.id }
        }
    }

    // ToD
    if (model.useContract) {
        for (const contract of model.contracts) {
            for (const x of analyzeContract(contract, model))
                yield { ...x, link: x.link ? x.link : (URL_APPLICATION + '#' + contract.id) }
        }
    }

    //if (model.useCapacityMarket && model.useContract && model.contracts.map(i=>i.obligationKind).every(x => x=='none')) {
    //    let capPercentage = model.capacityMarkets.map(i => i.allocatedStorageCapacity).reduce((a, b) => a + b,0);
    //    let todPercentage = model.contracts.map(i => i.contractedStorageCapacity).reduce((a, b) => a + b,0);
    //    if ((capPercentage + todPercentage) > 100) {
    //        yield { label: 'Storage allocation for RA + TOD should not be greater that 100%', link: URL_APPLICATION }
    //    }
    //}
    // fields
    for (const x of Analyzer.range('Project Lifetime', 1, 40, true)(model.lifetime))
        yield { label: x, link: URL_OTHER }

    if (model.hasExportLimitTimeSeries) {
        const hasExportTimeseries = (model.hasExportLimitTimeSeries === true && model.exportLimitTimeSeriesData.state === 'ready')
        if (!hasExportTimeseries)
            yield { label: 'No export limit timeseries', link: URL_EQUIPMENT }
    }
    if (model.hasExportLimit) {
        for (const x of Analyzer.min('Export Limit', 0, true)(model.exportLimit))
            yield { label: x, link: URL_EQUIPMENT }
    }

    if (model.hasImportLimitTimeSeries) {
        const hasImportTimeseries = (model.hasImportLimitTimeSeries === true && model.importLimitTimeSeriesData.state === 'ready')
        if (!hasImportTimeseries)
            yield { label: 'No import limit timeseries', link: URL_EQUIPMENT }
    }
    if (model.hasImportLimit) {
        for (const x of Analyzer.min('Import Limit', 0, true)(model.importLimit))
            yield { label: x, link: URL_EQUIPMENT }
    }

    if (model.useSolar) {
        const requiresSolarResource = model.solar.some(x => x.kind !== ComponentKind.custom)
        if (model.solarResource.kind === 'timeseries' && requiresSolarResource)
            for (const x of Analyzer.min('Solar GHI', 0, true)(model.solarResource.scale.singleValue))
                yield { label: x, link: URL_EQUIPMENT }

        if (model.temperatureResource.kind === 'timeseries')
            for (const x of Analyzer.range('Temperature', -100, 100, true, true)(model.temperatureResource.scale.singleValue))
                yield { label: x, link: URL_EQUIPMENT }

        for (const solar of model.solar) {
            if (solar.cost.costTableType === CostTableType.complex && !checkCostTableHasCorrectUnits(solar.cost.complex, solarUnits))
                yield { label: 'Solar complex cost structure has incorrect units', link: URL_EQUIPMENT }
            if ((solar.kind === ComponentKind.homer && solar.sizing.onlyZero) ||
                (solar.kind === ComponentKind.custom && solar.customSizeArray.onlyZero))
                yield { label: 'Solar size is 0 MW', link: URL_EQUIPMENT, warning: true }
            if (solar.kind === ComponentKind.custom && eq(solar.customData.calculateAverage(), 0))
                yield { label: 'Imported PV production undefined', link: URL_EQUIPMENT }

            // dedicated converter
            const converterLabel = model.bus === 'ac' ? 'Solar DC/AC Inverter' : 'Solar DC/DC Converter'
            if (solar.hasConverter
                && solar.converter.sizing.kind === SizingKind.relative
                && !solar.converter.sizing.relativeValues.map(x => x.value).every(x => 0 < x && x < 10))
                yield { label: `${converterLabel} relative size must be > 0 and < 10`, link: URL_EQUIPMENT }
            if (solar.hasConverter && solar.converter.sizing.onlyZero)
                yield { label: `${converterLabel} size is 0 MW`, link: URL_EQUIPMENT, warning: true }

            if (solar.hasConverter) {
                if (!solar.converter.efficiency.values.map(x => x.value).every(x => 0 < x && x <= 100))
                    yield { label: 'Solar Inverter/Converter efficiency must be > 0 and ≤ 100', link: URL_EQUIPMENT }
                if (!solar.converter.lifetime.values.map(x => x.value).every(x => 0 < x))
                    yield { label: 'Solar Inverter/Converter lifetime must be > 0', link: URL_EQUIPMENT }
            }
        }
    }

    if (model.useWind && model.wind.length > 0) {
        //if (model.wind[0].lifetime !== undefined && model.wind[0].lifetime.values.some(x => x.value <= 0))
        //    yield { label: 'All wind lifetime values must be > 0', link: URL_EQUIPMENT }
        if ((model.wind[0].kind !== 'production' && model.wind[0].sizing.onlyZero) ||
            (model.wind[0].kind === 'production' && model.wind[0].customSizing.onlyZero))
            yield { label: 'Wind size is 0 MW', link: URL_EQUIPMENT, warning: true }

        if (model.wind[0].kind === 'production' && model.wind[0].customSizing.values.some(x => x.value < 1))
            yield { label: 'Wind size must be at least 1 MW', link: URL_EQUIPMENT, warning: true }

        if (model.wind[0].cost.costTableType === CostTableType.complex) {//temporarily forcing wind to have at least one cost so 2XML defaults are not used
            if (model.wind[0].cost.complex.directCapital.items.length === 0)
                yield { label: 'Add wind capital cost', link: URL_EQUIPMENT }
            if (model.wind[0].cost.complex.operating.items.length === 0)
                yield { label: 'Add wind operating cost', link: URL_EQUIPMENT }
        }
    }

    if (model.useStorage) {
        for (const storage of model.storage) {
            if (storage.cost.costTableType === CostTableType.complex && !checkCostTableHasCorrectUnits(storage.cost.complex, storageUnits))
                yield { label: 'Storage complex cost structure has incorrect units', link: URL_EQUIPMENT }
            if (!storage.augmentationDegradationLimit.values.map(x => x.value).every(x => 0 < x))
                yield { label: 'Augmentation/End-of-life SoH limit must be > 0', link: URL_EQUIPMENT }
            if ((storage.allowAugmentation || model.earlyAnalysis) && storage.augmentationCost === 0)
                yield { label: 'Augmentation cost is zero', link: URL_EQUIPMENT, warning: true }
            if ((storage.allowAugmentation || model.earlyAnalysis) && storage.augmentationCost < 0)
                yield { label: 'Augmentation cost must be equal or greater than 0', link: URL_EQUIPMENT }
            if (storage.sizing.values.some(x => !Number.isInteger(x.value)))
                yield { label: 'Storage units must be an integer', link: URL_EQUIPMENT }
            if (storage.sizing.onlyZero)
                yield { label: 'Storage units is set to 0', link: URL_EQUIPMENT, warning: true }
            if (storage.hasConverter) {
                const converterLabel = model.bus === 'ac' ? 'Storage DC/AC Inverter' : 'Storage DC/DC Converter'
                if (!storage.converter.efficiency.values.map(x => x.value).every(x => 0 < x && x <= 100))
                    yield { label: `${converterLabel} efficiency must be > 0 and ≤ 100`, link: URL_EQUIPMENT }
                if (!storage.converter.lifetime.values.map(x => x.value).every(x => 0 < x))
                    yield { label: `${converterLabel} lifetime must be > 0`, link: URL_EQUIPMENT }
                if (storage.hasConverter && storage.converter.sizing.onlyZero)
                    yield { label: `${converterLabel} size is 0 MW`, link: URL_EQUIPMENT, warning: true }
            }
        }
        //if (model.storage.length > 1 && model.storage.some(x => x.hasDeferment))
        //    yield { label: `Storage deferment cannot be applied on projects with multiple storages`, link: URL_EQUIPMENT }
    }

    if (model.hasSystemConverter) {
        if (!model.inverter.efficiency.values.map(x => x.value).every(x => 0 < x && x <= 100))
            yield { label: 'System Converter efficiency must be > 0 and ≤ 100', link: URL_EQUIPMENT }
        if (!model.inverter.lifetime.values.map(x => x.value).every(x => 0 < x))
            yield { label: 'System Converter lifetime must be > 0', link: URL_EQUIPMENT }
        if (model.inverter.sizing.onlyZero)
            yield { label: 'System Converter size is 0 MW', link: URL_EQUIPMENT, warning: true }
    }

    for (const x of Analyzer.range('Inflation Rate', -100, 100)(model.inflationRate))
        yield { label: x, link: URL_OTHER }

    for (const x of Analyzer.range('Discount Rate', 0, 100)(model.discountRate))
        yield { label: x, link: URL_OTHER }

    for (const incentive of model.incentives) {
        switch (incentive.kind) {
            case 'itc': {
                const v = incentive as InvestmentTaxCredit
                for (const x of Analyzer.range('Portion of capital cost eligible for incentive', 0, 100)(v.eligiblePercent))
                    yield { label: x, link: URL_OTHER + '#' + v.id }
                break
            }
            case 'macrs': {
                const v = incentive as Macrs
                for (const x of Analyzer.range('Portion of capital cost eligible for incentive', 0, 100)(v.eligiblePercent))
                    yield { label: x, link: URL_OTHER + '#' + v.id }
                for (const x of Analyzer.range('Marginal tax rate for an incentive', 0, 100)(v.marginalTaxPercent))
                    yield { label: x, link: URL_OTHER + '#' + v.id }
                break
            }
            case 'bonus': {
                const v = incentive as BonusDepreciation
                for (const x of Analyzer.range('Portion of capital cost eligible for incentive', 0, 100)(v.eligiblePercent))
                    yield { label: x, link: URL_OTHER + '#' + v.id }
                for (const x of Analyzer.range('Marginal tax rate for an incentive', 0, 100)(v.marginalTaxPercent))
                    yield { label: x, link: URL_OTHER + '#' + v.id }
                break
            }
        }
    }

    function checkMultiYearEnergyPrice(multiYearPrice: MultiYearTimeSeries[]) {
        var checkedYears = 0
        for (let i = 0; i < multiYearPrice.length; i++) {
            checkedYears += multiYearPrice[i].projectYearsValid.filter(Boolean).length
        }
        return checkedYears === model.lifetime
    }

    if (!model.earlyAnalysis && model.useComponentLifetimes) {
        let solarLifetime = model.useSolar ? rangeArray(model.solarStartYear, model.solarEndYear) : []
        let windLifetime = model.useWind ? rangeArray(model.windStartYear, model.windEndYear) : []
        let storageLifetime = model.useStorage  && model.storage.every(x => x.allowAugmentation === true) ? rangeArray(model.storageStartYear, model.storageEndYear) : []
        let hasStorageWithoutAugmentation = model.useStorage && model.storage.length > 0 && model.storage.some(x => x.allowAugmentation === false)
        let combinedLifetimes = combinedArray(solarLifetime, windLifetime, storageLifetime)
        let combinedPvWindLifetimes = combinedArray(solarLifetime, windLifetime)
        let projectLifetime = rangeArray(1, model.lifetime)
        let yearsWithouthComponent = projectLifetime.filter(x => !combinedLifetimes.includes(x))
        let yearsWithoutPvAndStorage = projectLifetime.filter(x => !combinedPvWindLifetimes.includes(x))
        if (yearsWithouthComponent.length > 0) {
            if (!hasStorageWithoutAugmentation)
                yield { label: `One or more years do not have any component, adjust component lifetimes.`, link: URL_EQUIPMENT }
            else {
                if (model.useWind && model.useSolar)
                    yield { label: `When BESS doesn't allow augmentation, Wind + PV lifetime must cover the whole project`, link: URL_EQUIPMENT }
                else if (model.useWind)
                    yield { label: `When BESS doesn't allow augmentation, Wind lifetime must cover the whole project`, link: URL_EQUIPMENT }
                else if (model.useSolar)
                    yield { label: `When BESS doesn't allow augmentation, PV lifetime must cover the whole project`, link: URL_EQUIPMENT }
                else
                    yield { label: `When BESS doesn't allow augmentation, add Wind or PV`, link: URL_EQUIPMENT }
            }
        }
        if (model.useStorage && model.storage.length > 0 && (model.storageStartYear > 1 || model.storageEndYear < model.lifetime)) {
            if (model.useContract) {
                yield { label: `TOD contracts require a BESS for the whole project, adjust BESS lifetime.`, link: URL_EQUIPMENT }
            }
            if (model.useCapacityMarket) {
                yield { label: `Capacity Market require a BESS for the whole project, adjust BESS lifetime.`, link: URL_EQUIPMENT }
            }
        }
        if (model.useStorage && model.storage.length > 0 && model.storage.some(x => x.allowAugmentation === false)) {
            if (model.useContract) {
                yield { label: `TOD contracts require that all BESS be set to allow augmentation.`, link: URL_EQUIPMENT }
            }
            if (model.useCapacityMarket) {
                yield { label: `Capacity Market require that all BESS be set to allow augmentation.`, link: URL_EQUIPMENT }
            }
        }
        if (yearsWithoutPvAndStorage.length > 0 && !model.useEnergyMarket && model.useCapacityMarket)
            yield { label: 'Capacity markets require that all years have a source of power to charge the battery (with size > 0), adjust component lifetimes', link: URL_EQUIPMENT }
    }

    const simulations = model.estimateTotalSimulations()
    if (simulations.TotalCalculations > MAX_SIMULATION_ERROR_THRESHOLD) {
        // main message
        if (!model.earlyAnalysis && model.lifetime > 2) {
            yield { label: `This project will run ${numFormatter.format(simulations.TotalCalculations)} simulations.\nThe max simulations is ${numFormatter.format(MAX_SIMULATION_ERROR_THRESHOLD)}.\nReduce the number of simulations.`, link: URL_SETUP, warning: true }
        }
        if (simulations.RevenueSensitivities > 1) {
            yield { label: `This project will run ${numFormatter.format(simulations.TotalCalculations)} simulations.\nThe max simulations is ${numFormatter.format(MAX_SIMULATION_ERROR_THRESHOLD)}.\nReduce the number of simulations.`, link: URL_APPLICATION, warning: true }
            yield { label: 'Reduce application sensitivities', link: URL_APPLICATION, warning: true }
            yield { label: 'Reduce application sensitivities', link: URL_EQUIPMENT }
        }
        if (simulations.EquipmentSensitivities > 1 || simulations.EquipmentOptimizations > 1) {
            yield { label: `This project will run ${numFormatter.format(simulations.TotalCalculations)} simulations.\nThe max simulations is ${numFormatter.format(MAX_SIMULATION_ERROR_THRESHOLD)}.\nReduce the number of simulations.`, link: URL_EQUIPMENT }
        }
        if (simulations.EquipmentSensitivities > 1) {
            yield { label: 'Reduce application sensitivities', link: URL_EQUIPMENT }
        }
        // extra suggestions
        for (const x of analyzeSearchSpace(model)) yield x
    } else if (simulations.TotalCalculations > MAX_SIMULATION_WARNING_THRESHOLD) {
        //const time = model.estimateTotalSimulationTime()
        const hours = estimation / 60 / 60
        if (!model.earlyAnalysis && model.lifetime > 2) {
            yield { label: `This project will run ${numFormatter.format(simulations.TotalCalculations)} simulations.\nEstimated run time is ${num1Formatter.format(hours)} hours.\nConsider reducing number of simulations.`, link: URL_SETUP, warning: true }
        }
        if (simulations.RevenueSensitivities > 1) {
            yield { label: `This project will run ${numFormatter.format(simulations.TotalCalculations)} simulations.\nEstimated run time is ${num1Formatter.format(hours)} hours.\nConsider reducing number of simulations.`, link: URL_APPLICATION, warning: true }
            yield { label: 'Reduce application sensitivities', link: URL_APPLICATION, warning: true }
            yield { label: 'Reduce application sensitivities', link: URL_EQUIPMENT, warning: true }
        }
        if (simulations.RevenueSensitivities > 1 || simulations.EquipmentOptimizations > 1) {
            yield { label: `This project will run ${numFormatter.format(simulations.TotalCalculations)} simulations.\nEstimated run time is ${num1Formatter.format(hours)} hours.\nConsider reducing number of simulations.`, link: URL_EQUIPMENT, warning: true }
        }
        if (simulations.RevenueSensitivities > 1) {
            yield { label: 'Reduce equipment sensitivities', link: URL_EQUIPMENT, warning: true }
        }
        // extra suggestions
        for (const x of analyzeSearchSpace(model, true)) yield x
    }
}

const numFormatter = new Intl.NumberFormat('en', { maximumFractionDigits: 0 })
const num1Formatter = new Intl.NumberFormat('en', { maximumFractionDigits: 1 })

const dateFormatter = new Intl.DateTimeFormat('en', {
    month: 'short', day: 'numeric',
    hour: 'numeric', minute: 'numeric', hour12: false,
})

function* analyzeCapacityMarket(capacityMarket: CapacityMarket, energyMarketKind: EnergyMarketKind[], useEnergyMarket?: boolean) {
    if (capacityMarket.eventKind === 'scheduled') {
        for (const x of capacityMarket.getOverlappingEvents())
            yield { label: `Events overlap: ${dateFormatter.format(x[0].date)} and ${dateFormatter.format(x[1].date)}`, link: URL_APPLICATION + '#' + capacityMarket.id }
        for (const x of capacityMarket.getMidNightSpanEvents())
            yield { label: `Capacity market events may not span midnight: date is ${dateFormatter.format(x[0].date)} and ${x[0].duration} hours duration `, link: URL_APPLICATION + '#' + capacityMarket.id }
    }
    if (capacityMarket.eventKind === 'random') {
        if (capacityMarket.hasEmptySchedule)
            yield { label: `No specified period for random events in capacity market`, link: URL_APPLICATION + '#' + capacityMarket.id }
        // for (const x of capacityMarket.getMidNightSpanRAEvents())
        //     yield { label: `Capacity market events may not span midnight: starting hour is ${String(x[0].hour).padStart(2, '0') + ':00'} and ${x[0].duration} hours duration `, link: URL_APPLICATION + '#' + capacityMarket.id }
        for (const x of capacityMarket.getShorterRAEvents())
            yield { label: `One or more windows selected for Random Events is smaller than the event duration`, link: URL_APPLICATION + '#' + capacityMarket.id }
    }
    if (capacityMarket.energyPriceKind === 'market' && capacityMarket.energyMarket?.name && (energyMarketKind.includes(capacityMarket.energyMarket?.kind) == false || !useEnergyMarket)) {
        yield { label: `Capacity market references an energy market which does not exist.`, link: URL_APPLICATION + '#' + capacityMarket.id }
    }
}

function* analyzeContract(contract: Contract, model: Project,) {
    if (contract.obligationKind === 'daily' && contract.obligations.length === 0)
        yield { label: 'Time of delivery energy obligation unspecified', link: URL_APPLICATION + '#' + contract.id }
    if (contract.obligationKind === 'import' && contract.obligationTimeSeries.state === 'blank')
        yield { label: 'Time of delivery import obligation unspecified', link: URL_APPLICATION + '#' + contract.id }
    if (contract.energyPriceKind === 'import' && contract.energyPriceTimeSeries.state === 'blank')
        yield { label: 'Time of delivery import annual price profile unspecified', link: URL_APPLICATION + '#' + contract.id }
    if (model.useStorage && (!model.useWind && !model.useSolar && model.energyMarkets.length === 0))
        yield { label: 'Add power generation source', link: URL_EQUIPMENT }
}

function* analyzeSearchSpace(model: Project, warning?: boolean) {
    if (model.useSolar && model.solar.some(x => (x.kind === ComponentKind.homer && x.sizing.estimateSimulations() > 1) || (x.kind === ComponentKind.custom && x.customSizeArray.estimateSimulations() > 1)))
        yield { label: 'Reduce possible PV sizes', link: URL_EQUIPMENT, warning }
    if (model.useSolar && model.wind.some(x => (x.kind !== 'production' && x.sizing.estimateSimulations() > 1) || (x.kind === 'production' && x.customSizing.estimateSimulations() > 1)))
        yield { label: 'Reduce possible wind sizes', link: URL_EQUIPMENT, warning }
    if (model.useStorage && model.storage.some(x => x.sizing.estimateSimulations() > 1))
        yield { label: 'Reduce possible storage array sizes', link: URL_EQUIPMENT, warning }
    if (!model.earlyAnalysis && model.lifetime > 2) {
        yield { label: 'Reduce project lifetime', link: URL_SETUP, warning: true }
        yield { label: 'Reduce project lifetime', link: URL_EQUIPMENT, warning }
        yield { label: 'Reduce project lifetime', link: URL_APPLICATION, warning: true }
    }
}



const checkCostTableHasCorrectUnits = (table: ComplexCostTable, units: UnitOptionSet) =>
    checkItemsHasCorrectUnits(table.directCapital, units.capital) &&
    checkItemsHasCorrectUnits(table.indirectCapital, units.capital) &&
    checkItemsHasCorrectUnits(table.operating, units.operating) &&
    checkItemsHasCorrectUnits(table.replacement, units.replacement)

const checkItemsHasCorrectUnits = (items: ComplexCostItems, units: UnitOption[]) =>
    items.items.every(item => units.some(u => u.units === item.unit))

function rangeArray(start: number, end: number): number[] {
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => start + i);
}

function combinedArray<T>(...arrays: T[][]): T[] {
    const combinedArray = arrays.reduce((acc, array) => acc.concat(array), []);
    return Array.from(new Set(combinedArray));
}
// checks

class Analyzer {
    static min(name: string, x: number, strict?: boolean): ValidationFunc {
        return (value: number) => {
            return strict ?
                (x < value ? [] : [`${name} must be > ${x}`]) :
                (x <= value ? [] : [`${name} must be ≥ ${x}`])
        }
    }

    static max(name: string, x: number, strict?: boolean): ValidationFunc {
        return (value: number) => {
            return strict ?
                (x > value ? [] : [`${name} must be < ${x}`]) :
                (x >= value ? [] : [`${name} must be ≤ ${x}`])
        }
    }

    static range(name: string, min: number, max: number, strictMin?: boolean, strictMax?: boolean): ValidationFunc {
        return (value: number) => {
            if (strictMin && strictMax)
                return (min < value && value < max) ? [] : [`${name} must be > ${min} and < ${max}`]
            if (strictMin && !strictMax)
                return (min < value && value <= max) ? [] : [`${name} must be > ${min} and ≤ ${max}`]
            if (!strictMin && strictMax)
                return (min <= value && value < max) ? [] : [`${name} must be ≥ ${min} and < ${max}`]
            if (!strictMin && !strictMax)
                return (min <= value && value <= max) ? [] : [`${name} must be ≥ ${min} and ≤ ${max}`]
            return []
        }
    }

    static checkIfDuplicateExists(arr: string[]) {
        return new Set(arr).size !== arr.length
    }
}
