import * as React from 'react'
import { useState } from 'react'
import {Button, Modal} from 'react-bootstrap'

import { RateSchedule } from '../project/model/Schedule'
import RateScheduleEditor from '../project/pages/revenue/RateScheduleEditor'
import { observer } from 'mobx-react-lite'


interface ScheduleSetterProps {
    model: RateSchedule
    isPriceSchedule: boolean
    onSelect?: (data: number[], year: number) => void
    label?: string
    disabled?: boolean
    visible?: boolean
}

const RateScheduleView = observer(({model, isPriceSchedule, onSelect, label, disabled = false, visible = true}: ScheduleSetterProps) => {
    const [show, setShow] = useState(false)
    const open = () => setShow(true)
    const close = () => setShow(false)

    return (<>
        {visible && <button disabled={disabled} className={`btn btn-file btn-primary pillButton`}
            onClick={open}>
            {label ?? 'Set...'}
        </button>}
        <RateScheduleModal show={show} onCancel={close} model={model} isPriceSchedule={isPriceSchedule} />
    </>)
})

export default RateScheduleView

interface RateScheduleModalProps {
    show: boolean
    onCancel: () => void
    model: RateSchedule
    isPriceSchedule: boolean
}

const RateScheduleModal = ({ show, onCancel, model, isPriceSchedule }: RateScheduleModalProps) => {

    return (
        <Modal show={show} onHide={onCancel} size="xl">
            <Modal.Header closeButton onHide={onCancel}>
                <Modal.Title>Set Schedule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RateScheduleEditor model={model} isPriceSchedule={isPriceSchedule} />
            </Modal.Body>
            <Modal.Footer>
                <Button className='pillButton' onClick={onCancel}>OK</Button>
            </Modal.Footer>
        </Modal>
    )
}
