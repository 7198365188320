import * as React from 'react'
import { ValidationFunc } from './validation'
import NumberInput from './NumberInput'


interface NumberFieldProps {
    value: number
    onChange: (x: number) => void
    label: string
    validator?: ValidationFunc
    disabled?: boolean
    id?: string
    className?: string
    useCommaAsDecimalPoint: boolean
    tooltip?: React.ReactNode
}


const NumberField = ({ label, value, onChange, id, disabled, className, validator, useCommaAsDecimalPoint, tooltip }: NumberFieldProps) => {
    return (
        <div className='form-group row align-items-center'>
            <div className='col-5'>{label}</div>
            <div className='col-3'>
                <NumberInput value={value}
                    onChange={onChange}
                    validator={validator}
                    disabled={disabled}
                    id={id} className={className}
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint} />

            </div>
            <div className='col-1'>
            {tooltip && (
                    <span>
                        {tooltip}
                    </span>
                )}
            </div>
        </div>
    )
}


export default NumberField
