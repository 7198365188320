import { observer } from "mobx-react-lite"
import { Form, Row } from "react-bootstrap";
import ContextHelp from "../helper/ContextHelp";
import NumberInput from "../helper/NumberInput";
import { Validation } from "../helper/validation";
import CostTableView from "../project/pages/equipment/shared/CostTableView";
import { ChangeUnitOptionSetCurrency, storageUnits } from "../project/pages/equipment/shared/DetailCostBreakup";
import { UserStorageClass } from "./UserStorage"
import * as React from 'react'

interface StorageGeneralTabProps {
    model: UserStorageClass
    useCommaAsDecimalPoint: boolean
    defaultCurrency: string
}

const StorageLibraryDefaultsTab = observer(({ model, useCommaAsDecimalPoint, defaultCurrency }: StorageGeneralTabProps) => {
    let currency = defaultCurrency ?? '$'
    const storageUnitsAux = ChangeUnitOptionSetCurrency(storageUnits, currency)
    const currencyByPowerUnit = `${currency}/MWh`

    return (
        <>
            <Row className="mt-2 ml-1 d-flex no-gutters">
                <div className="col-auto">
                    <CostTableView cost={model.costTable} id={model.id}
                    showDetailedCostTable={true} showDetailedReplacement={false} unitsSet={storageUnitsAux}
                    capitalUnits={`${currencyByPowerUnit}`} operatingUnits={`${currencyByPowerUnit}/yr`}
                    simpleCostLabel='Cost Sensitivity Analysis' hideSensitivity={true}
                        complexCostLabel='Cost Breakdown' isStorage={true} useCommaAsDecimalPoint={useCommaAsDecimalPoint} hideHelp={true} defaultCurrency={defaultCurrency} />
                </div>
                <div className="col-auto mt-3">
                    <ContextHelp helpId='USERLIBRARYDEFAULTS' />
                </div>
                </Row>
            <Row className="mt-1 mb-3 d-flex">
                <div className='col-3'>
                    Electrical Bus:
                </div>
                <div className='ml-3'>
                    <Form.Check custom label='AC' id='isAc' type='radio'
                        checked={model.isAc === true}
                        onChange={ev => model.setUserStorageIsAc(ev.target.checked)} />
                </div>
                <div className='ml-5'>
                    <Form.Check custom label='DC' id='isDc' type='radio'
                        checked={model.isAc === false}
                        onChange={ev => model.setUserStorageIsAc(!ev.target.checked)} />
                </div>
            </Row>
            <Row className=" d-flex">
                <label className="col-3 mt-2">Initial State of Charge (%):</label>
                <NumberInput value={model.initialStateOfCharge}
                    onChange={model.setUserStorageInitialStateOfCharge}
                    className="ml-3 col-2 mb-4"
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.range(0, 100, false, false)} />
                <Form.Check className='col-4 ml-5 mt-2' custom label='Consider Temperature Effects?' id='considerTemperatureEffects'
                    checked={model.useFixedTemperature}
                    onChange={ev => { model.setUserStorageUseFixedTemperature(ev.target.checked); }} />
            </Row>
            <Row className=" d-flex">
                <label className="col-3 mt-2">Minimum State of Charge (%):</label>
                <NumberInput value={model.minimumStateOfCharge}
                    onChange={model.setUserStorageMinimumStateOfCharge}
                    className="ml-3 col-2 mb-4"
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.range(0, 100, false, false)}/>
            </Row>
            <Row className="mt-2 mb-3 d-flex">
            <Form.Check className='col-3 ml-3 mr-0' custom label='Use Minimum Storage Life (yrs):' id='useMinimumStorageLife'
                checked={model.applyMinimumLifetime}
                onChange={ev => { model.setUserStorageApplyMinimumLifetime(ev.target.checked);}} />
                <NumberInput value={model.minimumLifetime}
                    onChange={model.setUserStorageMinimumLifetime}
                    className="col-2"
                    disabled={!model.applyMinimumLifetime}
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0, false)} />
                </Row>
        </>
        )
})

export default StorageLibraryDefaultsTab
