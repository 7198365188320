import { observer } from "mobx-react-lite"
import { Form, Row } from "react-bootstrap";
import ContextHelp from "../helper/ContextHelp";
import InverterView from "../project/pages/equipment/InverterView";
import { UserStorageClass } from "./UserStorage"
import * as React from 'react'

interface StorageGeneralTabProps {
    model: UserStorageClass
    useCommaAsDecimalPoint: boolean
    defaultCurrency?: string
}

const StorageLibraryConverterTab = observer(({ model, useCommaAsDecimalPoint, defaultCurrency }: StorageGeneralTabProps) => {
    const converterLabel = model.isAc ? 'Storage DC/AC Converter' : 'Storage DC/DC Converter'

    return (
        <>
            <Row className="mt-2 d-flex">
                <Form.Check className='col-2 ml-3 mt-2' custom label='Explicitly Model Dedicated Converter' id='explicitlyModelDedicatedConverter'
                    checked={model.useDedicatedConverter}
                    onChange={ev => { model.setUseDedicatedConverter(ev.target.checked); }} />
                <ContextHelp helpId='USERLIBRARYCONVERTER' />
            </Row>
           
            {model.useDedicatedConverter && < InverterView model={model.dedicatedConverter} label={converterLabel}
                relativeUnits='Ratio' hideSensitivity={true} defaultCurrency={defaultCurrency}
                relativeLabel='Size Relative to Storage Capacity (C-rate or 1/hr Rate)'
                useCommaAsDecimalPoint={useCommaAsDecimalPoint} hideHelp={true} />}
        </>
        )
})

export default StorageLibraryConverterTab
