import { observer } from "mobx-react-lite"
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import ContextHelp from "../helper/ContextHelp";
import NumberInput from "../helper/NumberInput";
import { Validation } from "../helper/validation";
import { UserStorageClass } from "./UserStorage"
import { CycleLifetimeChart } from "./UserStorageChart";
import StorageCurveTable from "./UserStorageCurveTable";
import api from "services/api";
import { RecalculateCycleLifetimeRequestDto } from "services/Service";

interface StorageFuncModelTabProps {
    model: UserStorageClass
    useCommaAsDecimalPoint: boolean
}

const StorageLibraryCycleLifetimeTab = observer(({ model, useCommaAsDecimalPoint }: StorageFuncModelTabProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleRecalculateCycleLifetime = async () => {
        setIsLoading(true);

        const requestData: RecalculateCycleLifetimeRequestDto = {
            DegradationLimit: model.capacityDegradationLimitForFitCyclelifetimeModel,
            NominalVoltage: model.nominalVoltage,
            MaximumCapacityQ: model.maximumCapacity,
            MinimumStateOfCharge: model.minimumStateOfCharge,
            LifetimeCurve: model.lifetimeCurve.map(item => ({
                DepthOfDischarge: item.x,
                CyclesToFailure: item.y
            }))
        };

        try {
            const response = await api.recalculateCycleLifetime(requestData);
            model.setUserStorageFittedA(response.fittedA);
            model.setUserStorageEstimatedLifetimeThroughput(response.estimatedLifetimeThroughputMwh);
            model.setUserStorageFittedBeta(response.fittedBeta);
            //i want to trigger model.fittedBetta validation here

            setShowError(false);
        } catch {
            setShowError(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Row className="mt-2 d-flex flex-column align-items-center justify-content-center">

                <h4>Lifetime Curve</h4>
            </Row>
            <Row>
                <Col xs={3}>
                    <StorageCurveTable model={model.lifetimeCurve} useCommaAsDecimalPoint={useCommaAsDecimalPoint} setItem={model.setUserStorageLifetimeCurveItem} deleteItem={model.userStorageLifetimeCurveDeleteItem} addItem={model.userStorageLifetimeCurveAddRow} columnHead1="Depth of Discharge (%)" columnHead2="Cycles to Failure" percentage={true} />
                    <div hidden={!showError} className='text-danger'>Could not recalculate, input is invalid </div>
                </Col>
                <Col xs={5}>
                    <CycleLifetimeChart model={model.lifetimeCurve} />
                </Col>
                <Col xs={4}>
                    <Row>
                        <label className="col-7 mt-2">Degradation Limit for Model Fitting (%):</label>
                        <NumberInput value={model.capacityDegradationLimitForFitCyclelifetimeModel}
                            onChange={model.setUserStorageCapacityDegradationLimitForFitCyclelifetimeModel}
                            className="mt-1 col-2 mb-4 mr-4"
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.range(0, 100)} />
                        <ContextHelp helpId='USERLIBRARYCYCLELIFETIME' />
                    </Row>
                    <p><b>Model Pararameters Fit from the Data: 1/N = A*DOD^beta</b></p>
                    <Row>
                        <label className="col-7 mt-2">Fitted A:</label>
                        <NumberInput value={model.fittedA}
                            onChange={model.setUserStorageFittedA}
                            className="mt-1 col-2"
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0, false)}/>
                    </Row>
                    <Row>
                        <label className="col-7 mt-2">Fitted Beta:</label>
                        <NumberInput value={model.fittedBeta}
                            onChange={model.setUserStorageFittedBeta}
                            className="mt-1 col-2"
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.range(0, 1)}/>
                    </Row>
                    <Row>
                        <label className="col-7 mt-2">Estimated Lifetime Throughput (MWh):</label>
                        <NumberInput value={model.estimatedLifetimeThroughput}
                            onChange={model.setUserStorageEstimatedLifetimeThroughput}
                            className="mt-1 col-2"
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0, false)}/>
                    </Row>
                    <Button variant="primary" onClick={handleRecalculateCycleLifetime} disabled={isLoading}>
                        {isLoading ? 'Recalculating...' : 'Recalculate Cycle Lifetime'}
                    </Button>
                </Col>
            </Row>
            <Row className="mt-1 d-flex">

            </Row>
        </>
    )
})

export default StorageLibraryCycleLifetimeTab
