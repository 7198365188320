import { observer } from 'mobx-react-lite'
import { Dropdown, Modal, Button, Form, Spinner } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { GoX } from 'react-icons/all'
import CostTableView from './shared/CostTableView'
import PowerCurveChart from './PowerCurveChart'
import LossesTableView from './shared/LossesTableView'
import MultiTimeSeriesImport, { MultiFileData, MultiImportState } from '../../../helper/MultiTimeSeriesImport'
import MultiYearView from '../SharedComponents/MultiYearView'
import { ChangeUnitOptionSetCurrency, windUnits } from './shared/DetailCostBreakup'
import { Validation } from '../../../helper/validation'
import SuccessImportModal from './shared/ImportSuccessModal'
import * as React from 'react'
import { Wind } from '../../model/Wind'
import api, { WindTurbineSpec } from '../../../../services/api'
import { useProjectStore } from '../../ProjectProvider'
import ContextHelp from '../../../helper/ContextHelp'
import SensitivityVarView from '../EnergyMarket/SensitivityVarView'
import SizingView from './shared/SizingView'
import WindGroupView from './WindGroupView'
import FilePicker from '../../../helper/FilePicker'
import TimeSeriesView from '../../../helper/TimeSeriesView'

interface WindViewProps {
    model: Wind
    onDelete?: () => void
    index: number
    state: MultiImportState
    setState: (state: MultiImportState) => void
    fileData: MultiFileData[]
    setFileData: (fileData: MultiFileData[]) => void
    handleSelect?: (sourceList: string[], customName: string[], customSize: number[], data: number[][], year: number, index: number) => void
    handleDeleteFile: (id: number) => void
}


const WindView = observer(({ model, index, state, setState, fileData, setFileData, onDelete, handleSelect, handleDeleteFile }: WindViewProps) => {
    const handleSelectLibrary = (x: WindTurbineSpec) => {
        model.setKind('library')
        model.setSpecs(x)
        model.setModel(x.name)
    }
    const currency = useProjectStore().project!.currencySymbol ?? "$"
    const windUnitsAux = ChangeUnitOptionSetCurrency(windUnits, currency)

    const [modelOptions, setModelOptions] = useState<WindTurbineSpec[]>([])
    const [showImport, setShowImport] = useState(false)
    useEffect(() => {
        let cancel = false
        const run = async () => {
            const rv = await api.listWindTurbineSpecs()
            if (!cancel) { setModelOptions(rv) }
        }
        run().then()
        return () => { cancel = true }
    }, [])
    const project = useProjectStore().project!
    const useCommaAsDecimalPoint = project.useCommaAsDecimalPoint
    const disableMultipleSizes = project.useComponentLifetimes

    const handleShowImport = () => setShowImport(true);
    const handleHideImport = () => setShowImport(false);
    const handleSelectCustom = (content: string, specs: WindTurbineSpec) => {
        setShowImport(false)
        model.setKind('custom')
        model.setSpecs(specs)
        model.setModel(specs.name)
        model.setCustomData(content)
        model.setCustomName(specs.name)
        //model.setCost(specs.costMatrix)
        model.setAvailabilityLosses(specs.availabilityLosses)
        model.setElectricalLosses(specs.electricalLosses)
        model.setCurtailmentLosses(specs.curtailmentLosses)
        model.setEnvironmentalLosses(specs.environmentalLosses)
        model.setWakeEffectLosses(specs.wakeEffectsLosses)
        model.setOtherLosses(specs.otherLosses)
        model.setLifeTime(specs.lifeTime)
        model.setHubHeight(specs.hubHeight)
        model.setPerformanceLosses(specs.turbinePerformanceLosses)
    }
    const powerUnit = "MW"

    return (
        <>
            <div className='row align-items-start no-gutters'>
                <div className='col-11'>
                    <div className='d-flex flex-row align-items-start mb-2 mt-2'>
                        <Form.Check id={model.id + '-kind-homer'} type='radio' label='HOMER Wind Calculator'
                            className='mr-2' custom
                            checked={model.kind === 'library'}
                            onChange={ev => ev.target.value && model.setKind('library')} />
                            <ContextHelp helpId="WINDCALC" />
                    </div>
                    <div className='d-flex flex-row align-items-start mb-2 mt-2'>
                        <Form.Check id={model.id + '-kind-custom'} type='radio' label='Wind Import' custom
                            checked={model.kind === 'production'}
                            onChange={ev => ev.target.value && model.setKind('production')} />    
                    </div>
                    {model.kind === 'production' && <div className='d-flex flex-row align-items-start mb-2 mt-2 labelHelpText'>
                        <p>Negative values will be replaced by zeros</p>
                    </div>}
                </div>
                <div className='col-1'>
                    <button className='float-right btn btn-outline-danger pillButton' onClick={() => onDelete?.()}><GoX /></button>
                </div>
            </div>
            
            {model.kind !== 'production' && <>
                <div className='d-flex align-items-center mb-2'>
                    <label className='mr-3 mb-0'>Turbine Type: </label>
                    <Dropdown>
                        <Dropdown.Toggle className='pillButton' variant='outline-primary' id='dropdown-basic'>
                            {model.model}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {modelOptions.map(x =>
                                <Dropdown.Item key={x.name} onClick={() => handleSelectLibrary(x)}>{x.name}</Dropdown.Item>)}
                            <Dropdown.Item onClick={handleShowImport}>Import...</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <div className='m-auto' />
                <SensitivityVarView model={model.hubHeight} label='Hub Height (m)' units='m' useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    <div className='m-auto' />
                </div>
            </>}
            {model.kind === 'production' && <WindImportView state={state} index={index} setState={setState} model={model} fileData={fileData} setFileData={setFileData} handleSelect={handleSelect} handleDeleteFile={handleDeleteFile} />}

            <WindImportModal show={showImport} onCancel={handleHideImport} onSelect={handleSelectCustom} />
            <div className='form-group d-flex mb-2'>
                {model.kind === 'production' &&
                    <><div className='d-flex flex-column align-items-center   border rounded w-50'>
                    <div className='d-flex flex-row align-items-center p-5'>
                        <SizingView id={model.id} model={model.customSizing} disablemutipleSizes={disableMultipleSizes} customTblHead="Wind Farm Size (MW)" componentId="numberOfTurbines" useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    </div>
                </div>
                    <div className='border rounded w-50 ml-1 p-2'>
                        <CostTableView cost={model.cost} id={model.id} showAugmentation={false} showReplacement={model.allowReplacement}
                            showDetailedCostTable={true} showDetailedReplacement={model.allowReplacement} unitsSet={windUnitsAux}
                                capitalUnits={`${currency}/${powerUnit}`} operatingUnits={`${currency}/${powerUnit}`} replacementUnits={`${currency}/${powerUnit}`}
                            simpleCostLabel='Cost Sensitivity Analysis'
                            complexCostLabel='Cost Breakdown' isWind={true} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                        <MultiYearView model={model.omEscalator}
                            label='O&M cost escalator (%/yr)'
                            id={model.id + '-om'} />
                        {/*<Form.Check className='mr-2 mb-3 mt-2' custom label="Allow Replacement" id={`wind-allowReplacement-${model.id}`} type='switch'
                            checked={model.allowReplacement}
                            onChange={ev => { model.setAllowReplacement(ev.target.checked); }} />*/}
                        {model.allowReplacement && <SensitivityVarView model={model.lifetime} label='Lifetime (yrs)' units='years' useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0, true)} />}
                        </div>
                    </>}
            </div>
            {model.kind !== 'production' && <div className='form-group d-flex mb-2'>
                <div className='border rounded w-50 mr-1 p-2'>
                    <SizingView id={model.id} model={model.sizing} disablemutipleSizes={disableMultipleSizes} customTblHead="Number of Turbines" componentId="numberOfTurbines" useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                </div>
                
                <div className='border rounded w-50 ml-1 p-2'>
                    <CostTableView cost={model.cost} id={model.id}
                        showDetailedCostTable={true} showReplacement={model.allowReplacement} showDetailedReplacement={model.allowReplacement} unitsSet={windUnitsAux}
                        capitalUnits={`${currency}/turbine`} operatingUnits={`${currency}/turbine`} replacementUnits={`${currency}/turbine`}
                        simpleCostLabel='Cost Sensitivity Analysis'
                        complexCostLabel='Cost Breakdown' isWind={true} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />

                    <MultiYearView model={model.omEscalator}
                        label='O&M cost escalator (%/yr)'
                        id={model.id + '-om'} />
                    {/*<Form.Check className='mr-2 mb-3 mt-2' custom label="Allow Replacement" id={`wind-allowReplacement-${model.id}`} type='switch'
                        checked={model.allowReplacement}
                        onChange={ev => { model.setAllowReplacement(ev.target.checked); }} />*/}
                    {model.allowReplacement && <SensitivityVarView model={model.lifetime} label='Lifetime (yrs)' units='years' useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0, true)} />}
                </div>
            </div>}
            
            <div hidden={model.kind === 'production'}>
                <div className='form-group d-flex mb-2'>
                    <div className='border rounded w-100 mr-1 p-2'>
                        <PowerCurveChart id={model.id} model={model.specs?.powerCurve!} yAxisTitle='Power Output(MW)' />
                    </div>
                </div>
                <div className='form-group d-flex mb-2'>
                    <div className='border rounded mr-1 p-2'>
                        <h6 className='mt-2'>Losses</h6>
                        <LossesTableView model={model} useCommaAsDecimalPoint={useCommaAsDecimalPoint}></LossesTableView>
                    </div>
                </div>
                <div className='form-group d-flex mb-2 w-100'>
                    <div className='border rounded mr-1 p-2 w-100'>
                        <WindGroupView id={model.id}></WindGroupView>
                    </div>
                </div>
            </div>
        </>
    )
})


export default WindView


interface WindImportModalProps {
    show: boolean
    onSelect: (content: string, specs: WindTurbineSpec) => void
    onCancel: () => void
}

type State
    = { kind: 'blank' }
    | { kind: 'importing', source: string, content: string }
    | { kind: 'ready', source: string, content: string, specs: WindTurbineSpec }
    | { kind: 'error', source: string, message: string }

const WindImportModal = ({ show, onSelect, onCancel }: WindImportModalProps) => {
    const [state, setState] = useState<State>({ kind: 'blank' })
    useEffect(() => setState({ kind: 'blank' }), [show])

    const handleSelect = async (content: string, file: File) => {
        const source = file.name
        try {
            setState({ kind: 'importing', source, content })
            const r = await api.importWind(content)
            if (!r.success) {
                console.error(r.message)
                setState({ kind: 'error', source, message: r.message })
                return
            }
            setState({ kind: 'ready', source, content, specs: r.spec! })
        } catch (error) {
            console.error(error)
            setState({ kind: 'error', source, message: `Service unavailable` })
            return
        }
    }

    const handleImport = () => {
        if (state.kind !== 'ready') return
        const { content, specs } = state
        onSelect(content, specs)
    }

    return (
        <Modal show={show} onHide={onCancel} size='lg'>
            <Modal.Header closeButton onHide={onCancel}>
                Import Wind Specifications
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex align-items-center flex-row'>
                    <FilePicker onSelect={handleSelect} label='Import *.xml' types='.xml' />
                    <div className='mx-2' />
                    {state.kind === 'ready' && <div>
                        <div>Imported: <strong>{state.source}</strong></div>
                    </div>}
                    {state.kind === 'error' && <div className='text-danger'>
                        <div>Import error:</div>
                        <div>File format unsupported.</div>
                    </div>}
                </div>
                <div className='my-2' />
                <div>Contact <a href='mailto:support@homerenergy.com'>support@homerenergy.com</a> for assistance in creating a customized .xml file for your wind turbine  specifications.
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Spinner hidden={state.kind !== 'importing'} animation='border' size='sm' className='text-primary' />
                <Button className='pillButton' onClick={onCancel} variant='outline-primary'>Cancel</Button>
                <Button className='pillButton' onClick={handleImport} disabled={state.kind !== 'ready'}>Add Wind</Button>
            </Modal.Footer>
        </Modal>
    )
}

interface WindImportViewProps {
    model: Wind
    index: number
    state: MultiImportState
    setState: (state: MultiImportState) => void
    fileData: MultiFileData[]
    setFileData: (fileData: MultiFileData[]) => void
    handleSelect?: (sourceList: string[], customName: string[], customSize: number[], data: number[][], year: number, index: number) => void
    handleDeleteFile: (id: number) => void
}

const WindImportView = observer(({ model, index, state, setState, fileData, setFileData, handleSelect, handleDeleteFile }: WindImportViewProps) => {
    const store = useProjectStore()
    const intlCulture = store.project!.useCommaAsDecimalPoint ? 'es' : 'en'
    const ts = model.data
    const tsName = model.dataName

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
    const closeSuccessModal = () => {
        setShowSuccessModal(false)
    }
    const openSuccessModal = () => {
        setShowSuccessModal(true)
    }

    return (
        <div className='d-flex flex-column align-items-stretch align-self-stretch'>
            <div className='mb-2'>Import .csv file in kW</div>
            <div className='mb-2'>
                <MultiTimeSeriesImport onSelect={handleSelect} index={index} state={state} setState={setState} fileData={fileData} setFileData={setFileData} handleDeleteFile={handleDeleteFile} addFiles={false} allowMultipleImport={false} isPv={false} showSuccessModal={openSuccessModal} />

                {/*    <TimeSeriesImport onSelect={handleSelect} />*/}
            </div>

            {ts.state === 'blank' && <>
                <div className='mb-2 font-weight-bold'>You need to export your file from Wind with a specific format:</div>
                <ul>
                    <li>A .csv file format with a single column, no headers and no separators</li>
                    <li>HOMER detects the time step when you import the data file.<br/>
                        For example, if the data file contains 8,760 lines, HOMER will assume that it contains hourly data.<br />
                        If the data file contains 52,560 lines, HOMER will assume that it contains 10-minute data.</li>
                </ul>
            </>}

            {ts.state === 'ready' && <TimeSeriesView model={ts} chartTitle={tsName} yAxisTitle='MW/MW-peak' intlCulture={intlCulture} />}
            <SuccessImportModal show={showSuccessModal} multipleFiles={true} onConfirm={closeSuccessModal} />
        </div>
    )
})
