import {BiPlusCircle} from 'react-icons/all'
import * as React from 'react'

interface ButtonAddProps {
    onClick?: () => void
    disabled?: boolean
}

const ButtonAdd = ({ onClick, disabled = false }: ButtonAddProps) =>
    <button className='btn btn-sm btn-outline-primary border-0 pillButton' disabled={disabled} onClick={onClick}>
        <BiPlusCircle/>
    </button>

export default ButtonAdd
