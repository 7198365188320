import { observer } from 'mobx-react-lite'
import { IntlService } from 'Kendo-Intl-5'
import * as React from 'react'
import { StorageResultItem } from '../../results-details'
import { useProjectStore } from '../../ProjectProvider'

interface AugmentationTableProps {
    items: StorageResultItem[]
    allowAugmentation: boolean
    baseYear: number
    intlCulture: string
    showBaseYearAsYearZero: boolean
}


const AugmentationTable = observer(({ items, allowAugmentation, baseYear, intlCulture, showBaseYearAsYearZero }: AugmentationTableProps) => {
    const intl = new IntlService(intlCulture)
    const project = useProjectStore().project!
    const currency = project.currencySymbol ?? "$"
    const currencyByPowerUnit = `${currency}/MWh`

    return (
        <table className='table table-bordered table-responsive-sm small'>
            <thead>
                <tr>
                    <th>Year</th>
                    <th>Maximum Usable Energy Capacity (MWh)</th>
                    <th>Minimum Usable Energy Capacity (MWh)</th>
                    {allowAugmentation && <>
                        <th>Augmentation (%)</th>
                        <th>Augmentation Capacity (MWh)</th>
                        <th>{`Augmentation Price Forecast (${currencyByPowerUnit})`}</th>
                        <th>{`Augmentation Expenses (${currency})`}</th>
                    </>}
                </tr>
            </thead>
            <tbody>
                {items.map(x =>
                    <tr key={x.year}>
                        <td>{showBaseYearAsYearZero ? project.baseYear + x.year : x.year + 1}</td>
                        <td>{intl.formatNumber(x.maximum, 'n2')}</td>
                        <td>{intl.formatNumber(x.minimum, 'n2')}</td>
                        {allowAugmentation && <>
                            <td>{intl.formatNumber(x.augmentationFraction, 'p0')}</td>
                            <td>{intl.formatNumber(x.augmentation, 'n2')}</td>
                            <td>{`${intl.formatNumber(x.augmentationCost, 'n0')} ${currency}`}</td>
                            <td>{`${intl.formatNumber(x.augmentationTotalCost, 'n0')} ${currency}`}</td>
                        </>}
                    </tr>)}
            </tbody>
        </table>
    )
})

export default AugmentationTable
