import {RouteComponentProps} from '@reach/router'
import {observer} from 'mobx-react-lite'
import * as React from 'react'
import { useMemo, useState } from 'react'
import {mapTree, TreeList, TreeListCellProps, TreeListColumnProps, TreeListExpandChangeEvent, treeToFlat} from '@progress/kendo-react-treelist'
import {v4} from 'uuid'
import {IntlService} from 'Kendo-Intl-5'
import {ExcelExport} from '@progress/kendo-react-excel-export'
import { useProjectStore } from '../../ProjectProvider'
import { ResultCashFlowTreeItem } from '../../results-details'
import { eq, range } from '../../../../utils'


interface ResultProFormaProps extends RouteComponentProps {
    showBaseYearAsYearZero: boolean
}


const ID_FIELD: string = 'id'
const EXPANDED_FIELD: string = 'expanded'
const CHILDREN_FIELD: string = 'children'

const ResultProForma = observer(({showBaseYearAsYearZero }: ResultProFormaProps) => {
    const project = useProjectStore().project!
    const data = project.results.details.details.cashflowTree
    const years = project.lifetime

    // add 'id'
    // add 'expanded'
    // make fields from 'values'
    const flat = useMemo(() => mapTree(data, CHILDREN_FIELD, (x: ResultCashFlowTreeItem) => {
        return {
            ...x,
            ...arrayToFields(x.values),
            [ID_FIELD]: v4(),
            [EXPANDED_FIELD]: false,
        }
    }), [data])

    // expansion
    const [expanded, setExpanded] = useState<string[]>([])

    const handleExpandChange = (ev: TreeListExpandChangeEvent) => {
        const newExpanded = ev.value ?
            expanded.filter(id => id !== ev.dataItem[ID_FIELD]) :
            [...expanded, ev.dataItem[ID_FIELD]]
        setExpanded(newExpanded)
    }

    const tree = useMemo(() => mapTree(flat, CHILDREN_FIELD, (x: any) => {
        return {...x, [EXPANDED_FIELD]: expanded.includes(x[ID_FIELD])}
    }), [flat, expanded])

    const cols = useMemo(() => buildColumns(years, showBaseYearAsYearZero, project.baseYear), [years])

    // export
    let _export: any
    const handleExport = () => {
        const rows = treeToFlat(tree, EXPANDED_FIELD, CHILDREN_FIELD)
        _export.save(rows, cols)
    }

    return (
        <div className='d-flex flex-column m-2'>
            <div className='mb-2'>
                <button className='btn btn-outline-primary pillButton' onClick={handleExport}>Export to Excel</button>
            </div>
            <ExcelExport ref={(exporter) => (_export = exporter)}
                hierarchy={true}
                fileName='ProForma.xlsx'>
                <TreeList data={tree} style={{maxHeight: '800px', overflow: 'auto'}}
                    onExpandChange={handleExpandChange}
                    expandField={EXPANDED_FIELD}
                    subItemsField={CHILDREN_FIELD}
                    columns={cols}/>
            </ExcelExport>
        </div>
    )
})


export default ResultProForma


export function arrayToFields(values: number[]): { [key: string]: number } {
    return values.reduce((acc, value, index) => ({...acc, [`y_${index}`]: value}), {})
}


const ValueCell = (props: TreeListCellProps) => {
    const project = useProjectStore().project!
    const currency = project.currencySymbol ?? "$"
    const intlCulture = project.useCommaAsDecimalPoint ? 'es' : 'en'
    const {dataItem} = props
    const field = props.field || ''
    const value = dataItem[field] as number
    const intl = new IntlService(intlCulture)

    return (
        <td className='text-right'>
            {eq(value, 0) ? '' : `${intl.formatNumber(value, 'n0')} ${currency}`}
        </td>
    )
}


const NAME_COL: TreeListColumnProps = {
    title: 'Name',
    field: 'name',
    width: '250px',
    expandable: true,
}

export function buildColumns(years: number, showBaseYearAsYearZero: boolean, baseYear: number): TreeListColumnProps[] {
    const yearCols: TreeListColumnProps[] = range(0, years)
        .map(year => ({
            title: `${showBaseYearAsYearZero ? baseYear -1  + year : year}`,
            field: `y_${year}`,
            cell: ValueCell,
        }))
    return [NAME_COL, ...yearCols]
}
