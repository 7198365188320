import Alert from 'react-bootstrap/Alert'
import * as React from 'react'
import { Row } from 'react-bootstrap'
import { useProjectStore } from '../../ProjectProvider'
import { URL_APPLICATION, URL_EQUIPMENT } from '../../ProjectStatus'

interface InfeasibleResultProps {
    calculate: (recalculate: boolean) => void
}

const InfeasibleResult = ({ calculate }: InfeasibleResultProps) => {
    const project = useProjectStore().project!
    const handleEditApplications = async () => await project.status.navigateUrl(URL_APPLICATION)
    const handleEditEquipment = async () => await project.status.navigateUrl(URL_EQUIPMENT)
    const recalculate = () => {
        calculate(true)
    }
    return (
        <div className='d-flex flex-column align-items-center justify-content-center h-100 p-2'>
            <div className='my-2'/>

            <h5>Infeasible Results</h5>
            <div className='my-1'/>
            <p className='text-center'>
                No system configuration meets the energy requirements.<br/>
                Please adjust system size or energy requirements.
            </p>
            <div className='my-1'/>
            <p className='text-center'><small>
                For example, the simulation will produce infeasible results
                if the time of delivery contract requires a certain amount of energy per day,
                but the system configuration doesn't generate enough energy to meet this obligation.
            </small></p>

            <div className='my-2'/>

            <div className='d-flex flex-row justify-content-center'>
                <Alert variant='danger' style={{cursor: 'pointer'}}
                       onClick={handleEditApplications}>
                    Edit Application
                </Alert>
                <div className='mx-2'/>
                <Alert variant='danger' style={{cursor: 'pointer'}}
                       onClick={handleEditEquipment}>
                    Edit Equipment
                </Alert>
            </div>
            <Row><p className='mb-3'>Change the inputs and recalculate</p></Row>
            <Row><button className='btn btn-primary pillButton' onClick={recalculate}>Recalculate</button></Row>
        </div>
    )
}

export default InfeasibleResult
