import { observer } from "mobx-react-lite"
import { Row } from "react-bootstrap";
import ContextHelp from "../helper/ContextHelp";
import NumberInput from "../helper/NumberInput";
import { Validation } from "../helper/validation";
import { UserStorageClass } from "./UserStorage"
import * as React from 'react'

interface StorageGeneralTabProps {
    model: UserStorageClass
    useCommaAsDecimalPoint: boolean
}

const StorageLibraryThermalTab = observer(({ model, useCommaAsDecimalPoint }: StorageGeneralTabProps) => {
    return (
        <>
            <Row>
                <label className="col-7 mt-2">Mass (kg)</label>
                <NumberInput value={model.mass}
                    onChange={model.setUserStorageMass}
                    className="mt-1 col-2 mb-4 mr-4"
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0, false)} />
                <ContextHelp helpId='USERLIBRARYTHERMAL' />
            </Row>
            <Row>
                <label className="col-7 mt-2">Maximum Operating Temperature (C)</label>
                <NumberInput value={model.maximumOperatingTemperature}
                    onChange={model.setUserStorageMaximumOperatingTemperature}
                    className="mt-1 col-2 mb-4"
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
            </Row>
            <Row>
                <label className="col-7 mt-2">Minimum Operating Temperature (C)</label>
                <NumberInput value={model.minimumOperatingTemperature}
                    onChange={model.setUserStorageMinimumOperatingTemperature}
                    className="mt-1 col-2 mb-4"
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
            </Row>
            <Row>
                <label className="col-7 mt-2">Fixed Bulk Temperature (C)</label>
                <NumberInput value={model.fixedTemperature}
                    onChange={model.setUserStorageFixedTemperature}
                    className="mt-1 col-2 mb-4"
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
            </Row>
            <p><b>Parameters Calculated from Capacity Curve:</b></p>
            <Row>
                <label className="col-7 mt-2">Conductance to Ambient (W/K)</label>
                <NumberInput value={model.thermalH}
                    onChange={model.setUserStorageThermalH}
                    className="mt-1 col-2 mb-4"
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0, false)}/>
            </Row>
            <Row>
                <label className="col-7 mt-2">Specific Heat Capacity (J/kg-K)</label>
                <NumberInput value={model.thermalCSpecific}
                    onChange={model.setUserStorageThermalCSpecific}
                    className="mt-1 col-2 mb-4"
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0, false)}/>
            </Row>
        </>
        )
})

export default StorageLibraryThermalTab
