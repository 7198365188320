import {v4} from 'uuid'
import {action, computed, makeObservable, observable} from 'mobx'
import { defaultSchedule, Schedule } from './Schedule'
import { MultiYearVar } from './MultiYearVar'
import { EnergyMarket } from './EnergyMarket'
import { SensitivityVar } from './SensitivityVar'

export type RaEventKind = 'random' | 'scheduled'

export type EnergyPriceKind = 'market' | 'flat'

export enum PriceUnit {
    kwMo = '$/kW-mo',
    kwYr = '$/kW-yr',
    mwMo = '$/MW-mo',
    mwYr = '$/MW-yr'
}


export class RaEvent {
    id: string = v4()
    constructor(init: Partial<RaEvent> = {}) {
        Object.assign(this, init)
        makeObservable(this)
    }

    @observable date: Date = new Date() // start date-time
    @observable duration: number = 0 // (hours)

    @computed get begin(): Date {
        return this.date
    }
    @computed get end(): Date {
        const ms = this.duration * 60 * 60 * 1000
        return new Date(this.date.getTime() + ms)
    }
}


const raEventOverlap = (a: RaEvent, b: RaEvent): boolean => {
    const ab = a.end.getTime() <= b.begin.getTime()
    const ba = b.end.getTime() <= a.begin.getTime()
    return !ab && !ba
}

const months: string[] = Array(12).fill(null).map((_, index) =>
    new Date(2007, index, 1).toLocaleString('en', {month: 'long'}))


export class MonthlyVarItem {
    id: string = v4()

    constructor(public index: number, init: Partial<MonthlyVarItem> = {}) {
        Object.assign(this, init)
        makeObservable(this)
    }

    @observable value: number = 0
    @action setValue(x: number) { this.value = x }
    get month(): string { return months[this.index] }
}


export class MonthlyVar {
    constructor() { makeObservable(this) }

    @observable values: MonthlyVarItem[] = Array(12).fill(null).map((_, index) => new MonthlyVarItem(index))
    @observable average: number = 0

    @action setAverage(x: number) {
        for (const value of this.values) { value.setValue(x) }
        this.average = x
    }

    @action setValue(month: number, x: number) {
        this.values[month].setValue(x)
        this.average = this.values.map(x => x.value).reduce((x, acc) => x + acc) / 12
    }
}


export class CapacityMarket {
    constructor(lifetime: number) {
        makeObservable(this)
        this.capacityEscalator.updateLifetime(lifetime)
        this.energyEscalator.updateLifetime(lifetime)
    }
    @observable id: string = v4()
    @observable schedule: Schedule = defaultSchedule()

    @observable priceUnit: PriceUnit = PriceUnit.mwYr
    @observable price: MonthlyVar = new MonthlyVar()
    @observable capacityEscalator: MultiYearVar = new MultiYearVar()
    @observable energyEscalator: MultiYearVar = new MultiYearVar()
    @observable eventKind: RaEventKind = 'random'

    @observable energyPriceKind: EnergyPriceKind = 'flat'
    @observable energyMarket: EnergyMarket | null = null
    @observable energyPrice: number = 20

    @observable allocatedStorageCapacity: number = 100;
    // random
    @observable totalCallsPerYear = 10 // (#)
    @observable averageCallDuration = 4 // (hours)
    @observable numberOfHighestPriceDays = 10 // (#)

    // scheduled
    @observable events: RaEvent[] = observable([])

    // capacity price sensitivity
    @observable capacityPriceSensitivity: SensitivityVar = new SensitivityVar(1)
    @action setSchedule(x: Schedule) { this.schedule = x }

    @action setEventKind(x: RaEventKind) { this.eventKind = x }

    @action setTotalCallsPerYear(x: number) { this.totalCallsPerYear = x }
    @action setAverageCallDuration(x: number) { this.averageCallDuration = x }
    @action setNumberOfHighestPriceDays(x: number) { this.numberOfHighestPriceDays = x }

    @action addEvent(date: Date, duration: number) {
        const event = new RaEvent()
        event.date = date
        event.duration = duration
        this.events.push(event)
    }

    @action removeEvent(id: string) {
        const i = this.events.findIndex(x => x.id === id)
        if (i === -1) { return }
        this.events.splice(i, 1)
    }
    @action setPriceUnit(x: string) {
        this.priceUnit = x as PriceUnit
    }

    @action setEnergyPriceKind(kind: EnergyPriceKind) { this.energyPriceKind = kind }
    @action setEnergyPrice(x: number) { this.energyPrice = x }
    @action setEnergyMarket(x: EnergyMarket | null) { this.energyMarket = x }

    @action setAllocatedStorageCapacity(x: number) { this.allocatedStorageCapacity = x }
    * getOverlappingEvents() {
        for (let a = 0; a < this.events.length; a++)
            for (let b = 0; b < a; b++)
                if (raEventOverlap(this.events[a], this.events[b]))
                    yield [this.events[a], this.events[b]]
    }

    * getMidNightSpanEvents() {
        for (let a = 0; a < this.events.length; a++)
            if ((this.events[a].date.getHours() + this.events[a].duration) >= 24)
                yield [this.events[a]]
    }
    * getMidNightSpanRAEvents() {
        var isSpanned = false;
        for (var a = 0; a < this.schedule.length - 1; a++) {
            for (var j = 0; j < this.schedule[a].length - 1; j++) {
                if (isSpanned) {
                    break;
                }
                if (this.schedule[a][j] && (j + this.averageCallDuration > 23)) {
                    yield [{ duration: this.averageCallDuration, hour: j }]
                    isSpanned = true
                }
            }
        }
    }
    * getShorterRAEvents() {
        var start = 0, end = 0, initial = 0;
        for (var a = 0; a < this.schedule.length - 1; a++) {
            for (var j = 0; j < this.schedule[a].length - 1; j++) {
                if (this.schedule[a][j]) {
                    start = start ? start : j;
                    end = (start !== j && !initial) ? j : end;
                }
                if ((start !== j) && j >= 1 && (!this.schedule[a][j + 1])) {
                    if ((end) && ((end - start) < (this.averageCallDuration - 1))) {
                        initial = initial + 1;
                        break;
                    }
                    else if (this.schedule[a][j + 2]) {
                        start = j + 2;
                        end = j + 2;
                    }
                }
            }
            if (((end - start) < (this.averageCallDuration - 1))) {
                if (end || this.schedule[a][0]) {
                    yield [a];
                    break
                }
            }
            if (initial) {
                break;
            }
        }
    }

    @computed get hasEmptySchedule(): boolean { return this.schedule.every(x => x.every(x => !x)) }

    estimateSimulations(): number {
        const sens = this.capacityPriceSensitivity.estimateSimulations()
        return sens
    }
}
