import {observer} from 'mobx-react-lite'
import {Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartSeries, ChartSeriesItem, ChartTitle, ChartTooltip, ChartValueAxis, ChartValueAxisItem} from '@progress/kendo-react-charts'
import * as React from 'react'
import { useProjectStore } from '../../ProjectProvider'
import { StorageResult } from '../../results-details'

interface AugmentationCostChartProps {
    result: StorageResult
    showBaseYearAsYearZero: boolean
    baseYear: number
}

const AugmentationCostChart = observer(({ result, showBaseYearAsYearZero, baseYear }: AugmentationCostChartProps) => {
    const items = result.items.map(item => ({ ...item, year: showBaseYearAsYearZero ? item.year + baseYear : item.year + 1 }))
    const currency = useProjectStore().project!.currencySymbol ?? "$"
    const currencyByPowerUnit = `${currency}/MWh`

    return (
        <Chart>
            <ChartTitle text='Augmentation Cost'/>
            <ChartCategoryAxis>
                <ChartCategoryAxisItem axisCrossingValue={[0, items.length]}
                                       labels={{visible: true, rotation: 'auto'}} title={{text: 'Year'}}/>
            </ChartCategoryAxis>
            <ChartValueAxis>
                <ChartValueAxisItem name='augmentationTotalCost' title={{text: currency}} min={0} labels={{format: '{0:c0}', rotation: 'auto'}}/>
                <ChartValueAxisItem name='augmentationCost' title={{ text: `${currencyByPowerUnit}` }} min={0} labels={{format: '{0:c0}', rotation: 'auto'}}/>
            </ChartValueAxis>
            <ChartSeries>
                <ChartSeriesItem type='column' data={items} categoryField='year' field='augmentationTotalCost'
                    name={`Augmentation Expenses (${currency})`} color='#8B0000' axis='augmentationTotalCost'/>
                <ChartSeriesItem type='line' data={items} categoryField='year' field='augmentationCost'
                    name={`Augmentation Price Forecast (${currencyByPowerUnit})`} color='#0000FF' axis='augmentationCost'/>
            </ChartSeries>
            <ChartLegend position='bottom' align='center' orientation='vertical'/>
            <ChartTooltip/>
        </Chart>
    )
})

export default AugmentationCostChart
