import {Chart, ChartSeries, ChartSeriesItem, ChartCategoryAxis, ChartLegend, ChartCategoryAxisItem, ChartTooltip, ChartValueAxisItem, ChartValueAxis} from '@progress/kendo-react-charts'
import {MonthMetricResultItem} from '../../results-details'
import * as React from 'react'

interface ResultStorageChartByHourProps {
    items: MonthMetricResultItem[]
}

// color for each month
const COLORS = [
    '#ff0000', '#ffc0cb', '#ffa500', '#ffff00',
    '#32CD32', '#008000', '#008080', '#add8e6',
    '#00008b', '#cbc3e3', '#301934', '#ff00ff',
]
const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const HOURS = Array(24).fill(true).map((x, index) => index)

const ResultStorageChartByHour = ({items}: ResultStorageChartByHourProps) => {
    return (
        <Chart>
            <ChartCategoryAxis>
                <ChartCategoryAxisItem categories={HOURS} labels={{visible: true, rotation: 'auto'}} title={{text: 'Hour'}}/>
            </ChartCategoryAxis>
            <ChartValueAxis>
                <ChartValueAxisItem title={{text: 'State of Change (%)'}} min={0}/>
            </ChartValueAxis>
            <ChartSeries>
                {items.map((item, index) =>
                    <ChartSeriesItem key={index} type='line' data={item.byHour}
                                     name={MONTHS[index]} color={COLORS[index]} markers={{visible: false}}/>)}
            </ChartSeries>
            <ChartLegend position='bottom'/>
            <ChartTooltip format='{0:n2}'/>
        </Chart>
    )
}

export default ResultStorageChartByHour
