import * as React from 'react'
import { MouseEvent, useEffect } from 'react'
import { Button, Spinner, ButtonGroup } from 'react-bootstrap'
import { observer } from 'mobx-react-lite'
import { AiOutlineExclamationCircle, AiFillCheckCircle, BsChevronLeft, BsChevronRight } from 'react-icons/all'
import { observable, reaction, toJS } from 'mobx'
import ToolTipInfo from '../helper/ToolTipInfo'
import { useProjectStore } from './ProjectProvider'
import NavLink from '../helper/NavLink'


export const ProjectNavHeader = observer(() => {
    const project = useProjectStore().project!
    const status = useProjectStore().project!.status
    const { steps, lastStep } = status
    const dbProjectStatus = useProjectStore().dbProjectStatus

    useEffect(() => {
        project.analyze();
    }, [status.currentStep, project.currentStepErrors.length])

    const onClick = (ev: MouseEvent, index: number) => {
        project.analyze();
        ev.preventDefault()
        if (index < 3) {
            if ((project.issues?.filter(x => !x.warning && !x.estimation && x.link?.includes(status.steps[index - 1]?.url)).length === 0)) {
                status.goTo(index)
            }
        }
        else {
            if ((project.issues.filter(x => !x.warning && !x.estimation).length === 0)) {
                status.goTo(index)
            }
        }
    }

    return (
        <div className='project-nav d-flex justify-content-between align-items-center'>
            {steps.map(({ label, url }, index) => (
                <React.Fragment key={url}>
                    <NavLink to={url} disabled={status.isDisabled(index, project.issues.filter(x => !x.warning && !x.estimation))} className='project-nav-item' onClick={ev => onClick(ev, index)}>
                        {index < 3 && (project.issues?.filter(x => !x.warning && !x.estimation && x.link?.includes(status.steps[index]?.url)).length > 0) && <span style={{ fontSize: '16px', fontWeight: 'bold', color: (status.currentStep === index ? 'white' : 'black') }} className='project-nav-number mr-2'>{index + 1}</span>}
                        {index >= 3 && (project.issues.filter(x => !x.warning && !x.estimation).length > 0) && <span style={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }} className='project-nav-number mr-2'>{index + 1}</span>}
                        {index < 3 && (project.issues?.filter(x => !x.warning && !x.estimation && x.link?.includes(status.steps[index]?.url)).length === 0) && <AiFillCheckCircle size={'30'} color='#00948F' style={{ fontSize: '14px', marginRight: '2px' }}></AiFillCheckCircle>}
                        {index === 3 && (project.issues.filter(x => !x.warning && !x.estimation).length === 0) && <AiFillCheckCircle size={'30'} color='#00948F' style={{ fontSize: '14px', marginRight: '2px' }}></AiFillCheckCircle>}
                        {index === 4 && (project.issues.filter(x => !x.warning && !x.estimation).length === 0) && <AiFillCheckCircle size={'30'} color={(dbProjectStatus !== 1 ? '#00948F' : '#d3d3d3')} style={{ fontSize: '14px', marginRight: '2px' }}></AiFillCheckCircle>}
                        <span style={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}>{label}</span>
                    </NavLink>
                    {index < lastStep &&
                        <div className='horizontal-divider flex-grow-1 mx-2' />}
                </React.Fragment>
            ))}
        </div>
    )
})


export const ProjectNavFooter = observer(() => {
    const change = observable({
        isChanged: false
    })
    let project = useProjectStore().project!
    let readOnly = useProjectStore().readOnly

    const estimatedCalculationTime = project.estimatedCalculationTime()
    const estimatedCalculationTimeString = estimatedCalculationTime !== undefined && estimatedCalculationTime.length > 0 && estimatedCalculationTime[0] !== undefined && estimatedCalculationTime[0].label !== undefined ? estimatedCalculationTime[0].label : ''

    let { status } = project;
    if (window.performance) {
        if ((performance.getEntriesByType("navigation")[0] as any).type === 'reload') {
            status.setCurrentStep(JSON.parse(window.localStorage.getItem('currentStep')! ?? 0))
        }
    }
    reaction(() => toJS(project), () => {
        change.isChanged = true
    })
    reaction(() => change.isChanged, () => {
        project.analyze()
    })
    return (
        <div className='d-flex justify-content-end align-items-center'>
            {project.currentStepWarnings.length > 0 && project.currentStepWarnings.map(x => <ButtonGroup className='mr-1'>
                <Button className='pillButton' variant='warning' style={{ color: '#996B04' }}><AiOutlineExclamationCircle /></Button>
                <Button className='pillButton' variant='outline-warning' style={{ backgroundColor: 'initial', color: '#996B04' }}><span className='text-keep-lines'>{x.label}</span></Button>
            </ButtonGroup>)}
            {project.currentStepErrors.length > 0 && project.currentStepErrors.map(x => <ButtonGroup className='mr-1'>
                <Button className='pillButton' variant='danger'><AiOutlineExclamationCircle /></Button>
                <Button className='pillButton' variant='outline-danger' style={{ backgroundColor: 'initial', color: '#dc3545' }}><span className='text-keep-lines'>{x.label}</span></Button>
            </ButtonGroup>)}
            <ButtonGroup className='mr-1'>
                {!status.isLast && estimatedCalculationTimeString !== '' && <p className='ml-2 mr-2' style={{ color: '#5b0428' }}>{estimatedCalculationTimeString}</p>}
            </ButtonGroup>
            <Spinner animation='border' size='sm' hidden={!status.transition} className='mr-3 text-primary' />
            {!status.isFirst &&
                <Button className='pillButton d-flex align-items-center' variant='outline-primary' onClick={() => { project.analyze(); status.goToPrevious(project.currentStepErrors) }}>
                    <BsChevronLeft className="me-2" />
                    {(project.status?.currentStep === 1 ? "Setup" : project.status?.currentStep === 2 ? "Application" : project.status?.currentStep === 3 ? "Equipment" : project.status?.currentStep === 4 ? "Economics" : "Back")}
                </Button>            }
            {!status.isLast && <>
                <Button
                    id='saveNext'
                    disabled={project.currentStepErrors.length > 0}
                    variant={project.currentStepErrors.length > 0 ? 'secondary' : 'primary'}
                    onClick={() => { project.analyze(); status.goToNext(project.currentStepErrors) }}
                    className='ml-2 pillButton d-flex align-items-center'
                >
                    {(project.status?.currentStep === 0 ? "Application" : project.status?.currentStep === 1 ? "Equipment" : project.status?.currentStep === 2 ? "Economics" : project.status?.currentStep === 3 ? "Results" : "Next")}
                    <BsChevronRight className="ms-2" />
                </Button>
                {readOnly === true && <ToolTipInfo label={'Project in Read Only mode, changes are not being saved and will be lost when project is closed.'} />}
            </>}
        </div>
    )
})
