import './SideNavigationView.scss'
import { useMatch } from '@reach/router'
import { ResultHeader, ResultItem, ResultModel } from '../../results'
import { observer } from 'mobx-react-lite'
import { ResultDetailsSectionKind } from '../../results-details'
import * as React from 'react'
import { useState } from 'react'
import ResultExportModal from './ResultExport'
import { Button } from 'react-bootstrap'
import ToolTipInfo from '../../../helper/ToolTipInfo'
import { SiMicrosoftexcel } from "react-icons/si";
import ComponentLifetime from '../equipment/shared/ComponentLifetime'
import { useProjectStore } from '../../ProjectProvider'
import { getAllowAugmentation } from './SelectedSystem'

const SideNavigationView = observer(() => {
    const isSensitivity = useMatch('sensitivity/:selection')
    //const isGraph = useMatch('graph')
    const isOptimization = useMatch('optimization/:selection')
    const isDetails = useMatch('details/:selection')

    const project = useProjectStore().project!
    const results = project.results

    const canSelectSensitivity = ['none', 'sensitivity', 'optimization'].includes(results.selection.kind)
    const canSelectOptimization = ['sensitivity', 'optimization'].includes(results.selection.kind)
    const canSelectDetails = ['optimization'].includes(results.selection.kind)

    const allowAugmentation = getAllowAugmentation(project.storage, results.details.details.storage)

    const handleSelectSensitivity = async () => {
        if (canSelectSensitivity) {
            await results.navigateSelection('sensitivity')
        }
    }
    const handleSelectGraph = async () => {
        await results.navigateSelection('graph')
    }
    const handleSelectOptimization = async () => {
        if (canSelectOptimization) {
            await results.navigateSelection('optimization')
        }
    }
    const handleSelectDetails = async () => {
        if (canSelectDetails) {
            await results.navigateSelection('details')
        }
    }
    const [showResultExportModal, setShowResultExportModal] = useState(false)
    const handleExportResults = async () => {
        setShowResultExportModal(true)
    }
    const closeResultExportModal = () => {
        setShowResultExportModal(false)
    }
    return (
        <>
            <ul className='side-nav-ul-common-list-style'>
                {results.sensitivityTable.items.length > 1 &&
                    <li style={{ minHeight: '55px' }} className={`side-nav-li-common-list-style sens-opt-tables ` + (isSensitivity ? 'active ' : '') + (canSelectSensitivity ? '' : 'disabled ')}
                        onClick={handleSelectSensitivity}>
                        <span className='cursor-pointer ml-2 '>Sensitivity Table</span>

                        {results.selectedSensitivityCaseItem ?
                            <SensitivityVars header={results.sensitivityTable.header}
                                selection={results.selectedSensitivityCaseItem} /> :
                            <div className='submenu-helper-text font-size-12 ul-gray-text-color mt-1 mb-2 ml-3'>
                                Lowest NPC system configuration for each sensitivity case
                            </div>}
                    </li>}
                {results.ostDimension && results.ostDimension.length > 1 &&
                    <li style={{ minHeight: '55px' }} className={`side-nav-li-common-list-style sens-opt-tables ` + (isSensitivity ? 'active ' : '') + (canSelectSensitivity ? '' : 'disabled ')}
                        onClick={handleSelectGraph}>
                        <span className='cursor-pointer ml-2 '>Sensitivity Graph</span>
                        <div className='submenu-helper-text font-size-12 ul-gray-text-color ml-3'>
                            Optimal System Type
                        </div>
                    </li>}
                <li style={{ minHeight: '55px' }} className={`side-nav-li-common-list-style sens-opt-tables ` + (isOptimization ? 'active ' : '') + (canSelectOptimization ? '' : 'disabled ')}
                    onClick={handleSelectOptimization}>
                    <span className={'cursor-pointer ml-2 ' + (canSelectOptimization ? '' : 'disabled ')}>Optimization Results</span>
                    {results.selectedOptimizationCaseItem ?
                        <OptimizationVars header={results.optimizationTable.header}
                            selection={results.selectedOptimizationCaseItem} /> :
                        <div className='submenu-helper-text font-size-12 ul-gray-text-color ml-3'>
                            List of optimization
                        </div>}
                </li>

                <li style={{ minHeight: '55px' }} className={`side-nav-li-common-list-style sens-opt-tables` + (isDetails ? 'active ' : '') + (canSelectDetails ? '' : 'disabled ')}
                    onClick={handleSelectDetails}>
                    <span className={'cursor-pointer ml-2 ' + (canSelectDetails ? '' : 'disabled ')}>Selected Overview</span>

                    {!isDetails ?
                        <div className='submenu-helper-text ml-3'>Performance details</div> :
                        <ul className='side-nav-ul-common-list-style font-size-12 side-subnav-ul-common-list-style side-nav-li-overview-list-style'>
                            <ResultsLink label='Summary' section='summary' results={results} />
                            {project.useRegulationMarket && results.details.details.regulationMarketResult?.length > 0 && (results.details.details.regulationMarketResult[0].regUpCapacityRevenueTimeseries?.length > 0 || results.details.details.regulationMarketResult[0].regUpEnergyRevenueTimeseries?.length > 0 || results.details.details.regulationMarketResult[0].regDownCapacityRevenueTimeseries?.length > 0 || results.details.details.regulationMarketResult[0].regDownEnergyExpenseTimeseries?.length > 0) &&
                                <ResultsLink label='Regulation Market' section='regMarket' results={results} />}
                            {results.details.details.wind.status === 0 &&
                                <ResultsLink label='Wind' section='wind' results={results} />}
                            {results.details.details.solar.status === 0 &&
                                <ResultsLink label='Solar' section='solar' results={results} />}
                            {results.details.details.storage.status === 0 && <>
                                <ResultsLink label='Storage' section='storage' results={results} />
                                <ResultsLink label={(allowAugmentation ? 'Augmentation' : 'Degradation')} section='augmentation' results={results} />
                            </>}
                            <ResultsLink label='Revenue' section='revenue' results={results} />
                            <ResultsLink label='Time Series' section='timeseries' results={results} />
                            <ResultsLink label='Cash Flow' section='cashflow' results={results} />
                            <ResultsLink label='Pro Forma' section='proforma' results={results} />
                            <ResultsLink label='Compare Economics' section='compareEconomics' results={results} />
                            <div className='row ml-2 mb-2'>
                                <Button id='createNewProject' size='sm' className='btn btn-primary d-flex align-items-center mt-3 pillButton' onClick={handleExportResults}>
                                    <SiMicrosoftexcel className='mr-1' />
                                    Export to Excel
                                </Button>
                                <div className='mt-4 ml-2'>
                                    <ToolTipInfo label={'Export results to an Excel file.'} />
                                </div>
                            </div>
                            <ResultExportModal show={showResultExportModal} project={project} onCancel={closeResultExportModal} result={results} />
                        </ul>}
                </li>
                {!project.earlyAnalysis && project.useComponentLifetimes &&
                    <li>
                        <ComponentLifetime isMiniature={true} />
                    </li>
                }

            </ul>
        </>
    )
})


export default SideNavigationView


interface DetailsLinkProps {
    label: string
    active?: boolean
    onSelect?: () => void
}

const DetailsLink = ({ label, active, onSelect }: DetailsLinkProps) => {
    const c = 'side-nav-li-common-list-style side-subnav-li-common-list-style d-flex align-items-center ' +
        (active ? 'active active-subnav' : '')
    return (
        <li className={c} style={{ height: '28px' }} onClick={onSelect}>
            <span className='cursor-pointer ml-3' onClick={onSelect}>{label}</span>
        </li>
    )
}


interface ResultsLinkProps {
    label: string
    results: ResultModel
    section: ResultDetailsSectionKind
}

const ResultsLink = observer(({ label, results, section }: ResultsLinkProps) => {
    return (
        <DetailsLink label={label}
            active={results.details.section === section}
            onSelect={() => results.details.selectSection(section)} />
    )
})


const numFormat = Intl.NumberFormat('en', { maximumFractionDigits: 2 })

interface SensitivityVarsProps {
    header: ResultHeader
    selection: ResultItem
}

const SensitivityVars = ({ header, selection }: SensitivityVarsProps) => {
    return (
        <div className='font-size-12 ul-gray-text-color'>
            {header.sensitivity.map((x, index) =>
                <div className='my-1 ml-3' key={x.name}>{x.name} : <strong>{numFormat.format(selection.sensitivity[index])}</strong> {x.units}</div>,
            )}
        </div>
    )
}

interface OptimizationVarsProps {
    header: ResultHeader
    selection: ResultItem
}

const OptimizationVars = ({ header, selection }: OptimizationVarsProps) => {
    return (
        <div className='font-size-12 ul-gray-text-color'>
            {header.optimization
                .map((x, index) =>
                    selection.optimization[index] !== null &&
                    <div className='my-1 ml-3' key={x.name}>{x.name} :&nbsp;
                        {selection.optimization[index].map((value, valueIndex) =>
                            value !== null &&
                            <React.Fragment key={valueIndex}>
                                <strong>{numFormat.format(value)}</strong>&nbsp;{x.units[valueIndex]}
                                {valueIndex < selection.optimization[index].length - 1 && ' / '}
                            </React.Fragment>)}
                    </div>)}
        </div>
    )
}
