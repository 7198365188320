import { observer } from "mobx-react-lite"
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import ContextHelp from "../helper/ContextHelp";
import NumberInput from "../helper/NumberInput";
import { Validation } from "../helper/validation";
import { UserStorageClass } from "./UserStorage"
import { TemperatureCapacityChart } from "./UserStorageChart";
import StorageCurveTable from "./UserStorageCurveTable";
import { RecalculateTempCapacityRequestDto } from "services/Service";
import api from 'services/api'

interface StorageFuncModelTabProps {
    model: UserStorageClass
    useCommaAsDecimalPoint: boolean
}

const StorageLibraryTempCapacityTab = observer(({ model, useCommaAsDecimalPoint }: StorageFuncModelTabProps) => {
    const [ignoreCapacityChangesWithTemp, setIgnoreCapacityChangesWithTemp] = useState(model.ignoreCapacityVsTemperatureInModel)
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleRecalculateTemperatureCapacity = async () => {
        setIsLoading(true);

        const requestData: RecalculateTempCapacityRequestDto = {
            IgnoreCapacityVsTemperature: ignoreCapacityChangesWithTemp,
            CapacityTemperatureD0: model.fittedD0,
            CapacityTemperatureD1: model.fittedD1,
            CapacityTemperatureD2: model.fittedD2,
            TableData: model.capacityTemperatureCurve.map(item => ({
                Capacity: item.x,
                Temperature: item.y
            }))
        };

        try {
            var response = await api.recalculateTemperatureCapacity(requestData);
            model.fittedD0 = response.d0;
            model.fittedD1 = response.d1;
            model.fittedD2 = response.d2;
            setShowError(false);
        } catch (error) {
            setShowError(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Row>
                <Form.Check className='col-4 ml-3 mt-2' custom label='Ignore Capacity Changes with Temperature' id='ignoreCapacityChangesWithTemp'
                    checked={model.ignoreCapacityVsTemperatureInModel}
                    onChange={ev => {
                        model.setUserStorageIgnoreCapacityVsTemperatureInModel(ev.target.checked);
                        setIgnoreCapacityChangesWithTemp(ev.target.checked)
                    }} />
            </Row>
            <Row className="mt-2 d-flex flex-column align-items-center justify-content-center">
                <h4><b>Relative Capacity versus Temperature</b></h4>
            </Row>
            <Row>
                <Col xs={3}>
                    <StorageCurveTable
                        disabled={ignoreCapacityChangesWithTemp}
                        model={model.capacityTemperatureCurve}
                        useCommaAsDecimalPoint={useCommaAsDecimalPoint}
                        setItem={model.setUserStorageCapacityTemperatureCurveItem}
                        deleteItem={model.userStorageCapacityTemperatureCurveDeleteItem}
                        addItem={model.userStorageCapacityTemperatureCurveAddRow}
                        columnHead1="Capacity (% of Nominal)"
                        columnHead2="Temperature (C)"
                        percentage={true}
                        allowNegativeOnSecondColumn={true}
                    />
                    <div hidden={!showError} className='text-danger'>Could not recalculate, input is invalid </div>
                </Col>
                <Col xs={5}>
                    <TemperatureCapacityChart model={model.capacityTemperatureCurve} />
                </Col>
                <Col xs={4}>
                    <p><b>Parameters Fit from Data:</b></p>
                    <p>Capacity(T) = NominalCapacity * (d0 + d1*T + d2*T^2)</p>
                    <Row>
                        <label className="col-7 mt-2">Fitted d0:</label>
                        <NumberInput disabled={ignoreCapacityChangesWithTemp} value={model.fittedD0}
                            onChange={model.setUserStorageFittedD0}
                            className="mt-1 col-2 mr-4"
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0, false)} />
                        <ContextHelp helpId='USERLIBRARYTEMPCAPACITY' />
                    </Row>
                    <Row>
                        <label className="col-7 mt-2">Fitted d1:</label>
                        <NumberInput disabled={ignoreCapacityChangesWithTemp} value={model.fittedD1}
                            onChange={model.setUserStorageFittedD1}
                            className="mt-1 col-2"
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0, false)}/>
                    </Row>
                    <Row>
                        <label className="col-7 mt-2">Fitted d2:</label>
                        <NumberInput disabled={ignoreCapacityChangesWithTemp} value={model.fittedD2}
                            onChange={model.setUserStorageFittedD2}
                            className="mt-1 col-2"
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    </Row>
                    <Button
                        variant="primary"
                        onClick={handleRecalculateTemperatureCapacity}
                        disabled={isLoading || ignoreCapacityChangesWithTemp}
                    >
                        {isLoading ? 'Recalculating...' : 'Recalculate Temperature Capacity'}
                    </Button>
                </Col>
            </Row>
            <Row className="mt-1 d-flex">

            </Row>
        </>
    )
})

export default StorageLibraryTempCapacityTab
