import {ChangeEvent} from 'react'
import './FilePicker.css'
import { readFile } from '../../utils'
import * as React from 'react'

interface FilePickerProps2 {
    onSelect?: (content: string[], file: File[], keepExistingFiles?: boolean) => void
    label?: string
    types?: string
    disabled?: boolean
    keepExistingFiles?: boolean
    allowMultipleImport?: boolean
}


const MultiFilePicker = ({ onSelect, label, types, disabled, allowMultipleImport = true, keepExistingFiles = false}: FilePickerProps2) => {
    const handleSelectFile = async (ev: ChangeEvent<HTMLInputElement>) => {
        if (disabled) {
            ev.preventDefault()
            return
        }
        const data = ev.target.files
        var contentList: string[] = []
        var files: File[] = []
        if (data != undefined && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                const content = await readFile(data[i])
                contentList.push(content)
                files.push(data![i])
            }
        }
        onSelect?.(contentList, files, keepExistingFiles)
    }

    const css = `btn btn-file btn-primary pillButton ${disabled ? 'disabled' : ''}`

    return (
        <button className={css} type='button'>
            {label ?? 'Import...'}
            <input accept={types} type='file' multiple={allowMultipleImport} disabled={disabled}
                   onChange={handleSelectFile}/>
        </button>
    )
}

export default MultiFilePicker
