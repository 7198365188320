import {action, computed, makeObservable, observable} from 'mobx'
import { geocode } from '../../../utils'


export interface Pos {
    lat: number
    lng: number
}


export class Location {
    constructor() { makeObservable(this) }

    @observable pos: Pos = {lat: 40.014984, lng: -105.270546}
    @observable address: string = 'Boulder, CO, USA'
    @observable countryName: string = 'United States'
    @observable countryCode: string = 'US'

    @action setPos(x: Pos) { this.pos = x }
    @action setAddress(x: string) { this.address = x }
    @action setCountry(name: string, code: string) {
        this.countryName = name
        this.countryCode = code
    }

    @computed get isInternational(): boolean { return this.countryCode !== 'US' }

    @action
    async updatePos(x: Pos) {
        this.setPos(x)
        const results = await geocode(x)
        this.setAddress(results.address)
        this.setCountry(results.countryName, results.countryCode)
    }

    @action async updateCountry() {
        const results = await geocode(this.pos)
        this.setCountry(results.countryName, results.countryCode)
    }
}
