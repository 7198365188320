import * as React from 'react'
import { useEffect, useState } from 'react'
import { Button, Modal, Table, Spinner } from 'react-bootstrap'
import { GoX } from 'react-icons/all'
import MultiFilePicker from './MultiFilePicker'
import ToolTipInfo from './ToolTipInfo'
import { useProjectStore } from '../project/ProjectProvider'
import api from '../../services/api'


interface MultiTimeSeriesImportProps {
    onSelect?: (sourceList: string[], customName: string[], customSize: number[], data: number[][], year: number, index: number) => void
    index: number
    label?: string
    disabled?: boolean
    state: MultiImportState
    setState: (state: MultiImportState) => void
    fileData: MultiFileData[]
    setFileData: (fileData: MultiFileData[]) => void
    handleDeleteFile: (id: number) => void
    addFiles: boolean
    allowMultipleImport: boolean
    isPv: boolean //PV or Wind Data import
    showSuccessModal?: () => void
}

const MultiTimeSeriesImport = ({ onSelect, index, label, disabled, state, setState, fileData, setFileData, handleDeleteFile, allowMultipleImport, isPv, showSuccessModal }: MultiTimeSeriesImportProps) => {
    const [show, setShow] = useState(false)
    const open = () => setShow(true)
    const close = () => {
        setShow(false)
        setFileData([])
    }
    const handleSelect = (sourceList: string[], customName: string[], customSize: number[], data: number[][], year: number) => {
        close()
        onSelect?.(sourceList, customName, customSize, data, year, index)
    }

    return (<>
        <button className={`btn btn-file btn-primary pillButton ${disabled ? 'disabled' : ''}`}
            onClick={open}>
            {label ?? 'Import...'}
        </button>
        <MultiTimeSeriesImportModal show={show} state={state} setState={setState} onSelect={handleSelect} fileData={fileData} setFileData={setFileData} onCancel={close} handleDeleteFile={handleDeleteFile} allowMultipleImport={allowMultipleImport} isPv={isPv} showSuccessModal={showSuccessModal} />
    </>)
}

export default MultiTimeSeriesImport

export type MultiImportState
    = { kind: 'blank' }
    | { kind: 'selected', year?: number }
    | { kind: 'importing', year?: number }
    | { kind: 'error', message: string, year?: number }

export type MultiFileData = { source: string, name?: string, size?: number, content: string }

interface MultiTimeSeriesImportModalProps {
    show: boolean
    onSelect: (sourceList: string[], customName: string[], customSize: number[], data: number[][], year: number) => void
    onCancel: () => void
    state: MultiImportState
    setState: (state: MultiImportState) => void
    fileData: MultiFileData[]
    setFileData: (fileData: MultiFileData[]) => void
    handleDeleteFile: (id: number) => void
    allowMultipleImport: boolean
    isPv: boolean //PV or Wind data import
    showSuccessModal?: () => void
}

const MultiTimeSeriesImportModal = ({ show, onSelect, state, setState, fileData, setFileData, onCancel, handleDeleteFile, allowMultipleImport, isPv, showSuccessModal }: MultiTimeSeriesImportModalProps) => {
    const project = useProjectStore().project!
    const useCommaAsDecimalPoint = project.useCommaAsDecimalPoint

    useEffect(() => setState({ kind: 'blank' }), [show])
    const [pageRender, setPageRender] = useState<boolean>(false)

    const handleSelect = (contentList: string[], data: File[], keepExistingFiles?: boolean) => {
        setState({ kind: 'selected' })
        var fileData: MultiFileData[] = []
        if (data !== undefined && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                var source = data[i].name
                var content = contentList[i]
                var file: MultiFileData = { source, content, name: '', size: 1 }
                fileData.push(file)
            }
        }
        setFileData(fileData)
    }

    let sizeLabel = isPv ? 'Array Size (kW)' : 'Wind Farm Rating (kW)'
    let sizeHelp = isPv ? 'Input the capacity of the PV array used to produce this data in kW' : 'Input the capacity of the wind farm used to produce the data in kW, 1 kW minimum.'
    const onDeleteFile = (id: number) => {
        handleDeleteFile(id)
        setPageRender(!pageRender)
    }
    const handleImport = async () => {
        if ((state.kind === 'error' && fileData.length < 1) || state.kind === 'blank') return

        try {
            const currentYear = new Date().getFullYear();
            setState({ kind: 'importing' })
            if (fileData !== undefined && fileData.length > 0) {
                var sourceList: string[] = []
                var content: number[][] = []
                var customName: string[] = []
                var customSize: number[] = []
                var message: string = ''
                var numErrors: number = 0
                for (let i = 0; i < fileData.length; i++) {
                    var r = await api.import(useCommaAsDecimalPoint, fileData[i].content, state.year, true)
                    if (!r.success) {
                        console.error(r.message)
                        message += fileData[i].source
                        numErrors += 1
                    }
                    else {
                        sourceList.push(fileData[i].source)
                        var size = fileData[i].size
                        var currentCustomSize = (size !== undefined ? size : 1)
                        customSize.push(currentCustomSize)
                        var k = 1.0 / (currentCustomSize); //kW
                        var currentData: number[] = []
                        r.data.forEach(x => currentData.push(x * k))
                        content.push(currentData)
                        var name = fileData[i].name
                        customName.push(name !== undefined && name !== '' ? name : fileData[i].source.replace(/\.[^/.]+$/, ""))
                        
                        setState({ kind: 'importing', year: r.year })
                    }
                }
                if (numErrors > 0) {
                    var newFileData = fileData.filter(item => sourceList.includes(item.source));
                    setState({ kind: 'error', message: 'One or more files were not in the correct format and were removed from the list.', year: state.year })
                    setFileData(newFileData)
                    return
                }
                if (sourceList.length > 0) {
                    onSelect(sourceList, customName, customSize, content, state.year !== undefined ? state.year : currentYear)
                    if (showSuccessModal)
                        showSuccessModal()
                }
            }
            else {
                setState({ kind: 'blank' })
            }
        } catch (error) {
            console.error(error)
            setState({ kind: 'error', message: `Import error: service unavailable` })
            return
        }
    }

    const handleSetYear = (x?: number) => {
        if (state.kind !== 'selected') return
        setState({ ...state, year: x })
    }
    const handleSetName = (name: string, id: number) => {
        fileData[id].name = name
    }
    const handleSetSize = (size: string, id: number) => {
        fileData[id].size = isNaN(Number(size)) ? 1 : Number(size)
    }
    return (
        <Modal show={show} onHide={onCancel} size='lg'>
            <Modal.Header closeButton onHide={onCancel}>
                <Modal.Title>Import File</Modal.Title>                
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-row align-items-center'>
                    {fileData.length < 1 &&
                        <MultiFilePicker onSelect={handleSelect} keepExistingFiles={false} allowMultipleImport={allowMultipleImport} label='Open...' />
                    }
                </div>
                {fileData.length < 1 &&
                    <div className='mx-2' />
                }
                {(state.kind === 'selected' || state.kind === 'importing' || state.kind === 'error') && fileData != undefined && fileData.length > 0 &&
                    <div className='d-flex flex-row align-items-center'>
                    <Table borderless={true}>
                            <thead>
                                <tr>
                                    <th>Imported File</th>
                                    <th>Name (Optional)</th>
                                    <th>{sizeLabel}<ToolTipInfo label={sizeHelp} /></th>
                                    <th>Delete File</th>
                                </tr>
                            </thead>
                            {(state.kind === 'selected' || state.kind === 'importing' || state.kind === 'error') && fileData != undefined && fileData.length > 0 &&
                                fileData.map((x, index) => <React.Fragment key={index}>
                                    <tbody>
                                        <tr>
                                            <td><strong>{x.source}</strong></td>
                                            <td><input className='form-control' id='importCustomName' onChange={ev => handleSetName(ev.target.value, index)} /></td>
                                            <td><input className='form-control' id='importCustomSize' defaultValue={1} min={1} onChange={ev => handleSetSize(ev.target.value, index)} /></td>
                                            <td><button className='btn pillButton' onClick={() => onDeleteFile(index)}><GoX/></button></td>
                                        </tr>
                                    </tbody>
                                </React.Fragment>)}
                        </Table>
                    </div>}
                {state.kind === 'error' &&
                    <div className='mt-2 text-danger'>{state.message}</div>}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onCancel} variant='outline-primary pillButton'>Cancel</Button>
                {fileData.length > 0 &&
                    <MultiFilePicker onSelect={handleSelect} keepExistingFiles={false} label='Cancel and Import Again' />
                }
                <Spinner hidden={state.kind !== 'importing'} animation='border' size='sm' className='text-primary' />
                <Button className='pillButton' onClick={handleImport} disabled={state.kind !== 'selected' && fileData.length < 1}>OK</Button>
            </Modal.Footer>
        </Modal>
    )
}
