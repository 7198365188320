import { observer } from 'mobx-react-lite'
import './sensitivityView.scss'
import { AllocationType } from './HourlyMaxView'
import { Project } from '../../../model'
import { useEffect } from 'react'
import { SensitivityItem, SensitivityVar } from '../../../model/SensitivityVar'
import { useProjectStore } from '../../../ProjectProvider'
import ButtonDelete from '../../../../helper/ButtonDelete'
import ButtonAdd from '../../../../helper/ButtonAdd'
import NumberInput from '../../../../helper/NumberInput'
import { Validation } from '../../../../helper/validation'
import * as React from 'react'


interface SensitivityViewProps {
    model: SensitivityVar
    allocationType: AllocationType
}


const HourlyMaxSensitivity = observer(({
    model, allocationType
}: SensitivityViewProps) => {
    const handleAdd = () => {
        if (allocationType === 'PV')
            project.energyMarkets.forEach(x => x.pvAllocationSensitivity.addNewValue())
        else if (allocationType === 'Wind')
            project.energyMarkets.forEach(x => x.windAllocationSensitivity.addNewValue())
        else
            project.energyMarkets.forEach(x => x.maxStorageCommitmentSensitivity.addNewValue())
    }

    const handleDelete = (i: number) => {
        if (allocationType === 'PV')
            project.energyMarkets.forEach(x => {
                x.pvAllocationSensitivity.removeValue(x.pvAllocationSensitivity.values[i].id)
            })
        else if (allocationType === 'Wind')
            project.energyMarkets.forEach(x => {
                x.windAllocationSensitivity.removeValue(x.windAllocationSensitivity.values[i].id)
            })
        else
            project.energyMarkets.forEach(x => {
                x.maxStorageCommitmentSensitivity.removeValue(x.maxStorageCommitmentSensitivity.values[i].id)
            })
    }

    const project = useProjectStore().project!
    const useCommaAsDecimalPoint = project.useCommaAsDecimalPoint

    return (
        <table className='table-bordered table-hover table-sm p-2'>
            <thead>
                <tr>
                    <th />
                    {model.values.map((item, i) =>
                        <th key={item.id}>
                            <ButtonDelete onClick={() => handleDelete(i)} />
                        </th>)}
                    <th><ButtonAdd onClick={handleAdd} /></th>
                </tr>

                {project.energyMarkets.map(i => {
                    return <HourlyRow items={allocationType === 'PV' ? [...i.pvAllocationSensitivity.values] : allocationType === 'Wind' ? [...i.windAllocationSensitivity.values] : [...i.maxStorageCommitmentSensitivity.values]}
                        label={i.name}
                        getter={item => item.value}
                        setter={item => item.setValue.bind(item)}
                        useCommaAsDecimalPoint={useCommaAsDecimalPoint}
                        project={project}
                    />
                })}
            </thead>
        </table>
    )
})

export default HourlyMaxSensitivity


interface HourlyRowProps {
    label: string
    items: SensitivityItem[]
    getter: (item: SensitivityItem) => number
    setter: (item: SensitivityItem) => ((x: number) => void)
    useCommaAsDecimalPoint: boolean
    project: Project
}

const HourlyRow = observer(({ label, items, getter, setter, useCommaAsDecimalPoint, project }: HourlyRowProps) => {
    useEffect(() => {
        project.analyze()
    }, [items])
    return (
        <tr>
            <td>{label}</td>
            {items.map(item =>
                <td key={item.id} className='table-w-1 p-0 position-relative'>
                    <NumberInput useCommaAsDecimalPoint={useCommaAsDecimalPoint} value={getter(item)}
                        onChange={setter(item)}
                        className='h-100 border-0 rounded-0' validator={Validation.percent} />
                </td>)}
        </tr>
    )
})
