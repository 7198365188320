import { observer } from "mobx-react-lite"
import { BiPlusCircle } from "react-icons/bi"
import { StorageCurveItem } from "../../services/api"
import ButtonDelete from "../helper/ButtonDelete"
import NumberInput from "../helper/NumberInput"
import { Validation } from "../helper/validation"
import * as React from 'react'

interface CurveTableViewProps {
    model: StorageCurveItem[]
    useCommaAsDecimalPoint: boolean
    setItem: (value: number, index: number, x: boolean) => void
    deleteItem: (index: number) => void
    addItem: () => void
    columnHead1: string
    columnHead2: string
    disabled?: boolean
    percentage?: boolean
    allowNegativeOnSecondColumn?: boolean
}


const StorageCurveTable = observer(({ model, useCommaAsDecimalPoint, setItem, deleteItem, addItem, columnHead1, columnHead2, disabled = false, percentage = false, allowNegativeOnSecondColumn = false }: CurveTableViewProps) => {
    const handleAdd = () => addItem()
    const handleDelete = (index: number) => deleteItem(index)
    const handleSet = (value: number, index: number, x: boolean) => setItem(value, index, x)

    return (<>
        <style>{
            `table, tr, th, td{
            border:1px solid lightgray;
            text-align: center;
            }`
        }
        </style>
        <table >
            <tr className="ml-2">
                <th>{columnHead1}</th>
                <th>{columnHead2}</th>
                <th><button className='btn btn-sm btn-outline-primary border-0 pillButton' onClick={handleAdd}>
                    <BiPlusCircle />
                </button></th>
            </tr>
            {model.map((x, i) =>
                <tr>
                    {percentage && <td><NumberInput disabled={disabled} value={x.x} useCommaAsDecimalPoint={useCommaAsDecimalPoint} onChange={ev => handleSet(ev, i, true)} validator={Validation.range(0, 100, false, false)} /></td>}
                    {!percentage &&
                        <td><NumberInput
                            disabled={disabled}
                            value={x.x}
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint}
                            onChange={ev => handleSet(ev, i, true)}
                            validator={Validation.min(allowNegativeOnSecondColumn ? Number.MIN_VALUE : 0, false)}
                        /></td>
                    }
                    <td><NumberInput disabled={disabled} value={x.y} useCommaAsDecimalPoint={useCommaAsDecimalPoint} onChange={ev => handleSet(ev, i, false)} validator={Validation.min(0)} /></td>
                    <td><ButtonDelete onClick={() => handleDelete(i)} /></td>
                </tr>)}
        </table>
    </>
    )
})

export default StorageCurveTable
