import { RouteComponentProps } from "@reach/router"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import UserStorageLibraryComponentEditView from "./UserStorageLibraryComponentEditView"
import { useUserLibraryStore } from "./UserStorageProvider"
import * as React from 'react'
import api from "../../services/api"

interface UserLibraryViewProps extends RouteComponentProps {
    id?: string
}

const UserStorageLibraryComponentView = observer(({ id }: UserLibraryViewProps) => {
    var storageId = id
    var store = useUserLibraryStore()
    var ready = store.specs !== null
    var defaultCurrency = api?.defaultCurrencySymbol

    useEffect(() => {
        store.getUserStorage(storageId!).then()
    }, [store])

    return (<>
        {!ready ?
            <h1>Loading...</h1> :
            <UserStorageLibraryComponentEditView id={id} defaultCurrency={defaultCurrency} />
        }
    </>
    )
})

export default UserStorageLibraryComponentView
