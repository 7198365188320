import Form from 'react-bootstrap/Form'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { SizingKind } from '../../../../../services/inputs'
import { Sizing } from '../../../model/Sizing'
import { ValidationFunc } from '../../../../helper/validation'
import ContextHelp from '../../../../helper/ContextHelp'
import ButtonAdd from '../../../../helper/ButtonAdd'
import NumberInput from '../../../../helper/NumberInput'
import ButtonDelete from '../../../../helper/ButtonDelete'


interface SizingViewProps {
    id: string
    model: Sizing
    absoluteUnits?: string // `undefined` to exclude
    relativeUnits?: string // `undefined` to exclude
    absoluteLabel?: string
    relativeLabel?: string
    validator?: ValidationFunc,
    customTblHead?: string
    componentId?: string
    useCommaAsDecimalPoint: boolean
    hideHelp?: boolean
    customLabel?: string
    showLabel?: boolean
    disablemutipleSizes?: boolean
}


const SizingView = observer(({ id, model, absoluteUnits, relativeUnits, absoluteLabel, relativeLabel, validator, customTblHead, useCommaAsDecimalPoint, componentId = 'componentId', hideHelp = false, customLabel = "", showLabel = true, disablemutipleSizes = false }: SizingViewProps) => {
    const absoluteText = absoluteLabel ?? `Size in ${absoluteUnits}`
    const relativeText = relativeLabel ?? `Size in ${relativeUnits}`

    return (
        <div className='m-2'>
            <div className='d-flex mb-2 justify-content-start'>
                {showLabel && <h6 className='mr-2'>{`Size ${customLabel}`}</h6>}
                {!hideHelp && <ContextHelp helpId={customTblHead ? 'WINDSIZING' : (!absoluteUnits ? 'STORAGESIZING' : 'SOLARSIZING')} />}
            </div>

            <div className='d-flex flex-row my-3'>
                {(absoluteUnits && relativeUnits) &&
                    <>
                        <Form.Check custom label={absoluteText} id={`${id}-absolute`} type='radio' className='mr-3'
                            checked={model.kind === SizingKind.absolute}
                            onChange={ev => ev.target.checked && model.setKind(SizingKind.absolute)} />
                        <Form.Check custom label={relativeText} id={`${id}-relative`} type='radio'
                            checked={model.kind === SizingKind.relative}
                            onChange={ev => ev.target.checked && model.setKind(SizingKind.relative)} />
                    </>}
                {(absoluteUnits && !relativeUnits) && <h6>{absoluteText}</h6>}
                {(!absoluteUnits && relativeUnits) && <h6>{relativeText}</h6>}
            </div>

            <SizingTable model={model} units={model.kind === SizingKind.absolute ? absoluteUnits! : relativeUnits!} validator={validator} customTblHead={customTblHead} componentId={componentId} useCommaAsDecimalPoint={useCommaAsDecimalPoint} disableMultipleSizes={disablemutipleSizes} />
        </div>
    )
})


export default SizingView


interface SizingTableProps {
    model: Sizing
    units: string
    validator?: ValidationFunc
    customTblHead?: string
    componentId?: string
    useCommaAsDecimalPoint: boolean
    disableMultipleSizes: boolean
}

const SizingTable = observer(({ model, units, validator, customTblHead, componentId, useCommaAsDecimalPoint, disableMultipleSizes }: SizingTableProps) => {
    const handleAdd = () => { model.addNewValue() }
    const handleRemove = (id: string) => { model.removeValue(id) }

    return (
        <table className='table table-bordered table-lg w-auto my-3'>
            <thead>
                <tr>
                    <th>{customTblHead ? customTblHead : units}</th>
                    {!disableMultipleSizes  && <th><ButtonAdd onClick={handleAdd} disabled={disableMultipleSizes} /></th>}
                </tr>
            </thead>
            <tbody>
                {!disableMultipleSizes && model.values.map(v =>
                    <tr key={v.id}>
                        <td className='table-w-2 p-0 position-relative'>
                            <NumberInput id={componentId} value={v.value} onChange={x => v.setValue(x)} className='h-100 border-0 rounded-0' onEnter={handleAdd} autoFocus={true} validator={validator} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                        </td>
                        <td className='py-1 align-middle'>
                            <ButtonDelete onClick={() => handleRemove(v.id)} />
                        </td>
                    </tr>)}
                {disableMultipleSizes && model?.values?.length > 0 &&
                    <tr key={model.values[0].id}>
                        <td className='table-w-2 p-0 position-relative'>
                            <NumberInput id={componentId} value={model.values[0].value} onChange={x => model.values[0].setValue(x)} className='h-100 border-0 rounded-0' onEnter={handleAdd} autoFocus={true} validator={validator} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                        </td>
                    </tr>}
            </tbody>
        </table>
    )
})
