import {action, computed, makeObservable, observable} from 'mobx'
import {v4} from 'uuid'
import { TimeSeries } from './TimeSeries'
import { MultiYearTimeSeries } from './MultiYearTimeSeries'
import { SensitivityVar } from './SensitivityVar'
import { MultiYearVar } from './MultiYearVar'


export type EnergyMarketKind = 'dam' | 'fmm' | 'rtm'

export const allEnergyMarkets: EnergyMarketKind[] = ['dam', 'fmm', 'rtm']

export const getEnergyMarketKindLabel = (kind: EnergyMarketKind) => {
    switch (kind) {
        case 'dam':
            return 'Day-ahead'
        case 'fmm':
            return 'Fifteen-minute'
        case 'rtm':
            return 'Real-time'
    }
}


export class EnergyMarket {
    constructor(lifetime: number) {
        makeObservable(this)
        this.priceEscalator.updateLifetime(lifetime)
    }
    id: string = v4()

    @observable kind: EnergyMarketKind = 'dam'
    @observable useMultiYearPrice: boolean = false
    @observable useSingleFileMultiYearPrice: boolean = false
    @observable price: TimeSeries = new TimeSeries()
    @observable priceName: string = 'Energy Price' 
    @observable multiYearPrice: MultiYearTimeSeries[] = new Array<MultiYearTimeSeries>()
    @observable multiYearPriceReady: boolean = false//error blank ready
    @observable singleFileMultiYearPrice: MultiYearTimeSeries[] = new Array<MultiYearTimeSeries>()
    @observable singleFileMultiYearPriceReady: boolean = false//error blank ready

    @observable priceAverage: SensitivityVar = new SensitivityVar() // ($/MWh)
    @observable priceEscalator: MultiYearVar = new MultiYearVar() // (%)
    @observable allocation: number = 100
    @observable allocationSensitivity: SensitivityVar = new SensitivityVar(100) // (%)
    @observable maxStorageCommitmentSensitivity: SensitivityVar = new SensitivityVar(100) // (%)
    @observable windAllocationSensitivity: SensitivityVar = new SensitivityVar(100) // (%)
    @observable pvAllocationSensitivity: SensitivityVar = new SensitivityVar(100) // (%)

    @observable hasSeparateImportPrice: boolean = false
    @observable useImportPriceMultiplier: boolean = true
    @observable importPriceMultiplier: number = 1.0
    //@observable useMultiYearImportPrice: boolean = false
    @observable importPrice: TimeSeries = new TimeSeries()
    @observable importPriceName: string = 'Import Energy Price'
    @observable multiYearImportPrice: MultiYearTimeSeries[] = new Array<MultiYearTimeSeries>()
    @observable importPriceAverage: SensitivityVar = new SensitivityVar() // ($/MWh)
    @observable importPriceEscalator: MultiYearVar = new MultiYearVar() // (%)

    @computed get name(): string { return getEnergyMarketKindLabel(this.kind) }

    @action setAllocation(x: number) { this.allocation = x }

    @action setData(data: number[], year: number) {
        this.price.setData(data, year)
        const average = this.price.calculateAverage()
        this.priceAverage.setSingleValue(average)
    }
    @action setPriceName(x: string) { this.priceName = x }

    //@action setMultiData(data)
    @action setUseMultiYearPrice(x: boolean) { this.useMultiYearPrice = x }
    @action setUseSingleFileMultiYearPrice(x: boolean) { this.useSingleFileMultiYearPrice = x }

    @action setHasSeparateImportPrice(x: boolean) { this.hasSeparateImportPrice = x }
    //@action setUseMultiYearImportPrice(x: boolean) { this.useMultiYearImportPrice = x }
    @action setImportPriceMultiplier(x: number) { this.importPriceMultiplier = x }
    @action setImportPriceName(x: string) { this.importPriceName = x }
    @action setImportData(data: number[], year: number) {
        this.importPrice.setData(data, year)
        const average = this.importPrice.calculateAverage()
        this.importPriceAverage.setSingleValue(average)
    }

    estimateSensitivitySimulations(): number {
        const sens = this.windAllocationSensitivity.estimateSimulations() * this.pvAllocationSensitivity.estimateSimulations() * this.maxStorageCommitmentSensitivity.estimateSimulations()
        return sens
    }
}
