import { RouteComponentProps } from '@reach/router'
import ResultTableView from './ResultTableView'
import * as React from 'react'
import { useCallback} from 'react'
import { useProjectStore } from '../../ProjectProvider'
import { observer } from 'mobx-react-lite'
import { Spinner, Row } from 'react-bootstrap'
import ContextHelp from '../../../helper/ContextHelp'

interface SensitivityResultsProps extends RouteComponentProps {
    calculate: (recalculate: boolean) => void
}

const SensitivityResults = observer(({ calculate }: SensitivityResultsProps) => {
    const results = useProjectStore().project!.results

    const handleSelect = useCallback((index: number) => {
        results.selectSensitivity(index).then()
    }, [results])

    const loading = results.sensitivityTableState === 'loading'


    return loading ? (
        <div className='d-flex flex-column h-100 align-items-center justify-content-center'>
            <Spinner animation='border' className='text-primary' />
        </div>
    ) : (
        <>
            <div className='ml-4'>
                <Row>
                    <div className='mt-2 ml-4 mr-2' style={{ fontSize: '12px' }}>Double click on a row or select row and click "Select Sensitivity" button to see additional simulations for the selected sensitivity</div>
                    <ContextHelp helpId='SENSITIVITY' />
                    <div className='mr-3' />
                </Row>
                <Row>
                    <ResultTableView onSelect={handleSelect} calculate={calculate}
                        intlCulture='en-US' resultType='sensitivity' />
                </Row>
            </div>
        </>
    )
})

export default SensitivityResults
