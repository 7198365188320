import {RouteComponentProps} from '@reach/router'
import {useProjectStore} from '../../ProjectProvider'
import TimeSeriesViewer from '../../pages/Results/time-series/TimeSeriesViewer'
import * as React from 'react'

interface ResultTimeSeriesSectionProps extends RouteComponentProps {
    showBaseYearAsYearZero: boolean
    baseYear: number
}


const ResultTimeSeriesSection = ({showBaseYearAsYearZero, baseYear}: ResultTimeSeriesSectionProps) => {
    const project = useProjectStore().project!
    const details = project.results.details
    return (<TimeSeriesViewer model={details.timeSeriesViewerModel} showBaseYearAsYearZero={showBaseYearAsYearZero} baseYear={baseYear} />)
}

export default ResultTimeSeriesSection
