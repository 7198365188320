import {StorageResult} from '../../results-details'
import {IntlService} from 'Kendo-Intl-5'
import {useProjectStore} from '../../ProjectProvider'
import * as React from 'react'

interface ResultStorageTableProps {
    result: StorageResult
    intlCulture: string
    showBaseYearAsYearZero: boolean
}

const ResultStorageTable = ({result, intlCulture, showBaseYearAsYearZero}: ResultStorageTableProps) => {
    const intl = new IntlService(intlCulture)

    const currency = useProjectStore().project!.currencySymbol ?? "$"
    const items = result.items

    const project = useProjectStore().project!
    const hasConverter = result.hasConverter ?? false
    const converterLabel = project.bus === 'ac' ? 'DC/AC Converter' : 'DC/DC Converter'

    return (
        <table className='table table-bordered table-responsive-sm small scrollable'>
            <thead>
                <tr>
                    <th>Year</th>
                    <th>Maximum Usable Energy Capacity (MWh)</th>
                    <th>Minimum Usable Energy Capacity (MWh)</th>
                    <th>Storage Energy Input (MWh)</th>
                    <th>Storage Energy Output (MWh)</th>
                    {hasConverter && <th>Storage {converterLabel} Input Energy (MWh)</th>}
                    {hasConverter && <th>Storage {converterLabel} Output Energy (MWh)</th>}
                    {result.saleMarketNames && result.saleMarketNames.map(item =>
                        <th key={item}>Storage Energy Sold to {item} (MWh)</th>)}
                    {result.purchaseMarketNames && result.purchaseMarketNames.map(item =>
                        <th key={item}>Storage Energy Purchased from {item} (MWh)</th>)}
                    <th>Utilization Rate (%)</th>
                    <th>Cycle Cost ({currency}/MWh)</th>
                    <th>State of Charge Percentiles, 25 (%)</th>
                    <th>State of Charge Percentiles, 50 (%)</th>
                    <th>State of Charge Percentiles, 75 (%)</th>
                    <th>Storage Operating Expenses, Excl. Augmentation ({currency})</th>
                    <th>Augmentation Cost ({currency})</th>
                    <th>Augmentation (MWh)</th>
                    <th>Equivalent Cycles</th>
                </tr>
            </thead>
            <tbody>
                {items.map(x =>
                    <tr key={x.year}>
                        <td>{showBaseYearAsYearZero ? project.baseYear + x.year : x.year + 1}</td>
                        <td>{intl.formatNumber(x.maximum, 'n')}</td>
                        <td>{intl.formatNumber(x.minimum, 'n')}</td>
                        <td>{intl.formatNumber(x.energyInput, 'n')}</td>
                        <td>{intl.formatNumber(x.energyOutput, 'n')}</td>
                        {hasConverter && <td>{intl.formatNumber(x.converterEnergyInput!, 'n')}</td>}
                        {hasConverter && <td>{intl.formatNumber(x.converterEnergyOutput!, 'n')}</td>}
                        {x.energySold && x.energySold.map((item, i) =>
                            <td key={i}>{intl.formatNumber(item, 'n')}</td>)}
                        {x.energyPurchased && x.energyPurchased.map((item, i) =>
                            <td key={i}>{intl.formatNumber(item, 'n')}</td>)}
                        <td>{intl.formatNumber(x.utilizationRate, 'n0')}</td>
                        <td>{`${intl.formatNumber(x.cycleCost, 'n')} ${currency}`}</td>
                        <td>{intl.formatNumber(x.stateOfChargePercentile25, 'n0')}</td>
                        <td>{intl.formatNumber(x.stateOfChargePercentile50, 'n0')}</td>
                        <td>{intl.formatNumber(x.stateOfChargePercentile75, 'n0')}</td>
                        <td>{`${intl.formatNumber(x.operatingTotalCost + 0, 'n0')} ${currency}`}</td>
                        <td>{`${intl.formatNumber(x.augmentationTotalCost !== 0 ? x.augmentationTotalCost : 0, 'n0')} ${currency}`}</td>
                        <td>{intl.formatNumber(x.augmentation, 'n2')}</td>
                        <td>{intl.formatNumber(x.equivalentCycles, 'n0')}</td>
                    </tr>)}
            </tbody>
        </table>
    )
}

export default ResultStorageTable
