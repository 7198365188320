import {GoX} from 'react-icons/all'
import * as React from 'react'

interface ButtonDeleteProps {
    onClick?: () => void
    disabled?: boolean
}

const ButtonDelete = ({ onClick, disabled = false }: ButtonDeleteProps) =>
    <button className='btn btn-sm btn-outline-primary border-0 pillButton' disabled={disabled} onClick={onClick}>
        <GoX/>
    </button>

export default ButtonDelete
