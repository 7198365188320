import {BusKind, DateFormat, Project} from '../../model'
import {StorageResult} from '../../results-details'
import {IntlService} from 'Kendo-Intl-5'
import { useProjectStore } from '../../ProjectProvider'
import * as React from 'react'
import ContextHelp from '../../../helper/ContextHelp'

interface ResultStorageMetricTableProps {
    result: StorageResult
    model: Project
    intlCulture: string
    dateFormat: number
}

interface RowProps {
    label: string
    value: string
    isHelp?:boolean
}

const Row = ({label, value, isHelp}: RowProps) => {
    return (
        <tr className='font-size-14'>
            <td className={isHelp ? ' d-flex align-items-center' : ''}>{label} {isHelp && <span className='ml-1'><ContextHelp helpId='CBID' /></span>}</td>
            <td>{value}</td>
        </tr>)
}

function getCouplingLabel(bus: BusKind): string {
    switch (bus) {
        case 'ac': {return 'AC'}
        case 'dc': {return 'DC'}
    }
}

const ResultStorageMetricTable = ({ result, model, intlCulture, dateFormat }: ResultStorageMetricTableProps) => {
    const intl = new IntlService(intlCulture)
    const dateFormatArray = Object.values(DateFormat)
    const currency = useProjectStore().project!.currencySymbol ?? "$"
    const currencyByPowerUnit = `${currency}/MWh`

    return (<>
                <div className="d-flex align-items-center justify-content-between">
        <div className='font-size-16 font-weight-bold'>Project Metrics</div>
        <div className='ml-1 mb-1'>
                        <ContextHelp helpId='STORAGEMETRICS' />
                        </div>
                    </div>
        <table className='table table-bordered table-responsive-sm small'>
            <tbody>
                <Row label='Notice to Proceed' value={intl.formatDate(model.noticeToProceed, dateFormatArray[dateFormat])}/>
                <Row label='Expected Commercial Operational Date' value={intl.formatDate(model.expectedDate, dateFormatArray[dateFormat])}/>
                <Row label='Nameplate Capacity' value={`${intl.formatNumber(result.nameplateCapacity, 'n')} MWh`}/>
                {result.nameplatePower &&
                <Row label='Nameplate Power' value={`${intl.formatNumber(result.nameplatePower, 'n')} MW`}/>}
                <Row label='Coupling' value={getCouplingLabel(model.bus)}/>
                <Row label='Capital Expenses' value={`${intl.formatNumber(result.capitalCost, 'n0')} ${currency}`}/>
                <Row label='Augmentation Limit' value={`${intl.formatNumber(result.augmentationLimit, 'n0')}%`}/>
                <Row label='LCOS' value={`${intl.formatNumber(result.levelizedCostOfStorage + 0, 'n3')} ${currencyByPowerUnit}`}/>
                <Row label='Capacity bid' value={`${intl.formatNumber(result.capacityBid, 'n1')} MW`} isHelp={true} />
                <Row label='Annual Effective Full Cycles' value={`${intl.formatNumber(result.annualEffectiveFullCycles, 'n1')}`} />
                <Row label='Daily Effective Full Cycles' value={`${intl.formatNumber(result.dailyEffectiveFullCycles, 'n1')}`} />
            </tbody>
        </table>
    </>)
}

export default ResultStorageMetricTable
