import * as React from 'react'
import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import Modal from 'react-bootstrap/Modal'
import HourlyMaxSensitivity from './HourlyMaxSensitivity'
import Button from 'react-bootstrap/Button'
import SensitivityVarIcon from '../../EnergyMarket/SensitivityVarIcon'
import { SensitivityVar } from '../../../model/SensitivityVar'
import NumberInput from '../../../../helper/NumberInput'
import { Validation } from '../../../../helper/validation'


interface HourlyMaxViewProps {
    model: SensitivityVar
    id: string
    useCommaAsDecimalPoint: boolean
    allocationType: AllocationType
}


const HourlyMaxView = observer(({ id, model, useCommaAsDecimalPoint, allocationType }: HourlyMaxViewProps) => {
    const [show, setShow] = useState(false)
    const handleOpen = () => setShow(true)
    const handleClose = () => setShow(false)

    let label = ''
    switch (allocationType) {
        case 'DAM':
            label = 'Max capacity bid into DAM (%)';
            break;
        case 'Storage':
            label = 'Maximum Storage Commitment (%)';
            break;
        case 'Wind':
            label = 'Wind Turbine Allocation (%)';
            break;
        default:
            label = 'PV Allocation (%)';
            break;
    }

    return (<>
        <div className='form-group row align-items-center'>
            <div className='col-5'>{label}</div>
            <div className='col-3'>
                <NumberInput useCommaAsDecimalPoint={useCommaAsDecimalPoint} value={model.values[0].value}
                    onChange={x => model.values[0].setValue(x)} validator={Validation.percent} />
            </div>
            <div className='col-2'>
                <SensitivityVarIcon count={model.values.length} onClick={handleOpen} />
            </div>
        </div>
        <Modal id={id} show={show} onHide={handleClose} centered size='lg'>
            <Modal.Header>
                <Modal.Title>{`${allocationType} Hourly Max Sensitivity`}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='k-overflow-x-auto'>
                <HourlyMaxSensitivity model={model} allocationType={allocationType} />
            </Modal.Body>
            <Modal.Footer>
                <Button className='pillButton' onClick={handleClose}>Save &amp; Close</Button>
            </Modal.Footer>
        </Modal>
    </>)
})


export default HourlyMaxView

export type AllocationType = 'Storage' | 'Wind' | 'PV' | 'DAM'
