import {RouteComponentProps} from '@reach/router'
import {Project} from '../../model'
import {RevenueResult} from '../../results-details'
import {observer} from 'mobx-react-lite'
import {Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartSeries, ChartSeriesItem, ChartTitle, ChartTooltip, ChartValueAxis, ChartValueAxisItem} from '@progress/kendo-react-charts'
import * as React from 'react'
import { useState } from 'react'
import {Dropdown} from 'react-bootstrap'
import ResultRevenueTable from './ResultRevenueTable'
import ContextHelp from '../../../helper/ContextHelp'
import { useProjectStore } from '../../ProjectProvider'

interface ResultRevenueProps extends RouteComponentProps {
    result: RevenueResult
    model: Project
    intlCulture: string
    showBaseYearAsYearZero: boolean
}

type GroupingKind = 'market' | 'technology'

const ResultRevenue = observer(({result, model, intlCulture, showBaseYearAsYearZero}: ResultRevenueProps) => {
    const [revenueKind, setRevenueKind] = useState<GroupingKind>('market')
    return (<>
        <div className='row m-2'>
            <div className='col-12'>
                <div className='d-flex flex-row align-items-center justify-content-start'>
                    <div className='mr-2 ml-4'>Visualize Graphs by</div>
                    <GroupingSelector value={revenueKind} onValueChange={setRevenueKind} id='revenueGraph'/>
                </div>
            </div>
            {revenueKind === 'market' && <>
                <div className='col-6 pt-4'>
                    <RevenueChartByMarket result={result} showBaseYearAsYearZero={showBaseYearAsYearZero} baseYear={model.baseYear} />
                </div>
                <div className='col-6 pt-4'>
                    <EnergyChartByMarket result={result} showBaseYearAsYearZero={showBaseYearAsYearZero} baseYear={model.baseYear} />
                </div>
            </>}
            {revenueKind === 'technology' && <>
            <div className='col-6 pt-4'>
                    <RevenueChartByTechnology result={result} model={model} showBaseYearAsYearZero={showBaseYearAsYearZero} />
            </div>
            <div className='col-6 pt-4'>
                    <EnergyChartByTechnology result={result} model={model} showBaseYearAsYearZero={showBaseYearAsYearZero} />
            </div>
            </>}
            <div className='col-12'>
                <div className='float-right'>
                    <ContextHelp helpId='REVENUESERIES'/>
                </div>
            </div>
            <div className='col-12 pt-4 overflow-scroll'>
                <ResultRevenueTable columns={result.columns} revenueItems={result.items} intlCulture={intlCulture} showBaseYearAsYearZero={showBaseYearAsYearZero} />
            </div>
        </div>
    </>)
})

export default ResultRevenue


interface GroupingSelectorProps {
    id: string
    value: GroupingKind
    onValueChange: (x: GroupingKind) => void
}

function getGroupingLabel(x: GroupingKind) {
    switch (x) {
        case 'market': {return 'Market'}
        case 'technology': {return 'Technology'}
    }
}

const GroupingSelector = ({id, value, onValueChange}: GroupingSelectorProps) => {
    return (
        <Dropdown drop='down'>
            <Dropdown.Toggle variant='outline-primary' id={id}>
                {getGroupingLabel(value)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => onValueChange('market')}>{getGroupingLabel('market')}</Dropdown.Item>
                <Dropdown.Item onClick={() => onValueChange('technology')}>{getGroupingLabel('technology')}</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}


interface SeriesItem {
    name: string
    data: { year: number, value: number }[]
}


interface RevenueChartByMarketProps {
    result: RevenueResult
    showBaseYearAsYearZero: boolean
    baseYear: number
}

type MarketData = { name: string, data: number[] }

function getRevenueMarketData({columns, items}: RevenueResult): MarketData[] {
    return [
        ...columns.energyMarkets.map((name, i) => ({
            name,
            data: items.map(x => x.energyMarkets[i].revenue),
        })),
        ...columns.capacityMarkets.map((name, i) => ({
            name,
            data: items.map(x => x.capacityMarkets[i].revenue),
        })),
        ...columns.contracts.map((name, i) => ({
            name,
            data: items.map(x => x.contracts[i].revenue),
        })),
    ]
}

function getRevenueSeries({name, data}: MarketData, showBaseYearAsYearZero: boolean, baseYear: number): SeriesItem {
    return {
        name: `${name} Revenue, $`,
        data: data.map((value, i) => ({ year: showBaseYearAsYearZero ? baseYear + i : i + 1, value})),
    }
}

function getRevenueChangeMarketData({columns, items}: RevenueResult): MarketData[] {
    return [
        ...columns.energyMarkets.map((name, i) => ({
            name,
            data: items.map(x => x.energyMarkets[i].revenueChange),
        })),
        ...columns.capacityMarkets.map((name, i) => ({
            name,
            data: items.map(x => x.capacityMarkets[i].revenueChange),
        })),
        ...columns.contracts.map((name, i) => ({
            name,
            data: items.map(x => x.contracts[i].revenueChange),
        })),
    ]
}

function getRevenueChangeSeries({ name, data }: MarketData, showBaseYearAsYearZero: boolean, baseYear: number): SeriesItem {
    return {
        name: `${name} Change, %`,
        data: data.map((value, i) => ({ year: showBaseYearAsYearZero ? baseYear + i : i + 1, value: value * 100})),
    }
}


const RevenueChartByMarket = ({ result, showBaseYearAsYearZero, baseYear }: RevenueChartByMarketProps) => {
    const currency = useProjectStore().project!.currencySymbol ?? "$"

    const [count, setCount] = useState(1)
    const handleChartRefresh = (chartOptions: any, themeOptions: any, chartInstance: any) => {
        if (count == 1) {
            chartInstance.setOptions(chartOptions, themeOptions);
        }
        setCount(2)
    };
    const revenueData = getRevenueMarketData(result)
    var revenueSeries = revenueData.map(item => getRevenueSeries(item, showBaseYearAsYearZero, baseYear))

    const revenueChangeData = getRevenueChangeMarketData(result)
    var revenueChangeSeries = revenueChangeData.map(item => getRevenueChangeSeries(item, showBaseYearAsYearZero, baseYear))

    return (
        <Chart >
            <ChartTitle text="Revenue by Market" />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem labels={{visible: true, rotation: 'auto'}} title={{text: 'Year'}}
                                       axisCrossingValue={[0, result.items.length]}/>
            </ChartCategoryAxis>
            <ChartValueAxis>
                <ChartValueAxisItem title={{text: currency}} name='absolute' min={0} labels={{format: '{0:n}', rotation: 'auto'}}/>
                <ChartValueAxisItem title={{text: '%'}} name='relative'/>
            </ChartValueAxis>
            <ChartSeries>
                {revenueSeries.map(s =>
                    <ChartSeriesItem key={s.name.replace("$", currency)} type='column' data={s.data} categoryField='year' field='value' axis='absolute' name={s.name.replace("$", currency)}/>)}
                {revenueChangeSeries.map(s =>
                    <ChartSeriesItem key={s.name.replace("$", currency)} type='line' data={s.data} categoryField='year' field='value' axis='relative' name={s.name.replace("$", currency)}/>)}
            </ChartSeries>
            <ChartLegend visible={true} position='bottom'/>
            <ChartTooltip format='{0:n}'/>
        </Chart>
    )
}


interface EnergyChartByMarketProps {
    result: RevenueResult
    showBaseYearAsYearZero: boolean
    baseYear: number
}

const EnergyChartByMarket = ({ result, showBaseYearAsYearZero, baseYear }: EnergyChartByMarketProps) => {
    const currency = useProjectStore().project!.currencySymbol ?? "$"
  
    const getEnergyMarketData = ({ columns, items }: RevenueResult): MarketData[] => {
        return [
            ...columns.energyMarkets.map((name, i) => ({
                name,
                data: items.map(x => x.energyMarkets[i].energy),
            })),
            ...columns.capacityMarkets.map((name, i) => ({
                name,
                data: items.map(x => x.capacityMarkets[i].energy),
            })),
            ...columns.contracts.map((name, i) => ({
                name,
                data: items.map(x => x.contracts[i].energy),
            })),
        ]
    }

    const getEnergySeries = ({ name, data }: MarketData): SeriesItem => {
        return {
            name: `${name} Exported, MWh`,
            data: data.map((value, i) => ({ year: showBaseYearAsYearZero ? baseYear + i : i + 1, value })),
        }
    }
    const series = getEnergyMarketData(result)
    const energySeries = series.map(item => getEnergySeries(item))

    return (
        <Chart >
            <ChartTitle text="Energy Exported to Grid by Market" />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem labels={{visible: true, rotation: 'auto'}} title={{text: 'Year'}}/>
            </ChartCategoryAxis>
            <ChartValueAxis>
                <ChartValueAxisItem title={{text: 'MWh'}} min={0}/>
            </ChartValueAxis>
            <ChartSeries>
                {energySeries.map(s =>
                    <ChartSeriesItem key={s.name.replace("$", currency)} type='column' data={s.data} categoryField='year' field='value' name={s.name.replace("$", currency)}/>)}
            </ChartSeries>
            <ChartLegend visible={true} position='bottom'/>
            <ChartTooltip format='{0:n}'/>
        </Chart>
    )
}


interface RevenueChartByTechnologyProps {
    result: RevenueResult,
    model: Project
    showBaseYearAsYearZero: boolean
}

const RevenueChartByTechnology = ({ result, model, showBaseYearAsYearZero }: RevenueChartByTechnologyProps) => {
    const [count, setCount] = useState(1)
    const handleChartRefresh = (chartOptions: any, themeOptions: any, chartInstance: any) => {
        if (count == 1) {
            chartInstance.setOptions(chartOptions, themeOptions);
        }
        setCount(2)
    };
    const solarData = result.items.map(item => ({
        year: showBaseYearAsYearZero ? model.baseYear + item.year : item.year + 1,
        value: item.energyMarkets!.length>0?item.energyMarkets.map(x => x.solarRevenue).reduce((a, b) => a + b):0,
    }))

    const storageData = result.items.map(item => ({
        year: showBaseYearAsYearZero ? model.baseYear + item.year : item.year + 1,
        value: item.energyMarkets!.length>0?item.energyMarkets.map(x => x.storageRevenue).reduce((a, b) => a + b):0,
    }))

    const windData = result.items.map(item => ({
        year: showBaseYearAsYearZero ? model.baseYear + item.year : item.year + 1,
        value:item.energyMarkets!.length>0? item.energyMarkets.map(x => x.windRevenue).reduce((a, b) => a + b):0,
    }))
    const currency = useProjectStore().project!.currencySymbol ?? "$"

    return (
        <Chart >
            <ChartTitle text="Revenue by Technology" />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem labels={{visible: true, rotation: 'auto'}} title={{text: 'Year'}}/>
            </ChartCategoryAxis>
            <ChartValueAxis>
                <ChartValueAxisItem title={{text: currency}} min={0}/>
            </ChartValueAxis>
            <ChartSeries>
                {model.useSolar && <ChartSeriesItem type='column' data={solarData} categoryField='year' field='value'
                    name={`Solar Revenue, ${currency}`}/>}
               {model.useStorage && <ChartSeriesItem type='column' data={storageData} categoryField='year' field='value'
                    name={`Storage Revenue, {currency}`}/>}
                {model.useWind && <ChartSeriesItem type='column' data={windData} categoryField='year' field='value'
                    name={`Wind Revenue, ${currency}`} />}
            </ChartSeries>
            <ChartLegend visible={true} position='bottom'/>
            <ChartTooltip format='{0:n}'/>
        </Chart>
    )
}


interface EnergyChartByTechnologyProps {
    result: RevenueResult,
    model: Project
    showBaseYearAsYearZero: boolean
}

const EnergyChartByTechnology = ({ result, model, showBaseYearAsYearZero }: EnergyChartByTechnologyProps) => {
    const [count, setCount] = useState(1)
    const handleChartRefresh = (chartOptions: any, themeOptions: any, chartInstance: any) => {
        if (count == 1) {
            chartInstance.setOptions(chartOptions, themeOptions);
        }
        setCount(2)
    };
    const solarData = result.items.map(item => ({
        year: showBaseYearAsYearZero ? model.baseYear + item.year : item.year + 1,
        value: item.energyMarkets!.length>0?item.energyMarkets.map(x => x.solarEnergy).reduce((a, b) => a + b):0,
    }))

    const storageData = result.items.map(item => ({
        year: showBaseYearAsYearZero ? model.baseYear + item.year : item.year + 1,
        value: item.energyMarkets!.length>0?item.energyMarkets.map(x => x.storageEnergy).reduce((a, b) => a + b):0,
    }))

    const windData = result.items.map(item => ({
        year: showBaseYearAsYearZero ? model.baseYear + item.year : item.year + 1,
        value: item.energyMarkets!.length>0?item.energyMarkets.map(x => x.windEnergy).reduce((a, b) => a + b):0,
    }))

    return (
        <Chart >
            <ChartTitle text="Energy Exported to Grid by Technology" />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem labels={{visible: true, rotation: 'auto'}} title={{text: 'Year'}}/>
            </ChartCategoryAxis>
            <ChartValueAxis>
                <ChartValueAxisItem title={{text: 'MWh'}} min={0}/>
            </ChartValueAxis>
            <ChartSeries>
                {model.useSolar && <ChartSeriesItem type='column' data={solarData} categoryField='year' field='value'
                                 name='Solar Energy Exported, MWh'/>}
                {model.useStorage && <ChartSeriesItem type='column' data={storageData} categoryField='year' field='value'
                                 name='Storage Energy Exported, MWh'/>}
                {model.useWind && <ChartSeriesItem type='column' data={windData} categoryField='year' field='value'
                    name='Wind Energy Exported, MWh' />}
            </ChartSeries>
            <ChartLegend visible={true} position='bottom'/>
            <ChartTooltip format='{0:n}'/>
        </Chart>
    )
}
