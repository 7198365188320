import {HLineSvg} from './Shapes'
import * as React from 'react'

interface SchematicIconProps {
    src: string;
    label?: string;
}

const SchematicIcon = ({src, label}: SchematicIconProps) => {
    return (
        <div className='flex-column schematic-icon-container'>
            <div className='flex-row'>
                <img className='schematic-icon' src={src} alt={`${src}-icon`}/>
                <HLineSvg/>
            </div>
            <div className='schematic-label'>{label}</div>
        </div>
    )
}

export default SchematicIcon
