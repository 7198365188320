import { observer } from 'mobx-react-lite'
import { Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartSeries, ChartSeriesItem, ChartTitle, ChartTooltip, ChartValueAxis, ChartValueAxisItem } from '@progress/kendo-react-charts'
import * as React from 'react'
import ContextHelp from '../../../helper/ContextHelp'
import { StorageResult } from '../../results-details'

interface AugmentationCapacityChartProps {
    result: StorageResult
    allowAugmentation: boolean
    showBaseYearAsYearZero: boolean
    baseYear: number
}

const AugmentationCapacityChart = observer(({ result, allowAugmentation, showBaseYearAsYearZero, baseYear }: AugmentationCapacityChartProps) => {
    const items = result.items.map(item => ({ ...item, year: showBaseYearAsYearZero ? item.year + baseYear : item.year + 1 }))

    return (
        <div>
            <div className='mr-5' style={{ float: 'right', zIndex: 99, position: 'relative' }}><ContextHelp helpId='AUGCAPACITY' /></div>
            <Chart>
                <div>
                    <ChartTitle text={(allowAugmentation ? 'Augmentation Capacity' : 'Capacity Degradation')} />
                </div>
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem labels={{ visible: true, rotation: 'auto' }} title={{ text: 'Year' }} />
                </ChartCategoryAxis>
                <ChartValueAxis>
                    <ChartValueAxisItem title={{ text: 'MWh' }} min={0} labels={{ format: '{0:n0}', rotation: 'auto' }} />
                </ChartValueAxis>
                <ChartSeries>
                    <ChartSeriesItem type='column' data={items} categoryField='year' field='maximum' stack='begin'
                        name='Maximum Usable Energy Capacity (MWh)' color='#0000FF' />
                    <ChartSeriesItem type='column' data={items} categoryField='year' field='minimum' stack='end'
                        name='Minimum Usable Energy Capacity (MWh)' color='#FF8C00' />
                    {allowAugmentation && <ChartSeriesItem type='column' data={items} categoryField='year' field='augmentation' stack='end'
                        name='Augmentation Capacity (MWh)' color='#32CD32' />}
                </ChartSeries>
                <ChartLegend position='bottom' align='center' orientation='vertical' />
                <ChartTooltip format='{0:n4}' />
            </Chart>
        </div>
    )
})

export default AugmentationCapacityChart
