import SchematicIcon from './SchematicIcon'
import Converter from './Converter'
import batteryLevel from 'assets/images/battery-level.svg'
import * as React from 'react'

interface StorageTechnologyProps {
    hasConverter: boolean;
    bus: string;
}

const StorageTechnology = ({hasConverter, bus}: StorageTechnologyProps) => {
    return (
        <div className='d-flex flex-row align-items-center justify-content-center'>
            <SchematicIcon src={batteryLevel}/>
            {hasConverter && <Converter bus={bus}/>}
        </div>
    )
}

export default StorageTechnology
