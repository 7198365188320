import {Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartCategoryAxisTitle, ChartLegend, ChartSeries, ChartSeriesItem, ChartTooltip, ChartValueAxis, ChartValueAxisItem, ChartSeriesItemTooltip, TooltipContext} from '@progress/kendo-react-charts'
import ChartValueAxisTitle from '@progress/kendo-react-charts/dist/es/components/value-axis-item/Title'
import {observer} from 'mobx-react-lite'
import {useProjectStore} from '../../ProjectProvider'
import {ResultSummaryChart, ResultSummaryChartDay, ResultSummaryChartSeries} from '../../results-details'
import {Dropdown} from 'react-bootstrap'
import * as React from 'react'
import { useEffect, useState } from 'react'
import {DatePicker} from '@progress/kendo-react-dateinputs'
import {v4} from 'uuid'
import { IntlService } from 'Kendo-Intl-5'


export type DaySelectionKind = 'average' | 'highest' | 'lowest' | 'index'

export type DaySelection
    = { kind: 'average' }
    | { kind: 'highest' }
    | { kind: 'lowest' }
    | { kind: 'index', index: number }

const getSelectionLabel = (x: DaySelectionKind) => {
    switch (x) {
        case 'average':
            return 'Average Day'
        case 'highest':
            return 'Highest Revenue Day'
        case 'lowest':
            return 'Lowest Revenue Day'
        case 'index':
            return 'Specific Day'
    }
}

const getDataForSelection = (chart: ResultSummaryChart, selection: DaySelection): ResultSummaryChartDay => {
    switch (selection.kind) {
        case 'average':
            return chart.average
        case 'highest':
            return chart.days[chart.highestRevenueDayIndex]
        case 'lowest':
            return chart.days[chart.lowestRevenueDayIndex]
        case 'index':
            return chart.days[selection.index]
    }
}

const getSeries = (series: ResultSummaryChartSeries[], index: number, data: number[]): ChartSeriesInfo => {
    return {
        name: series[index].name,
        color: series[index].color,
        data: data,
    }
}

interface SummaryChartByHourProps {
    selection: DaySelection
    intlCulture: string
    year: number
}

const SummaryChartByHour = observer(({ selection, intlCulture, year = 0 }: SummaryChartByHourProps) => {
    const project = useProjectStore().project!
    const details = project.results.details
    const chart = details.details.summaryChartList !== undefined && details.details.summaryChartList.length > 0 ? details.details.summaryChartList[year] : details.details.summaryChart

    const data = getDataForSelection(chart, selection)

    const prices = data.prices.map((x, index) => getSeries(chart.prices, index, x))
    const revenues = data.revenues.map((x, index) => getSeries(chart.revenues, index, x))

    return (<ChartView prices={prices} revenues={revenues} intlCulture={intlCulture} />)
})


interface ChartSeriesInfo {
    name: string
    color: string
    data: number[]
}

interface ChartViewProps {
    prices: ChartSeriesInfo[]
    revenues: ChartSeriesInfo[]
    intlCulture: string
}

const HOURS = Array(24).map((x, index) => index)


const ChartView = ({ prices, revenues, intlCulture }: ChartViewProps) => {
    const currency = useProjectStore().project!.currencySymbol ?? "$"
    const intl = new IntlService(intlCulture)

    const tooltipRender = ({ point }: TooltipContext, units: string) => (`${intl.formatNumber(point.value, 'n2') } ${units}`);

    const priceSeries = prices.map((x, index) =>
        <ChartSeriesItem key={v4()} name={x.name + ` (${currency}/MWh)`} color={x.color} data={x.data}
            axis='price' type='line' stack={false}>
            <ChartSeriesItemTooltip render={(point) =>  tooltipRender(point, `(${currency}/MWh)`) } />
        </ChartSeriesItem>)
    const revenueSeries = revenues.map((x, index) =>
        <ChartSeriesItem key={v4()} name={x.name + ` (${currency})`} color={x.color} data={x.data}
            axis='revenue' type='column' stack={true}>
            <ChartSeriesItemTooltip render={(point) => tooltipRender(point, `(${currency})`)} />
        </ChartSeriesItem>)
    const labelContent = (e: any): string => {
        return intl.formatNumber(e.value, 'n2')
    }
    return (
        <Chart>
            <ChartTooltip />
            <ChartLegend position='right' />
            <ChartValueAxis>
                <ChartValueAxisItem name='price' labels={{ content: labelContent }}>
                    <ChartValueAxisTitle text={`Energy Price (${currency}/MWh)`} />
                </ChartValueAxisItem>
                <ChartValueAxisItem name='revenue' labels={{ content: labelContent }}>
                    <ChartValueAxisTitle text={`Revenue (${currency})`} />
                </ChartValueAxisItem>
            </ChartValueAxis>
            <ChartCategoryAxis>
                <ChartCategoryAxisItem categories={HOURS} axisCrossingValue={[24, 0]} labels={{ visible: true }}>
                    <ChartCategoryAxisTitle text='Hour of the Day' />
                </ChartCategoryAxisItem>
            </ChartCategoryAxis>
            <ChartSeries>{priceSeries.concat(...revenueSeries)}</ChartSeries>
        </Chart>
    )
}

export default SummaryChartByHour


interface DaySelectorProps {
    value: DaySelection
    onValueChange: (x: DaySelection) => void
    highest: number
    lowest: number
}


const getIndexByDate = (date: Date): number => {
    const start = new Date(date.getFullYear(), 0, 1)
    const ms = date.getTime() - start.getTime()
    const index = ms / (24 * 60 * 60 * 1000)
    return Math.trunc(index)
}

const allSelectionKinds: DaySelectionKind[] = ['highest', 'lowest', 'average', 'index']

const dateFormatter = new Intl.DateTimeFormat('en', {month: 'short', day: 'numeric'/*, year: 'numeric'*/ })


export const DaySelector = ({value, onValueChange, highest, lowest}: DaySelectorProps) => {
    const project = useProjectStore().project!

    const [index, setIndex] = useState(0)
    useEffect(() => {
        if (value.kind === 'index') { setIndex(value.index) }
    }, [value])

    const handleChangeKind = (x: DaySelectionKind) => {
        if (x === 'index') onValueChange({kind: x, index: index})
        else onValueChange({kind: x})
    }

    const handleChangeDay = (x: Date | null) => {
        if (!x) return
        const i = getIndexByDate(x)
        onValueChange({kind: 'index', index: i})
    }

    const getDateByIndex = (index: number): Date => {
        const start = new Date(project.baseYear, 0, 1)
        const ms = index * 24 * 60 * 60 * 1000
        return new Date(start.getTime() + ms)
    }

    return (<>
        <span className='mr-3'>Key Days</span>
        <Dropdown className='mr-3'>
            <Dropdown.Toggle variant='outline-primary' id='dropdown-basic'>
                {getSelectionLabel(value.kind)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {allSelectionKinds.map(x =>
                    <Dropdown.Item key={x} onClick={() => handleChangeKind(x)}>
                        {getSelectionLabel(x)}
                    </Dropdown.Item>)}
            </Dropdown.Menu>
        </Dropdown>
        {value.kind === 'highest' && <span>{dateFormatter.format(getDateByIndex(highest))}</span>}
        {value.kind === 'lowest' && <span>{dateFormatter.format(getDateByIndex(lowest))}</span>}
        {value.kind === 'index' &&
            <DatePicker format='MMM-dd'
                value={getDateByIndex(index)}
                onChange={ev => handleChangeDay(ev.value)}/>}
    </>)
}
