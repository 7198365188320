import {Dropdown} from 'react-bootstrap'
import * as React from 'react'

interface DropDownSelectorProps {
    max: number
    value: number
    onValueChange: (x: number) => void
    hidden?: boolean
    showBaseYearAsYearZero: boolean
    baseYear: number
    isYearItem?: boolean
}

const DropDownSelector = ({ max, value, onValueChange, hidden, showBaseYearAsYearZero, baseYear, isYearItem = true }: DropDownSelectorProps) => {
    const items = new Array(max).fill(null).map((_, index) => index)

    return (
        <div hidden={max < 2 || hidden}>
            <Dropdown>
                <Dropdown.Toggle className='pillButton' variant='outline-primary' size='sm'>
                    {showBaseYearAsYearZero && isYearItem ? baseYear + value : value + 1}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {items.map(item =>
                        <Dropdown.Item key={item} onClick={() => onValueChange(item)}>{showBaseYearAsYearZero && isYearItem ? baseYear + item : item + 1}</Dropdown.Item>)}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default DropDownSelector
