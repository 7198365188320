import {DateFormat, Project} from '../../model'
import {SolarPvResult} from '../../results-details'
import {IntlService} from 'Kendo-Intl-5'
import { useProjectStore } from '../../ProjectProvider'
import * as React from 'react'
import ContextHelp from '../../../helper/ContextHelp'

interface ResultSolarEnergyMetricTableProps {
    result: SolarPvResult
    model: Project
    intlCulture: string
    dateFormat: number
}

interface RowProps {
    label: string
    value: string
}

const Row = ({label, value}: RowProps) => {
    return (
        <tr className='font-size-14'>
            <td>{label}</td>
            <td>{value}</td>
        </tr>)
}

const ResultSolarEnergyMetricTable = ({ result, model, intlCulture, dateFormat }: ResultSolarEnergyMetricTableProps) => {
    const intl = new IntlService(intlCulture)
    const dateFormatArray = Object.values(DateFormat)

    const currency = useProjectStore().project!.currencySymbol ?? "$"

    return (<>
        <div className="d-flex align-items-center justify-content-between">
        <div className='font-size-16 font-weight-bold'>Project Metrics</div>
            <div className='ml-1 mb-1'>
                <ContextHelp helpId='SOLARMETRICS' />
            </div>
        </div>
        <table className='table table-bordered table-responsive-sm small'>
            <tbody>
                <Row label='Notice to Proceed' value={intl.formatDate(model.noticeToProceed, dateFormatArray[dateFormat])}/>
                <Row label='Expected Commercial Operational Date' value={intl.formatDate(model.expectedDate, dateFormatArray[dateFormat])}/>
                <Row label='Generator Nameplate' value={`${intl.formatNumber(result.nameplateCapacity, 'n')} MW`}/>
                <Row label='Capital Expenses' value={(currency + intl.formatNumber(result.capitalExpenses, 'n'))} />
                <Row label='Useful Life' value={`${intl.formatNumber(model.lifetime, 'n')} yrs`}/>
                <Row label='Derate Factor' value={`${intl.formatNumber(result.derateFactor, 'n0')}%`}/>
            </tbody>
        </table>
    </>)
}

export default ResultSolarEnergyMetricTable
