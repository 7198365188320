import {observer} from 'mobx-react-lite'
import Form from 'react-bootstrap/Form'
import * as React from 'react'
import { useState } from 'react'
import ToolTipInfo from '../../../helper/ToolTipInfo'
import RateScheduleEditor from './RateScheduleEditor'
import { GoX } from 'react-icons/go'
import SuccessImportModal from '../equipment/shared/ImportSuccessModal'
import { Contract } from '../../model/Contract'
import { useProjectStore } from '../../ProjectProvider'
import ContextHelp from '../../../helper/ContextHelp'
import TimeSeriesImport from '../../../helper/TimeSeriesImport'
import MultiYearView from '../SharedComponents/MultiYearView'
import TimeSeriesView from '../../../helper/TimeSeriesView'


interface ContractPriceScheduleProps {
    model: Contract
    onDelete: () => void
}

const ContractPriceSchedule = observer(({model, onDelete}: ContractPriceScheduleProps) => {
    const store = useProjectStore()
    const intlCulture = store.project!.useCommaAsDecimalPoint ? 'es' : 'en'
    const handleImport = async (data: number[], year: number) => {
        model.setScheduleData(data, year)
        await store.saveProject()
    }

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
    const closeSuccessModal = () => {
        setShowSuccessModal(false)
    }
    const openSuccessModal = () => {
        setShowSuccessModal(true)
    }

    return (
        <div className='border rounded p-2 mb-2'>
        <div className='row'>
            <div className='col-12'>
                <div className='d-flex mb-2 justify-content-between'>
                    <h5>Energy Price Schedule</h5>
                    <ContextHelp helpId='PRICESCHEDULE' />
                        <button className='btn btn-outline-danger pillButton' onClick={() => onDelete()}><GoX /></button>
                </div>
            </div>

            <div className='col-md-12 col-xl-3'>
                <div className='row'>
                    <div className='col-md-3 col-xl-12 mb-4'>
                        <Form.Check type='radio' id={`energy-price-schedule-${model.id}`} custom
                                    checked={model.energyPriceKind === 'schedule'}
                                    onChange={ev => ev.target.checked && model.setEnergyPriceKind('schedule')}
                                    label='Define 12×24 energy price'/>
                        <Form.Check type='radio' id={`energy-price-import-${model.id}`} custom
                                    checked={model.energyPriceKind === 'import'}
                                    onChange={ev => ev.target.checked && model.setEnergyPriceKind('import')}
                                    label='Import annual price profile'/>
                        <div className='ml-4 mt-2'>
                                <div className='d-flex mb-2'>
                                    <TimeSeriesImport disabled={model.energyPriceKind === 'schedule'} onSelect={handleImport} showSuccessModal={openSuccessModal} />
                                <div className='pl-1 mt-2'>
                                    <ContextHelp helpId='ANNUALPRICEPROFILE'/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-9 col-xl-12'>
                        <MultiYearView model={model.energyPriceEscalator}
                                       label='Price Escalator (%/yr)'
                                       id={`contractPriceEscalator-${model.id}`}/>
                    </div>
                </div>
                <div className='d-flex mt-4'>
                        <Form.Check type='checkbox' id={`tod-energy-market-alternative`} custom
                            checked={model.competeWithWholesaleMarket && model.obligationKind === 'none'}
                onChange={ev => model.setCompeteWithWholesaleMarket(ev.target.checked)}
                label='Time of Delivery may be elected if more advantageous than the last market'
                disabled={model.obligationKind !== 'none'} />
            {model.obligationKind !== 'none' && <ToolTipInfo label={'TOD can only compete with last market if Percent contracted capacity is selected'} />}  
                </div>
                </div>

            <div className='col-md-12 col-xl-9'>
                <div className='border rounded p-2 mb-2'>
                    {model.energyPriceKind === 'schedule' &&
                        <RateScheduleEditor model={model.energyPriceSchedule} isPriceSchedule={true} />}
                    {model.energyPriceKind === 'import' &&
                        <TimeSeriesView model={model.energyPriceTimeSeries} intlCulture={intlCulture} />}
                </div>
            </div>
            </div>
            <SuccessImportModal show={showSuccessModal} onConfirm={closeSuccessModal} />
        </div>
    )
})


export default ContractPriceSchedule
