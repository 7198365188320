import {RouteComponentProps} from '@reach/router'
import ProjectCashFlowChart from './ProjectCashFlowChart'
import {observer} from 'mobx-react-lite'
import * as React from 'react'
import {Spinner} from 'react-bootstrap'
import {IntlService} from 'Kendo-Intl-5'
import { useProjectStore } from '../../ProjectProvider'
import ContextHelp from '../../../helper/ContextHelp'
import { ResultCashFlowTableItem } from '../../results-details'


interface ResultCashFlowProps extends RouteComponentProps {
    showBaseYearAsYearZero: boolean
}


const ResultCashFlow = observer(({showBaseYearAsYearZero }: ResultCashFlowProps) => {
    const project = useProjectStore().project!
    const details = useProjectStore().project!.results.details
    const intlCulture = project.useCommaAsDecimalPoint ? 'es' : 'en'
    return (
        <div className='m-2'>
            <ProjectCashFlowChart
                chartData={details.details.cashflow}
                chartDataCumulative={details.details.cashflowCumulative}
                noOfYears={project.lifetime}
                showBaseYearAsYearZero={showBaseYearAsYearZero}
                baseYear={project.baseYear}/>
            <hr/>
            <div className='col-12'>
                <div className='float-right'>
                    <ContextHelp helpId='CASHFLOW'/>
                </div>
            </div>
            <CashFlowTable names={details.details.cashflowTableRevenues} items={details.details.cashflowTable} expectedDate={project.expectedDate} intlCulture={intlCulture} showBaseYearAsYearZero={showBaseYearAsYearZero} />
            <div className='d-flex flex-row align-items-center'>
                <button className='btn btn-sm btn-outline-primary pillButton' onClick={() => details.exportCashFlowCsv()}>Export...</button>
                <Spinner animation='border' size='sm' hidden={!details.loadingCashFlowCsv} className='ml-2 text-primary'/>
            </div>
        </div>
    )
})


export default ResultCashFlow


interface CashFlowTableProps {
    names: string[]
    items: ResultCashFlowTableItem[]
    expectedDate?: Date
    intlCulture: string
    showBaseYearAsYearZero: boolean
}

const CashFlowTable = ({ names, items, expectedDate, intlCulture, showBaseYearAsYearZero }: CashFlowTableProps) => {
    const intl = new IntlService(intlCulture)

    const currency = useProjectStore().project!.currencySymbol ?? "$"

    return (
        <table className='table table-bordered table-responsive-sm small'>
            <thead>
                <tr>
                    <th>Year</th>
                    <th>Solar Production (MWh)</th>
                    <th>Wind Production (MWh)</th>
                    <th>Storage Throughput (MWh)</th>
                    <th>Capital Expenses ({currency})</th>
                    <th>Operating Expenses, Excl. Augmentation ({currency})</th>
                    <th>Augmentation Cost ({currency})</th>
                    {names.map((name, j) =>
                        <th key={j}>{name} Revenue ({currency})</th>)}
                    <th>Total Revenue ({currency})</th>
                    <th>Net Operating Income ({currency})</th>
                    <th>Cumulative Cash Flow ({currency})</th>
                </tr>
            </thead>
            <tbody>
                {items.map((x, i) =>
                    <tr key={i}>
                        <td>{showBaseYearAsYearZero ? expectedDate!.getFullYear() - 1 + x.year : x.year}</td>
                        <td>{intl.formatNumber(x.solar, 'n0')}</td>
                        <td>{intl.formatNumber(x.wind, 'n0')}</td>
                        <td>{intl.formatNumber(x.storage, 'n')}</td>
                        <td>{`${intl.formatNumber(x.capEx, 'n2')} ${currency}`}</td>
                        <td>{`${intl.formatNumber(x.opEx + 0, 'n2')} ${currency}`}</td>
                        <td>{`${intl.formatNumber(x.augmentation, 'n2')} ${currency}`}</td>
                        {names.map((name, j) =>
                            <td key={j}>{`${intl.formatNumber(x.revenues[j], 'n2')} ${currency}`}</td>)}
                        <td>{`${intl.formatNumber(x.revenue, 'n2')} ${currency}`}</td>
                        <td>{`${intl.formatNumber(x.income, 'n2')} ${currency}`}</td>
                        <td>{`${intl.formatNumber(x.cumulative, 'n2')} ${currency}`}</td>
                    </tr>)}
            </tbody>
        </table>
    )
}
