import { observer } from 'mobx-react-lite'
import { Form } from 'react-bootstrap'
import { ProjectStore } from '../../ProjectStore'
import TimeSeriesImport from '../../../helper/TimeSeriesImport'
import RateScheduleView from '../../../helper/RateScheduleView'
import ToolTipInfo from '../../../helper/ToolTipInfo'
import SensitivityVarView from '../EnergyMarket/SensitivityVarView'
import { useState } from 'react'
import SuccessImportModal from './shared/ImportSuccessModal'
import ComponentLifetime from './shared/ComponentLifetime'
import * as React from 'react'
import ContextHelp from '../../../helper/ContextHelp'
import NumberInput from '../../../helper/NumberInput'
import { Validation } from '../../../helper/validation'
import { Project } from '../../model'

interface ConfigurationViewProps {
    model: Project
    store: ProjectStore
    useCommaAsDecimalPoint: boolean
}

const ConfigurationView = observer(({ model, store, useCommaAsDecimalPoint }: ConfigurationViewProps) => {
    const handleImportLimitTimeseries = async (data: number[], year: number, fileName: string) => {
        model.importLimitTimeSeriesData.setData(data, year)
        model.setImportLimitTimeSeriesDataName(fileName)
        await store.saveProject()
    }
    const handleExportLimitTimeseries = async (data: number[], year: number, fileName: string) => {
        model.exportLimitTimeSeriesData.setData(data, year)
        model.setExportLimitTimeSeriesDataName(fileName)
        await store.saveProject()
    }

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
    const closeSuccessModal = () => {
        setShowSuccessModal(false)
    }
    const openSuccessModal = () => {
        setShowSuccessModal(true)
    }

    return (<>
        {((model.useSolar && model.useStorage)) && <>
            <div className='form-group row align-items-center no-gutters'>
                <div className='mr-3'>
                    Solar & Storage Configuration
                </div>
                <div className='mr-3'>
                    <Form.Check custom label='AC-coupled' id='ac' type='radio'
                        checked={model.bus === 'ac'}
                        onChange={ev => model.setBus(ev.target.checked ? 'ac' : 'dc')} />
                </div>
                <div className='mr-3'>
                    <Form.Check custom label='DC-coupled' id='dc' type='radio'
                        checked={model.bus === 'dc'}
                        onChange={ev => model.setBus(ev.target.checked ? 'dc' : 'ac')} />
                </div>
                <div className='m-auto' />
                <div>
                    <ContextHelp helpId='CONFIGURATION' />
                </div>
            </div>
            <div className='form-group row align-items-center no-gutters'>
                <div className='mr-3'>
                    Battery Can Charge from Grid
                </div>
                <div className='mr-3'>
                    <Form.Check custom label='Yes' id='chargeYes' type='radio'
                        checked={model.canBatteryChargeFromGrid == 'true'}
                        onChange={ev => model.setBatteryCanChargeFromGrid(ev.target.checked)} />
                </div>
                <div className='mr-3'>
                    <Form.Check custom label='No' id='chargeNo' type='radio'
                        checked={model.canBatteryChargeFromGrid == 'false'}
                        onChange={ev => model.setBatteryCantChargeFromGrid(ev.target.checked)} />
                </div>
                {/*<div className='mr-0'>*/}
                {/*    <Form.Check disabled={model.earlyAnalysis } custom label='After the First 5 Years, as Dictated by ITC' id='chargeItc' type='radio'*/}
                {/*                checked={model.canBatteryChargeFromGrid == 'ITC'}*/}
                {/*        onChange={ev => model.setBatteryChargeFromGridItcRestriction(ev.target.checked)} />*/}
                {/*</div>*/}
                {/*<div className='mr-0'>*/}
                {/*    {model.earlyAnalysis && <ToolTipInfo label={'Disabled for early-stage analysis, because only a single year is simulated'} />}*/}
                {/*</div>*/}
            </div>
        </>}
        <div className='form-group row align-items-center no-gutters'>
            <div className='col-2'>
                Interconnection Limit (MW)
            </div>
            <div className='col-1 position-relative'>
                <NumberInput useCommaAsDecimalPoint={useCommaAsDecimalPoint} value={model.exportLimit}
                    disabled={!model.hasExportLimit}
                    onChange={x => model.setExportLimit(x)}
                    validator={Validation.min(0, false)} />
            </div>
            <div className='col-1' />
            <div className='col-4 ml-2'>
                <Form.Check custom type='radio' label={`Don't Include a Grid Import Limit`} id='noImportLimitTimeseries'
                    checked={!model.hasImportLimitTimeSeries && !model.hasImportLimitSchedule && !model.hasImportLimit}
                    onChange={ev => { model.setHasImportLimitTimeSeries(false); model.setHasImportLimitSchedule(false); model.setHasImportLimit(false); }} />

            </div>
            <div className='col-2'>
                AC Line Efficiency (%)
            </div>
            <div className='col-1'>
                <SensitivityVarView model={model.acLineEfficiencySensitivity}
                    label='AC Line Efficiency (%)' units='%' hideLabel={true}
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint} customClass='mt-1'
                />
            </div>
            <div className='m-auto' />
            <div>
                {(!model.useSolar || !model.useStorage) && <ContextHelp helpId='CONFIGURATION' />}
            </div>
        </div>
        <div className='form-group row align-items-center no-gutters'>
            <div className='col-4'>
                <Form.Check custom type='radio' label={`Don't Include a Grid Export Limit`} id='noExportLimitTimeseries'
                    checked={!model.hasExportLimitTimeSeries && !model.hasExportLimitSchedule}
                    onChange={ev => { model.setHasExportLimitTimeSeries(false); model.setHasExportLimitSchedule(false); }} />
            </div>
            <div className='col-2 ml-2'>
                <Form.Check type='radio' custom label='Set a Grid Import Limit (MW):' id='hasImportLimit'
                    checked={model.hasImportLimit}
                    disabled={model.importLimitsDisabled}
                    onChange={ev => { model.setHasImportLimitTimeSeries(!ev.target.checked); model.setHasImportLimit(ev.target.checked); model.setHasImportLimitSchedule(!ev.target.checked); }} />
            </div>
            <div className='col-1 position-relative'>
                <NumberInput useCommaAsDecimalPoint={useCommaAsDecimalPoint} value={model.importLimit}
                    disabled={!model.hasImportLimit || model.importLimitsDisabled}
                    onChange={x => model.setImportLimit(x)}
                    validator={Validation.min(0)} />
            </div>
            <div className='col-1 ml-0'>
                {model.importLimitsDisabled && <ToolTipInfo label={'Import limits are disabled because there is not an energy storage system or it is not allowed to charge from the grid.'} />}
            </div>
            <div className='col-2 ml-0'>
                Project Transformer Efficiency (%)
            </div>
            <div className='col-1 position-relative'>
                <SensitivityVarView model={model.acTransfomerEfficiencySensitivity}
                    label='Project Transformer Efficiency (%)' units='%' hideLabel={true}
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint} customClass='mt-1'
                />
            </div>
        </div>
        <div className='form-group row align-items-center'>
            <div className='col-2'>
                <Form.Check type='radio' custom label='Set a Grid Export Limit Schedule:' id='hasExportLimitSchedule'
                    checked={model.hasExportLimitSchedule}
                    onChange={ev => { model.setHasExportLimitTimeSeries(!ev.target.checked); model.setHasExportLimitSchedule(ev.target.checked); }} />
            </div>
            <div className='col-2 position-relative'>
                <RateScheduleView model={model.exportLimitSchedule} disabled={!model.hasExportLimitSchedule} isPriceSchedule={false} label="Set..." />
            </div>
            <div className='col-2'>
                <Form.Check type='radio' custom label='Set a Grid Import Limit Schedule:' id='hasImportLimitSchedule'
                    checked={model.hasImportLimitSchedule}
                    disabled={model.importLimitsDisabled}
                    onChange={ev => { model.setHasImportLimitTimeSeries(!ev.target.checked); model.setHasImportLimit(!ev.target.checked); model.setHasImportLimitSchedule(ev.target.checked); }} />
            </div>
            <div className='col-2 position-relative'>
                <RateScheduleView model={model.importLimitSchedule} isPriceSchedule={false} disabled={!model.hasImportLimitSchedule || model.importLimitsDisabled} label="Set..." />
            </div>
        </div>
        <div className='form-group row align-items-center'>
            <div className='col-2'>
                <Form.Check custom type='radio' label='Include a Grid Export Limit Time Series:' id='hasExportLimitTimeseries'
                    checked={model.hasExportLimitTimeSeries}
                    onChange={ev => { model.setHasExportLimitTimeSeries(ev.target.checked); model.setHasExportLimitSchedule(!ev.target.checked); }} />
            </div>
            <div className='col-2 position-relative'>
                <TimeSeriesImport onSelect={handleExportLimitTimeseries} disabled={!model.hasExportLimitTimeSeries} model={model.exportLimitTimeSeriesData} showTimseriesViewer={true} timeSeriesViewerTitle={model.exportLimitTimeSeriesDataName} showSuccessModal={openSuccessModal} />
            </div>
            <div className='col-2'>
                <Form.Check type='radio' custom label='Include a Grid Import Limit Time Series:' id='hasImportLimitTimeseries'
                    checked={model.hasImportLimitTimeSeries}
                    disabled={model.importLimitsDisabled}
                    onChange={ev => { model.setHasImportLimitTimeSeries(ev.target.checked); model.setHasImportLimit(!ev.target.checked); model.setHasImportLimitSchedule(!ev.target.checked); }} />
            </div>
            <div className='col-2 position-relative'>
                <TimeSeriesImport onSelect={handleImportLimitTimeseries} disabled={!model.hasImportLimitTimeSeries || model.importLimitsDisabled} model={model.importLimitTimeSeriesData} showTimseriesViewer={true} timeSeriesViewerTitle={model.importLimitTimeSeriesDataName} showSuccessModal={openSuccessModal} />
            </div>
        </div>
        <div className='form-group row align-items-center ml-1'>
            <Form.Check custom label='Use Component Lifetimes' id='useComponentLifetime'
                checked={model.useComponentLifetimes && !model.earlyAnalysis} disabled={model.earlyAnalysis}
                onChange={ev => model.setUseComponentLifetimes(ev.target.checked)} />
            {!model.earlyAnalysis && <ToolTipInfo label={'When using component lifetimes, only one Wind turbine and only one PV can be used, and only one size optimization can be set for each'} />}
            {model.earlyAnalysis && <ToolTipInfo label={'Disabled for early-stage analysis'} />}
        </div>
        <SuccessImportModal show={showSuccessModal} onConfirm={closeSuccessModal} />
        {!model.earlyAnalysis && model.useComponentLifetimes && <ComponentLifetime />}
    </>)
})

export default ConfigurationView
