import {Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartSeries, ChartSeriesItem, ChartTooltip, ChartValueAxis, ChartValueAxisItem} from '@progress/kendo-react-charts'
import * as React from 'react'

interface ResultSolarEnergyProductionByMonthChartProps {
    values: number[]
}

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

interface ChartItem {
    month: string
    value: number
}

const ResultSolarEnergyProductionByMonthChart = ({values}: ResultSolarEnergyProductionByMonthChartProps) => {
    const data: ChartItem[] = values.map(
        (value, index) => ({month: MONTHS[index], value}))

    return (
        <Chart>
            <ChartCategoryAxis>
                <ChartCategoryAxisItem labels={{visible: true, rotation: 'auto'}} title={{text: 'Month'}}/>
            </ChartCategoryAxis>
            <ChartValueAxis>
                <ChartValueAxisItem title={{text: 'MWh'}} min={0} labels={{format: '{0:n}'}}/>
            </ChartValueAxis>
            <ChartSeries>
                <ChartSeriesItem type='column' data={data} categoryField='month' field='value'
                                 name='Production (MWh)' color='#40a040' markers={{visible: false}}/>
            </ChartSeries>
            <ChartLegend visible={false}/>
            <ChartTooltip format='{0:n}'/>
        </Chart>
    )
}

export default ResultSolarEnergyProductionByMonthChart
