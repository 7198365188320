import {Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartSeries, ChartSeriesItem, ChartTooltip, ChartValueAxis, ChartValueAxisItem, SharedTooltipContext} from '@progress/kendo-react-charts'
import {MonthMetricResultItem} from '../../results-details'
import {TooltipContext} from '@progress/kendo-react-charts/dist/npm/tooltip/Context'
import { IntlService } from 'Kendo-Intl-5'
import * as React from 'react'

interface ResultStorageChartByMonthProps {
    items: MonthMetricResultItem[]
}

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

interface ChartItem {
    month: string
    minimum: number
    averageMinimum: number
    average: number
    averageMaximum: number
    maximum: number
}

const ResultStorageChartByMonth = ({items}: ResultStorageChartByMonthProps) => {
    const data: ChartItem[] = items.map(
        (item, index) => ({month: MONTHS[index], ...item}))

    return (
        <Chart>
            <ChartCategoryAxis>
                <ChartCategoryAxisItem labels={{visible: true, rotation: 'auto'}} title={{text: 'Month'}}/>
            </ChartCategoryAxis>
            <ChartValueAxis>
                <ChartValueAxisItem title={{text: 'State of Charge (%)'}} min={0}/>
            </ChartValueAxis>
            <ChartSeries>
                <ChartSeriesItem type='boxPlot' categoryField='month' data={data}
                                 lowerField='minimum'
                                 q1Field='averageMinimum'
                                 medianField='average'
                                 q3Field='averageMaximum'
                                 upperField='maximum'
                                 markers={{visible: false}}/>
            </ChartSeries>
            <ChartLegend visible={false}/>
            <ChartTooltip render={Tooltip}/>
        </Chart>
    )
}

export default ResultStorageChartByMonth

const Tooltip = (context: TooltipContext | SharedTooltipContext) => {
    const {point} = context as TooltipContext
    const { minimum, averageMinimum, average, averageMaximum, maximum } = point.dataItem as ChartItem
    const intl = new IntlService('en')
    const format = (x: number) => intl.formatNumber(x, 'n0') + '%'

    return (
        <div className='k-chart-tooltip'>
            <table>
                <tr>
                    <th colSpan={2}>{point.category}</th>
                </tr>
                <tr>
                    <td>Max</td>
                    <td>{format(maximum)}</td>
                </tr>
                <tr>
                    <td>Average Max</td>
                    <td>{format(averageMaximum)}</td>
                </tr>
                <tr>
                    <td>Average</td>
                    <td>{format(average)}</td>
                </tr>
                <tr>
                    <td>Average Min</td>
                    <td>{format(averageMinimum)}</td>
                </tr>
                <tr>
                    <td>Min</td>
                    <td>{format(minimum)}</td>
                </tr>
            </table>
        </div>
    )
}
