import {RevenueResultCol, RevenueResultItem, SolarResultItem} from '../../results-details'
import {IntlService} from 'Kendo-Intl-5'
import * as React from 'react'
import { useProjectStore } from '../../ProjectProvider'

interface ResultRevenueTableProps {
    columns: RevenueResultCol
    revenueItems: RevenueResultItem[]
    solarItems?: SolarResultItem[]
    intlCulture: string
    showBaseYearAsYearZero: boolean
}

const ResultRevenueTable = ({columns, solarItems, revenueItems, intlCulture, showBaseYearAsYearZero}: ResultRevenueTableProps) => {
    const intl = new IntlService(intlCulture)


    const project = useProjectStore().project!
    const currency = project.currencySymbol ?? "$"

    return (
        <table className='table table-bordered table-responsive-sm small'>
            <thead>
                <tr>
                    <th>Year</th>
                    {solarItems && <>
                        <th>Solar Energy Production (MWh-dc)</th>
                        <th>Clipped Solar Energy Production (MWh-dc)</th>
                        <th>Cumulative Degradation (%)</th>
                    </>}
                    {columns.contracts.map((x, index) => <React.Fragment key={index}>
                        <th>{x} Revenue ({currency})</th>
                        <th>Annual Change (%)</th>
                    </React.Fragment>)}
                    {columns.energyMarkets.map((x, index) => <React.Fragment key={index}>
                        <th>{x} Revenue ({currency})</th>
                        <th>Annual Change (%)</th>
                        <th>{x} Exported (MWh)</th>
                        <th>{x} Average Price ({currency}/MWh)</th>
                        <th>Annual Change (%)</th>
                    </React.Fragment>)}

                    {columns.capacityMarkets.map((x, index) => <React.Fragment key={index}>
                        <th>{x} Revenue ({currency})</th>
                        <th>Annual Change (%)</th>
                        <th>{x} Exported (MWh)</th>
                        <th>{x} Average Price ({currency}/MWh)</th>
                        <th>Annual Change (%)</th>
                    </React.Fragment>)}
                    {columns.regulationMarkets?.map((x, index) => <React.Fragment key={index}>
                        <th>{x} Reg-Up Revenue ({currency})</th>
                        <th>Annual Change (%)</th>
                        <th>{x} Reg-Down Revenue ({currency})</th>
                        <th>Annual Change (%)</th>
                        {/*<th>{x} Reg Up Exported (MWh)</th>*/}
                        {/*<th>{x} Reg Down Imported (MWh)</th>*/}
                    </React.Fragment>)}
                    <th>Operating Expenses, Excl. Augmentation ({currency})</th>
                    <th>Augmentation Cost ({currency})</th>
                </tr>
            </thead>
            <tbody>
                {revenueItems.map((x, index) =>
                    <tr key={x.year}>
                        <td>{showBaseYearAsYearZero ? project.baseYear + x.year : x.year + 1}</td>
                        {solarItems && <>
                            <td>{intl.formatNumber(solarItems[index].production, 'n0')}</td>
                            <td>{intl.formatNumber(solarItems[index].clippedProduction, 'n0')}</td>
                            <td>{`${intl.formatNumber(solarItems[index].degradation, 'p2')}`}</td>
                        </>}
                        {columns.contracts.map((_, index) => <React.Fragment key={index}>
                            <td>{`${intl.formatNumber(x.contracts[index].revenue, 'n0')} ${currency}`}</td>
                            <td>{intl.formatNumber(x.contracts[index].revenueChange, 'p2')}</td>
                        </React.Fragment>)}
                        {columns.energyMarkets.map((_, index) => <React.Fragment key={index}>
                            <td>{`${intl.formatNumber(x.energyMarkets[index].revenue, 'n0')} ${currency}`}</td>
                            <td>{intl.formatNumber(x.energyMarkets[index].revenueChange, 'p2')}</td>
                            <td>{intl.formatNumber(x.energyMarkets[index].energy, 'n0')}</td>
                            <td>{`${intl.formatNumber(x.energyMarkets[index].price, 'n2')} ${currency}`}</td>
                            <td>{intl.formatNumber(x.energyMarkets[index].priceChange, 'p2')}</td>
                        </React.Fragment>)}
                            
                        {columns.capacityMarkets.map((_, index) => <React.Fragment key={index}>
                            <td>{`${intl.formatNumber(x.capacityMarkets[index].revenue, 'n0')} ${currency}`}</td>
                            <td>{intl.formatNumber(x.capacityMarkets[index].revenueChange, 'p2')}</td>
                            <td>{intl.formatNumber(x.capacityMarkets[index].energy, 'n0')}</td>
                            <td>{`${intl.formatNumber(x.capacityMarkets[index].price, 'n2')} ${currency}` }</td>
                            <td>{intl.formatNumber(x.capacityMarkets[index].priceChange, 'p2')}</td>
                        </React.Fragment>)}
                        {columns.regulationMarkets?.map((_, index) => <React.Fragment key={index}>
                            <td>{`${intl.formatNumber(x.regulationMarkets[index].totalRegUpRevenue, 'n0')} ${currency}`}</td>
                            <td>{intl.formatNumber(x.regulationMarkets[index].totalRegUpRevenueChange, 'p2')}</td>
                            <td>{`${intl.formatNumber(x.regulationMarkets[index].totalRegDownRevenue, 'n0')} ${currency}`}</td>
                            <td>{intl.formatNumber(x.regulationMarkets[index].totalRegDownRevenueChange, 'p2')}</td>
                            {/*<td>{intl.formatNumber(x.regulationMarkets[index].regUpEnergyExported, 'n0')}</td>*/}
                            {/*<td>{intl.formatNumber(x.regulationMarkets[index].regDownEnergyImported, 'n0')}</td>*/}
                        </React.Fragment>)}
                        <td>{`${intl.formatNumber(x.operatingCost, 'n0')} ${currency}`}</td>
                        <td>{`${intl.formatNumber(x.augmentationCost, 'n0')} ${currency}`}</td>
                    </tr>)}
            </tbody>
        </table>
    )
}

export default ResultRevenueTable
