import { RouteComponentProps } from '@reach/router'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { IntlService } from 'Kendo-Intl-5'
import { useProjectStore } from '../../ProjectProvider'
import * as React from 'react'
import { TimeSeries } from '../../model/TimeSeries'
import { RegulationMarketResult } from '../../results-details'
import { Project } from '../../model'
import TimeSeriesView from '../../../helper/TimeSeriesView'
import ContextHelp from '../../../helper/ContextHelp'

interface ResultRegMarketProps extends RouteComponentProps {
    result: RegulationMarketResult[]
    model: Project
    showBaseYearAsYearZero: boolean
}

const ResultRegulationMarket = observer(({ result, model }: ResultRegMarketProps) => {
    const getTimeseriesData = (x: number[] | undefined) => {
        if (x == undefined) return undefined
        let ts = new TimeSeries()
        ts.setData(x, model.baseYear)
        return ts
    }
    const ts = useMemo(() => {
        let rvs: RegulationMarketTimeseries[] = []
        result.map(x => {
            let rv: RegulationMarketTimeseries = {} as RegulationMarketTimeseries
            rv.regUpCapacityRevenueTs = getTimeseriesData(x.regUpEnergyRevenueTimeseries)
            rv.regUpEnergyRevenueTs = getTimeseriesData(x.regUpCapacityRevenueTimeseries)
            rv.regDownCapacityRevenueTs = getTimeseriesData(x.regDownEnergyExpenseTimeseries)
            rv.regDownEnergyExpenseTs = getTimeseriesData(x.regDownCapacityRevenueTimeseries)
            rvs.push(rv)
        })
        return rvs
    }, [result, model.baseYear])
    
    const intlCulture = model.useCommaAsDecimalPoint ? 'es' : 'en'
    const dateFormat = model.dateFormat

    return (<>
        {result?.length > 0 && result.map((x, index) =>
            <>
                {index > 0 && <hr className='bg-dark' />}
                <div className='mx-auto' />
                <div className='d-flex flex-row align-items-center m-1 ml-4 mr-4 mt-20' >
                    <h3 className='mr-4'>{model.regulationMarkets !== undefined && model.regulationMarkets.length > 0 && model.regulationMarkets[index].name !== undefined && model.regulationMarkets[index].name.length > 0 ? `${model.regulationMarkets[index].name} - Year ${model.storageStartYear ?? 1}` : `Regulation Market - Year ${model.storageStartYear ?? 1}`}</h3>
                </div>
                <div className='row m-2'>
                    <div className='col-6'>
                        <div className='row'>
                            <div className='col-12'>
                                <ResultRegulationMarketMetricTable index={index} result={result[index]} intlCulture={intlCulture} />
                            </div>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='row'>
                            <div className='col-12'>
                                {ts[index].regUpCapacityRevenueTs !== undefined && ts[index].regUpCapacityRevenueTs!.data.length > 0 && < TimeSeriesView model={ts[index].regUpCapacityRevenueTs!} chartTitle={`Reg-Up Capacity Revenue (${model.currencySymbol})`} intlCulture={intlCulture} />}
                            </div>
                            <div className='col-12'></div>
                            <div className='col-12'>
                                {ts[index].regUpEnergyRevenueTs != undefined && ts[index].regUpEnergyRevenueTs!.data.length > 0 && <TimeSeriesView model={ts[index].regUpEnergyRevenueTs!} chartTitle={`Reg-Up Energy Revenue (${model.currencySymbol})`} intlCulture={intlCulture} />}
                            </div>
                            <div className='col-12'></div>
                            <div className='col-12'>
                                {ts[index].regDownCapacityRevenueTs != undefined && ts[index].regDownCapacityRevenueTs!.data.length > 0 && < TimeSeriesView model={ts[index].regDownCapacityRevenueTs!} chartTitle={`Reg-Down Capacity Revenue (${model.currencySymbol})`} intlCulture={intlCulture} />}
                            </div>
                            <div className='col-12'></div>
                            <div className='col-12'>
                                {ts[index].regDownEnergyExpenseTs != undefined && ts[index].regDownEnergyExpenseTs!.data.length > 0 && <TimeSeriesView model={ts[index].regDownEnergyExpenseTs!} chartTitle={`Reg-Down Energy Expense (${model.currencySymbol})`} intlCulture={intlCulture} />}
                            </div>
                            <div className='col-12'></div>
                        </div>
                    </div>
                </div>
            </>
        )}

    </>)
})

export default ResultRegulationMarket

interface RegulationMarketTimeseries {
    regUpEnergyRevenueTs: TimeSeries | undefined
    regUpCapacityRevenueTs: TimeSeries | undefined
    regDownEnergyExpenseTs: TimeSeries | undefined
    regDownCapacityRevenueTs: TimeSeries | undefined
}

interface ResultStorageMetricTableProps {
    result: RegulationMarketResult
    intlCulture: string
    index: number
}

interface RowProps {
    label: string
    value: string
}

const Row = ({ label, value }: RowProps) => {
    return (
        <tr className='font-size-14'>
            <td>{label}</td>
            <td>{value}</td>
        </tr>)
}

const ResultRegulationMarketMetricTable = ({ result, intlCulture, index }: ResultStorageMetricTableProps) => {
    const intl = new IntlService(intlCulture)
    const project = useProjectStore().project!
    const currency = project.currencySymbol ?? "$"

    return (<>
        <div className="d-flex align-items-center justify-content-between">
            <h5 className='font-size-16 font-weight-bold'>Regulation Market Metrics</h5>
            <div className='ml-1 mb-1'>
                <ContextHelp helpId='REGULATIONMARKETRESULTS' />
            </div>
        </div>
        <table className='table table-bordered table-responsive-sm small'>
            <tbody>
                {project.regulationMarkets[index].useRegUp && <>
                    <Row label='Reg-Up Capacity Revenue' value={(currency) + intl.formatNumber((result?.regUpCapacityRevenue ?? 0), 'n')} />
                    <Row label='Reg-Up Capacity Committed' value={intl.formatNumber((result?.regUpCapacityCommitted !== undefined ? (result?.regUpCapacityCommitted / 1000) : 0), 'n') + " MWh"} />
                    {project.regulationMarkets[index].useEnergyPrice && <Row label='Reg-Up Energy Revenue' value={(currency) + intl.formatNumber((result?.regUpEnergyRevenue ?? 0), 'n')} />}
                    <Row label='Reg-Up Energy Exports' value={intl.formatNumber((result?.regUpEnergyExports !== undefined ? (result?.regUpEnergyExports / 1000) : 0), 'n') + " MWh"} />
                </>}
                {project.regulationMarkets[index].useRegDown && <>
                    <Row label='Reg-Down Capacity Revenue' value={(currency) + intl.formatNumber((result?.regDownCapacityRevenue ?? 0), 'n')} />
                    <Row label='Reg-Down Capacity Committed' value={intl.formatNumber((result?.regDownCapacityCommitted !== undefined ? (result?.regDownCapacityCommitted / 1000) : 0), 'n') + " MWh"} />
                    {project.regulationMarkets[index].useEnergyPrice && <Row label='Reg-Down Energy Expense' value={(currency) + intl.formatNumber((result?.regDownEnergyExpense ?? 0), 'n')} />}
                    <Row label='Reg-Down Energy Imports' value={intl.formatNumber((result?.regDownEnergyImports != undefined ? (result?.regDownEnergyImports / 1000) : 0), 'n') + " MWh"} />
                </>}
            </tbody>
        </table>
    </>)
}
