import * as React from 'react'

export const iconHeight = 60 //iconSize + margin
const lineWidth = 20

const stroke = 2
const lineCenter = stroke / 2
const strokeColor = '#5b0428'

export const HLineSvg = () => (
    <svg width={lineWidth} height={stroke}>
        <line x1='0' y1={lineCenter} x2={lineWidth} y2={lineCenter} stroke={strokeColor} strokeWidth={stroke}/>
    </svg>
)

interface TSvgProps {
    height: number;
    isWindDcBus?:boolean;
}

export const TSvg = ({height,isWindDcBus}: TSvgProps) => {
    const tHeight = height + stroke
    const tCenter = tHeight / 2
    return (
        <div className='vertical-offset' style={{marginBottom:isWindDcBus?85:''}}>
            <svg width={lineWidth + stroke} height={tHeight}>
                <line x1={lineCenter} y1='0' x2={lineCenter} y2={tHeight} stroke={strokeColor} strokeWidth={stroke}/>
                <line x1={stroke} y1={tCenter} x2={lineWidth + stroke} y2={tCenter} stroke={strokeColor} strokeWidth={stroke}/>
            </svg>
        </div>
    )
}
