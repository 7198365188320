import { RouteComponentProps } from '@reach/router'
import Col from 'react-bootstrap/Col'
import * as React from 'react'
import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Dropdown, Spinner } from 'react-bootstrap'
import ResultOst from './ResultOst'
import ResultOstModal from './ResultOstModal'
import { selectedValue } from '../../results'
import { useProjectStore } from '../../ProjectProvider'

interface GraphResultsProps extends RouteComponentProps { }


const GraphResults = observer((_: GraphResultsProps) => {
    const results = useProjectStore().project!.results
    const ostDimensions = results.ostDimension
    const ostResult = results.ostResult

    const loading = results.sensitivityGraphState == 'loading'

    const [showModal, setShowModal] = useState(false)
    const [clickPosition, setClickPosition] = useState([0, 0])
    const [buttonState, setButtonState] = useState(0)
    const [superimposedSelected, setSuperimposedSelected] = useState(0)

    var showSpinner = (results.sensitivityGraphState == 'ready' || results.sensitivityGraphState == 'unavailable')
    var hideButton = results.cache.ostResultState == 'ready'

    const handleAxisSelect = (index: number, key: selectedValue) => {
        let dimensions = results.selectDimension(index, key)
        if (dimensions?.filter(x => x.isXAxis == true).length == 1 && dimensions?.filter(x => x.isYAxis == true).length == 1)
            setButtonState(2)
        else
            setButtonState(1)
    }
    const handleGetProject = () => {
        results.sensitivityGraphState = 'changed'
        results.fetchOstResult()
    }
    var xAxis = ostDimensions?.filter(x => x.isXAxis == true);
    var yAxis = ostDimensions?.filter(x => x.isYAxis == true);

    function handleOstPanelClick(canvas: HTMLCanvasElement, event: MouseEvent, leftMargin: number, topMargin: number) {
        const rect = canvas.getBoundingClientRect()
        var xClick = event.clientX - rect.left
        var yClick = event.clientY - rect.top
        if (xClick > leftMargin && xClick < 800 + leftMargin && yClick > topMargin && yClick < 400 + topMargin) {
            setClickPosition([(xClick - leftMargin), 400 + topMargin - yClick])
            setShowModal(true)
        }
    }
    const onCancel = () => setShowModal(false)
    const handleSuperimposedSelect = (i: number) => {
        setSuperimposedSelected(i)
    }
    return loading ? (
        <div className='d-flex flex-column h-100 align-items-center justify-content-center'>
            <Spinner animation='border' className='text-primary' />
        </div>
    ) : (
        <>
            <Col>
                <div className='d-flex flex-row align-content-center mt-2'>
                    {ostDimensions && ostDimensions != undefined && ostDimensions.length > 1 && ostResult && ostResult.blendedPlane.length > 0 && ostResult.superimposedValues.length > 0 &&
                            ostDimensions.map((item, index) => <React.Fragment>
                                <label className='mr-2'>{item.components[0] != "" ? item.components[0] + ": " + item.displayNames.slice(0, 2).join(', ') : item.displayNames.slice(0, 2).join(', ')}</label>
                            <Dropdown>
                                <Dropdown.Toggle variant='outline-primary' className='d-flex align-items-center mr-2 pillButton'>
                                        {item.isXAxis ? 'xAxis' : item.isYAxis ? 'yAxis' : item.points[item.selectedValue].join(',')}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {item.points.map((x, i) =>
                                        <Dropdown.Item key={x.join(', ')} onClick={() => handleAxisSelect(index, i)}>{x.join(',')}</Dropdown.Item>)}
                                    <Dropdown.Item key='xAxis' onClick={() => handleAxisSelect(index, 'xAxis')}>xAxis</Dropdown.Item>
                                    <Dropdown.Item key='yAxis' onClick={() => handleAxisSelect(index, 'yAxis')}>yAxis</Dropdown.Item>
                                </Dropdown.Menu>
                                </Dropdown>
                            </React.Fragment>
                        )}
                </div>
                <div className='d-flex flex-row align-content-center'>
                    <Dropdown>
                        <Dropdown.Toggle variant='outline-primary' className='d-flex align-items-center mr-2 pillButton'>
                            {ostResult?.superimposedValues[superimposedSelected].name}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {ostResult?.superimposedValues.map((x, i) =>
                                <Dropdown.Item key={i} onClick={() => handleSuperimposedSelect(i)}>{x.name}</Dropdown.Item>)}
                        </Dropdown.Menu>
                        </Dropdown>
                        <Button id='getOstResults' hidden={hideButton || buttonState == 0} disabled={buttonState !== 2} variant="primary" onClick={() => { handleGetProject() }} className='ml-2 mr-2 pillButton'>Get Results</Button>
                        <Spinner hidden={showSpinner} animation='border' className='text-primary loader-spinner mr-3' />
                </div>
                {ostDimensions && ostDimensions != undefined && ostDimensions.length > 0 && ostResult && ostResult.blendedPlane.length > 0 && xAxis && xAxis.length > 0 && yAxis && yAxis.length > 0 && yAxis[0].displayNames.length > 0 && xAxis[0].displayNames.length > 0 &&
                    <React.Fragment>
                        <ResultOst getCursorPosition={handleOstPanelClick} superimposedSelected={superimposedSelected} ostResult={ostResult} width={800} height={400} xAxis={xAxis[0]} yAxis={yAxis[0]} />
                        <ResultOstModal show={showModal} dimensions={ostDimensions} planes={ostResult.planes} xPosition={clickPosition[0]} yPosition={clickPosition[1]} onCancel={onCancel} />
                    </React.Fragment>
                }
            </Col>
        </>//TODO Error Message
    )
})

export default GraphResults
