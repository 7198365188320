import {action, makeObservable, observable} from 'mobx'
import {v4} from 'uuid'


export type IncentiveKind = 'itc' | 'macrs' | 'bonus' | 'prod'


export interface Incentive {
    id: string
    kind: IncentiveKind

    solar: boolean
    storage: boolean
    wind: boolean

    setSolar(x: boolean): void
    setStorage(x: boolean): void
    setWind(x: boolean): void
}


export class InvestmentTaxCredit implements Incentive {
    constructor() { makeObservable(this) }
    id: string = v4()
    kind: 'itc' = 'itc'

    @observable solar: boolean = true
    @observable storage: boolean = true
    @observable wind: boolean = false

    @observable eligiblePercent: number = 100
    @observable itcPercent: number = 30

    @action setSolar(x: boolean) { this.solar = x }
    @action setStorage(x: boolean) { this.storage = x }
    @action setWind(x: boolean) { this.wind = x }

    @action setEligiblePercent(x: number) { this.eligiblePercent = x }
    @action setItcPercent(x: number) { this.itcPercent = x }
}


export class Macrs implements Incentive {
    constructor() { makeObservable(this) }
    id: string = v4()
    kind: 'macrs' = 'macrs'

    @observable solar: boolean = true
    @observable storage: boolean = true
    @observable wind: boolean = true

    @observable eligiblePercent: number = 100
    @observable marginalTaxPercent: number = 35

    @action setSolar(x: boolean) { return this.solar = x }
    @action setStorage(x: boolean) { return this.storage = x }
    @action setWind(x: boolean) { return this.wind = x }

    @action setEligiblePercent(x: number) { this.eligiblePercent = x }
    @action setMarginalTaxPercent(x: number) { this.marginalTaxPercent = x }
}


export class BonusDepreciation implements Incentive {
    constructor() { makeObservable(this) }
    id: string = v4()
    kind: 'bonus' = 'bonus'

    @observable solar: boolean = true
    @observable storage: boolean = true
    @observable wind: boolean = true

    @observable eligiblePercent: number = 100
    @observable marginalTaxPercent: number = 35
    @observable bonusPercent: number = 50

    @action setSolar(x: boolean) { return this.solar = x }
    @action setStorage(x: boolean) { return this.storage = x }
    @action setWind(x: boolean) { return this.wind = x }

    @action setEligiblePercent(x: number) { this.eligiblePercent = x }
    @action setMarginalTaxPercent(x: number) { this.marginalTaxPercent = x }
    @action setBonusPercent(x: number) { this.bonusPercent = x }
}


export class ProductionBased implements Incentive {
    constructor() { makeObservable(this) }
    id: string = v4()
    kind: 'prod' = 'prod'

    @observable solar: boolean = false
    @observable storage: boolean = false
    @observable wind: boolean = false

    @observable credit: number = 0 // $/MWh
    @observable maxCredit: number = 0 // $/year
    @observable useDuration: boolean = false
    @observable duration: number = 10 // year
    @observable useCreditTaxPercent: boolean = false
    @observable creditTaxPercent: number = 35 // %

    @action setSolar(x: boolean) { this.solar = x }
    @action setStorage(x: boolean) { this.storage = x }
    @action setWind(x: boolean) { this.wind = x }

    @action setCredit(x: number) { this.credit = x }
    @action setMaxCredit(x: number) { this.maxCredit = x }
    @action setUseDuration(x: boolean) { this.useDuration = x }
    @action setDuration(x: number) { this.duration = x }
    @action setUseCreditTaxPercent(x: boolean) { this.useCreditTaxPercent = x }
    @action setCreditTaxPercent(x: number) { this.creditTaxPercent = x }
}


export const createIncentive = (kind: IncentiveKind) => {
    switch (kind) {
        case 'itc': {return new InvestmentTaxCredit()}
        case 'macrs': {return new Macrs()}
        case 'bonus': {return new BonusDepreciation()}
        case 'prod': {return new ProductionBased()}
    }
}
