import * as React from 'react'
import { useRef, useEffect } from 'react';
import { OstDimension, OstResult } from '../../results';


interface ResultOstProps {
    width: number;
    height: number;
    ostResult: OstResult
    superimposedSelected: number;
    getCursorPosition: (canvas: HTMLCanvasElement, event: MouseEvent, leftMargin: number, topMargin: number) => void
    xAxis: OstDimension
    yAxis: OstDimension
}

const ResultOst = ({ ostResult, superimposedSelected, width, height, getCursorPosition, xAxis, yAxis }: ResultOstProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const data = ostResult.blendedPlane
    const dimensions = ostResult.planes
    const simulationPoints = ostResult.simulationPoints
    const pointsXMax = simulationPoints.filter(x => x.yIndex == 0).length - 1
    const pointsYMax = simulationPoints.filter(x => x.xIndex == 0).length - 1

    var leftMargin = 38
    const topMargin = 20
    const rightMargin = 240
    const bottomMargin = 50

    const tickSize = 5
    const padding = 2
    const lineWidth = 2
    const radius = 6
    const grayStroke = '#e1e6e6'
    const blackStroke = '#000000'
    const font = "12px Arial"
    const numOfTicks = 6

    const drawPlane = (context: CanvasRenderingContext2D, data: number[]) => {
        var palette = context.getImageData(leftMargin, topMargin, width, height); //x,y,w,h
        palette.data.set(new Uint8ClampedArray(data)); // assuming values 0..255, RGBA, pre-mult.
        context.putImageData(palette, leftMargin, topMargin);
    }
    const drawSides = (context: CanvasRenderingContext2D, data: number[]) => {
        context.beginPath();
        context.lineWidth = lineWidth;
        context.strokeStyle = grayStroke
        context.moveTo(leftMargin, topMargin);
        context.lineTo(leftMargin, height + topMargin + tickSize);
        context.moveTo(leftMargin - tickSize, height + topMargin);
        context.lineTo(width + leftMargin, height + topMargin);
        context.moveTo(width + leftMargin, height + topMargin + tickSize)
        context.lineTo(width + leftMargin, topMargin);
        context.lineTo(leftMargin - tickSize, topMargin)
        context.stroke();
    }

    const formatNumber = (x: number): string => {
        return Number.isInteger(x) ? x.toLocaleString().toString() : x.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 })
    }

    const formatPoints = (points: number[]): string[] => {
        let pointsStr: string[] = []
        points.forEach(x => {
            pointsStr.push(formatNumber(x))
        })
        return pointsStr
    }

    const interpolateYTicks = (context: CanvasRenderingContext2D, minYTicks: number[], maxYTicks: number[]) => {
        context.fillStyle = grayStroke
        context.strokeStyle = grayStroke
        const yNumOfTicks = yAxis.points[0].length
        let yTicksDistance: number[] = []
        for (let i = 0; i < yNumOfTicks; i++) {
            let distance = maxYTicks[i] - minYTicks[i]
            yTicksDistance.push(distance)
        }
        for (let n = 1; n < numOfTicks - 1; n++) {
            let y = height / (numOfTicks - 1) * n
            context.moveTo(leftMargin - 5, y + topMargin);
            context.lineTo(leftMargin + width, y + topMargin);
            context.fillStyle = grayStroke
            context.strokeStyle = grayStroke
            context.stroke();

            context.fillStyle = grayStroke
            context.strokeStyle = grayStroke
            let interpYPoints = yAxis.points[0]?.map((x, i) => x + (yTicksDistance[i] / 5 * (5 - n)))
            var interpYPointsStr = formatPoints(interpYPoints)
            let yPoints = interpYPointsStr?.slice(0, 2)
            context.fillStyle = blackStroke
            context.strokeStyle = blackStroke
            if (yPoints.length === 1) {
                let pointY = context.measureText(yPoints[0]).width
                let x = leftMargin - tickSize - padding - pointY > 0 ? leftMargin - tickSize - padding - pointY : 0
                context.fillText(yPoints[0], x, y + topMargin + 4)
            }
            else {
                let pointYMinSize1 = context.measureText(yPoints[0]).width
                let pointYMinSize2 = context.measureText(yPoints[1]).width
                let pointYMinSize = pointYMinSize1 > pointYMinSize2 ? pointYMinSize1 : pointYMinSize2
                let x = leftMargin - tickSize - padding - pointYMinSize > 0 ? leftMargin - tickSize - padding - pointYMinSize : 0
                context.fillText(yPoints[0] + ',', x, y + topMargin -2)
                context.fillText(yPoints[1], x, y + topMargin + 9)
            }
        }
    }
    const drawMinYTick = (context: CanvasRenderingContext2D, minYTicks: number[]) => {
        var minYTicksStr = formatPoints(minYTicks)
        let pointYMin = minYTicksStr.slice(0, 2)
        context.fillStyle = blackStroke
        context.strokeStyle = blackStroke
        if (pointYMin.length === 1) {
            let pointYMinSize = context.measureText(pointYMin[0]).width
            let xYMin = leftMargin - tickSize - padding - pointYMinSize > 0 ? leftMargin - tickSize - padding - pointYMinSize : 0            
            context.fillText(pointYMin[0], xYMin, height + topMargin)
        }
        else {
            let pointYMinSize1 = context.measureText(pointYMin[0]).width
            let pointYMinSize2 = context.measureText(pointYMin[1]).width
            let pointYMinSize = pointYMinSize1 > pointYMinSize2 ? pointYMinSize1 : pointYMinSize2
            let xYMin = leftMargin - tickSize - padding - pointYMinSize > 0 ? leftMargin - tickSize - padding - pointYMinSize : 0
            context.fillText(pointYMin[0] + ',', xYMin, height + topMargin - 11)
            context.fillText(pointYMin[1], xYMin, height + topMargin)
        }
    }
    const drawMaxYTick = (context: CanvasRenderingContext2D, maxYTicks: number[]) => {
        var maxYTicksStr = formatPoints(maxYTicks)
        var pointYMax = maxYTicksStr.slice(0, 2)
        context.fillStyle = blackStroke
        context.strokeStyle = blackStroke
        if (pointYMax.length === 1) {
            let pointYMaxSize = context.measureText(pointYMax[0]).width
            let xYMax = leftMargin - tickSize - padding - pointYMaxSize > 0 ? leftMargin - tickSize - padding - pointYMaxSize : 0
            context.fillText(pointYMax[0], xYMax, topMargin + 9)
        }
        else {
            let pointYMaxSize1 = context.measureText(pointYMax[0]).width
            let pointYMaxSize2 = context.measureText(pointYMax[1]).width
            let pointYMaxSize = pointYMaxSize1 > pointYMaxSize2 ? pointYMaxSize1 : pointYMaxSize2
            let xYMax = leftMargin - tickSize - padding - pointYMaxSize > 0 ? leftMargin - tickSize - padding - pointYMaxSize : 0
            context.fillText(pointYMax[0] + ',', xYMax, topMargin - 2)
            context.fillText(pointYMax[1], xYMax, topMargin + 9)
        }
    }
    const drawYTicks = (context: CanvasRenderingContext2D) => {
        const minYTicks = yAxis.points[0]
        const maxYTicks = yAxis.points[yAxis.points.length - 1]

        drawMinYTick(context, minYTicks)
        drawMaxYTick(context, maxYTicks)
        interpolateYTicks(context, minYTicks, maxYTicks)

    }
    const interpolateXTicks = (context: CanvasRenderingContext2D, minXTicks: number[], maxXTicks: number[]) => {
        const xNumOfTicks = xAxis.points[0].length
        let xTicksDistance: number[] = []
        for (let i = 0; i < xNumOfTicks; i++) {
            let distance = maxXTicks[i] - minXTicks[i]
            xTicksDistance.push(distance)
        }
        for (let n = 1; n < numOfTicks - 1; n++) {
            let x = (width / (numOfTicks - 1) * n) + leftMargin
            context.fillStyle = grayStroke
            context.strokeStyle = grayStroke
            context.moveTo(x, topMargin);
            context.lineTo(x, height + topMargin + 5);
            context.stroke();

            let interpXPoints = xAxis.points[0]?.map((x, i) => x + (xTicksDistance[i] / 5 * n))
            let interpXPointsStr = formatPoints(interpXPoints)
            let point = interpXPointsStr?.slice(0, 2).join(', ')
            let pointSize = context.measureText(point).width
            x = x - (pointSize / 2) > 0 ? x - (pointSize / 2) : 0
            context.fillStyle = blackStroke
            context.strokeStyle = blackStroke
            context.font = font;
            context.fillText(point, x, height + topMargin + 15)
        }
    }
    const drawMinXTick = (context: CanvasRenderingContext2D, minXTicks: number[]) => {
        let minXTicksStr = formatPoints(minXTicks)
        let pointXMin = minXTicksStr.slice(0, 2).join(', ')
        let pointXMinSize = context.measureText(pointXMin).width
        let xXMin = leftMargin - (pointXMinSize / 2) > 0 ? leftMargin - (pointXMinSize / 2) + 18 : 0
        context.font = font;
        context.fillText(pointXMin, xXMin, height + topMargin + 15)
    }
    const drawMaxXTick = (context: CanvasRenderingContext2D, maxXTicks: number[]) => {
        let maxXTicksStr = formatPoints(maxXTicks)
        var pointXMax = maxXTicksStr.slice(0, 2).join(', ')
        var pointXMaxSize = context.measureText(pointXMax).width
        context.font = font;
        context.fillText(pointXMax, width + leftMargin - (pointXMaxSize / 2), height + topMargin + 15)
    }
    const drawXTicks = (context: CanvasRenderingContext2D) => {
        const minXTicks = xAxis.points[0]
        const maxXTicks = xAxis.points[xAxis.points.length - 1]

        drawMinXTick(context, minXTicks)
        drawMaxXTick(context, maxXTicks)
        interpolateXTicks(context, minXTicks, maxXTicks)
    }
    const drawSuperimposedVerticesCircles = (context: CanvasRenderingContext2D, x0: number, y0: number, x1: number, y1: number, x2: number, y2: number, s: number, xL: number, yL: number, xLabel: number, yLabel: number) => {
        context.beginPath();
        context.moveTo(x0, y0)
        context.lineTo(x1, y1)
        context.lineTo(x2, y2)
        context.arc(x1, y1, radius, s * Math.PI, (s + 0.5) * Math.PI);
        context.fillStyle = grayStroke
        context.strokeStyle = grayStroke
        context.fill();

        let superimposedValues = simulationPoints.filter(x => x.xIndex == xLabel && x.yIndex == yLabel)
        let superimposedValue = superimposedValues.length > 0 ? superimposedValues[0].superimposedValues[superimposedSelected].value : ""
        let superImposedValueSize = context.measureText(superimposedValue).width
        let x = xL === 0 ? -(superImposedValueSize + 10) : xL
       
        context.fillText(superimposedValue, x1 + x, y1 + yL)
    }
    const drawSuperimposedVerticesValues = (context: CanvasRenderingContext2D) => {
        //Params: Canvas Contex, Starting point coordinates, first line coordinates, second line coordinate, arc starting point, x and y indexes for the label
        drawSuperimposedVerticesCircles(context, leftMargin, topMargin + radius, leftMargin, topMargin, leftMargin + radius, topMargin, 0, 10, 25, 0, pointsYMax)
        drawSuperimposedVerticesCircles(context, width + leftMargin - radius, topMargin, width + leftMargin, topMargin, width + leftMargin, topMargin, 0.5, 0, 25, pointsXMax, pointsYMax)
        drawSuperimposedVerticesCircles(context, width + leftMargin, height + topMargin - radius, width + leftMargin, height + topMargin, width + leftMargin - radius, height + topMargin, 1, 0, -15, pointsXMax, 0)
        drawSuperimposedVerticesCircles(context, leftMargin + radius, height + topMargin, leftMargin, height + topMargin, leftMargin, height + topMargin - radius, 1.5, 10, -15, 0, 0)
    }
    const drawSuperimposedXSidesValues = (context: CanvasRenderingContext2D, xSteps: number[]) => {
        xSteps.map((x, i) => {
            let xCircle = width * x
            context.beginPath()
            context.arc(xCircle + leftMargin, topMargin, radius, 0 * Math.PI, 1 * Math.PI);
            context.fill();

            let superimposedValues1 = simulationPoints.filter(x => x.xIndex === i + 1 && x.yIndex === pointsYMax)
            let superimposedValue1 = superimposedValues1.length > 0 ? superimposedValues1[0].superimposedValues[superimposedSelected].value : ""
            let superImposedValueSize1 = context.measureText(superimposedValue1).width
            context.fillText(superimposedValue1, xCircle + leftMargin - (superImposedValueSize1 / 2), topMargin + 25)

            context.beginPath()
            context.arc(xCircle + leftMargin, height + topMargin, radius, 1 * Math.PI, 0 * Math.PI);
            context.fill();

            let superimposedValues2 = simulationPoints.filter(x => x.xIndex === i + 1 && x.yIndex === 0)
            let superimposedValue2 = superimposedValues2.length > 0 ? superimposedValues2[0].superimposedValues[superimposedSelected].value : ""
            let superImposedValueSize2 = context.measureText(superimposedValue2).width
            context.fillText(superimposedValue2, xCircle + leftMargin - (superImposedValueSize2 / 2), height + topMargin - 15)
        })
    }
    const drawSuperimposedYSidesValues = (context: CanvasRenderingContext2D, ySteps: number[]) => {
        ySteps.map((y, i) => {
            let yCircle = height * y
            context.beginPath()
            context.arc(leftMargin, height + topMargin - yCircle, radius, 1.5 * Math.PI, 0.5 * Math.PI);
            context.fill();

            let superimposedValues1 = simulationPoints.filter(x => x.xIndex === 0 && x.yIndex === i + 1)
            let superimposedValue1 = superimposedValues1.length > 0 ? superimposedValues1[0].superimposedValues[superimposedSelected].value : ""
            context.fillText(superimposedValue1, leftMargin + 5, height + topMargin - yCircle - 15)

            context.beginPath()
            context.arc(width + leftMargin, height + topMargin - yCircle, radius, 0.5 * Math.PI, 1.5 * Math.PI);
            context.fill();

            let superimposedValues2 = simulationPoints.filter(x => x.xIndex === pointsXMax && x.yIndex === i + 1)
            let superimposedValue2 = superimposedValues2.length > 0 ? superimposedValues2[0].superimposedValues[superimposedSelected].value : ""
            let superImposedValueSize2 = context.measureText(superimposedValue2).width
            context.fillText(superimposedValue2, width + leftMargin - (superImposedValueSize2) - 5, height + topMargin - yCircle - 15)
        })
    }
    const drawSuperimposedInterpolatedValues = (context: CanvasRenderingContext2D, xSteps: number[], ySteps: number[]) => {
        xSteps.map((x, ix) => {
            ySteps.map((y, iy) => {
                let xCircle = width * x
                let yCircle = height * y
                context.beginPath()
                context.arc(xCircle + leftMargin, height + topMargin - yCircle, radius, 0 * Math.PI, 2 * Math.PI);
                context.fill();

                let superimposedValues = simulationPoints.filter(x => x.xIndex === ix + 1 && x.yIndex === iy + 1)
                let superimposedValue = superimposedValues.length > 0 ? superimposedValues[0].superimposedValues[superimposedSelected].value : ""
                let superImposedValueSize = context.measureText(superimposedValue).width
                context.fillText(superimposedValue, xCircle + leftMargin - (superImposedValueSize / 2), height + topMargin - yCircle - 15)
            })
        })
    }
    const drawSuperimposedValues = (context: CanvasRenderingContext2D, data: number[]) => {
        drawSuperimposedVerticesValues(context)

        let xSteps = xAxis.steps.slice(1, -1)
        let ySteps = yAxis.steps.slice(1, -1)
        if (xSteps.length > 0 && ySteps.length > 0) {
            drawSuperimposedXSidesValues(context, xSteps)
            drawSuperimposedYSidesValues(context, ySteps)
            drawSuperimposedInterpolatedValues(context, xSteps, ySteps)
        }
    }
    const drawXAxisLabel = (context: CanvasRenderingContext2D) => {
        let label = xAxis.displayNames.slice(0, 2).join(', ')
        let labelSize = context.measureText(label).width
        let x = leftMargin + (width / 2) - (labelSize / 2)
        context.fillText(label, x, topMargin + height + 35)
    }
    const drawYAxisLabel = (context: CanvasRenderingContext2D) => {
        context.save();
        context.translate(10, topMargin + height);
        context.rotate(-Math.PI / 2);
        let label = yAxis.displayNames.slice(0, 2).join(', ')
        let labelSize = context.measureText(label).width
        let x = (height / 2) - (labelSize / 2)
        context.fillText(label, x, padding)
        context.restore();

    }
    const drawLegend = (context: CanvasRenderingContext2D) => {
        dimensions.map((x, i) => {
            context.beginPath()
            context.fillStyle = x.color
            context.fillRect(leftMargin + width + 10, topMargin + (20 * i), 10, 10)

            context.fillStyle = blackStroke
            context.strokeStyle = blackStroke
            let label = x.name
            let labelSize = context.measureText(label).width
            context.fillText(label, leftMargin + width + 25, topMargin + 10 + (20 * i))
        })
    }
    const calculateLeftMargin = (context: CanvasRenderingContext2D) : number => {
        let yAxisString: string[][] = []
        yAxis.points.forEach(x => {
            yAxisString.push(formatPoints(x))
        })
        let yLabelSizes: number[] = []
        yAxisString.forEach(x => {
            let point = x.slice(0, 1).join(', ')
            yLabelSizes.push(context.measureText(point).width)
        })
        let maxYlabelSize = Math.max(...yLabelSizes)
        return maxYlabelSize
    }

    useEffect(() => {
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            if (data && context && data.length > 0) {

                context.clearRect(0, 0, canvas.width, canvas.height);

                let maxYLabelSize = calculateLeftMargin(context)
                leftMargin += maxYLabelSize

                context.fillStyle = blackStroke
                context.strokeStyle = blackStroke
                drawPlane(context, data)
                drawSides(context, data)
                drawXAxisLabel(context)
                drawYAxisLabel(context)
                drawXTicks(context)
                drawYTicks(context)
                drawSuperimposedValues(context, data)
                drawLegend(context)
            }
        }
    }, [data, superimposedSelected]);

    useEffect(() => {
        const element = canvasRef.current;

        if (element) {
            element.addEventListener('click', function (e) {
                getCursorPosition(element, e, leftMargin, topMargin)
            });

            return () => {
                element.removeEventListener('click', function (e) {
                    getCursorPosition(element, e, leftMargin, topMargin)
                });
            };
        }

    }, []);

    return <canvas ref={canvasRef} id='ostCanvas' height={height + topMargin + bottomMargin} width={width + leftMargin + rightMargin} />;
};

ResultOst.defaultProps = {
    width: window.innerWidth,
    height: window.innerHeight
};

export default ResultOst
