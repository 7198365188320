import { observer } from 'mobx-react-lite'
import { RouteComponentProps } from '@reach/router'
import * as React from 'react'
import { StorageResult } from '../../results-details'
import AugmentationCapacityChart from './AugmentationCapacityChart'
import AugmentationCostChart from './AugmentationCostChart'
import ContextHelp from '../../../helper/ContextHelp'
import AugmentationTable from './AugmentationTable'

interface ResultAugmentationProps extends RouteComponentProps {
    result: StorageResult,
    allowAugmentation: boolean
    baseYear: number
    intlCulture: string
    showBaseYearAsYearZero: boolean
}

const ResultAugmentation = observer(({ result, allowAugmentation, baseYear, intlCulture, showBaseYearAsYearZero }: ResultAugmentationProps) => {
    return (
        <div className='row m-2'>
            <div className='col-6'>
                <AugmentationCapacityChart result={result} allowAugmentation={allowAugmentation} baseYear={baseYear} showBaseYearAsYearZero={showBaseYearAsYearZero} />
            </div>
             <div className='col-6'>
                {allowAugmentation && <AugmentationCostChart result={result} baseYear={baseYear} showBaseYearAsYearZero={showBaseYearAsYearZero} />}
            </div>
            <div className={(allowAugmentation ? 'col-12' : 'col-6 mt-5')}>
                <div>
                    <div className='float-right mb-1'>
                        {allowAugmentation && <ContextHelp helpId='AUGCOST' />}
                        {!allowAugmentation && <ContextHelp helpId='DEGRADATIONTAB' />}
                    </div>
                    <AugmentationTable items={result.items} allowAugmentation={allowAugmentation} baseYear={baseYear} intlCulture={intlCulture} showBaseYearAsYearZero={showBaseYearAsYearZero} />
                </div>
            </div>
        </div>
    )
})

export default ResultAugmentation
