import { useState, useEffect, ChangeEvent, FocusEvent, ClipboardEvent } from 'react'
import { StrValidationFunc } from './StringValidation'
import * as React from 'react'

interface StringInputProps {
    value: string
    onChange?: (x: string) => void
    onEnter?: () => void

    id?: string
    className?: string
    disabled?: boolean,
    autoFocus?: boolean

    validator?: StrValidationFunc
    onPaste?: (x: ClipboardEvent<HTMLInputElement>) => void
    textArea?: boolean
}


const StringInput = ({ value, onChange, onEnter, id, className, disabled, autoFocus, validator, onPaste, textArea = false }: StringInputProps) => {
    const [text, setText] = useState(value)
    const [errors, setErrors] = useState<string[]>([])

    useEffect(() => {
        setText(value)
    }, [value])

    const commit = (s: string) => {
        const error = validator?.(s) ?? []
        setErrors(error)
        onChange?.(s)      
    }

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setText(ev.target.value)
        setErrors([])
    }

    const handleBlur = (ev: FocusEvent<HTMLInputElement>) => {
        commit(text)
    }

    const c = 'form-control ' +
        (errors.length > 0 ? 'is-invalid ' : '') +
        (className ?? '')

    return (
        <>{!textArea && <>
            <input type='text' id={id} className={c} disabled={disabled}
                value={text}
                onChange={handleChange}
                onBlur={handleBlur}
                autoFocus={autoFocus}
            />
            {errors.length > 0 &&
                <div className='invalid-tooltip text-keep-lines'>
                    {errors.join('\n')}
                </div>}
            </>}
            {textArea && <>
                <textarea id={id} className={c} disabled={disabled}
                    value={text}
                    onChange={ev => handleChange}
                    onBlur={ev => handleBlur}
                    autoFocus={autoFocus}
                />
                {errors.length > 0 &&
                    <div className='invalid-tooltip text-keep-lines'>
                        {errors.join('\n')}
                    </div>}
            </>}
        </>
    )
}


export default StringInput
