import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { BiSpaceBar, BiZoomIn, BiZoomOut, BiChevronLeft, BiChevronRight } from 'react-icons/all'
import { Chart, ChartSeries, ChartSeriesItem, ChartXAxis, ChartXAxisItem, ChartYAxis, ChartYAxisItem, DragEndEvent, ZoomEndEvent, ChartTooltip, SharedTooltipContext, TooltipContext } from '@progress/kendo-react-charts'
import { Range, defaultRange, ensureBounds, ensureMinLength, zoomIn, zoomOut, makeRange, round, panLeft, panRight } from '../project/pages/Results/time-series/Range'
import { IntlProvider, IntlService, LocalizationProvider } from 'Kendo-Intl-5'
import * as React from 'react'
import { TimeSeries } from '../project/model/TimeSeries'
import RangeSelector from '../project/pages/Results/time-series/RangeSelector'

interface TimeSeriesViewProps {
    model: TimeSeries,
    chartTitle?: string,
    yAxisTitle?: string,
    intlCulture: string
}


const AXIS_NAME = 'x'
const START_DATE = new Date(2007, 0, 1)

const dateFormatter = new Intl.DateTimeFormat('en', { month: 'short', day: 'numeric' })
const timeFormatter = new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric', hour12: false })

const formatLabel = (ms: number, rangeHours: number): string => {
    if (rangeHours < 24 * 7) {
        return dateFormatter.format(ms) + '\n' + timeFormatter.format(ms)
    }
    return dateFormatter.format(ms)
}

const getMajorUnits = (rangeHours: number): number => {
    // 1 day -> each hour
    if (rangeHours < 24) {
        return 1
    }
    // 2 days -> each hour
    if (rangeHours < 24 * 2) {
        return 4
    }
    // 1 week -> each 12 hours
    else if (rangeHours < 24 * 7) {
        return 12
    }
    // 4 weeks -> each day
    else if (rangeHours < 24 * 7 * 2) {
        return 24
    }
    // 3 months -> each week
    else if (rangeHours < 24 * 30 * 3) {
        return 24 * 7
    }
    // year -> each month
    else {
        return 24 * 30
    }
}

const MIN_RANGE = 12

const TimeSeriesView = observer(({ model, chartTitle, yAxisTitle, intlCulture }: TimeSeriesViewProps) => {
    // x -> index, y -> value
    const data = model.data.map((x, index) => [index, x])

    const [range, setRange] = useState(defaultRange())
    const [bounds, setBounds] = useState(defaultRange())

    useEffect(() => {
        const l = model.data.length
        setBounds(makeRange(0, l))
        setRange(makeRange(0, l))
    }, [model.data, model.data.length])

    const setNewRange = (range: Range) => {
        let r = round(range)
        r = ensureMinLength(r, MIN_RANGE)
        r = ensureBounds(r, bounds)
        setRange(r)
    }

    const handleZoomEnd = (ev: ZoomEndEvent) => {
        const axis = ev.axisRanges[AXIS_NAME]
        let r = makeRange(Number(axis.min), Number(axis.max))
        setNewRange(r)
    }

    const handleDragEnd = (ev: DragEndEvent) => {
        const isZooming = ev.nativeEvent.event?.shiftKey ?? false
        if (isZooming) return
        const axis = ev.axisRanges[AXIS_NAME]
        let r = makeRange(Number(axis.min), Number(axis.max))
        setNewRange(r)
    }

    const handlePanLeft = () => { setNewRange(panLeft(range)) }
    const handlePanRight = () => { setNewRange(panRight(range)) }
    const handleZoomIn = () => { setNewRange(zoomIn(range)) }
    const handleZoomOut = () => { setNewRange(zoomOut(range)) }
    const handleResetZoom = () => { setRange(bounds) }

    const handleRangeChange = (r: Range) => { setRange(r) }

    const stepsPerHour = model.data.length / (365 * 24)
    const rangeHours = (range.max - range.min) / stepsPerHour

    const labelContent = (e: any): string => {
        const step = Number(e.value)
        const ms = (step / stepsPerHour) * 60 * 60 * 1000
        const t = START_DATE.getTime() + ms
        return formatLabel(t, rangeHours)
    }
    const intl = new IntlService(intlCulture)

    const tooltipContent = (context: TooltipContext | SharedTooltipContext) => {
        if (!context.hasOwnProperty('point')) return
        const point = (context as TooltipContext).point
        const y = (intl.formatNumber(point.value.y, 'n2') + (yAxisTitle ? (" " + `(${yAxisTitle})`) : ''))
        return (<div>{y}</div>)
    }

    return (
        <div>
            <div className='d-flex flex-row mb-1 justify-content-end'>
                <h6 className='m-auto'>{chartTitle ?? ''}</h6>
                <button className='btn btn-sm btn-outline-primary btn-no-focus mr-1 pillButton' onClick={handlePanLeft}><BiChevronLeft /></button>
                <button className='btn btn-sm btn-outline-primary btn-no-focus pillButton' onClick={handlePanRight}><BiChevronRight /></button>
            </div>
            <div className='d-flex flex-row'>
                <div className='flex-grow-1'>
                    <LocalizationProvider language={intlCulture} >
                        <IntlProvider locale={intlCulture} >
                            <Chart style={{ height: 200 }} renderAs='canvas' transitions={false}
                                pannable={{ lock: 'y' }}
                                zoomable={{ mousewheel: { lock: 'y' }, selection: { lock: 'y' } }}
                                onDragEnd={handleDragEnd}
                                onZoomEnd={handleZoomEnd}>
                                <ChartXAxis>
                                    <ChartXAxisItem name={AXIS_NAME} min={range.min} max={range.max}
                                        labels={{ content: labelContent, rotation: 'auto' }}
                                        majorUnit={getMajorUnits(rangeHours) * stepsPerHour} />
                                </ChartXAxis>
                                <ChartYAxis>
                                    <ChartYAxisItem title={{ text: yAxisTitle ?? '' }} />
                                </ChartYAxis>
                                <ChartSeries>
                                    <ChartSeriesItem type='scatterLine' name='' data={data} markers={{ visible: false }} />
                                </ChartSeries>
                                <ChartTooltip render={tooltipContent} />
                            </Chart>
                        </IntlProvider >
                    </LocalizationProvider>
                    <RangeSelector range={range} bounds={bounds}
                        onRangeChange={handleRangeChange} />
                </div>
                <div className='d-flex flex-column ml-1'>
                    <button className='btn btn-sm btn-outline-primary mb-1 pillButton' onClick={handleZoomIn}><BiZoomIn /></button>
                    <button className='btn btn-sm btn-outline-primary mb-1 pillButton' onClick={handleZoomOut}><BiZoomOut /></button>
                    <button className='btn btn-sm btn-outline-primary mb-1 pillButton' onClick={handleResetZoom}><BiSpaceBar /></button>
                </div>
            </div>
        </div>
    )
})

export default TimeSeriesView
