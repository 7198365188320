import {ChangeEvent} from 'react'
import './FilePicker.css'
import { readFile } from '../../utils'
import * as React from 'react'


interface FilePickerProps {
    onSelect?: (content: string, file: File) => void
    label?: string
    types?: string
    disabled?: boolean
}


const FilePicker = ({onSelect, label, types, disabled}: FilePickerProps) => {
    const handleSelectFile = async (ev: ChangeEvent<HTMLInputElement>) => {
        if (disabled) {
            ev.preventDefault()
            return
        }
        const files = ev.target.files
        const file = files![0]
        const content = await readFile(file)
        onSelect?.(content, file)
    }

    const css = `btn btn-file btn-primary pillButton ${disabled ? 'disabled' : ''}`

    return (
        <button className={css} type='button'>
            {label ?? 'Import...'}
            <input accept={types} type='file' disabled={disabled}
                   onChange={handleSelectFile}/>
        </button>
    )
}

export default FilePicker
