import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { MultiYearItem } from './model/MultiYearVar'
import { RegulationMarket } from './model/RegulationMarket'
import { RateSchedule } from './model/Schedule'
import { TimeSeries } from './model/TimeSeries'
import { CostTableType } from './model/CostTable'
import Currency from '../../Currency'
import { ProjectStatus } from './ProjectStatus'
import { ComponentKind, deserializeComplexCostTable, MeasurementUnits } from '../../services/inputs'
import { allEnergyMarkets, EnergyMarket, EnergyMarketKind } from './model/EnergyMarket'
import { CapacityMarket } from './model/CapacityMarket'
import { Contract } from './model/Contract'
import { Solar } from './model/Solar'
import { Wind } from './model/Wind'
import { Storage } from './model/Storage'
import { Inverter } from './model/Inverter'
import { SensitivityVar } from './model/SensitivityVar'
import { Resource } from './model/Resource'
import { createIncentive, Incentive, IncentiveKind } from './model/Incentive'
import { Commitments } from './model/Commitments'
import api, { WindTurbineSpec } from '../../services/api'
import { SchematicComponent, Schematics } from './SchematicModel'
import { ResultModel } from './results'
import { analyze, AnalyzerItem } from './analyze'
import { Location } from './model/Location'

export type BusKind = 'ac' | 'dc'

export const allElectricityMarkets: string[] = ['CAISO', 'ERCOT', 'NY-ISO', 'PJM', 'ISO-NE', 'SPP', 'MISO', 'Other']

export const allCurrencies: Currencies[] = Currency

export interface Currencies {
    CountryAndCurrency: string;
    Code: string;
    Symbol: string;
}

export enum DecimalSeparator {
    comma,
    dot
}

export enum DateFormatLabel {
    MMddyyyy = '01-30-2030',
    ddMMyyyy = '30-01-2030',
    yyyyMMdd = '2030-01-30',
}

export enum DateFormat {
    MMddyyyy = 'MM-dd-yyyy',
    ddMMyyyy = 'dd-MM-yyyy',
    yyyyMMdd = 'yyyy-MM-dd',
}

type NavCallback = (from: string, to: string) => Promise<void>

export const MAX_SIMULATION_WARNING_THRESHOLD = 500
export const MAX_SIMULATION_ERROR_THRESHOLD = 5000

export class Project {
    constructor(public id: string, private onNavCallback?: NavCallback) {
        makeObservable(this)
    }

    //@observable readonly: boolean = false

    @observable status: ProjectStatus = new ProjectStatus(this.id,
        async (f: string, t: string) => {
            await onNav(this, f, t)
            this.onNavCallback?.(f, t)
        })

    location: Location = new Location()
    measurementUnits?: MeasurementUnits
    fileVersion: number = 0
    @observable currency: string = ''
    @observable dateFormat: number = 0
    @observable useCommaAsDecimalPoint: boolean = false
    @observable applyShiftDays: boolean = true
    @observable isNewSystemConverter: boolean | null = true;
    @observable currencySymbol: string = ''

    @observable name: string = ''
    @observable author: string = ''
    @observable notes: string = ''
    @observable expectedDate: Date = new Date()
    @observable noticeToProceed: Date = new Date()
    @observable electricityMarket: string = allElectricityMarkets[0]

    @observable useEnergyMarket: boolean = false
    @observable useCapacityMarket: boolean = false
    @observable useContract: boolean = false
    @observable useRegulationMarket: boolean = false
    @observable useSystemSizer: boolean = false

    @observable energyMarkets: EnergyMarket[] = observable([])
    @observable capacityMarkets: CapacityMarket[] = observable([])
    @observable contracts: Contract[] = observable([])
    //@observable regulationMarket: RegulationMarket = new RegulationMarket()
    @observable regulationMarkets: RegulationMarket[] = observable([])

    @observable useSolar: boolean = false
    @observable useWind: boolean = false
    @observable useStorage: boolean = false
    @computed get hasSystemConverter(): boolean { return this.bus === 'dc' }

    @observable solarStartYear: number = 1
    @observable solarEndYear: number = 2
    @observable windStartYear: number = 1
    @observable windEndYear: number = 2
    @observable storageStartYear: number = 1
    @observable storageEndYear: number = 2
    @observable useComponentLifetimes: boolean = false

    @observable solar: Solar[] = observable([])
    @observable wind: Wind[] = observable([])
    @observable storage: Storage[] = observable([])
    @observable inverter: Inverter = new Inverter()

    @observable bus: BusKind = 'ac'
    @observable canBatteryChargeFromGrid: string = 'true'
    @observable interconnectionLimit: number = 1 // MW
    @observable hasImportLimit: boolean = false
    @observable importLimit: number = 999
    @observable hasImportLimitTimeSeries: boolean = false
    @observable importLimitTimeSeriesData: TimeSeries = new TimeSeries()
    @observable importLimitTimeSeriesDataName: string = 'Import Limit Data'
    @observable hasImportLimitSchedule: boolean = false
    @observable importLimitSchedule: RateSchedule = new RateSchedule()
    @computed get importLimitsDisabled(): boolean { return ((this.useSolar && this.useStorage && this.canBatteryChargeFromGrid === "false") || !this.useStorage) }

    @observable acLineEfficiencySensitivity: SensitivityVar = new SensitivityVar()
    @observable acTransfomerEfficiencySensitivity: SensitivityVar = new SensitivityVar()

    @observable hasExportLimit: boolean = true
    @observable exportLimit: number = 999
    @observable hasExportLimitTimeSeries: boolean = false
    @observable exportLimitTimeSeriesData: TimeSeries = new TimeSeries()
    @observable exportLimitTimeSeriesDataName: string = 'Export Limit Data'
    @observable hasExportLimitSchedule: boolean = false
    @observable exportLimitSchedule: RateSchedule = new RateSchedule()

    @observable solarResource: Resource = new Resource()
    @observable solarResourceName: string = 'Solar Resource Imported Data'
    @observable windResource: Resource = new Resource()
    @observable windResourceName: string = 'Wind Resource Imported Data'
    @observable temperatureResource: Resource = new Resource()
    @observable temperatureResourceName: string = 'Temperature Resource Imported Data'

    @observable systemFixedCost: number = 0
    @observable systemOmCost: number = 0
    @observable discountRate: number = 0
    @observable inflationRate: number = 0
    @observable lifetime: number = 25
    @observable timeStepSize: number = 60

    @observable incentives: Incentive[] = observable([])

    @observable commitments: Commitments[] = observable([])
    @observable orderOfCommitments: Commitments[] = observable([])

    @observable earlyAnalysis: boolean = true
    @computed get baseYear(): number { return this.expectedDate.getFullYear() }

    @observable locked: boolean = false
    @observable lockId: string = ''

    @action setName(x: string) { this.name = x }
    @action setAuthor(x: string) { this.author = x }
    @action setNotes(x: string) { this.notes = x }
    @action setCurrency(x: string, y: string) { this.currency = x; this.currencySymbol = y }
    @action setUseCommaAsDecimalPoint(x: boolean) { this.useCommaAsDecimalPoint = x }
    @action setApplyShiftDays(x: boolean) { this.applyShiftDays = x }
    @action setDateFormat(x: number) { this.dateFormat = x }
    @action setExpectedDate(x: Date) { this.expectedDate = x }
    @action setNoticeToProceed(x: Date) { this.noticeToProceed = x }
    @action setElectricityMarket(x: string) { this.electricityMarket = x }
    @action setEarlyAnalysis(x: boolean) {
        this.earlyAnalysis = x;
        if (x === true) {
            if (this.useComponentLifetimes === true)
                this.useComponentLifetimes = false
            if (this.canBatteryChargeFromGrid === "ITC") {
                this.canBatteryChargeFromGrid = "true";
            }
            for (let sol of this.solar) {
                sol.degradation.setEnabled(false)
                sol.omEscalator.setEnabled(false)
                sol.converter.omEscalator.setEnabled(false)
                sol.converter.replacementEscalator.setEnabled(false)
            }
            for (let wind of this.wind) {
                wind.omEscalator.setEnabled(false)
            }
            for (let bess of this.storage) {
                bess.omEscalator.setEnabled(false)
                bess.augmentationPriceDecline.setEnabled(false)
                bess.converter.omEscalator.setEnabled(false)
                bess.converter.replacementEscalator.setEnabled(false)
                bess.auxiliaryLoadPriceMultiYear.setEnabled(false)
                bess.rteDegradationOverTime.setEnabled(false)
                bess.allowAugmentation = true
            }
            for (let em of this.energyMarkets) {
                em.setUseMultiYearPrice(false)
                em.priceEscalator.setEnabled(false)
                em.importPriceEscalator.setEnabled(false)
            }
            for (let cm of this.capacityMarkets) {
                cm.capacityEscalator.setEnabled(false)
                cm.energyEscalator.setEnabled(false)
            }
            for (let tod of this.contracts) {
                tod.energyPriceEscalator.setEnabled(false)
                tod.shortagePenaltyPriceEscalator.setEnabled(false)
            }
            //this.solarStartYear = 0
            //this.windStartYear = 0
            //this.storageStartYear = 0
            //this.solarEndYear = this.lifetime
            //this.windEndYear = this.lifetime
            //this.storageEndYear = this.lifetime
        }
    }
    @action setUseEnergyMarket(x: boolean) {
        this.useEnergyMarket = x
        if (this.useEnergyMarket && this.energyMarkets.length === 0) {
            this.energyMarkets.push(new EnergyMarket(this.lifetime))
            this.addCommitments('Day-ahead energy market')
        }
        else if (this.energyMarkets.length === 1 && !this.commitments.map(i => i.name === this.energyMarkets[0].name)) {
            this.commitments.filter(x => x.name === this.energyMarkets[0].name) && this.addCommitments(`${this.energyMarkets[0].name} energy market`)
        }
        this.analyze()
    }
    @action setImportLimitTimeSeriesDataName(x: string) { this.importLimitTimeSeriesDataName = x }
    @action setExportLimitTimeSeriesDataName(x: string) { this.exportLimitTimeSeriesDataName = x }
    @action setSolarResourceName(x: string) { this.solarResourceName = x }
    @action setWindResourceName(x: string) { this.windResourceName = x }
    @action setTemperatureResourceName(x: string) { this.temperatureResourceName = x }

    @action setSolarStartYear(x: number) { this.solarStartYear = x }
    @action setWindStartYear(x: number) { this.windStartYear = x }
    @action setStorageStartYear(x: number) { this.storageStartYear = x }
    @action setSolarEndYear(x: number) {
        this.solarEndYear = x
        this.analyze()
    }
    @action setWindEndYear(x: number) {
        this.windEndYear = x
        this.analyze()
    }
    @action setStorageEndYear(x: number) {
        this.storageEndYear = x
        this.analyze()
    }

    @action setUseComponentLifetimes(x: boolean) {
        this.useComponentLifetimes = x
        //if (x === true) {
        //    if (this.solar.length > 0) {
        //        this.solar[0].sizing.absoluteValues.slice(1)
        //        this.solar[0].customSizeArray.absoluteValues.slice(1)
        //        if (this.solar.length > 1) {
        //            this.solar.slice(1)
        //        }
        //    }
        //    if (this.wind.length > 0) {
        //        this.wind[0].sizing.absoluteValues.slice(1)
        //        this.wind[0].customSizing.absoluteValues.slice(1)
        //        if (this.wind.length > 1) {
        //            this.wind.slice(1)
        //        }
        //    }
        //}
        //    this.analyze()
    }

    @action setUseRegulationMarket(x: boolean) {
        this.useRegulationMarket = x
        if (this.useRegulationMarket && this.regulationMarkets.length === 0) {
            this.regulationMarkets.push(new RegulationMarket())
            this.addCommitments('Regulation market')
        }
        else if (this.useRegulationMarket && this.commitments.filter(i => i.name === 'Regulation market')?.length === 0) {
            this.addCommitments('Regulation market')
        }
        else {
            this.removeCommitments('Regulation market')
        }
        this.analyze()
    }

    @action addNewRegulationMarket() {
        this.regulationMarkets.push(new RegulationMarket())
    }
    @action removeRegulationMarket(id: string) {
        const i = this.regulationMarkets.findIndex(x => x.id === id)
        if (i === -1) { return }
        this.regulationMarkets.splice(i, 1)
        if (this.regulationMarkets.length === 0) {
            this.setUseRegulationMarket(false)
        }
    }

    @action setUseCapacityMarket(x: boolean) {
        this.useCapacityMarket = x
        if (this.useCapacityMarket && this.capacityMarkets.length === 0) {
            this.capacityMarkets.push(new CapacityMarket(this.lifetime))
            this.addCommitments('Capacity market')
        }
        else if (this.useCapacityMarket && this.capacityMarkets.length > 0 && this.commitments.filter(i => i.name === 'Capacity market')?.length === 0) {
            this.addCommitments('Capacity market')
        }
        else {
            this.removeCommitments('Capacity market')
        }
        this.analyze()
    }

    @action removeCapacityMarket() {
        this.capacityMarkets = []
        this.setUseCapacityMarket(false)
    }

    @action removeContract() {
        this.contracts = []
        this.setUseContract(false)
    }

    @action setUseContract(x: boolean) {
        this.useContract = x
        if (this.useContract && this.contracts.length === 0) {
            this.contracts.push(new Contract(this.lifetime, this.id))
            this.addCommitments('Time of Delivery Contract')
        }
        else if (this.useContract && this.contracts.length > 0 && this.commitments.filter(i => i.name === 'Time of Delivery Contract').length === 0) {
            this.addCommitments('Time of Delivery Contract')
        }
        else {
            this.removeCommitments('Time of Delivery Contract')
        }
        this.analyze()
    }

    setUseSystemSizer(value: boolean) {
        this.useSystemSizer = value;
    }

    @computed get availableEnergyMarkets(): EnergyMarketKind[] {
        const current = this.energyMarkets.map(x => x.kind)
        const available = allEnergyMarkets.filter(x => !current.includes(x))
        return available
    }

    @computed get canAddEnergyMarket(): boolean { return this.availableEnergyMarkets.length > 0 }
    @computed get canAddRegulationMarket(): boolean { return this.useEnergyMarket }

    @computed get totalEnergyAllocation(): number {
        return this.energyMarkets.map(x => x.allocation).reduce((acc, x) => acc + x)
    }

    @action getEstimatedCalculationTime(): number {
        //based on https://github.com/Underwriters-Labs/renewables.homer/wiki/Front-Performance-Testing

        let calcServiceThreadCount = Number(api.calcServiceThreadCount)

        let n = 8
        let b = 30
        let s = 1
        let v = !isNaN(calcServiceThreadCount) ? calcServiceThreadCount : 4
        let c = 1
        let estimate = 0

        //number of years
        let y = this.earlyAnalysis ? 1 : this.lifetime

        //sensitivities
        //resource scale??
        if (this.acLineEfficiencySensitivity.estimateSimulations() > 1)
            s *= this.acLineEfficiencySensitivity.estimateSimulations()
        if (this.acTransfomerEfficiencySensitivity.estimateSimulations() > 1)
            s *= this.acTransfomerEfficiencySensitivity.estimateSimulations()
        if (this.useCapacityMarket && this.capacityMarkets[0].capacityPriceSensitivity.estimateSimulations() > 1)
            s *= this.capacityMarkets[0].capacityPriceSensitivity.estimateSimulations()
        if (this.hasSystemConverter) {
            if (this.inverter.efficiency.estimateSimulations() > 0)
                s *= this.inverter.efficiency.estimateSimulations()
            if (this.inverter.lifetime.estimateSimulations() > 0)
                s *= this.inverter.lifetime.estimateSimulations()
        }
        this.energyMarkets.forEach(x => {
            if (x.priceAverage.estimateSimulations() > 1)
                s *= x.priceAverage.estimateSimulations()
        })
        if (this.energyMarkets.length > 0) {
            if (this.useStorage && this.energyMarkets[0].maxStorageCommitmentSensitivity.estimateSimulations() > 1)
                s *= this.energyMarkets[0].maxStorageCommitmentSensitivity.estimateSimulations()
            if (this.useWind && this.energyMarkets[0].windAllocationSensitivity.estimateSimulations() > 1)
                s *= this.energyMarkets[0].windAllocationSensitivity.estimateSimulations()
            if (this.useSolar && this.energyMarkets[0].pvAllocationSensitivity.estimateSimulations() > 1)
                s *= this.energyMarkets[0].pvAllocationSensitivity.estimateSimulations()
        }
        this.regulationMarkets.forEach(x => {
            if (x.useRegUp && x.maxCommitmentRegUp.estimateSimulations() > 1)
                s *= x.maxCommitmentRegUp.estimateSimulations()
            if (x.useRegDown && x.maxCommitmentRegDown.estimateSimulations() > 1)
                s *= x.maxCommitmentRegDown.estimateSimulations()
            if (x.useRegUp && x.minRegUpPrice.estimateSimulations() > 1)
                s *= x.minRegUpPrice.estimateSimulations()
            if (x.useRegDown && x.minRegDownPrice.estimateSimulations() > 1)
                s *= x.minRegDownPrice.estimateSimulations()
            if (x.useEnergyPrice && x.throughputPercentage.estimateSimulations() > 1)
                s *= x.throughputPercentage.estimateSimulations()
        })
        this.solar.forEach(x => {
            if (x.deratingFactor.estimateSimulations() > 1)
                s *= x.deratingFactor.estimateSimulations()
            if (x.cost.simple.items.length > 1)
                s *= x.cost.simple.items.length
            if (x.hasConverter) {
                if (x.converter.efficiency.estimateSimulations() > 0)
                    s *= x.converter.efficiency.estimateSimulations()
                if (x.converter.lifetime.estimateSimulations() > 0)
                    s *= x.converter.lifetime.estimateSimulations()
            }
        })
        this.wind.forEach(x => {
            if (x.hubHeight.estimateSimulations() > 1)
                s *= x.hubHeight.estimateSimulations()
            if (x.lifetime.estimateSimulations() > 1)
                s *= x.lifetime.estimateSimulations()
            if (x.cost.simple.items.length > 1)
                s *= x.cost.simple.items.length
        })
        this.storage.forEach(x => {
            if (x.augmentationDegradationLimit.estimateSimulations() > 1)
                s *= x.augmentationDegradationLimit.estimateSimulations()
            if (x.cycleLimitPerDay.estimateSimulations() > 1)
                s *= x.cycleLimitPerDay.estimateSimulations()
            if (x.auxiliaryLoadSensitivity.estimateSimulations() > 1)
                s *= x.auxiliaryLoadSensitivity.estimateSimulations()
            if (x.cost.simple.items.length > 1)
                s *= x.cost.simple.items.length
            if (x.hasConverter) {
                if (x.converter.efficiency.estimateSimulations() > 0)
                    s *= x.converter.efficiency.estimateSimulations()
                if (x.converter.lifetime.estimateSimulations() > 0)
                    s *= x.converter.lifetime.estimateSimulations()
            }
        })
        if (s === 0)
            s = 1

        //optimization combinations
        let pv = 1
        if (this.useSolar)
            this.solar.forEach(x => {//temporarily limiting PV and wind to only 1 component and 1 size with component lifetimes
                pv *= this.useComponentLifetimes ? 1 : x.kind === ComponentKind.homer ? x.sizing.estimateSimulations() : x.customSizeArray.estimateSimulations()
                if (x.hasConverter)
                    pv *= x.converter.sizing.estimateSimulations()
            })
        let wind = 1
        if (this.useWind)
            this.wind.forEach(x => {
                wind *= this.useComponentLifetimes ? 1 : x.kind === 'production' ? x.customSizing.estimateSimulations() : x.sizing.estimateSimulations()
            })

        c = pv * wind

        if (!this.useStorage) {
            estimate = n * y * Math.ceil(s * c / v)
            return estimate
        }

        //number of markets
        //are obligations markets?
        let m = this.energyMarkets.length + this.capacityMarkets.length + this.regulationMarkets.length

        //difficult conversion - how to determine it is still unknown
        let d = 1

        //time step/cycle factor
        let f = 0
        let t = 60 / this.timeStepSize
        let l = 0 //!this.useStorage ? 0 : this.storage.every(x => x.optimizeCycleLimitPerDay === false) ? 0 : 1
        if (this.useStorage)
            this.storage.forEach(x => {
                let tempStorage = x.sizing.estimateSimulations()
                if (x.hasConverter)
                    tempStorage *= x.converter.sizing.estimateSimulations()

                l = x.useCycleLimitPerDay && x.optimizeCycleLimitPerDay ? 1 : 0

                if (l === 0 || t === 1)
                    f = t * 1.2
                else
                    f = (1.6 * Math.E) ** (0.98 * t)
                estimate += b * y * Math.ceil(s * c * tempStorage / v) * 1.2 * m * f * d
            })

        return estimate
    }

    @action addNewEnergyMarket(kind: EnergyMarketKind) {
        const item = new EnergyMarket(this.lifetime)
        item.kind = kind
        if (this.energyMarkets.length > 0) {
            let max = Math.max(...this.energyMarkets.map(i => i.allocationSensitivity.values.length))
            for (let i = 0; i <= max - 2; i++) {
                item.allocationSensitivity.addNewValue()
            }
            let maxStorage = Math.max(...this.energyMarkets.map(i => i.maxStorageCommitmentSensitivity.values.length))
            for (let i = 0; i <= maxStorage - 2; i++) {
                item.maxStorageCommitmentSensitivity.addNewValue()
            }
            let maxWind = Math.max(...this.energyMarkets.map(i => i.windAllocationSensitivity.values.length))
            for (let i = 0; i <= maxWind - 2; i++) {
                item.windAllocationSensitivity.addNewValue()
            }
            let maxPv = Math.max(...this.energyMarkets.map(i => i.pvAllocationSensitivity.values.length))
            for (let i = 0; i <= maxPv - 2; i++) {
                item.pvAllocationSensitivity.addNewValue()
            }
        }
        this.energyMarkets.push(item)
        this.energyMarkets.sort((a, b) => a.kind > b.kind ? 1 : -1)
    }

    @action removeEnergyMarket(id: string) {
        const i = this.energyMarkets.findIndex(x => x.id === id)
        if (i === -1) { return }
        this.energyMarkets.splice(i, 1)
        if (this.energyMarkets.length === 0) {
            this.useEnergyMarket = false
        }
    }

    @action
    async setUseSolar(x: boolean) {
        this.useSolar = x
        if (this.useSolar && this.solar.length === 0) {
            await this.addNewSolar()
        }
        if (!this.useStorage || !this.useSolar || this.useWind)
            this.bus = 'ac'
    }

    @action
    async setUseWind(x: boolean) {
        this.useWind = x
        if (this.useWind && this.wind.length === 0) {
            await this.addNewWind()
        }
        if (!this.useStorage || !this.useSolar || this.useWind)
            this.bus = 'ac'
    }

    @action
    async setUseStorage(x: boolean) {
        this.useStorage = x
        if (this.useStorage && this.storage.length === 0) {
            await this.addNewStorage()
        }
        if (!this.useStorage || !this.useSolar || this.useWind)
            this.bus = 'ac'
    }

    @action setIsNewSystemConverter(x: boolean) {
        this.isNewSystemConverter = x;
    }
    @computed get projectLabel(): string {
        if (this.useSolar && this.useWind && this.useStorage) return 'PV + Wind + Storage'
        else if (!this.useSolar && this.useWind && this.useStorage) return 'Wind + Storage'
        else if (this.useSolar && !this.useWind && this.useStorage) return 'PV + Storage'
        else if (this.useSolar && this.useWind && !this.useStorage) return 'PV + Wind'
        else if (this.useStorage) return 'Storage'
        else if (this.useWind) return 'Wind'
        else if (this.useSolar) return 'PV'
        else return ''
    }

    @action setBus(x: BusKind) {
        this.bus = x
        if (x === 'dc' && this.isNewSystemConverter) {
            this.inverter.cost.simple.items[0].capital = 50;
            this.inverter.cost.simple.items[0].operating = 0;
            this.inverter.cost.simple.items[0].replacement = 50;
        }
    }
    @action setBatteryCanChargeFromGrid(x: boolean) { if (x === true) this.canBatteryChargeFromGrid = 'true' }
    @action setBatteryCantChargeFromGrid(x: boolean) { if (x === true) this.canBatteryChargeFromGrid = 'false' }
    @action setBatteryChargeFromGridItcRestriction(x: boolean) { if (x === true) this.canBatteryChargeFromGrid = 'ITC' }

    @action setInterconnectionLimit(x: number) { this.interconnectionLimit = x }

    @action setHasImportLimit(x: boolean) { this.hasImportLimit = x }
    @action setImportLimit(x: number) { this.importLimit = x }
    @action setHasImportLimitTimeSeries(x: boolean) { this.hasImportLimitTimeSeries = x }
    @action setHasImportLimitSchedule(x: boolean) { this.hasImportLimitSchedule = x }

    @action setHasExportLimit(x: boolean) { this.hasExportLimit = x }
    @action setExportLimit(x: number) { this.exportLimit = x }
    @action setHasExportLimitTimeSeries(x: boolean) { this.hasExportLimitTimeSeries = x }
    @action setHasExportLimitSchedule(x: boolean) { this.hasExportLimitSchedule = x }

    @action
    async addNewSolar() {
        const item = new Solar()
        const costs = await api.getSolarCostItems()

        runInAction(() => {
            item.cost.complex = deserializeComplexCostTable(costs)
            item.cost.costTableType = CostTableType.complex

            item.converter.cost.simple.items[0].capital = 50;
            item.converter.cost.simple.items[0].operating = 0;
            item.converter.cost.simple.items[0].replacement = 50;

            item.deratingFactor.values[0].value = 80;
            this.solar.push(item)
        })
    }

    @action removeSolar(id: string) {
        const i = this.solar.findIndex(x => x.id === id)
        if (i === -1) { return }
        this.solar.splice(i, 1)
        if (this.solar.length === 0) { this.useSolar = false }
    }

    @action
    async addNewWind() {
        const item = new Wind()
        let specs = await api.listWindTurbineSpecs()
        specs = convertPowerCurveToMW(specs)

        runInAction(() => {
            if (specs.length > 0) {
                const spec = specs[0]
                item.setModel(spec.name)
                item.setSpecs(spec)
                item.hubHeight.setSingleValue(spec.hubHeight)
            }

            this.wind.push(item)
        })
    }

    @action removeWind(id: string) {
        const i = this.wind.findIndex(x => x.id === id)
        if (i === -1) { return }
        this.wind.splice(i, 1)
        if (this.wind.length === 0) { this.useWind = false }
    }

    @action
    async addNewStorage() {
        const item = new Storage()
        const costs = await api.getStorageCostItems()

        const specs = await api.listStorageSpecs()
        const defaultCosts = [150, 137, 123, 110, 103, 97, 91, 85, 79, 74, 70, 66, 62, 59, 55, 52, 49, 47, 44, 42, 41, 40, 39, 38, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37]
        runInAction(() => {
            if (specs.length > 0) {
                const spec = specs[0]
                item.setModel(spec.name)
                item.setSpecs(spec)
            }

            item.cost.complex = deserializeComplexCostTable(costs)
            item.cost.costTableType = CostTableType.complex
            for (var year = 0; year <= this.lifetime - 1; year++) {
                item.augmentationPriceDecline.customValues.push(new MultiYearItem({ year: year, value: defaultCosts[year] }))
            }
            item.converter.cost.simple.items[0].capital = 27;
            item.converter.cost.simple.items[0].operating = 0;
            item.converter.cost.simple.items[0].replacement = 27;

            this.storage.push(item)
        })
    }

    @action removeStorage(id: string) {
        const i = this.storage.findIndex(x => x.id === id)
        if (i === -1) { return }
        this.storage.splice(i, 1)
        if (this.storage.length === 0) { this.useStorage = false }
    }

    @action setSystemFixedCost(x: number) { this.systemFixedCost = x }
    @action setSystemOmCost(x: number) { this.systemOmCost = x }
    @action setDiscountRate(x: number) { this.discountRate = x }
    @action setInflationRate(x: number) { this.inflationRate = x }
    @action setTimeStepSize(x: number) { this.timeStepSize = x }

    @action setLifetime(x: number) {
        this.lifetime = x

        // update all multi-year variables
        for (const multiYearVar of this.allMultiYearVars()) {
            multiYearVar.updateLifetime(x)
        }

        //update ProjectYearsValid from EnergyMarket MultiYearPrice
        this.energyMarkets.forEach((market, marketIndex) => {
            if (market.multiYearPrice !== undefined && market.multiYearPrice.length > 0) {
                if (market.multiYearPrice[0].projectYearsValid.length >= x) {
                    market.multiYearPrice.forEach((price, priceIndex) => {
                        this.energyMarkets[marketIndex].multiYearPrice[priceIndex].projectYearsValid = price.projectYearsValid.slice(0, x)
                    })
                }
                else {
                    market.multiYearPrice.forEach((price, priceIndex) => {
                        const yearsToAdd = x - price.projectYearsValid.length
                        for (let y = 0; y < yearsToAdd; y++)
                            this.energyMarkets[marketIndex].multiYearPrice[priceIndex].projectYearsValid.push(false)
                    })
                }
            }
        })
        //this.solar.forEach(s => { //lifetime per technology or per component?
        //    if (s.startYear > x)
        //        s.startYear = 1
        //    if (s.endYear > x)
        //        s.endYear = x
        //})
        //this.wind.forEach(s => {
        //    if (s.startYear > x)
        //        s.startYear = 1
        //    if (s.endYear > x)
        //        s.endYear = x
        //})
        //this.storage.forEach(s => {
        //    if (s.startYear > x)
        //        s.startYear = 1
        //    if (s.endYear > x)
        //        s.endYear = x
        //})
        this.solarStartYear = 1
        this.windStartYear = 1
        this.storageStartYear = 1
        this.solarEndYear = x
        this.windEndYear = x
        this.storageEndYear = x
    }

    * allMultiYearVars() {
        for (const energyMarket of this.energyMarkets) {
            yield energyMarket.priceEscalator
        }
        for (const contract of this.contracts) {
            yield contract.energyPriceEscalator
            yield contract.shortagePenaltyPriceEscalator
        }
        for (const capacityMarket of this.capacityMarkets) {
            yield capacityMarket.capacityEscalator
            yield capacityMarket.energyEscalator
        }
        for (const solar of this.solar) {
            yield solar.omEscalator
            yield solar.degradation
            if (solar.converter) {
                yield solar.converter.omEscalator
                yield solar.converter.replacementEscalator
            }
        }
        for (const storage of this.storage) {
            yield storage.omEscalator
            yield storage.augmentationPriceDecline
            if (storage.converter) {
                yield storage.converter.omEscalator
                yield storage.converter.replacementEscalator
            }
        }
        if (this.inverter) {
            yield this.inverter.omEscalator
            yield this.inverter.replacementEscalator
        }
    }

    estimateTotalSimulations(): EstimatedCalculations {
        const equipmentSensitivities = this.estimateEquipmentSensitivities()
        const equipmentOptimizations = this.estimateEquipmentOptimizations()
        const marketSensitivities = this.estimateMarketSensitivities()
        const totalCalculations = equipmentOptimizations * equipmentSensitivities * marketSensitivities * (this.earlyAnalysis ? 1 : this.lifetime)
        return { EquipmentOptimizations: equipmentOptimizations, EquipmentSensitivities: equipmentSensitivities, RevenueSensitivities: marketSensitivities, TotalCalculations: totalCalculations }
    }

    estimateEquipmentSensitivities(): number {
        const solars = this.useSolar ? this.solar.map(x => x.estimateSensitivitySimulations()) : []
        const winds = this.useWind ? this.wind.map(x => x.estimateSensitivitySimulations()) : []
        const storages = this.useStorage ? this.storage.map(x => x.estimateSensitivitySimulations()) : []
        const converter = this.hasSystemConverter ? [this.inverter.estimateSensitiviySimulations()] : []
        const resources = [
            this.useSolar && this.solar.some(x => x.kind === ComponentKind.homer) ? this.solarResource.estimateSensitivitySimulations() : 1,
            this.useSolar && this.solar.some(x => x.kind === ComponentKind.homer) ? this.temperatureResource.estimateSensitivitySimulations() : 1,
            this.useWind && this.wind.some(x => x.kind !== 'production') ? this.windResource.estimateSensitivitySimulations() : 1,
        ]
        const interconnection = [this.acLineEfficiencySensitivity.estimateSimulations() * this.acTransfomerEfficiencySensitivity.estimateSimulations()]
        const all = [...solars, ...winds, ...storages, ...converter, ...resources, ...interconnection]
        const sensitivities = all.reduce((a, b) => a * b, 1)
        return sensitivities
    }

    estimateEquipmentOptimizations(): number {
        const solars = this.useSolar ? this.solar.map(x => x.estimateOptimizationSimulations()) : []
        const winds = this.useWind ? this.wind.map(x => x.estimateOptimizationSimulations()) : []
        const storages = this.useStorage ? this.storage.map(x => x.estimateOptimizationSimulations()) : []
        const converter = this.hasSystemConverter ? [this.inverter.estimateOptimizationSimulations()] : []

        const all = [...solars, ...winds, ...storages, ...converter]
        const optimizations = all.reduce((a, b) => a * b, 1)
        return optimizations
    }

    estimateMarketSensitivities(): number {
        const energyMarkets = this.useEnergyMarket ? [this.energyMarkets[0].estimateSensitivitySimulations()] : []
        const capacityMarkets = this.useCapacityMarket ? [this.capacityMarkets[0].estimateSimulations()] : []
        const regulationMarkets = this.useRegulationMarket ? this.regulationMarkets.map(x => x.estimateSensitivitySimulations()) : []

        const all = [...energyMarkets, ...capacityMarkets, ...regulationMarkets]
        const sensitivities = all.reduce((a, b) => a * b, 1)
        return sensitivities
    }

    //estimateTotalSimulationTime(): number {
    //    const timePerSimulation = 5 // seconds
    //    const time = this.estimateTotalSimulations() * timePerSimulation / 60 // minutes
    //    return Math.trunc(time)
    //}


    @action addNewIncentive(kind: IncentiveKind) {
        const x = createIncentive(kind)
        this.incentives.push(x)
    }

    @action removeIncentive(id: string) {
        const i = this.incentives.findIndex(x => x.id === id)
        if (i === -1) { return }
        this.incentives.splice(i, 1)
    }

    // commitments
    @action addCommitments(cmt: string) {
        var recCmt = ['Capacity market', 'Time of Delivery Contract', 'Day-ahead energy market', 'Fifteen-minute energy market', 'Real-time energy market', 'Regulation market'];
        let newCmt = new Commitments()
        newCmt.name = cmt;
        newCmt.reCorder = recCmt.indexOf(cmt) + 1;
        this.commitments.push(newCmt)
        this.updateOrderOfCommitments()
    }

    @action updateOrderOfCommitments() {
        var ordRecCmt = ['Capacity market', 'Time of Delivery Contract', 'Energy markets', 'Energy and Regulation markets'];
        var cmtToRpl = ['Day-ahead energy market', 'Fifteen-minute energy market', 'Real-time energy market', 'Regulation market']
        var recCmt: string[] = []
        this.commitments.forEach(x => {
            if (!cmtToRpl.includes(x.name))
                recCmt.push(x.name)
        })
        if (this.useEnergyMarket && this.useRegulationMarket)
            recCmt.push('Energy and Regulation markets')
        else if (this.useEnergyMarket)
            recCmt.push('Energy markets')
        this.orderOfCommitments = []
        recCmt.map(x => {
            let newOrdCmt = new Commitments()
            newOrdCmt.name = x;
            newOrdCmt.reCorder = ordRecCmt.indexOf(x) + 1;
            this.orderOfCommitments.push(newOrdCmt)
        })
    }

    @action removeCommitments(cmt: string) {
        const i = this.commitments.findIndex(x => x.name === cmt)
        if (i === -1) { return }
        this.commitments.splice(i, 1)
        this.updateOrderOfCommitments()
    }
    // SCHEMATICS:
    @computed get schematics(): Schematics {
        const solar: SchematicComponent[] = this.useSolar ?
            this.solar.map(x => ({ id: x.id, kind: 'solar', hasConverter: x.hasConverter })) : []
        const storage: SchematicComponent[] = this.useStorage ?
            this.storage.map(x => ({ id: x.id, kind: 'storage', hasConverter: x.hasConverter })) : []
        const wind: SchematicComponent[] = this.useWind ?
            this.wind.map(x => ({ id: x.id, kind: 'wind', hasConverter: false })) : []
        return {
            bus: this.bus,
            components: solar.concat(storage).concat(wind),
        }
    }


    // RESULTS:

    @observable results: ResultModel = new ResultModel(this.id)


    // VALIDATION:

    @observable issues: AnalyzerItem[] = observable([])
    @computed get errors(): AnalyzerItem[] { return this.issues.filter(x => !x.warning && !x.estimation) }
    @computed get currentStepErrors(): AnalyzerItem[] {
        return this.issues.filter(x => (!x.warning && !x.estimation && x.link?.includes(this.status.steps[this.status.currentStep].url)))
    }
    @action estimatedCalculationTime(): AnalyzerItem[] { return this.issues.filter(x => x.estimation) }

    @computed get warnings(): AnalyzerItem[] { return this.issues.filter(x => x.warning) }
    @computed get currentStepWarnings(): AnalyzerItem[] {
        return this.warnings.filter(x => (x.link?.includes(this.status.steps[this.status.currentStep].url)))
    }
    @computed get hasErrors(): boolean { return this.errors.length > 0 }
    @computed get hasWarnings(): boolean { return this.warnings.length > 0 }

    @observable ignoreWarnings: boolean = false
    @action setIgnoreWarnings() { this.ignoreWarnings = true }
    @computed get canNotCalculate(): boolean {
        return this.issues.filter(x => !x.warning && !x.estimation).length > 0 || (this.issues.filter(x => x.warning).length > 0 && !this.ignoreWarnings)
    }

    @action analyze() {
        this.issues.splice(0, this.issues.length, ...analyze(this))
    }

    @observable restartCalculation: boolean = false
    @action setRestartCalculation(x: boolean) { this.restartCalculation = x }
}

export interface EstimatedCalculations {
    EquipmentSensitivities: number
    EquipmentOptimizations: number
    RevenueSensitivities: number
    TotalCalculations: number
}


async function onNav(project: Project, from: string, to: string): Promise<void> {
    project.analyze()
}

function convertPowerCurveToMW(windTurbines: WindTurbineSpec[]): WindTurbineSpec[] {
    return windTurbines.map(turbine => ({
        ...turbine,
        powerCurve: turbine.powerCurve.map(item => ({
            ...item,
            y: item.y / 1000
        }))
    }));
}
