import './SensitivityVarView.scss'
import {observer} from 'mobx-react-lite'
import {SensitivityVar} from '../../model/SensitivityVar'
import * as React from 'react'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import NumberInput from '../../../helper/NumberInput'
import { ValidationFunc } from '../../../helper/validation'
import SensitivityVarIcon from './SensitivityVarIcon'
import ButtonAdd from '../../../helper/ButtonAdd'
import ButtonDelete from '../../../helper/ButtonDelete'


interface SensitivityVarViewProps {
    model: SensitivityVar
    label: string
    units: string
    validator?: ValidationFunc
    isNumHide?: boolean
    hideLabel?: boolean
    useCommaAsDecimalPoint: boolean
    isDisabled?: boolean
    customClass?: string
    labelWidth?: string
}


const SensitivityVarView = observer(({ model, label, units, validator, isNumHide, useCommaAsDecimalPoint, hideLabel = false, isDisabled = false, customClass = "", labelWidth = "" }: SensitivityVarViewProps) => {
    const [show, setShow] = useState(false)
    const handleOpen = () => setShow(true)
    const handleClose = () => setShow(false)

    return (<>
        <div className={`form-group row align-items-end ${customClass}`}>
            {!isNumHide && !hideLabel && <div className={(labelWidth !== '' ? labelWidth :'col-5')}>{label}</div>}
            {!isNumHide && <div className={hideLabel ? 'col-9 d-flex align-items-end' : 'col-3'}>
                <NumberInput value={model.values[0].value}
                    onChange={x => model.values[0].setValue(x)}
                    validator={validator}
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint}
                    disabled={isDisabled}
                />
            </div>}
            <div className={hideLabel ? 'col-3' : 'col-2'}>
                <SensitivityVarIcon count={model.values.length} onClick={handleOpen}/>
            </div>
        </div>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>{label}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SensitivityTable model={model} header={units} validator={validator} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
            </Modal.Body>
            <Modal.Footer>
                <Button className='pillButton' onClick={handleClose}>Save &amp; Close</Button>
            </Modal.Footer>
        </Modal>
    </>)
})

export default SensitivityVarView


interface SensitivityTableProps {
    model: SensitivityVar
    header: string
    validator?: ValidationFunc
    useCommaAsDecimalPoint: boolean
}

const SensitivityTable = observer(({ model, header, validator, useCommaAsDecimalPoint }: SensitivityTableProps) => {
    const handleAdd = () => model.addNewValue()
    const handleRemove = (id: string) => model.removeValue(id)

    return (
        <table className='table-bordered table-hover table-sm sensitivity-var-table'>
            <thead>
                <tr>
                    <th>{header}</th>
                    <th><ButtonAdd onClick={handleAdd}/></th>
                </tr>
            </thead>
            <tbody>
                {model.values.map(item =>
                    <tr key={item.id}>
                        <td className='table-w-2 p-0 position-relative'>
                            <NumberInput value={item.value}
                                onChange={x => item.setValue(x)}
                                validator={validator}
                                className='h-100 border-0 rounded-0'
                                useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                        </td>
                        <td>
                            <ButtonDelete onClick={() => handleRemove(item.id)}/>
                        </td>
                    </tr>)}
            </tbody>
        </table>
    )
})
