import * as React from 'react'
import { useState } from 'react'
import {observer} from 'mobx-react-lite'
import { ComponentKind } from '../../../../services/inputs'
import { DeleteComponentState } from './shared/DeleteConfirmationModal'
import SolarView from './SolarView'
import { useProjectStore } from '../../ProjectProvider'
import { MultiImportState } from '../../../helper/MultiTimeSeriesImport'
import ResourceView from './ResourceView'
import ToolTipInfo from '../../../helper/ToolTipInfo'

interface solarGroupViewProps {
    onDelete: (componentToDelete: DeleteComponentState) => void
}

const SolarGroupView = observer(({ onDelete }: solarGroupViewProps) => {
    const store = useProjectStore()
    const project = store.project!

    const handleAdd = () => project.addNewSolar()

    const hasHomerPv = project.solar.some(x => x.kind === ComponentKind.homer)
    const [state, setState] = useState<MultiImportState>({ kind: 'blank' })
    const [fileData, setFileData] = useState<any[]>([])
    const handleDeleteFile = (id: number) => {
        var newFileData = fileData
        newFileData.splice(id, 1)
        setFileData(newFileData)
    }
    const handleSelect = async (sourceList: string[], customName: string[], customSize: number[], data: number[][], year: number, index: number) => {
        project.solar[index].customData.setData(data[0], year)
        project.solar[index].setCustomName(customName[0])
        project.solar[index].setCustomSize(customSize[0] / 1000)
        project.solar[index].customSizeArray.setFirstValue(customSize[0] / 1000)

        if (data.length > 1) {
            var pvCount = project.solar.length
            for (let i = 1; i < data.length; i++) {
                await project.addNewSolar()
                var pvIndex = i + pvCount - 1
                project.solar[pvIndex].setKind(ComponentKind.custom)
                project.solar[pvIndex].customData.setData(data[i], year)
                project.solar[pvIndex].setCustomName(customName[i])
                project.solar[pvIndex].setCustomSize(customSize[i] / 1000)
            }
        }

        await store.saveProject()
    }
    return (
        <>
            {!project.useComponentLifetimes && project.solar.map((x, index) => <React.Fragment key={x.id}>
                <div className='my-3'>
                    <SolarView model={x} index={index} state={state} setState={setState} fileData={fileData} setFileData={setFileData} onDelete={() => onDelete({ kind: 'PV', id:x.id })} handleSelect={handleSelect} handleDeleteFile={handleDeleteFile} />
                </div>
                <hr className='bg-dark'/>
            </React.Fragment>)}

            {project.useComponentLifetimes && project.solar.length > 0 && <React.Fragment key={project.solar[0].id}>
                <div className='my-3'>
                    <SolarView model={project.solar[0]} index={0} state={state} setState={setState} fileData={fileData} setFileData={setFileData} onDelete={() => onDelete({ kind: 'PV', id: project.solar[0].id })} handleSelect={handleSelect} handleDeleteFile={handleDeleteFile} />
                </div>
                <hr className='bg-dark' />
            </React.Fragment>}

            <div className='d-flex flex-column row align-items-center my-4'>
                <button className='btn btn-outline-primary pillButton' disabled={project.useComponentLifetimes} onClick={handleAdd}>Add another solar technology</button>{project.useComponentLifetimes && <ToolTipInfo label={'Only one PV is allowed for component lifetimes analysis'} />}
            </div>

            {hasHomerPv && <>
                <hr className='bg-dark'/>
                <div className='my-4'>
                    <ResourceView/>
                </div>
            </>}
        </>
    )
})


export default SolarGroupView
