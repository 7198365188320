import { diff_match_patch } from 'diff-match-patch'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { v4 } from 'uuid'
import { Project } from './model'
import { TimeSeries } from './model/TimeSeries'
import { EnergyMarketKind, getEnergyMarketKindLabel } from './model/EnergyMarket'
import api, { BaseProjectInfo, DBProjectStatus, ProjectInfo, UserData } from '../../services/api'
import { deserializeInputs, getMarketType, InputsDto, InputTimeSeriesDataDto, serializeInputs } from '../../services/inputs'

export class ProjectStore {
    constructor() { makeObservable(this) }

    @observable id: string | null = null
    @observable info: ProjectInfo | null = null
    @observable data: string | null = null
    @observable project: Project | null = null
    @observable timeseries: TimeSeries[] | null = null
    @observable userData: UserData | null = null

    @observable lockId: string = ''
    @observable readOnly: boolean | null = null
    @observable dbProjectStatus: DBProjectStatus = 0

    @observable showReadOnlyPopup: boolean = false
    @action updateShowReadOnlyPopup(x: boolean) {
        this.showReadOnlyPopup = x
    }
    
    @action setDbProjectStatus(x: number) {
        this.dbProjectStatus = x
    }
    @action
    async loadProject(id: string) {
        // reset
        this.id = id
        this.info = null
        this.data = null
        this.project = null
        this.userData = null
        this.lockId = v4()

        await this.updateLock(true)

        const userData = await api.readUserData()
        this.userData = userData

        await this.loadInfo(id)
        const data = await api.readRawData(id)
        const inputs = JSON.parse(data) as InputsDto

        var project = new Project(id, (f, t) => this.onNav(f, t))
        const projectData = await api.read(id)
        project.currency = projectData.currency
        project.currencySymbol = projectData.currencySymbol
        project.dateFormat = projectData.dateFormat
        project.useCommaAsDecimalPoint = projectData.useCommaAsDecimalPoint
        project.applyShiftDays = projectData.applyShiftDays
        this.dbProjectStatus = projectData.projectStatus

        inputs.wind = inputs.wind ? inputs.wind : []

        let timeseriesList: InputTimeSeriesDataDto[] = []
        for (const x of inputs.energyMarkets) {
            const marketType = getMarketType(x.kind)
            const timeseriesCount = await api.getInputTimeseriesCount(id, marketType)
            if (timeseriesCount.success && timeseriesCount.quantity !== undefined && timeseriesCount.quantity > 0) {
                for (let i = 0; i < timeseriesCount.quantity; i++) {
                    var timeseriesData = await api.readInputTimeSeries(id, marketType, i)
                    if (timeseriesData.success && timeseriesData.data !== undefined && timeseriesData.data.length > 0)
                        timeseriesList.push(timeseriesData)
                }
            }
        }
        
        await deserializeInputs(this.id, inputs, project, timeseriesList)
        runInAction(() => {
            this.id = id
            this.data = data
            this.project = project
            this.project.energyMarkets.map(i => {
                this.project?.addCommitments(`${getEnergyMarketKindLabel(i.kind as EnergyMarketKind)} energy market`)
            })
            if (this.project.useContract && this.project.contracts.length === 0) {
                this.project.addCommitments('Time of Delivery Contract')
            }
            else if (this.project.useContract && this.project.contracts.length > 0 && this.project.commitments.filter(i => i.name === 'Time of Delivery Contract').length === 0 && this.project.contracts.map(i => i.obligationKind)[0] !== 'none') {
                this.project.addCommitments('Time of Delivery Contract')
            }
            if (this.project.useCapacityMarket && this.project.capacityMarkets.length > 0 && this.project.commitments.filter(i => i.name === 'Capacity market')?.length == 0) {
                this.project.addCommitments('Capacity market')
            }
        })
    }

    async checkLock(beforeResults: boolean) {
        const info = await api.read(this.project!.id)
        const previousReadOnlyState = this.readOnly
        this.readOnly = info.lockId !== this.lockId
        this.dbProjectStatus = info.projectStatus
        if (!beforeResults)
            this.showReadOnlyPopup = this.showReadOnlyPopup === true ? true : previousReadOnlyState === false && this.readOnly === true
    }

    async loadInfo(id: string) {
        const info = await api.read(id)
        this.readOnly = info.lockId !== this.lockId
        this.dbProjectStatus = info.projectStatus
        runInAction(() => this.info = info)
    }

    @action
    async saveProject() {
        if (!this.project) return
        await this.checkLock(false)
        if (this.readOnly) return
        //todo load info
        await this.updateInfo()
        await this.putOrPatchData()
    }

    @action async updateLock(status: boolean, force: boolean = false) {
        let projectId = this.id
        let lockId = this.lockId
        if (projectId === undefined || projectId === null || projectId === '') return
        if (force)
            this.readOnly = false
        await api.updateLock(projectId, status, lockId, force)
    }

    @action
    async closeProject() {
        await this.saveProject()
        await this.updateLock(false)
        this.readOnly = null
        runInAction(() => {
            this.id = null
            this.info = null
            this.data = null
            this.project = null
        })
    }


    async onNav(from: string, to: string) {
        console.info(`navigate [${from}] -> [${to}]`)
        if (from !== 'results')
            { await this.saveProject() }
    }

    async updateInfo() {
        const p = this.project!
        const info: BaseProjectInfo = {
            name: p.name,
            notes: p.notes,
            author: p.author,
            earlyAnalysis: p.earlyAnalysis,
            location: p.location.address,
            currency: p.currency,
            currencySymbol: p.currencySymbol,
            useCommaAsDecimalPoint: p.useCommaAsDecimalPoint,
            applyShiftDays: p.applyShiftDays,
            dateFormat: p.dateFormat,
            phase: '',
            type: p.projectLabel,
            coupling: p.bus === 'ac' ? 'AC' : 'DC',
            dateCod: p.expectedDate.toISOString(),
            dateNtp: p.noticeToProceed.toISOString(),
            electricityMarket: p.location.isInternational ? p.location.countryName : p.electricityMarket,
            isNewSystemConverter: p.isNewSystemConverter,
        }
        await api.update(p.id, info)
        await this.loadInfo(p.id)
    //    this.readonly = this.lockId !== this.info?.lockId
    }
    
    async putOrPatchData() {
        const p = this.project!
        const inputs = serializeInputs(p)
        const data = JSON.stringify(inputs)

        const hashExistingData = await SHA1(this.data!)
        const hashNewData = await SHA1(data)

        const dmp = new diff_match_patch()
        const patches = dmp.patch_make(this.data!, data)
        const patch = dmp.patch_toText(patches)

        const patchResponse = await api.patchRawData(p.id, hashExistingData, hashNewData, patch)
        const successPatch = patchResponse === 200 || patchResponse === 204
        if (patchResponse === 200)
            this.setDbProjectStatus(1)

        if (!successPatch) {
            console.info('[patch] error -> [put]')
            const putResponse = await api.updateRawData(p.id, data)
            const successPut = putResponse === 200
            if (!successPut) {
                console.error(`[put] error ${p.id}`)
                return
            } else {
                console.info(`[put] OK`)
                this.setDbProjectStatus(1)
            }
        } else {
            console.info('[patch] OK')
        }

        // store last successfully saved data
        this.data = data
    }
}



async function SHA1(s: string): Promise<string> {
    // string -> utf-8 bytes
    const encoder = new TextEncoder()
    const data = encoder.encode(s)
    const hash = await crypto.subtle.digest('SHA-1', data)

    // bytes -> hex string
    const hashArray = Array.from(new Uint8Array(hash))
    const hashHex = hashArray.map(x => x.toString(16).padStart(2, '0')).join('')

    return hashHex
}
