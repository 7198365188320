import {observer} from 'mobx-react-lite'
import {Dropdown, Form, Modal, Row} from 'react-bootstrap'
import {useState} from 'react'
import {DatePicker, TimePicker} from '@progress/kendo-react-dateinputs'
import {GoCalendar, GoX} from 'react-icons/all'
import {IntlService} from 'Kendo-Intl-5'
import { MultiYearKind } from '../../../../services/inputs'
import { DeleteComponentState } from '../equipment/shared/DeleteConfirmationModal'
import * as React from 'react'
import { useProjectStore } from '../../ProjectProvider'
import { CapacityMarket, PriceUnit, RaEvent } from '../../model/CapacityMarket'
import ContextHelp from '../../../helper/ContextHelp'
import NumberField from '../../../helper/NumberField'
import NumberInput from '../../../helper/NumberInput'
import ScheduleEditor from '../../capacityMarket/ScheduleEditor'
import MultiYearView from '../SharedComponents/MultiYearView'
import { EnergyMarket, getEnergyMarketKindLabel } from '../../model/EnergyMarket'
import ButtonDelete from '../../../helper/ButtonDelete'
import SensitivityVarView from '../EnergyMarket/SensitivityVarView'

interface CapacityMarketCollectionProps {
    onDelete: (componentToDelete: DeleteComponentState) => void
}

const CapacityMarketCollection = ({ onDelete }: CapacityMarketCollectionProps) => {
    const project = useProjectStore().project!
    const useCommaAsDecimalPoint = project.useCommaAsDecimalPoint

    return <CapacityMarketView model={project.capacityMarkets[0]} useCommaAsDecimalPoint={useCommaAsDecimalPoint} onDelete={onDelete} />
}

export default observer(CapacityMarketCollection)


interface CapacityMarketProps {
    model: CapacityMarket
    useCommaAsDecimalPoint: boolean
    onDelete: (componentToDelete: DeleteComponentState) => void
}


const CapacityMarketView = observer(({ model, useCommaAsDecimalPoint, onDelete }: CapacityMarketProps) => {
    const handleAddEvent = (date: Date, duration: number) => model.addEvent(date, duration)
    const handleDeleteEvent = (id: string) => model.removeEvent(id)

    const project = useProjectStore().project!
    const currency = project.currencySymbol ?? "$"
    const intlCulture = project.useCommaAsDecimalPoint ? 'es' : 'en'
    const applyPriceUnitCurrency = (priceUnit: PriceUnit | string = PriceUnit.mwYr): string => {
        let priceString: string = priceUnit
        let updatedPrice = priceString.replace("$", currency)
        return updatedPrice
    }
    const onSelectMarket = () => {
        model.setEnergyPriceKind('market')
        if (model.energyMarket === null || !project.energyMarkets.includes(model.energyMarket)) {
            model.setEnergyMarket(project.energyMarkets[0])
        }
    }

    const onSelectFlat = () => {
        model.setEnergyPriceKind('flat')
        model.setEnergyMarket(null)
    }

    const handleScheduleChange = (x: number[][]) => model.setSchedule(x)

    return (
        <>
            <div className='form-group d-flex mb-2' id={model.id}>
                <div className={'border rounded p-2 ' + (model.eventKind === 'random' ? 'w-50 mr-1' : 'w-100')}>
                    <div className='d-flex justify-content-start'>
                        <h6 className='mr-2'>Capacity Market Events</h6>
                        <ContextHelp helpId='EVENTS' />
                        {model.eventKind !== 'random' && <>
                            <div className='m-auto' />
                            <button className='btn btn-outline-danger ml-2 pillButton' onClick={() => onDelete({ kind: 'Capacity Market', id: '' })}><GoX /></button>
                        </> }

                    </div>
                    <p>Define when capacity market events are called (random or scheduled)</p>
                    <div className='form-group row no-gutters'>
                        <div className='mr-3'>
                            <Form.Check custom label='Random Events' id='random' type='radio'
                                        checked={model.eventKind === 'random'}
                                        onChange={ev => ev.target.checked && model.setEventKind('random')}/>
                        </div>
                        <div className='mr-3'>
                            <Form.Check custom label='Specific Dates' id='scheduled' type='radio'
                                        checked={model.eventKind === 'scheduled'}
                                        onChange={ev => ev.target.checked && model.setEventKind('scheduled')}/>
                        </div>
                    </div>
                    {model.eventKind === 'random' && <>
                        <NumberField label='Number of events per year' value={model.totalCallsPerYear} onChange={x => model.setTotalCallsPerYear(x)} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                        <NumberField label='Event duration (hours)' value={model.averageCallDuration} onChange={x => model.setAverageCallDuration(x)} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                        <div className='form-group row no-gutters align-items-center'>
                            Selected from the
                            <span style={{ maxWidth: '60px' }} className='mx-2'>
                                <NumberInput value={model.numberOfHighestPriceDays} onChange={x => model.setNumberOfHighestPriceDays(x)} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                                </span>
                            highest energy prices
                        </div>
                    </>}
                    {model.eventKind === 'scheduled' &&
                        <div className='form-group row'>
                            <div className='col-5'>
                                <div className='d-flex flex-column align-items-center'>
                                    <div className='d-flex flex-column align-items-center border border-info rounded p-2'>
                                        <div>Selected Days</div>
                                        <div>
                                            {model.events.length === 0 && <em>none</em>}
                                            {model.events.map(x =>
                                                <RaEventView key={x.id} model={x}
                                                    onDelete={() => handleDeleteEvent(x.id)}
                                                    intlCulture={intlCulture}/>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-4'>
                                <NewRaEventView onAdd={handleAddEvent}/>
                            </div>
                        </div>}
                </div>
                {model.eventKind === 'random' &&
                    <div className='border rounded w-50 ml-1 p-2'>
                        <Row className='ml-2 mr-2'>
                            <p>Select the window(s) in which the event may occur</p>
                            <div className='m-auto' />
                            <button className='btn btn-outline-danger pillButton' onClick={() => onDelete({ kind: 'Capacity Market', id: '' })}><GoX /></button>
                        </Row>
                        <ScheduleEditor value={model.schedule} onChange={handleScheduleChange} id={model.id} radioLabel1="May occur" radioLabel2="Will not occur" />
                    </div>}
            </div>
            <div className='form-group d-flex mb-2'>
                <div className='border rounded w-50 mr-1 p-2'>
                    <div className='d-flex justify-content-start'>
                        <h6 className='mr-2'>Capacity Price</h6>
                        <ContextHelp helpId='CAPACITYPRICE'/>
                    </div>
                    <div className='form-group row align-items-center no-gutters'>
                        <div className='col-3'>Define Capacity </div>
                        <div className='col-2'>
                            <Dropdown>
                                <Dropdown.Toggle className='pillButton' variant='outline-primary' id='dropdown-basic'>
                                    {applyPriceUnitCurrency(model.priceUnit)}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {(Object.values(PriceUnit) as string[]).map(x =>
                                        <Dropdown.Item key={x} onClick={() => { model.setPriceUnit(x) }}>
                                            {applyPriceUnitCurrency(x)}
                                        </Dropdown.Item>)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className='col-2 ml-2'>
                            <NumberInput value={model.price.average} onChange={x => model.price.setAverage(x)} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                        </div>
                        {model.priceUnit.endsWith('-mo') &&
                            <div className='col-1 mr-4' style={{ minWidth: '70px' }}>
                                <MonthlyValuesView model={model} />
                            </div>}
                        <div className='col-1 mt-1'>
                            <CapacityValuesView model={model} useCommaAsDecimalPoint={useCommaAsDecimalPoint}></CapacityValuesView>
                            </div>
                    </div>
                    <MultiYearView model={model.capacityEscalator} label='Capacity price escalator (%/yr)' id='capacityMarketCapacityPricesEscalator'/>
                    <NumberField label='Contracted storage capacity (%)' value={model.allocatedStorageCapacity} onChange={x => model.setAllocatedStorageCapacity(x)} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                </div>
                <div className='border rounded w-50 ml-1 p-2'>
                    <div className='d-flex justify-content-start'>
                        <h6 className='mr-2'>Energy Price</h6>
                        <ContextHelp helpId='ENERGYPRICE'/>
                    </div>
                    <p>Define price at which energy discharged during events shall be compensated</p>

                    <div className='form-group row align-items-center'>
                        <div className='col-5'>
                            <div className='d-flex align-items-center'>
                                <Form.Check id='price-market'
                                            custom label='' type='radio'
                                            disabled={project.energyMarkets.length === 0}
                                            checked={model.energyPriceKind === 'market'}
                                            onChange={ev => ev.target.checked && onSelectMarket()}/>
                                <Dropdown>
                                    <Dropdown.Toggle className='pillButton' variant='outline-primary' id='dropdown-basic' disabled={model.energyPriceKind !== 'market'}>
                                        {model.energyMarket?.name ?? 'Select Energy Market'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {project.energyMarkets.map(x =>
                                            <Dropdown.Item key={x.id} onClick={() => {model.setEnergyMarket(x)}}>
                                                {getEnergyMarketKindLabel(x.kind)}
                                            </Dropdown.Item>)}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        {model.energyMarket &&
                            <div className='col-7'>
                                <span>{getEscalationKindLabel(model.energyMarket)} <a className='text-primary' href={'#' + model.energyMarket.id}>Energy Market Section</a></span>
                            </div>}
                    </div>

                    <div className='form-group row align-items-center'>
                        <div className='col-5'>
                            <Form.Check id='price-flat'
                                type='radio' label={`Flat rate (${currency}/MWh)`} custom
                                        checked={model.energyPriceKind === 'flat'}
                                        onChange={ev => ev.target.checked && onSelectFlat()}/>
                        </div>
                        <div className='col-3'>
                            <NumberInput disabled={model.energyPriceKind !== 'flat'}
                                value={model.energyPrice}
                                onChange={x => model.setEnergyPrice(x)} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                        </div>
                    </div>

                    {model.energyPriceKind === 'flat' &&
                        <MultiYearView model={model.energyEscalator} label='Energy Price Escalator (%/yr)'
                                       id={`energyMarketEnergyPriceEscalator-${model.id}`}/>}
                </div>
            </div>
        </>
    )
})


const getEscalationKindLabel = (energyMarket: EnergyMarket): string => {
    const v = energyMarket.priceEscalator
    if (!v.enabled) return 'No escalation defined in'
    switch (v.multiYearKind) {
        case MultiYearKind.linear:
            return `${energyMarket.priceEscalator.linearValue}% escalation, as defined in`
        case MultiYearKind.custom:
            return 'Custom escalation, as defined in'
    }
}


interface NewRaEventViewProps {
    onAdd: (date: Date, duration: number) => void
}

function defaultDate(year: number) { return new Date(year, 0, 1, 0, 0)}

const defaultDuration = 1

const NewRaEventView = ({onAdd}: NewRaEventViewProps) => {
    const project = useProjectStore().project!
    const useCommaAsDecimalPoint = project.useCommaAsDecimalPoint

    const [date, setDate] = useState(defaultDate(project.baseYear))
    const [duration, setDuration] = useState(defaultDuration)

    const onClick = () => {
        onAdd(date, duration)
    }

    return (<>
        <div className='form-group row align-items-center'>
            <label className='col-5'>Date</label>
            <div className='col-7'>
                <DatePicker format='dd MMM' className='w-100'
                            value={date}
                            onChange={ev => ev.target.value != null && setDate(ev.target.value)}/>
            </div>
        </div>
        <div className='form-group row align-items-center'>
            <label className='col-5'>Start Time</label>
            <div className='col-7'>
                <TimePicker format='HH:mm' className='w-100'
                            value={date}
                            onChange={ev => ev.target.value != null && setDate(ev.target.value)}/>
            </div>
        </div>
        <NumberField label='Event duration (hours)' value={duration} onChange={x => setDuration(x)} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
        <div className='form-group row align-items-center'>
            <div className='col-5'/>
            <div className='col-7'>
                <button className='btn btn-outline-primary pillButton' onClick={onClick}>Add Event</button>
            </div>
        </div>
    </>)
}

interface RaEventViewProps {
    model: RaEvent
    onDelete?: () => void
    intlCulture: string
}

const RaEventView = observer(({model, onDelete, intlCulture}: RaEventViewProps) => {

    const intl = new IntlService(intlCulture)

    const {date, duration} = model
    const label = `${intl.formatDate(date, 'MMM d, HH:mm')} — ${intl.formatNumber(duration, 'n1')} hr`
    return (
        <div className='d-flex flex-row align-items-center mt-1 p-1 rounded'>
            <ButtonDelete onClick={onDelete}/>
            <div className='px-1'>{label}</div>
        </div>
    )
})


interface MonthlyValuesViewProps {
    model: CapacityMarket
}

const MonthlyValuesView = observer(({ model }: MonthlyValuesViewProps) => {
    const [show, setShow] = useState(false)

    const onEdit = () => { setShow(true)}
    const onSave = () => { setShow(false)}

    return (
        <>
            <div className='d-flex col-3 ml-4'>
                <div className='col-12'>
                    <button className='btn btn-outline-primary pillButton' onClick={onEdit}><GoCalendar/></button>
                    <MonthlyValuesEditorView model={model} show={show} onClose={onSave}/>
                </div>
            </div>
        </>
    )
})

interface CapacityValuesViewProps {
    model: CapacityMarket
    useCommaAsDecimalPoint: boolean
}
const CapacityValuesView = observer(({ model, useCommaAsDecimalPoint }: CapacityValuesViewProps) => {
    return <><div className='row mt-2 ml-2'>
        <SensitivityVarView model={model.capacityPriceSensitivity} label='' units='Multiplier' isNumHide={true} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
    </div>
    </>
})

interface MonthlyValuesEditorViewProps {
    model: CapacityMarket
    show: boolean
    onClose: () => void
}

const MonthlyValuesEditorView = observer(({ model, show, onClose }: MonthlyValuesEditorViewProps) => {
    const project = useProjectStore().project!
    const currency = project.currencySymbol ?? "$"
    const useCommaAsDecimalPoint = project.useCommaAsDecimalPoint

    const onHide = () => { onClose() }

    return (
        <Modal onHide={onHide} show={show}>
            <Modal.Header closeButton>
                <Modal.Title>Monthly Values</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Month</th>
                            <th>Capacity Price ({model.priceUnit.replace("$", currency)})</th>
                        </tr>
                    </thead>
                    <tbody>
                        {model.price.values.map((x, index) => <tr key={x.id}>
                            <td>{x.month}</td>
                            <td className='position-relative p-0 m-1'>
                                <NumberInput value={x.value} onChange={x => model.price.setValue(index, x)} className='form-control-sm' useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-primary pillButton' onClick={onHide}>Close</button>
            </Modal.Footer>
        </Modal>
    )
})
