import {GoogleMap, Marker} from '@react-google-maps/api'
import {observer} from 'mobx-react-lite'
import {Location} from '../../model/Location'
import * as React from 'react'

interface LocationMapViewProps {
    location: Location
}

const style = {height: '100%'}

const LocationMapView = observer(({location}: LocationMapViewProps) => {
    const {pos} = location

    const handleDragEnd = async (ev: google.maps.MapMouseEvent) => {
        const pos = {lat: ev.latLng.lat(), lng: ev.latLng.lng()}
        await location.updatePos(pos)
    }

    const handleDblClick = async (ev: google.maps.MapMouseEvent) => {
        ev.stop()
        const pos = {lat: ev.latLng.lat(), lng: ev.latLng.lng()}
        await location.updatePos(pos)
    }

    const onLoadMap = (inst: google.maps.Map) => {
        const options: google.maps.MapOptions = {
            disableDefaultUI: true,
            zoomControl: true,
        }
        inst.setOptions(options)
    }

    return (
        <GoogleMap mapContainerStyle={style} center={pos} zoom={10}
                   onLoad={onLoadMap} onDblClick={handleDblClick}>
            <Marker position={pos} draggable={true} onDragEnd={handleDragEnd}/>
        </GoogleMap>
    )
})


export default LocationMapView
