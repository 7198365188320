import {action, computed, makeObservable, observable} from 'mobx'
import {v4} from 'uuid'

export class CostSensitivityItem {
    constructor(init: Partial<CostSensitivityItem> = {}) {
        Object.assign(this, init)
        makeObservable(this)
    }

    id: string = v4()

    @observable capital: number = 0
    @observable operating: number = 0
    @observable replacement: number = 0
    @observable augmentation: number = 0

    @action setCapital(x: number) { this.capital = x }
    @action setOperating(x: number) { this.operating = x }
    @action setReplacement(x: number) { this.replacement = x }
    @action setAugmentation(x: number) { this.augmentation = x }
}


export class SimpleCostTable {
    constructor(isWind: boolean = false) {
        makeObservable(this)
        if (isWind) {
            this.items = observable([
                new CostSensitivityItem({ capital: 1000, operating: 10, replacement: 0, augmentation: 0 })
            ])
        } else {
            this.items = observable([
                new CostSensitivityItem({ capital: 1000000, operating: 10000, replacement: 0, augmentation: 0 })
            ])
        }
    }

    id: string = v4()

    @observable items: CostSensitivityItem[] = observable([])
    @computed get base(): CostSensitivityItem { return this.items[0] }

    @action addNewItem() {
        this.items.push(new CostSensitivityItem())
    }

    @action removeItem(id: string) {
        const i = this.items.findIndex(x => x.id === id)
        this.items.splice(i, 1)

        // make sure there is at least 1 item
        if (this.items.length === 0)
            this.addNewItem()
    }
}

export enum CostTableType {
    simple,
    complex
}

export type CostUnit =
    'Cost' |
    'CostPerWattDc' |
    'PercentOfDirectCapital' |
    'CostPerYear' |
    'PercentOfDirectCapitalPerYear' |
    'CostPerMwDcYear' |
    'CostPerMwhAc' |
    'MonthsOfOpex' |
    'PercentOfReserveAccountsPerYear' |
    'CostPerMw' |
    'CostPerMwh' |
    'CostPerUnit' |
    'CostPerUnitYear' |
    'CostPerMwYear' |
    'CostPerMwhYear' |
    'CostPerMwhThroughput'

export class ComplexCostItem {
    constructor(init: Partial<ComplexCostItem> = {}) {
        Object.assign(this, init)
        makeObservable(this)
    }

    id: string = v4()

    @observable name: string = ''
    @observable unit: CostUnit = 'Cost'
    @observable value: number = 0

    @action setName(x: string) { this.name = x }
    @action setUnit(x: CostUnit) { this.unit = x }
    @action setValue(x: number) { this.value = x }
}


export class ComplexCostItems {
    constructor() { makeObservable(this) }

    readonly items: ComplexCostItem[] = observable([])

    @action addNewItem(isOperational: boolean = false) {
        let item = new ComplexCostItem()
        if (isOperational)
            item.unit = 'CostPerYear'
        this.items.push(item)
    }

    @action removeItem(id: string) {
        const i = this.items.findIndex(x => x.id === id)
        this.items.splice(i, 1)
    }
}


export class ComplexCostTable {
    constructor() { makeObservable(this) }

    @observable directCapital: ComplexCostItems = new ComplexCostItems()
    @observable indirectCapital: ComplexCostItems = new ComplexCostItems()
    @observable operating: ComplexCostItems = new ComplexCostItems()
    @observable replacement: ComplexCostItems = new ComplexCostItems()
}


export class CostTable {
    constructor(isWind: boolean = false) {
        makeObservable(this)
        this.simple = new SimpleCostTable(isWind)
    }

    type: string = ''
    @observable costTableType: CostTableType = CostTableType.simple
    @observable simple!: SimpleCostTable
    @observable complex: ComplexCostTable = new ComplexCostTable()

    @action setType(type: CostTableType) {
        this.costTableType = type
    }

    estimateSimulations(): number {
        switch (this.costTableType) {
            case CostTableType.simple: { return this.simple.items.length }
            case CostTableType.complex: { return 1 }
        }
    }
}
