import {RouteComponentProps} from '@reach/router'
import {GrDocumentMissing} from 'react-icons/all'
import * as React from 'react'

interface NoMatchProps extends RouteComponentProps {}

const NoMatch = (_: NoMatchProps) => {
    return (
        <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
            <GrDocumentMissing size={50} className='m-2'/>
            <h6>NOT FOUND</h6>
        </div>
    )
}

export default NoMatch
