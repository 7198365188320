import api, { DetailsResponse } from "../../services/api"
import { Result } from "../../shared/Result"
import { DimensionState, OstDimension, OstResult, ResultTable } from "./results"



export class ResultCache {
    constructor(public id: string) { }

    // cache
    sensitivity: ResultTable | null = null
    optimizationGroup: Map<number, ResultTable> = new Map<number, ResultTable>()
    optimizationAll: Map<number, ResultTable> = new Map<number, ResultTable>()
    details: Map<string, Result<DetailsResponse>> = new Map<string, Result<DetailsResponse>>()
    ostDimensions: OstDimension[] | null = null
    ostResultState: DimensionState = 'loading'
    ostResult: OstResult | null = null

    clear() {
        this.sensitivity = null
        this.optimizationGroup.clear()
        this.optimizationAll.clear()
        this.details.clear()
    }


    async getSensitivity(): Promise<ResultTable> {
        if (!this.sensitivity) {
            const rv = await api.getResultTable(this.id)
            this.sensitivity = rv.data
            if (!this.ostDimensions || this.ostDimensions.length === 0 || this.ostResultState === 'loading' || this.ostResultState === 'error') {
                const dim = await api.getOstDimensions(this.id)
                this.ostDimensions = dim.data
                if (this.ostDimensions.length > 1)
                    this.ostResultState = 'changed'
                else
                    this.ostResultState = 'unavailable'
            }
        }
        return this.sensitivity
    }

    async getOstResult(): Promise<OstResult | null> {
        if (!this.ostDimensions || this.ostDimensions.length === 0 || this.ostResultState == 'loading' || this.ostResultState == 'error') {
            const dim = await api.getOstDimensions(this.id)
            this.ostDimensions = dim.data
            if (this.ostDimensions.length > 1)
                this.ostResultState = 'changed'
            else
                this.ostResultState = 'error'
        }
        if (this.ostDimensions && this.ostResultState === 'changed') {
            let ost = await api.getOstResult(this.id, this.ostDimensions)
            this.ostResult = ost.data
        }
        //}
        return this.ostResult
    }


    setXAxis(index: number, key: selectedValue) {
        var tempDimensions = this.ostDimensions
        tempDimensions = []
        this.ostDimensions?.map(x => {
            x.isXAxis = false
            tempDimensions?.push(x)
        })
        tempDimensions[index].isXAxis = true
        this.ostDimensions = tempDimensions
    }
    setYAxis(index: number, key: selectedValue) {
        var tempDimensions = this.ostDimensions
        tempDimensions = []
        this.ostDimensions?.map(x => {
            x.isYAxis = false
            tempDimensions?.push(x)
        })
        tempDimensions[index].isYAxis = true
        this.ostDimensions = tempDimensions
    }
    setDimValue(index: number, key: number) {
        var tempDimensions = this.ostDimensions
        tempDimensions = []
        this.ostDimensions?.map(x => {
            x.isYAxis = false
            x.isXAxis = false
            tempDimensions?.push(x)
        })
        tempDimensions[index].selectedValue = key
        this.ostDimensions = tempDimensions
    }
    axisSelect(index: number, key: selectedValue) {
        if (key === 'xAxis')
            this.setXAxis(index, key)
        else if (key === 'yAxis')
            this.setYAxis(index, key)
        else
            this.setDimValue(index, key)
    }
    selectDimension(index: number, key: selectedValue): OstDimension[] | null {
        this.ostResultState = 'changed'
        //this.axisSelect(index, key)
        if (key === 'xAxis') {
            this.ostDimensions![index].isYAxis = false
            this.ostDimensions![index].isXAxis = true
        }
        else if (key === 'yAxis') {
            this.ostDimensions![index].isXAxis = false
            this.ostDimensions![index].isYAxis = true
        }
        else {
            this.ostDimensions![index].isXAxis = false
            this.ostDimensions![index].isYAxis = false
            this.ostDimensions![index].selectedValue = key
        }
        return this.ostDimensions
    }

    async getOptimization(index: number, kind: 'all' | 'group'): Promise<ResultTable> {
        switch (kind) {
            case 'all':
                if (!this.optimizationAll.has(index)) {
                    const rv = await api.getResultTable(this.id, index, kind)
                    this.optimizationAll.set(index, rv.data)
                }
                return this.optimizationAll.get(index)!
            case 'group':
                if (!this.optimizationGroup.has(index)) {
                    const rv = await api.getResultTable(this.id, index, kind)
                    this.optimizationGroup.set(index, rv.data)
                }
                return this.optimizationGroup.get(index)!
        }
    }

    async getBaseCaseOptimization(index: number, baseCaseIndex: number): Promise<ResultTable> {
        const rv = await api.getResultTable(this.id, index, 'all', baseCaseIndex)
        return rv.data
    }

    async getDetails(resultId: string): Promise<Result<DetailsResponse>> {
        if (!this.details.has(resultId)) {
            const rv = await api.getDetails(this.id, resultId)
            this.details.set(resultId, rv)
        }
        return this.details.get(resultId)!
    }

    async removeDetails(resultId: string) {
        if (this.details.has(resultId)) {
            this.details.delete(resultId)
        }
    }
}

type selectedValue = 'xAxis' | 'yAxis' | number
