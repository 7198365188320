import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { navigate } from '@reach/router'
import envVariables from 'env-variables.json'
import { useInternationalization } from 'Kendo-Intl-5'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { ResultCache } from './ResultCache'
import api, { StatusKind, UserEmailNotification } from '../../services/api'
import { defaultDetails, ResultDetails, ResultDetailsModel } from './results-details'
import { delay } from '../../utils'

export interface ResultTable {
    header: ResultHeader
    items: ResultItem[]
}

interface SuperimposedValue {
    id: string
    name: string
    format: string
    value: string
}

interface SimulationPoint {
    superimposedValues: SuperimposedValue[]
    xIndex: number
    yIndex: number
}
export interface OstResult {
    planes: OstPlane[]
    blendedPlane: number[]
    ostLegends: OstLegend[]
    superimposedValues: SuperimposedValue[]
    simulationPoints: SimulationPoint[]
}

export interface OstPlane {
    name: string
    color: string
    data: number[][]
}

export interface OstLegend {
    name: string
    color: string
}

export interface OstDimension {
    ids: string[]
    displayNames: string[]
    components: string[]
    units: string[]
    points: number[][]
    steps: number[]
    isXAxis: boolean
    isYAxis: boolean
    selectedValue: number
}

export interface ResultHeader {
    sensitivity: SensitivityCol[]
    optimization: OptimizationCol[]
    revenue: RevenueCol[]
}

export interface SensitivityCol {
    name: string
    units: string
}

export type OptimizationColKind = 'solar' | 'storage' | 'wind' | 'converter'

export interface OptimizationCol {
    name: string
    units: string[]
    discrete: boolean
    kind: OptimizationColKind
}

export interface RevenueCol {
    name: string
}


export interface ResultItem {
    id: string
    baseCaseId: string

    // sensitivity group
    sensitivity: number[]

    // optimization group
    optimization: ((number | null)[])[]

    // revenue group
    revenue: number[]
    totalRevenue: number

    // cost group
    capex: number
    opex: number

    // economics group
    npv: number
    irr: number | null
    payback: number | null
    lcoe: number | null
}

export const formatOptimization = (xs: (number | null)[]) => xs
    .filter(x => x !== null)
    .map(x => useInternationalization().formatNumber(x!, 'n'))
    .join(' / ')

const defaultTable: ResultTable = {
    header: {
        sensitivity: [],
        optimization: [],
        revenue: [],
    },
    items: [],
}

export const defaultOstDimension: OstDimension = {
    ids: [],
    displayNames: [],
    components: [],
    units: [],
    points: [],
    steps: [],
    isXAxis: false,
    isYAxis: false,
    selectedValue: 0,
}

export const defaultOstResult: OstResult = {
    planes: [],
    blendedPlane: [],
    ostLegends: [],
    superimposedValues: [],
    simulationPoints: []
}

export type ResultState = 'loading' | 'error' | 'ready' | 'cancelled' | 'blank' | 'initialLoad' /*'loaded' |*/

export type OptimizationTableKind = 'all' | 'group'

export type DetailsKind = 'summary' | 'cashflow' | 'timeseries'

export type ResultTabKind = 'sensitivity' | 'graph' | 'optimization' | 'details'

export type DimensionState = 'loading' | 'unavailable' | 'ready' | 'changed' | 'error'

export type ResultSelection =
    { kind: 'none' } |
    { kind: 'sensitivity', sensitivity: number, tableKind: OptimizationTableKind } |
    { kind: 'optimization', sensitivity: number, tableKind: OptimizationTableKind, optimization: number/*, baseCase?: number*/ }

const formatSelection = (s: ResultSelection): string => {
    switch (s.kind) {
        case 'none':
            return '-'
        case 'sensitivity':
            return `${s.sensitivity}+${s.tableKind}`
        case 'optimization':
            return `${s.sensitivity}+${s.tableKind}+${s.optimization}`/*+${s.baseCase}*/
    }
}

const parseSelection = (query: string): ResultSelection => {
    const params = (query ?? '').split('+')
    if (params.length === 3)
        return { kind: 'optimization', sensitivity: Number(params[0]), tableKind: params[1] as OptimizationTableKind, optimization: Number(params[2]) }
    if (params.length === 2)
        return { kind: 'sensitivity', sensitivity: Number(params[0]), tableKind: params[1] as OptimizationTableKind }
    return { kind: 'none' }
}


export class ResultModel {
    constructor(public id: string) { makeObservable(this) }

    cache = new ResultCache(this.id)

    @observable state: ResultState = 'initialLoad'
    @observable detailState: ResultState = 'loading'
    @observable progress: number = 0
    @observable detailProgress: number = 0;
    @observable selection: ResultSelection = { kind: 'none' }
    @observable estimatedRemainingTime: string = "00:00:00"

    @action
    async calculate(recalculate: boolean): Promise<StatusKind> {
        try {
            this.cache.clear()
            this.selection = { kind: 'none' }

            this.state = 'loading'
            this.progress = 0
            const rv = await api.calculate(this.id, recalculate)
            runInAction(() => {
                if (rv.status !== 'ready') {
                    this.progress = rv.progress ?? 0
                    return rv.status
                }
                this.state = 'ready'
                this.progress = 100
            })
            return rv.status
        }
        catch (error) {
            return 'error';
        }
    }

    @action
    async checkStatus() {
        if (this.state === 'ready') return
        const rv = await api.getStatus(this.id)
        runInAction(() => {
            this.estimatedRemainingTime = rv.estimatedRemainingTime
            if (rv.status === 'cancelled') {
                this.state = 'cancelled'
            }
            if (rv.status !== 'ready') {
                this.progress = Math.max(this.progress, rv.progress ?? 0)
                return
            }
            this.state = 'ready'
            this.progress = 100
        })
    }

    @action
    async checkInitialStatus() {
        //this.state = 'blank'
        const rv = await api.getStatus(this.id)
        if (rv.status === 'ready')
            this.state = 'ready'
        else if (rv.status === 'calculating')
            this.state = 'loading'
        else if (rv.status === 'changed')
            try {
                await this.fetchDataForSelection()
                this.state = 'ready'
            }
            catch {
                this.state = 'blank'
            }
    }

    @action
    async cancelCalculation(): Promise<boolean> {
        if (this.state === 'ready') return false
        const rv = await api.cancelCalculation(this.id)
        //if (rv == true)
        //this.state = 'cancelled'
        return rv
    }

    @action
    async cancelDetailedCalculation(): Promise<boolean> {
        if (this.detailsState === 'ready') return false
        const rv = await api.cancelDetailsCalculation(this.id, this.resultId)
        //if (rv == true)
        //this.detailState = 'cancelled'
        return rv
    }

    // selection
    @action
    async selectSensitivity(index: number) {
        this.selection = {
            kind: 'sensitivity',
            sensitivity: index,
            tableKind: 'all',
        }
        await this.navigateSelection('optimization')
    }

    @action
    async selectOptimizationKind(kind: OptimizationTableKind) {
        if (this.selection.kind === 'none') return
        this.selection = {
            kind: 'sensitivity',
            sensitivity: this.selection.sensitivity,
            tableKind: kind,
        }
        await this.navigateSelection('optimization')
    }

    @action
    async selectOptimization(index: number) {
        if (this.selection.kind === 'none') return
        this.selection = {
            kind: 'optimization',
            sensitivity: this.selection.sensitivity,
            tableKind: this.selection.tableKind,
            optimization: index,
            //baseCase: this.selection.baseCase
        }
        await this.navigateSelection('details')

    }

    @action
    async navigateSelection(view: ResultTabKind) {
        //if (view == 'graph')
        //await navigate(`/project/${this.id}/results/${view}`)
        this.detailProgress = 0
        if (view === 'graph') {
            this.selection = { kind: 'none' }
        }
        const selection = formatSelection(this.selection)

        await navigate(`/project/${this.id}/results/${view}/${selection}`)

        const appInsights = new ApplicationInsights({
            config: {
                connectionString: envVariables[process.env.NODE_ENV].appInsightsConnString
            }
        });
        appInsights.loadAppInsights();
        appInsights.trackPageView();
    }

    @action
    async select(query: string) {
        const selection = parseSelection(query)
        this.selection = selection
        await this.fetchDataForSelection()
    }

    @action
    async fetchDataForSelection() {
        await this.fetchSensitivityTable()
        if (this.selection.kind === 'sensitivity' || this.selection.kind === 'optimization')
            await this.fetchOptimizationTable(this.selection.sensitivity, this.selection.tableKind)
        if (this.selection.kind === 'optimization')
            await this.fetchDetails(this.selection.optimization/*, this.selection.baseCase*/)

        runInAction(() => {
            // if there is single sensitivity case -> select it
            if (this.selection.kind === 'none') {
                //this.state = 'loaded'
                if (this.sensitivityTable.items.length === 1)
                    this.selectSensitivity(0)
            }
        })
    }


    // sensitivity tables
    @observable sensitivityTable: ResultTable = defaultTable
    @observable sensitivityTableState: ResultState = 'loading'
    @observable sensitivityGraphState: DimensionState = 'loading'
    @observable ostDimension: OstDimension[] | null = [defaultOstDimension]
    @observable ostResult: OstResult | null = defaultOstResult

    @action
    async fetchSensitivityTable() {
        this.sensitivityTableState = 'loading'
        const t = await this.cache.getSensitivity()
        this.ostDimension = this.cache.ostDimensions
        runInAction(() => {
            this.sensitivityTable = t
            this.sensitivityTableState = 'ready'
        })
    }

    @action
    async fetchOstResult() {
        const rv = await this.cache.getOstResult(/*this.ostDimension*/)
        if (rv && rv.blendedPlane && rv.planes.length > 0) {
            this.ostResult = rv
            this.cache.ostResultState = 'ready'
            this.sensitivityGraphState = 'ready'
        }
        else
            this.cache.ostResultState = 'error'
    }

    @action selectDimension(index: number, key: selectedValue) {
        const rv = this.cache.selectDimension(index, key)
        this.ostDimension = rv
        return this.ostDimension
    }

    @computed get selectedSensitivityCaseItem(): ResultItem | null {
        return this.selection.kind === 'sensitivity' || this.selection.kind === 'optimization' ?
            this.sensitivityTable.items[this.selection.sensitivity] : null
    }


    // optimization table
    @observable optimizationTable: ResultTable = defaultTable
    @observable optimizationTableState: ResultState = 'loading'

    @observable baseCaseOptimizationTable: ResultTable = defaultTable
    @observable compareEconomicsOptimizationTable: ResultTable = defaultTable
    @observable compareEconomicsOptimizationTableState: ResultState = 'blank'
    @observable compareEconomicsBaseCaseOptimizationTable: ResultTable = defaultTable
    @observable baseCaseOptimizationState: ResultState = 'blank'
    @observable baseCaseOptimizationTableState: ResultState = 'blank'
    @observable baseCaseOptimizationTableKind: OptimizationTableKind = 'all'
    @observable useZeroCashFlow: boolean = true

    @action
    async fetchOptimizationTable(index: number, kind: OptimizationTableKind) {
        this.optimizationTableState = 'loading'
        const t = await this.cache.getOptimization(index, kind)
        runInAction(() => {
            this.clearBaseCaseSelection()
            this.optimizationTable = t
            this.optimizationTableState = 'ready'
            //    this.state = 'loaded'
        })
    }
    @action
    async fetchSelectBaseCaseOptimizationTable(index: number, kind: OptimizationTableKind) {
        this.baseCaseOptimizationTableState = 'loading'
        const t = await this.cache.getOptimization(index, kind)
        this.baseCaseOptimizationTable = t
        this.baseCaseOptimizationTableState = 'ready'
    }
    @action clearBaseCaseSelection = () => {
        this.baseCaseState = 'blank'
        this.baseCaseOptimizationTableState = 'blank'
        this.baseCaseOptimizationState = 'blank'
    }
    @action
    async fetchBaseCaseOptimizationTable(index: number) {
        const t = await this.cache.getBaseCaseOptimization(index, this.baseCaseIndex)
        this.compareEconomicsBaseCaseOptimizationTable = t
    }
    @action setUseZeroCashFlow(x: boolean) { this.useZeroCashFlow = x }

    @computed get selectedOptimizationCaseItem(): ResultItem | null {
        return this.selection.kind === 'optimization' ?
            this.optimizationTable.items[this.selection.optimization] : null
    }


    // details
    @observable details: ResultDetailsModel = new ResultDetailsModel(this.id, '', defaultDetails)
    @observable detailsState: ResultState = 'loading'
    @observable detailsError: Error | null = null

    @observable resultId: string = ''
    @action
    async fetchDetails(index?: number) {
        this.detailsState = 'loading'
        if (index == undefined) {
            this.cache.removeDetails(this.resultId)
            this.detailsError = null
        }
        else {
            this.resultId = this.optimizationTable.items[index].id
        }

        await this.calculateDetails(this.resultId)
        if (this.detailState !== 'cancelled') {
            const t = await this.cache.getDetails(this.resultId)
            runInAction(() => {
                if (t.ok) {
                    this.details = new ResultDetailsModel(this.id, this.resultId, this.convertResultKwItemsToMW(t.value.data))
                    this.detailsState = 'ready'
                    this.detailsError = null
                } else {
                    this.details = new ResultDetailsModel(this.id, '', defaultDetails)
                    this.detailsState = 'error'
                    this.detailsError = t.error
                }
            })
        }
    }

    @action
    async fetchBaseCaseDetails() {
        //this.baseCaseId = this.optimizationTable.items[index].id

        await this.calculateBaseCaseDetails(this.baseCaseId)
        if (this.baseCaseState !== 'cancelled') {
            const t = await this.cache.getDetails(this.baseCaseId)
            runInAction(() => {
                if (t.ok) {
                    this.baseCaseDetails = new ResultDetailsModel(this.id, this.resultId, t.value.data)
                    this.baseCaseDetailsState = 'ready'
                    this.baseCaseError = null
                } else {
                    this.baseCaseDetails = new ResultDetailsModel(this.id, '', defaultDetails)
                    this.baseCaseDetailsState = 'error'
                    this.baseCaseError = t.error
                }
            })
        }
    }

    @action
    async calculateDetails(resultId: string) {
        this.detailState = 'loading'
        let s = await api.getStatusDetails(this.id, resultId)
        if (s.status === 'ready') {
            this.detailState = 'ready'
            return
        }
        runInAction(() => {
            this.estimatedRemainingTime = s.estimatedRemainingTime
            if (s.status !== 'ready') {
                this.detailProgress = 0
                return
            }
            this.detailState = 'ready'
            this.detailProgress = 100
        })
        await api.calculateDetails(this.id, resultId)

        let it = 0
        const MAX = 1000
        while (it < MAX) {
            s = await api.getStatusDetails(this.id, resultId)
            if (s.status === 'cancelled') {
                this.detailState = 'cancelled'
                this.detailsError = null
                break
            }
            this.detailProgress = Math.max(this.detailProgress, s.progress ?? 0)
            this.estimatedRemainingTime = s.estimatedRemainingTime
            await delay(5 * 1000)
            if (s.status === 'ready') break
        }
        runInAction(() => {
            this.estimatedRemainingTime = s.estimatedRemainingTime
            if (s.status !== 'ready') {
                this.detailProgress = Math.max(this.detailProgress, s.progress ?? 0)
                return
            }
            this.detailState = 'ready'
            this.detailProgress = 100
        })
    }

    @action
    async calculateBaseCaseDetails(resultId: string) {
        this.baseCaseDetailsState = 'loading'
        let s = await api.getStatusDetails(this.id, resultId)
        if (s.status === 'ready') {
            this.baseCaseDetailsState = 'ready'
            return
        }
        runInAction(() => {
            this.baseCaseProgress = s.progress ?? 0
            this.estimatedBaseCaseRemainingTime = s.estimatedRemainingTime
            if (s.status !== 'ready') return
            this.baseCaseDetailsState = 'ready'
        })
        await api.calculateDetails(this.id, resultId)

        let it = 0
        const MAX = 1000
        while (it < MAX) {
            s = await api.getStatusDetails(this.id, resultId)
            if (s.status == 'cancelled') {
                this.baseCaseDetailsState = 'cancelled'
                this.baseCaseError = null
                break
            }
            this.baseCaseProgress = s.progress ?? 0
            this.estimatedBaseCaseRemainingTime = s.estimatedRemainingTime
            await delay(5 * 1000)
            if (s.status === 'ready') break
        }
        runInAction(() => {
            this.baseCaseProgress = s.progress ?? 0
            this.estimatedBaseCaseRemainingTime = s.estimatedRemainingTime
            if (s.status !== 'ready') return
            this.baseCaseDetailsState = 'ready'
        })
    }

    // infeasible
    @computed get infeasible(): boolean {
        switch (this.selection.kind) {
            case 'none':
                return this.sensitivityTableState === 'ready' && this.sensitivityTable.items.length === 0
            case 'sensitivity':
                return this.optimizationTableState === 'ready' && this.optimizationTable.items.length === 0
            case 'optimization':
                return false // unreachable
            default:
                return true
        }
    }

    @action async setEmailNotification(userEmailNotification: UserEmailNotification): Promise<boolean> {
        let r = await api.setUserEmailNotification(this.id, userEmailNotification)
        return r
    }
    @action async deleteEmailNotification(): Promise<boolean> {
        let r = await api.deleteUserEmailNotification(this.id)
        return r
    }

    @observable baseCaseState: ResultState = 'blank'
    @observable baseCaseId: string = ''
    @observable baseCaseIndex: number = -1
    @observable proposedCaseId: string = ''
    @observable baseCaseDetails: ResultDetailsModel = new ResultDetailsModel(this.id, this.baseCaseId, defaultDetails)
    @observable baseCaseDetailsState: ResultState = 'blank'
    @observable baseCaseError: Error | null = null
    @action setBaseCaseId(x: string) { this.baseCaseId = x }
    @action setBaseCaseIndex(x: number) { this.baseCaseIndex = x }
    @action setBaseCaseDetailsState(x: ResultState) { this.baseCaseDetailsState = x }
    @action setBaseCaseDetailsError(x: Error | null) { this.baseCaseError = x }
    @observable baseCaseProgress: number = 0;
    @observable estimatedBaseCaseRemainingTime: string = "00:00:00"

    private convertResultKwItemsToMW(resultDetail: ResultDetails): ResultDetails {
        if (resultDetail?.financial?.lcoe) {
            resultDetail.financial.lcoe *= 1000;
        }

        if (resultDetail?.price?.capacityPrice) {
            resultDetail.price.capacityPrice *= 1000;
        }

        if (resultDetail?.performance?.cycleCost) {
            if (resultDetail.performance.cycleCost.average) {
                resultDetail.performance.cycleCost.average /= 1000;
            }
            if (resultDetail.performance.cycleCost.first) {
                resultDetail.performance.cycleCost.first /= 1000;
            }
        }

        resultDetail.storage?.items?.forEach(item => {
            if (item.augmentationCost) {
                item.augmentationCost /= 1000;
            }
        });

        if (resultDetail?.storage?.levelizedCostOfStorage) {
            resultDetail.storage.levelizedCostOfStorage *= 1000;
        }


        return resultDetail;
    }
}
export type selectedValue = 'xAxis' | 'yAxis' | number

