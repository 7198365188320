import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Wind } from '../../../model/Wind'
import NumberField from '../../../../helper/NumberField'

interface LossesTableViewProps {
    model: Wind
    useCommaAsDecimalPoint: boolean
}
const LossesTableView = observer(({ model, useCommaAsDecimalPoint }: LossesTableViewProps) => {
    return (<>
        <div className='pb-3 row'>
            <div className='col-6'>
                <NumberField useCommaAsDecimalPoint={useCommaAsDecimalPoint} label="Availability Losses (%)"
                    value={model.availabilityLosses}
                    onChange={x => model.setAvailabilityLosses(x)} />

                <NumberField useCommaAsDecimalPoint={useCommaAsDecimalPoint} label="Turbine Performance Losses (%)"
                    value={model.performanceLosses}
                    onChange={x => model.setPerformanceLosses(x)} />

                <NumberField useCommaAsDecimalPoint={useCommaAsDecimalPoint} label="Environmental Losses (%)"
                    value={model.environmentalLosses}
                    onChange={x => model.setEnvironmentalLosses(x)} />
                <NumberField useCommaAsDecimalPoint={useCommaAsDecimalPoint} label="Other Losses (%)"
                    value={model.otherLosses}
                    onChange={x => model.setOtherLosses(x)} />
            </div>
            <div className='col-6'>
                <NumberField useCommaAsDecimalPoint={useCommaAsDecimalPoint} label="Wake Effects Losses (%)"
                    value={model.wakeEffectLosses}
                    onChange={x => model.setWakeEffectLosses(x)} />

                <NumberField useCommaAsDecimalPoint={useCommaAsDecimalPoint} label="Electrical Losses (%)"
                    value={model.electricalLosses}
                    onChange={x => model.setElectricalLosses(x)} />

                <NumberField useCommaAsDecimalPoint={useCommaAsDecimalPoint} label="Curtailment Losses (%)"
                    value={model.curtailmentLosses}
                    onChange={x => model.setCurtailmentLosses(x)} />
            </div>
            <div className='d-flex w-50  justify-content-between mb-2 pl-4'>
                <h6>Overall Loss Factor (%)</h6>
                <div>{model.totalLosses.toFixed(2)}</div>
            </div>
        </div>

    </>)
})

export default LossesTableView
