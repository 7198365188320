import {Autocomplete} from '@react-google-maps/api'
import {useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Location} from '../../model/Location'
import * as React from 'react'

interface LocationAddressInputProps {
    location: Location
    updateCountry: () => void
}

const LocationAddressInput = observer(({location, updateCountry}: LocationAddressInputProps) => {
    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>()
    const onLoadAutocomplete = (inst: google.maps.places.Autocomplete) => setAutocomplete(inst)

    const [address, setAddress] = useState(location.address)
    useEffect(() => {
        setAddress(location.address)
    }, [location.address])

    const handlePlaceChanged = () => {
        if (!autocomplete) { return }

        const place = autocomplete.getPlace()
        if (place.geometry) {
            const latLng = place.geometry.location
            location.setPos({lat: latLng.lat(), lng: latLng.lng()})
        }
        if (place.formatted_address) {
            location.setAddress(place.formatted_address!)
        }
        updateCountry()
    }

    return (
        <Autocomplete onLoad={onLoadAutocomplete} onPlaceChanged={handlePlaceChanged}>
            <input className='form-control' placeholder='Address'
                   value={address} onChange={ev => setAddress(ev.target.value)}/>
        </Autocomplete>
    )
})

export default LocationAddressInput
