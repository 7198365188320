import {action, makeObservable, observable} from 'mobx'
import {v4} from 'uuid'
import { TimeSeries } from './TimeSeries'


export type TimeSeriesState = 'blank' | 'ready'


export class MultiYearTimeSeries {
    constructor() { makeObservable(this) }
    id: string = v4()

    @observable price: TimeSeries = new TimeSeries()
    @observable timeSeriesFileBaseName: string = ""
    @observable projectYearsValid: boolean[] = []
    @observable priceAverage: number = 0 // ($/MWh)
    @observable index: number = 0

    @action setData(data: number[], year: number, name: string, projectYearsValid: boolean[], index?: number) {
        this.timeSeriesFileBaseName = name
        this.price.setData(data, year)
        this.projectYearsValid = projectYearsValid
        const average = this.price.calculateAverage()
        this.priceAverage = average
        if (index !== undefined)
            this.index = index
    }

    calculateAverage(): number {
        const sum = this.price.data.reduce((acc, x) => acc + x)
        const n = this.price.data.length === 0 ? 1 : this.price.data.length
        return sum / n
    }
}

export interface MultiYearImportStatus {
    success: boolean
    message: string
}
