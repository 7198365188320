import {action, computed, makeObservable, observable} from 'mobx'

export enum DefermentUnit {
    day = 'Day',
    month = 'Month',
    year = 'Year',
}

export class Deferment {
    constructor() {
        makeObservable(this)
    }
    @observable period: number = 1
    @observable periodInYears: number = 0
    @observable periodUnit: number = 0
    @observable isExactDate: boolean = false
    @observable startDate: Date = new Date()

    @action setPeriod(x: number, firstYear: number) {
        this.period = x
        this.updatePeriodInYears(x, firstYear)
    }
    @action updatePeriodInYears(x: number, firstYear: number) {
        if (this.periodUnit === 0) {
            let projectDefermentDate = new Date(firstYear, 0, 1)
            projectDefermentDate.setDate(projectDefermentDate.getDate() + this.period);
            let defermentYear = projectDefermentDate.getFullYear()
            this.periodInYears = defermentYear - firstYear
        }
        else if (this.periodUnit === 1) {
            let projectDefermentDate = new Date(firstYear + Math.floor(this.period / 12), (this.period % 12), 1)
            let defermentYear = projectDefermentDate.getFullYear()
            this.periodInYears = defermentYear - firstYear
        }
        else
            this.periodInYears = x
    }
    @action setPeriodUnit(x: number, firstYear: number) {
        this.periodUnit = x
        this.updatePeriodInYears(this.period, firstYear)
    }
    @action setIsExactDate(x: boolean) { this.isExactDate = x }
    @action setStartDate(x: Date) { this.startDate = x }
}
