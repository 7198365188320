import { observer } from "mobx-react-lite"
import { Form, Row } from "react-bootstrap";
import ContextHelp from "../helper/ContextHelp";
import NumberInput from "../helper/NumberInput";
import StringInput from "../helper/StringInput";
import { Validation } from "../helper/validation";
import { UserStorageClass } from "./UserStorage"
import * as React from 'react'

interface StorageGeneralTabProps {
    model: UserStorageClass
    useCommaAsDecimalPoint: boolean
}

const StorageLibraryGeneralTab = observer(({ model, useCommaAsDecimalPoint }: StorageGeneralTabProps) => {
    return (
        <>
            <Row className="mt-2 d-flex">
                <label className="col-1 mt-2">Name</label>
                <StringInput value={model.name}
                    onChange={model.setUserStorageName}
                    className="mt-1 col-2" />
                <Form.Check className='col-3 ml-3 mt-2' custom label='Requires One Minute Timestep' id='requiresOneMinuteTimestep'
                    checked={model.requiresOneMinuteTimestep}
                    onChange={ev => { model.setUserStorageRequiresOneMinuteTimestep(ev.target.checked); }} />
                <ContextHelp helpId='USERLIBRARYGENERAL' />
            </Row>
            <Row className="mt-1 d-flex">
                <label className="col-1 mt-2">Abbreviation</label>
                <StringInput value={model.abbreviation}
                    onChange={model.setUserStorageAbbreviation}
                    className="mt-1 col-2" />
                <Form.Check className='col-2 mt-2 ml-3 mr-0' custom label='Max. Charge Rate (A/Ah):' id='maxChargeRate'
                    checked={model.applyMaxChargeRate}
                    onChange={ev => {
                        model.setUserStorageUseMaxChargeRate(ev.target.checked);}} />
                <NumberInput value={model.maxChargeRate}
                    onChange={model.setUserStorageMaxChargeRate}
                    className="mt-1 col-1"
                    disabled={!model.applyMaxChargeRate}
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint}
                    validator={Validation.min(0)}                />
            </Row>
            <Row className="mt-1 d-flex">
                <label className="col-1 mt-2">Manufacturer</label>
                <StringInput value={model.manufacturer}
                    onChange={model.setUserStorageManufacturer}
                    className="mt-1 col-2" />
                <Form.Check className='col-2 mt-2 ml-3 mr-0' custom label='Max. Charge Current (A):' id='maxChargeCurrent'
                    checked={model.applyMaxChargeCurrent}
                    onChange={ev => { model.setUserStorageUseMaxChargeCurrent(ev.target.checked); }} />
                <NumberInput value={model.maxChargeCurrent}
                    onChange={model.setUserStorageMaxChargeCurrent}
                    disabled={!model.applyMaxChargeCurrent}
                    className="mt-1 col-1"
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0)} />
            </Row>
            <Row className="mt-1 d-flex">
                <label className="col-1 mt-2">Chemistry</label>
                <StringInput value={model.chemistry}
                    onChange={model.setUserStorageChemistry}
                    className="mt-1 col-2" />
                <Form.Check className='col-2 mt-2 ml-3 mr-0' custom label='Max. Discharge Current (A):' id='maxDischargeCurrent'
                    checked={model.applyMaxDischargeCurrent}
                    onChange={ev => { model.setUserStorageUseMaxDischargeCurrent(ev.target.checked);}} />
                <NumberInput value={model.maxDischargeCurrent}
                    onChange={model.setUserStorageMaxDischargeCurrent}
                    className="mt-1 col-1"
                    disabled={!model.applyMaxDischargeCurrent}
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0)} />
            </Row>
            <Row className="mt-1 d-flex">
                <label className="col-1 mt-2">Notes</label>
                <StringInput value={model.notes}
                    onChange={model.setUserStorageNotes}
                    className="mt-1 col-5"
                //textArea={true}
                />

            </Row>

            <Row className="d-flex flex-column mt-2">Calculate End of Life by:</Row>
            <Row className="d-flex flex-column"><Form.Check custom label='Sum of Calendar and Cycling Degradation' id='AdditiveEndOfLifeCalculation' type='radio'
                checked={model.additiveEndOfLifeCalculation}
                onChange={ev => { model.setUserStorageAdditiveEndOfLifeCalculation(ev.target.checked ? true : false) }} /></Row>
            <Row className="d-flex flex-column"><Form.Check custom label='Calendar or Cycling Degradation, Whichever is Greater' id='calendarOrCyclingDegradation' type='radio'
                checked={!model.additiveEndOfLifeCalculation}
                onChange={ev => { model.setUserStorageAdditiveEndOfLifeCalculation(ev.target.checked ? false : true) }} /></Row>

            <Row className="d-flex flex-column mt-2">Cycling Degradation Uses Battery Depth of Discharge Based on:</Row>
            <Row className="d-flex flex-column"><Form.Check custom label='Nominal Battery Capacity' id='cyclingDegradationUsesBatteryDepth' type='radio'
                checked={model.degradationUsesNominalSoc}
                onChange={ev => { model.setUserStorageDegradationUsesNominalSoc(ev.target.checked ? true : false) }} /></Row>
            <Row className="d-flex flex-column"><Form.Check custom label='Degraded Battery Capacity' id='degradedBatteryCapacity' type='radio'
                checked={!model.degradationUsesNominalSoc}
                onChange={ev => { model.setUserStorageDegradationUsesNominalSoc(ev.target.checked ? false : true) }} /></Row>
        </>
        )
})

export default StorageLibraryGeneralTab
